import { BaseRecord } from "./BaseRecord";
import { Business } from "./business";
import { Service } from "./service";


export class BusinessServiceMapping extends BaseRecord<number> {

    public serviceId: number;
    public service: Service | null;
    public businessId: number | null | undefined;
    public business: Business | null;


    constructor() {
        super();
        this.id = 0;
        this.businessId = 0
        this.serviceId = 0;
    }
}
