import {Injectable} from "@angular/core";
import { JwtPayload } from "../../../models/other/JwtPayload";

@Injectable({
    providedIn: "root"
})
export class JWTTokenService {
    private parsedToken: Record<string, string>;

    // eslint-disable-next-line @typescript-eslint/no-empty-function
    constructor() {
    }
 

    public getUserId(): string | null {
        return this.parsedToken ? this.parsedToken["userId"] : null;
    }

    public getEmail(): string | null {
        return this.parsedToken ? this.parsedToken["email"] : null;
    }

    public getExpiryTime(): number | null {
        //return this.parsedToken ? this.parsedToken["exp"] : null;
        return this.parsedToken && this.parsedToken["exp"] !== null
        ? Number(this.parsedToken["exp"]) 
        : null;
    }

    private parseToken(token: string): JwtPayload | undefined {
        if (token) {
            const base64Url = token.split(".")[1];
            const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
            const jsonPayload = decodeURIComponent(atob(base64).split("").map(c => {
                return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
            }).join(""));
    
            return JSON.parse(jsonPayload);
        }
        return undefined;
    }
    
    public getTokenData(token: string): JwtPayload | undefined {
        return this.parseToken(token);
    }

    public isTokenExpired(): boolean {
        const expiryTime: number | null = this.getExpiryTime();
        if (expiryTime) {
            return ((1000 * expiryTime) - (new Date()).getTime()) < 500;
        } else {
            return false;
        }
    }
}
