// export class FilterDataType {
//     public static Int = "i";      
//     public static Long = "l";     
//     public static String = "s";   
//     public static DateTime = "dt";
//     public static Bool = "b";     
//     public static Byte = "bt";    
//     public static Short = "sh";   
//     public static Decimal = "d";  
//     public static Double = "db";  
//     public static Float = "f";    
//     public static Char = "c";     
//     public static Object = "o";   
//     public static Guid = "g";   
//     public static Array = "arr";
// }

export enum FilterDataType {
    Int = "i",
    Long = "l",
    Decimal = "d",
    Double = "db",
    Float = "f",
    DateTime = "dt",
    Bool = "b",
    Byte = "bt",
    Short = "sh",
    String = "s",
    Char = "c",
    Object = "o",
    Guid = "g",
    Array = "arr",
}
