<div class="popup-dialog">
    <div class="trezo-card w-100 border-radius d-block bg-white border-0 shadow-none">
        <div class="trezo-card-header d-flex align-items-center justify-content-between">
            <div class="trezo-card-title">
                <h5 class="mb-0">
                    {{titleText}}
                </h5>
            </div>
            <div class="trezo-card-subtitle">
                <button (click)="toggleClass()" class="p-0 border-0 lh-1 bg-transparent transition">
                    <i class="ri-close-fill"></i>
                </button>
            </div>
        </div>
        <form [formGroup]="frmGroup" (ngSubmit)="onSave()">
            <div class="trezo-card-content">
                <div class="row">

                    <div class=" col-md-6">
                        <div class="form-group">
                            <label class="main-label d-block fw-medium text-black">
                                Business Type <span class="text-danger">*</span>
                            </label>
                            <select class="form-select" formControlName="businessTypeControl"
                                [compareWith]="businessTypeComparer"
                                [ngClass]="{'is-invalid': isSubmitted && f['businessTypeControl']!.hasError('required')}">
                                <option [ngValue]="null" disabled>Please Select</option>
                                <option *ngFor="let c of businessTypes" [ngValue]="c">
                                    {{c.name}}
                                </option>
                            </select>
                            <div class="form-text text-danger"
                                *ngIf="isSubmitted && f['businessTypeControl']!.hasError('required') ">
                                Business Type is required.
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="form-group">
                            <label class="main-label d-block fw-medium text-black">
                                Name <span class="text-danger">*</span>
                            </label>
                            <input type="text" class="form-control" placeholder="Name" formControlName="nameControl"
                                maxlength="200">
                            <div class="form-text text-danger"
                                *ngIf="isSubmitted && f['nameControl']!.hasError('required')">
                                Name is required.
                            </div>
                            <div class="form-text text-danger"
                                *ngIf="isSubmitted && f['nameControl']!.hasError('onlyWhitespace')">
                                Name can not be only whitespace.
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="form-group">
                            <label class="main-label d-block fw-medium text-black">
                                Registration Number <span class="text-danger">*</span>
                            </label>
                            <input type="text" class="form-control" placeholder="Registration Number"
                                formControlName="registrtionNumberControl" maxlength="100">
                            <div class="form-text text-danger"
                                *ngIf="isSubmitted && f['registrtionNumberControl']!.hasError('required')">
                                Registration Number is required.
                            </div>
                            <div class="form-text text-danger"
                                *ngIf="isSubmitted && f['registrtionNumberControl']!.hasError('onlyWhitespace')">
                                Registration Number can not be only whitespace.
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="form-group">
                            <label class="main-label d-block fw-medium text-black">
                                Tax Number <span class="text-danger">*</span>
                            </label>
                            <input type="text" class="form-control" placeholder="Tax Number"
                                formControlName="taxNumberControl" maxlength="200">
                            <div class="form-text text-danger"
                                *ngIf="isSubmitted && f['taxNumberControl']!.hasError('required')">
                                Tax Number is required.
                            </div>
                            <div class="form-text text-danger"
                                *ngIf="isSubmitted && f['taxNumberControl']!.hasError('onlyWhitespace')">
                                Tax Number can not be only whitespace.
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="form-group">
                            <label class="main-label d-block fw-medium text-black">
                                Date Of Incorporation <span class="text-danger">*</span>
                            </label>
                            <!-- <input type="text" class="form-control" placeholder="Tax Number"
                               maxlength="200"> -->
                            <input type="date" formControlName="dateOfIncorporationControl"
                                (change)="onDateChange($event)" class="form-control">
                            <div class="form-text text-danger"
                                *ngIf="isSubmitted && f['dateOfIncorporationControl']!.hasError('required')">
                                Date Of Incorporation is required.
                            </div>
                            <div class="form-text text-danger"
                                *ngIf="isSubmitted && f['dateOfIncorporationControl']!.hasError('onlyWhitespace')">
                                Date Of Incorporation can not be only whitespace.
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="form-group">
                            <label class="main-label d-block fw-medium text-black" for="flexSwitchCheckChecked">
                                Active
                            </label>
                            <div class="form-check form-switch">
                                <input class="form-check-input radio" type="checkbox" role="switch"
                                    id="flexSwitchCheckChecked" formControlName="isActiveControl">
                                <!-- <label class="form-check-label" for="flexSwitchCheckChecked"> </label> -->
                            </div>
                        </div>
                    </div>

                    <div class=" col-md-12">
                        <div class="form-group">
                            <label class="main-label d-block fw-medium text-black">
                                Service <span class="text-danger">*</span>
                            </label>
                            <ng-content *ngIf="isLoadChipView">
                                <app-ttpl-chipview [isEditMode]="currentBusinessId>0"
                                    (serviceSearchEvent)="serviceSearchEvent($event)"
                                    (selectedServicesEvent)="selectedServicesEvent($event)"
                                    [filteredItems]="serviceFilterData"></app-ttpl-chipview>
                            </ng-content>
                            <div class="form-text text-danger"
                                *ngIf="isSubmitted && this.business.businessServiceMapping.length === 0">
                                Service is required.
                            </div>
                        </div>
                    </div>

                </div>
                <div class="btn-box">
                    <button (click)="toggleClass()"
                        class="warn d-inline-block fw-medium border-0 border-radius transition">
                        Cancel
                    </button>
                    <button class="primary d-inline-block fw-medium border-0 border-radius transition"
                        [disabled]="!formIsValid() || !dataHasChanged()">
                        <span class="position-relative d-inline-block">
                            <i class="material-symbols-outlined">
                                add
                            </i>
                            {{titleButton}}
                        </span>
                    </button>
                </div>
            </div>
        </form>
    </div>
</div>