<div class="sidebar-area bg-white transition" [ngClass]="{'active': isSidebarToggled}"
    [class.card-borderd]="themeService.isCardBordered()" [class.dark-sidebar]="themeService.isSidebarDark()"
    [class.hide-sidebar]="themeService.isHideSidebar()" [class.component-dark-theme]="themeService.isDark()"
    [class.right-sidebar]="themeService.isRightSidebar()" [class.component-rtl-enabled]="themeService.isRTLEnabled()"
    [class.card-with-box-shadow]="themeService.isCardWithBoxShadow()">
    <div class="logo bg-white">
        <a routerLink="/dashboard" class="d-flex align-items-center position-relative">
            <!-- <img src="images/logo-icon.svg" alt="logo-icon"> -->
            <img src="images/logo/svgviewer-png-output.png" alt="logo-icon" height="60px" width="60px">
            <span class="fw-bold text-black position-relative">
                PNG Address
            </span>
        </a>
    </div>
    <div (click)="toggle()" class="burger-menu transition" [ngClass]="{'active': isSidebarToggled}">
        <span class="top-bar transition d-block bg-black"></span>
        <span class="middle-bar transition d-block bg-black"></span>
        <span class="bottom-bar transition d-block bg-black"></span>
    </div>
    <ng-scrollbar visibility="hover">
        <div class="sidebar-inner">
            <div class="sidebar-menu">
                <div class="accordion">
                    <span class="sub-title d-block fw-medium text-uppercase">
                        MAIN
                    </span>
                    <div class="accordion-item border-radius border-0">
                        <a routerLink="/dashboard"
                            class="sidebar-menu-link d-block w-100 position-relative border-radius fw-medium transition"
                            routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
                            <i class="material-symbols-outlined">
                                dashboard
                            </i>
                            <span class="title">
                                Dashboard
                            </span>
                        </a>
                    </div>
                    <span class="sub-title d-block fw-medium text-uppercase">
                        LOCATION
                    </span>
                    <div class="accordion-item border-radius border-0">
                        <button type="button" (click)="toggleSection(0)" [ngClass]="{ 'open': isSectionOpen(0) }"
                            class="accordion-button d-block text-start shadow-none fw-medium border-0 transition border-radius">
                            <i class="material-symbols-outlined">
                                location_on
                            </i>
                            <span class="title">
                                Location
                            </span>
                            <span class="trezo-badge rounded-circle fw-medium d-inline-block text-center">
                                12
                            </span>
                        </button>
                        <div class="accordion-collapse" [ngClass]="{ 'open': isSectionOpen(0) }">
                            <div class="accordion-body">
                                <ul class="sidebar-sub-menu p-0 list-unstyled mb-0">
                                    <li class="sidemenu-item">
                                        <a routerLink="/admin/province" routerLinkActive="active"
                                            [routerLinkActiveOptions]="{exact: true}"
                                            class="sidemenu-link border-radius d-block position-relative transition fw-medium">
                                            Province
                                        </a>
                                    </li>
                                    <li class="sidemenu-item">
                                        <a routerLink="/admin/district" routerLinkActive="active"
                                            [routerLinkActiveOptions]="{exact: true}"
                                            class="sidemenu-link border-radius d-block position-relative transition fw-medium">
                                            District
                                        </a>
                                    </li>
                                    <li class="sidemenu-item">
                                        <a routerLink="/admin/town" routerLinkActive="active"
                                            [routerLinkActiveOptions]="{exact: true}"
                                            class="sidemenu-link border-radius d-block position-relative transition fw-medium">
                                            Town
                                        </a>
                                    </li>
                                    <li class="sidemenu-item">
                                        <a routerLink="/admin/llg" routerLinkActive="active"
                                            [routerLinkActiveOptions]="{exact: true}"
                                            class="sidemenu-link border-radius d-block position-relative transition fw-medium">
                                            LLG
                                        </a>
                                    </li>
                                    <li class="sidemenu-item">
                                        <a routerLink="/admin/ward" routerLinkActive="active"
                                            [routerLinkActiveOptions]="{exact: true}"
                                            class="sidemenu-link border-radius d-block position-relative transition fw-medium">
                                            Ward
                                        </a>
                                    </li>
                                    <li class="sidemenu-item">
                                        <a routerLink="/admin/suburb" routerLinkActive="active"
                                            [routerLinkActiveOptions]="{exact: true}"
                                            class="sidemenu-link border-radius d-block position-relative transition fw-medium">
                                            Suburb
                                        </a>
                                    </li>
                                    <li class="sidemenu-item">
                                        <a routerLink="/admin/roads" routerLinkActive="active"
                                            [routerLinkActiveOptions]="{exact: true}"
                                            class="sidemenu-link border-radius d-block position-relative transition fw-medium">
                                            Roads
                                        </a>
                                    </li>
                                    <li class="sidemenu-item">
                                        <a routerLink="/admin/street" routerLinkActive="active"
                                            [routerLinkActiveOptions]="{exact: true}"
                                            class="sidemenu-link border-radius d-block position-relative transition fw-medium">
                                            Village/Street
                                        </a>
                                    </li>
                                    <li class="sidemenu-item">
                                        <a routerLink="/admin/sectionNumber" routerLinkActive="active"
                                            [routerLinkActiveOptions]="{exact: true}"
                                            class="sidemenu-link border-radius d-block position-relative transition fw-medium">
                                            Section Number
                                        </a>
                                    </li>
                                    <li class="sidemenu-item">
                                        <a routerLink="/admin/allotmentNumber" routerLinkActive="active"
                                            [routerLinkActiveOptions]="{exact: true}"
                                            class="sidemenu-link border-radius d-block position-relative transition fw-medium">
                                            Allotment Number
                                        </a>
                                    </li>
                                    <li class="sidemenu-item">
                                        <a routerLink="/admin/postOffice" routerLinkActive="active"
                                            [routerLinkActiveOptions]="{exact: true}"
                                            class="sidemenu-link border-radius d-block position-relative transition fw-medium">
                                            Post Office
                                        </a>
                                    </li>
                                    <li class="sidemenu-item">
                                        <a routerLink="/admin/streetDetails" routerLinkActive="active"
                                            [routerLinkActiveOptions]="{exact: true}"
                                            class="sidemenu-link border-radius d-block position-relative transition fw-medium">
                                            Street Detail
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <span class="sub-title d-block fw-medium text-uppercase">
                        Business
                    </span>
                    <div class="accordion-item border-radius border-0">
                        <button type="button" (click)="toggleSection(1)" [ngClass]="{ 'open': isSectionOpen(1) }"
                            class="accordion-button d-block text-start shadow-none fw-medium border-0 transition border-radius">
                            <i class="material-symbols-outlined">
                                domain
                            </i>
                            <span class="title">
                                Business
                            </span>
                            <span class="trezo-badge rounded-circle fw-medium d-inline-block text-center">
                                6
                            </span>
                        </button>
                        <div class="accordion-collapse" [ngClass]="{ 'open': isSectionOpen(1) }">
                            <div class="accordion-body">
                                <ul class="sidebar-sub-menu p-0 list-unstyled mb-0">
                                    <li class="sidemenu-item">
                                        <a routerLink="/admin/businessType" routerLinkActive="active"
                                            [routerLinkActiveOptions]="{exact: true}"
                                            class="sidemenu-link border-radius d-block position-relative transition fw-medium">
                                            Business Type
                                        </a>
                                    </li>
                                    <li class="sidemenu-item">
                                        <a routerLink="/admin/serviceType" routerLinkActive="active"
                                            [routerLinkActiveOptions]="{exact: true}"
                                            class="sidemenu-link border-radius d-block position-relative transition fw-medium">
                                            Service Type
                                        </a>
                                    </li>
                                    <li class="sidemenu-item">
                                        <a routerLink="/admin/service" routerLinkActive="active"
                                            [routerLinkActiveOptions]="{exact: true}"
                                            class="sidemenu-link border-radius d-block position-relative transition fw-medium">
                                            Service
                                        </a>
                                    </li>
                                    <li class="sidemenu-item">
                                        <a routerLink="/admin/business" routerLinkActive="active"
                                            [routerLinkActiveOptions]="{exact: true}"
                                            class="sidemenu-link border-radius d-block position-relative transition fw-medium">
                                            Business Name
                                        </a>
                                    </li>
                                    <li class="sidemenu-item">
                                        <a routerLink="/admin/address" routerLinkActive="active"
                                            [routerLinkActiveOptions]="{exact: true}"
                                            class="sidemenu-link border-radius d-block position-relative transition fw-medium">
                                            Address
                                        </a>
                                    </li>
                                    <li class="sidemenu-item">
                                        <a routerLink="/admin/contact" routerLinkActive="active"
                                            [routerLinkActiveOptions]="{exact: true}"
                                            class="sidemenu-link border-radius d-block position-relative transition fw-medium">
                                            Contact
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <ng-content *ngIf="hasAdminRoles">
                        <span class="sub-title d-block fw-medium text-uppercase">
                            Configuration
                        </span>
                        <div class="accordion-item border-radius border-0">
                            <button type="button" (click)="toggleSection(2)" [ngClass]="{ 'open': isSectionOpen(1) }"
                                class="accordion-button d-block text-start shadow-none fw-medium border-0 transition border-radius">
                                <i class="material-symbols-outlined">
                                    manage_accounts
                                </i>
                                <span class="title">
                                    Configuration
                                </span>
                                <span class="trezo-badge rounded-circle fw-medium d-inline-block text-center">
                                    1
                                </span>
                            </button>
                            <div class="accordion-collapse" [ngClass]="{ 'open': isSectionOpen(2) }">
                                <div class="accordion-body">
                                    <ul class="sidebar-sub-menu p-0 list-unstyled mb-0">
                                        <li class="sidemenu-item">
                                            <a routerLink="/admin/clientConfiguration" routerLinkActive="active"
                                                [routerLinkActiveOptions]="{exact: true}"
                                                class="sidemenu-link border-radius d-block position-relative transition fw-medium">
                                                Client Configuration
                                            </a>
                                        </li>
                                        <li class="sidemenu-item">
                                            <a routerLink="/admin/logevents" routerLinkActive="active"
                                                [routerLinkActiveOptions]="{exact: true}"
                                                class="sidemenu-link border-radius d-block position-relative transition fw-medium">
                                                Log Events
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </ng-content>
                </div>
            </div>
        </div>
    </ng-scrollbar>
</div>