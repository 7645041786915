<div class="" [class.dark-theme]="themeService.isDark()">
    <div class="trezo-form mx-auto" [class.component-dark-theme]="themeService.isDark()"
        [class.component-rtl-enabled]="themeService.isRTLEnabled()">
        <div class="row">
            <!-- <div class="col-lg-6 col-md-12 order-2 order-lg-1">
                <div class="form-image">
                    <img src="images/sign-in1.jpg" alt="sign-in-image">
                </div>
            </div> -->
            <div class="col-lg-3"></div>
            <div class="col-lg-6 col-md-12 order-1 order-lg-2">
                <div class="form-content">
                    <div class="logo">
                        <img src="images/logo/FullLogo_Transparent.png" alt="logo">
                    </div>
                    <form [formGroup]="loginForm" (ngSubmit)="onSubmit()">
                        <div class="form-group">
                            <label for="email" class="main-label d-block fw-medium text-black">
                                Email Address
                            </label>
                            <input type="text" class="form-control" placeholder="example&#64;pngaddress.com"
                                id="input-email" formControlName="email"
                                [ngClass]="{ 'is-invalid': submitted && f['email'].errors }">
                            <div class="form-text text-danger"
                                *ngIf="f['email']!.hasError('required') && f['email']!.touched">
                                Email is required.
                            </div>
                            <div class="form-text text-danger"
                                *ngIf="f['email']!.hasError('email') && f['email']!.touched">
                                Please enter a valid email address.
                            </div>
                        </div>
                        <div class="form-group position-relative">
                            <label for="password" class="main-label d-block fw-medium text-black">
                                Password
                            </label>
                            <div class="position-relative">
                                <input [type]="isPasswordVisible ? 'text' : 'password'"
                                    (input)="onPasswordInput($event)" class="form-control" placeholder="Type password"
                                    formControlName="password"
                                    [ngClass]="{ 'is-invalid': submitted && f['password'].errors }">
                                <button type="button" (click)="togglePasswordVisibility()"
                                    class="icon-button p-0 border-0 bg-transparent transition">
                                    <i [ngClass]="isPasswordVisible ? 'ri-eye-line' : 'ri-eye-off-line'"></i>
                                </button>
                            </div>
                            <div class="form-text text-danger"
                                *ngIf="f['password']!.hasError('required') && f['password']!.touched">
                                Password is required.
                            </div>
                        </div>
                        <div class="forgot-password">
                            <a routerLink="/forgot-password" class="text-primary fw-semibold"
                                (click)="onForgotPasswordClick($event)">
                                Forgot Password?
                            </a>
                        </div>
                        <button type="submit" class="btn-box d-block w-100 border-0 transition fw-medium border-radius">
                            <span class="d-flex align-items-center justify-content-center">
                                <i class="material-symbols-outlined">
                                    login
                                </i>
                                Sign In
                            </span>
                        </button>
                        <div class="form-group link-danger">
                            <span>{{error}}</span>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>