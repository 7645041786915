import { BaseRecord } from "./BaseRecord";

export class BusinessType extends BaseRecord<number> {
    public name: string;


    constructor() {
        super();
        this.id = 0;
        this.name = "";
    }
}
