import { RouterModule, Routes } from "@angular/router";
import { SignInComponent } from "./components/sign-in/sign-in.component";
import { NgModule } from "@angular/core";
import { SignUpComponent } from "./components/sign-up/sign-up.component";
import { ForgotPasswordComponent } from "./components/forgot-password/forgot-password.component";
import { ResetPasswordComponent } from "./components/reset-password/reset-password.component";
import { LogoutComponent } from "./components/logout/logout.component";

export const routes: Routes = [
    {
        path: '', component: SignInComponent,
        // children: [
        //     {path: 'sign-up', component: SignUpComponent},
        //     // {path: 'forgot-password', component: ForgotPasswordComponent},
        //     // {path: 'reset-password', component: ResetPasswordComponent},
        //     // {path: 'confirm-email', component: ConfirmEmailComponent},
        //     // {path: 'lock-screen', component: LockScreenComponent},
        //     // {path: 'logout', component: LogoutComponent}
        // ]
    },
    {
        path: 'login', component: SignInComponent,
    },
    { path: 'sign-up', component: SignUpComponent },
    { path: 'forgot-password', component: ForgotPasswordComponent },
    { path: 'reset-password', component: ResetPasswordComponent },
    { path: 'logout', component: LogoutComponent },
    { path: 'logout', component: LogoutComponent },

    //{path: 'coming-soon', component: ComingSoonComponent},
    // Here add new pages component

    //{path: '**', component: NotFoundComponent} // This line will remain down from the whole pages component list
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class CoreRoutingModule { }