import { Component, EventEmitter, Input, OnInit, Output, AfterViewInit, OnDestroy } from '@angular/core';
import { RouterLink } from '@angular/router';
import { NgClass, NgIf } from '@angular/common';
import { CustomizerSettingsService } from '../../../../../../customizer-settings/customizer-settings.service';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ClientConfigurationService } from '../../../../services/clientConfiguration.service';
import { ClientConfiguration } from '../../../../../../models/domain/clientConfiguration';
import { ObjectUtility } from '../../../../../core/utilities/ObjectUtility';
import { catchError, of, Subject, takeUntil } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { whitespaceValidator } from '../../../../../core/validators/ValidationService';
import { LoadingService } from '../../../../../core/services/loading.service';
import { Clipboard } from "@angular/cdk/clipboard"

@Component({
  selector: 'app-clientConfiguration-details',
  templateUrl: './clientConfiguration-details.component.html',
  styleUrl: './clientConfiguration-details.component.scss'
})
export class ClientConfigurationDetailsComponent implements OnInit, AfterViewInit, OnDestroy {

  @Output() classApplied = new EventEmitter<boolean>();
  @Output() saveConfirmationEvent = new EventEmitter<boolean>();
  @Input() data!: ClientConfiguration;
  // isToggled
  isToggled = false;
  frmGroup: FormGroup;

  private destroy$: Subject<boolean> = new Subject<boolean>();
  public clientConfigurationOriginal = new ClientConfiguration;
  public clientConfiguration: ClientConfiguration = new ClientConfiguration;
  public titleText = "";
  public titleButton = "";
  public errorMessage = "";
  public currentClientConfigurationId = 0;
  isSubmitted = false;

  constructor(
    public themeService: CustomizerSettingsService,
    private formBuilder: FormBuilder,
    private clientConfigurationService: ClientConfigurationService,
    private toasterService: ToastrService,
    private loadingService: LoadingService,
    private clipBoard: Clipboard
  ) {
    this.initialize();
  }

  public ngOnInit(): void {
    if (this.data) {
      this.currentClientConfigurationId = this.data.id ? this.data.id : 0;
      this.clientConfiguration = ObjectUtility.deepClone(this.data);
      this.clientConfigurationOriginal = ObjectUtility.deepClone(this.data);
      this.setForm(this.clientConfiguration);
    }
    else {
      this.currentClientConfigurationId = 0;
      const newClientConfiguration = new ClientConfiguration();
      this.clientConfiguration = ObjectUtility.deepClone(newClientConfiguration);
      this.clientConfigurationOriginal = ObjectUtility.deepClone(newClientConfiguration);
      this.setForm(this.clientConfiguration);
    }
  }

  private initialize(): void {
    this.themeService.isToggled$.subscribe(isToggled => {
      this.isToggled = isToggled;
    });
    this.isSubmitted = false;
    this.createForm();
  }

  public ngAfterViewInit(): void {

    this.frmGroup.controls['nameControl'].valueChanges.subscribe(value => {
      this.clientConfiguration.name = value;
    });
    this.frmGroup.controls['emailControl'].valueChanges.subscribe(value => {
      this.clientConfiguration.email = value;
    });
    this.frmGroup.controls['websiteControl'].valueChanges.subscribe(value => {
      this.clientConfiguration.website = value;
    });
    this.frmGroup.controls['serverUrlControl'].valueChanges.subscribe(value => {
      this.clientConfiguration.serverUrl = value;
    });
    this.frmGroup.controls['apiKeyNameControl'].valueChanges.subscribe(value => {
      this.clientConfiguration.apiKeyName = value;
    });
    this.frmGroup.controls['maxRequestsControl'].valueChanges.subscribe(value => {
      this.clientConfiguration.maxRequests = value;
    });
    this.frmGroup.controls['timeWindowSecondsControl'].valueChanges.subscribe(value => {
      this.clientConfiguration.timeWindowSeconds = value;
    });

  }

  public ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  get f() { return this.frmGroup.controls; }

  private createForm(): void {
    this.frmGroup = this.formBuilder.group({
      nameControl: new FormControl('', [Validators.required, whitespaceValidator()]),
      emailControl: ['', [Validators.required, Validators.email]],
      websiteControl: new FormControl('', [Validators.required, whitespaceValidator()]),
      serverUrlControl: new FormControl('', [Validators.required, whitespaceValidator()]),
      apiKeyNameControl: new FormControl('', [Validators.required, whitespaceValidator()]),
      maxRequestsControl: new FormControl('', [Validators.required]),
      timeWindowSecondsControl: new FormControl('', [Validators.required]),
      apiKeyControl: new FormControl(''),
    })
  }

  private setForm(clientConfiguration: ClientConfiguration): void {
    if (clientConfiguration.id != 0) {
      this.frmGroup.patchValue({
        nameControl: clientConfiguration.name,
        emailControl: clientConfiguration.email,
        websiteControl: clientConfiguration.website,
        serverUrlControl: clientConfiguration.serverUrl,
        apiKeyNameControl: clientConfiguration.apiKeyName,
        maxRequestsControl: clientConfiguration.maxRequests,
        timeWindowSecondsControl: clientConfiguration.timeWindowSeconds,
        apiKeyControl: clientConfiguration.apiKey
      });
    }
    else {
      this.f['maxRequestsControl'].setValue(100);
      this.f['timeWindowSecondsControl'].setValue(60);
      this.clientConfiguration.maxRequests = 100;
      this.clientConfiguration.timeWindowSeconds = 60;
    }
    this.f['apiKeyControl'].disable();
    this.titleText = `${this.currentClientConfigurationId === 0 ? "Add" : "Edit"} Client Configuration`;
    this.titleButton = `${this.currentClientConfigurationId === 0 ? "Create" : "Update"}`;
  }

  public onSave(): void {
    this.isSubmitted = true;
    this.loadingService.showLoading();

    if (this.frmGroup.valid) {
      this.addEditClientConfiguration();
    }
  }

  private addEditClientConfiguration(): void {

    if (this.clientConfiguration?.id === 0) {
      this.clientConfigurationService.create(this.clientConfiguration)
        .pipe(
          catchError((err: any) => {

            if (err && err.error) {
              this.isSubmitted = false;
              if (err.error.isCustom) {
                this.errorMessage = err.error.message;
                this.toasterService.warning(err.error.message);
              } else {
                this.toasterService.error('Something went wrong!');
              }
            } else {
              this.isSubmitted = false;
              this.toasterService.error('Something went wrong!');
            }

            return of(null);
          }),
          takeUntil(this.destroy$))
        .subscribe(response => {
          if (response) {
            this.isSubmitted = false;
            this.clientConfiguration = response?.body != null ? response?.body : new ClientConfiguration;
            this.saveConfirmationEvent.emit(true);
            this.toggleClass();
            this.toasterService.success("Save successfully");
          }
        });
    } else {
      this.clientConfigurationService.update(this.clientConfiguration)
        .pipe(
          catchError((err: any) => {
            if (err && err.error) {
              this.isSubmitted = false;
              if (err.error.isCustom) {
                this.errorMessage = err.error.message;
                this.toasterService.warning(err.error.message);
              } else {
                this.toasterService.error('Something went wrong!');
              }
            } else {
              this.isSubmitted = false;
              this.toasterService.error('Something went wrong!');
            }
            return of(null);
          }),
          takeUntil(this.destroy$))
        .subscribe(response => {
          if (response) {
            this.isSubmitted = false;
            this.clientConfiguration = response?.body != null ? response?.body : new ClientConfiguration;
            this.saveConfirmationEvent.emit(true);
            this.toasterService.success("Update successfully");
            this.toggleClass();
          }
        });
    }
  }


  public toggleClass(): void {
    this.classApplied.emit(false);
  }

  public dataHasChanged(): boolean {
    const baseEqual = ObjectUtility.deepEqual(this.clientConfiguration, this.clientConfigurationOriginal);
    return !baseEqual;
  }

  public formIsValid(): boolean {
    const isValid = this.frmGroup.controls['nameControl'].valid
      && this.frmGroup.controls['emailControl'].valid
      && this.frmGroup.controls['websiteControl'].valid
      && this.frmGroup.controls['serverUrlControl'].valid
      && this.frmGroup.controls['apiKeyNameControl'].valid
      && this.frmGroup.controls['maxRequestsControl'].valid
      && this.frmGroup.controls['timeWindowSecondsControl'].valid
    return isValid;
  }

  copyToClipboard() {
    this.clipBoard.copy(this.f["apiKeyControl"].value)
    this.toasterService.info("Api Key Copied!");
    return;
  }



}