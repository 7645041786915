import { BaseRecord } from "./BaseRecord";
import { Llg } from "./llg";


export class Ward extends BaseRecord<number> {
    public name: string;
    public llgId: number;
    public llg: Llg | null;

    constructor() {
        super();
        this.id = 0;
        this.llgId = 0;
        this.name = "";
        this.llg = new Llg;
    }
}
