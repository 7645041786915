import { Component, OnInit } from '@angular/core';
import { CustomizerSettingsService } from '../../../../customizer-settings/customizer-settings.service';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Validators } from '@angular/forms';

@Component({
    selector: 'app-sign-up',
    templateUrl: './sign-up.component.html',
    styleUrl: './sign-up.component.scss'
})
export class SignUpComponent implements OnInit {

    // isToggled
    isToggled = false;
    submitted = false;
    signupForm!: FormGroup;
    isPasswordVisible= false;

    constructor(
        public themeService: CustomizerSettingsService,
        private formBuilder: FormBuilder
    ) {
        this.themeService.isToggled$.subscribe(isToggled => {
            this.isToggled = isToggled;
        });
    }

    ngOnInit(): void {
       this.initializer();
      }
    
    togglePasswordVisibility(): void {
        this.isPasswordVisible = !this.isPasswordVisible;
    }

    onPasswordInput(event: Event): void {
        const inputElement = event.target as HTMLInputElement;
        this.f['password'].setValue(inputElement.value);
    }

    initializer(){
        this.signupForm = this.formBuilder.group({
            email: ['', [Validators.required, Validators.email]],
            name: ['', [Validators.required]],
            password: ['', Validators.required],
          });
    }
      // convenience getter for easy access to form fields
    get f() { return this.signupForm.controls; }

    onSubmit() {
        this.submitted = true;
        // stop here if form is invalid
        if (this.signupForm.invalid) {
          return;
        }
      }

}