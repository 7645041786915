import { Component, EventEmitter, Input, OnInit, Output, AfterViewInit, OnDestroy } from '@angular/core';
import { CustomizerSettingsService } from '../../../../../../customizer-settings/customizer-settings.service';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ServiceService } from '../../../../services/service.service';
import { Service } from '../../../../../../models/domain/service';
import { ObjectUtility } from '../../../../../core/utilities/ObjectUtility';
import { catchError, forkJoin, of, Subject, takeUntil } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { whitespaceValidator } from '../../../../../core/validators/ValidationService';
import { LoadingService } from '../../../../../core/services/loading.service';
import { ServiceType } from '../../../../../../models/domain/serviceType';
import { ServiceTypeService } from '../../../../services/serviceType.service';
import { BaseSearchOptions } from '../../../../../../models/core/table/BaseSearchOptions';

@Component({
  selector: 'app-service-details',
  templateUrl: './service-details.component.html',
  styleUrl: './service-details.component.scss'
})
export class ServiceDetailsComponent implements OnInit, AfterViewInit, OnDestroy {

  @Output() classApplied = new EventEmitter<boolean>();
  @Output() saveConfirmationEvent = new EventEmitter<boolean>();
  @Input() currentServiceId = 0;
  // isToggled
  isToggled = false;
  frmGroup: FormGroup;

  private destroy$: Subject<boolean> = new Subject<boolean>();
  public serviceOriginal = new Service;
  public service: Service = new Service;
  public titleText = "";
  public titleButton = "";
  public errorMessage = "";

  isSubmitted = false;

  //dropdown DataBind
  public serviceTypes: ServiceType[];
  public pageIndex = 0;
  public pageSize = 1000;
  public searchOptions: BaseSearchOptions;

  constructor(
    public themeService: CustomizerSettingsService,
    private formBuilder: FormBuilder,
    private serviceService: ServiceService,
    private serviceTypeService: ServiceTypeService,
    private toasterService: ToastrService,
    private loadingService: LoadingService
  ) {
    this.initialize();
  }

  public ngOnInit(): void {
    if (this.currentServiceId > 0) {
      this.getDropdownData();
      this.getServiceById(this.currentServiceId);
    }
    else {
      this.currentServiceId = 0;
      this.getDropdownData();
      const newService = new Service();
      this.service = ObjectUtility.deepClone(newService);
      this.serviceOriginal = ObjectUtility.deepClone(newService);
      this.setForm(this.service);
    }
  }

  private initialize(): void {
    this.themeService.isToggled$.subscribe(isToggled => {
      this.isToggled = isToggled;
    });
    this.isSubmitted = false;
    this.searchOptions = new BaseSearchOptions(undefined, 'name', 0, this.pageIndex, this.pageSize);
    this.createForm();
  }

  public ngAfterViewInit(): void {

    this.frmGroup.controls['nameControl'].valueChanges.subscribe(value => {
      this.service.name = value;
    });
    this.frmGroup.controls['descriptionControl'].valueChanges.subscribe(value => {
      this.service.description = value;
    });
    this.frmGroup.controls['serviceTypeControl'].valueChanges.subscribe(value => {
      this.service.serviceType = value;
    });


  }

  public ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  get f() { return this.frmGroup.controls; }

  private createForm(): void {
    this.frmGroup = this.formBuilder.group({
      nameControl: new FormControl('', [Validators.required, whitespaceValidator()]),
      descriptionControl: new FormControl('', [whitespaceValidator()]),
      serviceTypeControl: new FormControl(null, [Validators.required]),
    })
  }

  private setForm(service: Service): void {
    if (service && service.id != undefined && service.id > 0) {
      this.frmGroup.patchValue({
        nameControl: service.name,
        descriptionControl: service.description,
        serviceTypeControl: service?.serviceType,
      });
    }
    this.titleText = `${this.currentServiceId === 0 ? "Add" : "Edit"} Service`;
    this.titleButton = `${this.currentServiceId === 0 ? "Create" : "Update"}`;
  }

  public onSave(): void {
    this.isSubmitted = true;
    this.loadingService.showLoading();

    if (this.frmGroup.valid) {
      this.addEditService();
    }
  }

  private addEditService(): void {

    if (this.service?.id === 0) {
      this.serviceService.create(this.sanitizeService(this.service))
        .pipe(
          catchError((err: any) => {
            if (err && err.error) {
              this.isSubmitted = false;
              if (err.error.isCustom) {
                this.errorMessage = err.error.message;
                this.toasterService.warning(err.error.message);
              } else {
                this.toasterService.error('Something went wrong!');
              }
            } else {
              this.isSubmitted = false;
              this.toasterService.error('Something went wrong!');
            }

            return of(null);
          }),
          takeUntil(this.destroy$))
        .subscribe(response => {
          if (response) {
            this.isSubmitted = false;
            this.service = response?.body != null ? response?.body : new Service;
            this.saveConfirmationEvent.emit(true);
            this.toggleClass();
            this.toasterService.success("Save successfully");
          }
        });
    } else {
      this.serviceService.update(this.sanitizeService(this.service))
        .pipe(
          catchError((err: any) => {
            if (err && err.error) {
              this.isSubmitted = false;
              if (err.error.isCustom) {
                this.errorMessage = err.error.message;
                this.toasterService.warning(err.error.message);
              } else {
                this.toasterService.error('Something went wrong!');
              }
            } else {
              this.isSubmitted = false;
              this.toasterService.error('Something went wrong!');
            }
            return of(null);
          }),
          takeUntil(this.destroy$))
        .subscribe(response => {
          if (response) {
            this.isSubmitted = false;
            this.service = response?.body != null ? response?.body : new Service;
            this.saveConfirmationEvent.emit(true);
            this.toasterService.success("Update successfully");
            this.toggleClass();
          }
        });
    }
  }


  public toggleClass(): void {
    this.classApplied.emit(false);
  }

  private getDropdownData() {
    const serviceType = this.serviceTypeService.get(this.searchOptions);

    forkJoin([serviceType])
      .pipe(takeUntil(this.destroy$)).subscribe((result: any) => {
        if (result) {
          this.serviceTypes = result[0].body.values;
        }
      });
  }

  private sanitizeService(service: Service): Service {
    service.serviceType = null;
    service.serviceTypeId = this.frmGroup.controls['serviceTypeControl'].value.id;

    return service;
  }

  public serviceTypeComparer(t1: ServiceType, t2: ServiceType): boolean {
    return t1 && t2 ? (t1.id === t2.id || t1.name === t2.name) : t1 === t2;
  }

  public dataHasChanged(): boolean {
    const baseEqual = ObjectUtility.deepEqual(this.service, this.serviceOriginal);
    return !baseEqual;
  }

  public formIsValid(): boolean {
    const isValid = this.frmGroup.controls['serviceTypeControl'].valid && this.frmGroup.controls['nameControl'].valid
      && this.frmGroup.controls['descriptionControl'].valid
    return isValid;
  }

  public getServiceById(serviceId: number) {
    this.loadingService.showLoading();
    this.serviceService.getById(serviceId)
      .pipe(takeUntil(this.destroy$))
      .subscribe(result => {
        if (result) {
          this.service = ObjectUtility.deepClone(result);
          this.serviceOriginal = ObjectUtility.deepClone(result);
          this.setForm(this.service);
        }
        this.loadingService.hideLoading();
      });
  }



}