import { Component } from '@angular/core';
import { ClientGrowthComponent } from './client-growth/client-growth.component';
import { TableDataCountComponent } from './table-data-count/total-orders.component';

@Component({
    selector: 'app-png-crmp',
    standalone: true,
    imports: [ClientGrowthComponent, TableDataCountComponent, TableDataCountComponent], //LeadConversionComponent, AnnualProfitComponent
    templateUrl: './crm.component.html',
    styleUrl: './crm.component.scss'
})
export class CrmComponent {}