import { BaseRecord } from "./BaseRecord";
import { Business } from "./business";

export class Contact extends BaseRecord<number> {
    public phone: string;
    public mobile: string;
    public email: string;
    public fax: string;
    public website: string;
    public facebook: string;
    public businessId: number;
    public business: Business | null;


    constructor() {
        super();
        this.id = 0;
        this.businessId = 0
        this.phone = "";
        this.mobile = "";
        this.email = "";
        this.fax = "";
        this.website = "";
        this.facebook = "";
    }
}
