import { FilterDataType } from "./FilterDataType";
import { QueryBuilderParams } from "./QueryBuilderParams";
import { SearchExpression } from "./SearchExpression";

export class BaseSearchOptions {
    public filter: string;
    public orderBy: string;
    public orderByDirection: number;
    public pageIndex: number; // page index
    public pageSize: number; //Page size, total record per page
    public expand: boolean;

    constructor(filter?: string, orderBy?: string, orderByDirection?: number, pageIndex?: number, pageSize?: number, expand?: boolean) {
        this.filter = filter ?? "";
        this.orderBy = orderBy ?? "";
        this.orderByDirection = orderByDirection ?? 0;
        this.pageIndex = pageIndex ?? 0;
        this.pageSize = pageSize ?? 10;
        this.expand = expand ?? true;

    }

    public update(filter?: string, orderBy?: string, orderByDirection?: number, pageIndex?: number, pageSize?: number, expand?: boolean) {
        this.filter = filter ?? this.filter;
        this.orderBy = orderBy ?? this.orderBy;
        this.orderByDirection = orderByDirection ?? this.orderByDirection;
        this.pageIndex = pageIndex ?? this.pageIndex;
        this.pageSize = pageSize ?? this.pageSize;
        this.expand = expand ?? this.expand;

    }

    public updateByObject(obj: BaseSearchOptions): void {
        this.filter = obj.filter ?? this.filter;
        this.orderBy = obj.orderBy ?? this.orderBy;
        this.orderByDirection = obj.orderByDirection ?? this.orderByDirection;
        this.pageIndex = obj.pageIndex ?? this.pageIndex;
        this.pageSize = obj.pageSize ?? this.pageSize;
        this.expand = obj.expand ?? this.expand;

    }

    public reset(): void {
        this.filter = "";
        this.orderBy = "";
        this.orderByDirection = 0;
        this.pageIndex = 0;
        this.pageSize = 10;
        this.expand = true;

    }

    public generateQuery(searchParams: QueryBuilderParams[]): string {
        let filterString = "";
    
        searchParams.forEach((element, index) => {
            if (index > 0 && filterString !== "") {
                filterString += `${element.groupType}`; // Use the group type from the current element
            }
    
            // Construct the filter segment
            filterString += `c=${element.fieldName}~d=${element.dataType}~o=${element.searchOperator}~v=${element.searchValue}`;
        });
    
        return filterString;
    }

    private getColumnDataTypeCode(type: string): FilterDataType | undefined {
        const typeMap: Record<string, FilterDataType> = {
            Int: FilterDataType.Int,
            Long: FilterDataType.Long,
            String: FilterDataType.String,
            DateTime: FilterDataType.DateTime,
            Bool: FilterDataType.Bool,
            Byte: FilterDataType.Byte,
            Short: FilterDataType.Short,
            Decimal: FilterDataType.Decimal,
            Double: FilterDataType.Double,
            Float: FilterDataType.Float,
            Char: FilterDataType.Char,
            Object: FilterDataType.Object,
            Guid: FilterDataType.Guid,
            Array: FilterDataType.Array,
        };
    
        return typeMap[type]; // Returns undefined if the type does not exist
    }

    
    public generateQueryOld(searchParams: QueryBuilderParams[]): string {
        var filterString = "";
        searchParams.forEach((element) => {
            if (filterString != "" && searchParams.length > 1)
                filterString += element.groupType;

            switch (element.searchOperator) {
                case SearchExpression.NotEquals:
                    filterString += element.fieldName + "~~" + element.searchOperator + "~" + element.searchValue;
                    break;
                default:
                    filterString += element.fieldName + "~" + element.searchOperator + "~" + element.searchValue;
                    break;
            }

        });
        return filterString;

    }
}
