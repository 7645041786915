import { Component, EventEmitter, Input, OnInit, Output, OnDestroy } from '@angular/core';
import { CustomizerSettingsService } from '../../../../../../customizer-settings/customizer-settings.service';
import { FormGroup } from '@angular/forms';
import { ObjectUtility } from '../../../../../core/utilities/ObjectUtility';
import { Subject, takeUntil } from 'rxjs';
import { LoadingService } from '../../../../../core/services/loading.service';
import { Logevents } from '../../../../../../models/domain/logevents';
import { LogeventsService } from '../../../../services/logevents.service';

@Component({
  selector: 'app-logevents-details',
  templateUrl: './logevents-details.component.html',
  styleUrl: './logevents-details.component.scss'
})
export class LogeventsDetailsComponent implements OnInit, OnDestroy {

  @Output() classApplied = new EventEmitter<boolean>();
  @Output() saveConfirmationEvent = new EventEmitter<boolean>();
  @Input() currentLogeventsId = 0;
  // isToggled
  isToggled = false;
  frmGroup: FormGroup;

  private destroy$: Subject<boolean> = new Subject<boolean>();
  public logeventsOriginal = new Logevents;
  public logevents: Logevents;
  public titleText = "";
  public titleButton = "";
  public errorMessage = "";
  isSubmitted = false;

  constructor(
    public themeService: CustomizerSettingsService,
    private logeventsService: LogeventsService,
    private loadingService: LoadingService,
  ) {
    this.initialize();
  }

  public ngOnInit(): void {
    this.geLogeventsById(this.currentLogeventsId);
  }

  private initialize(): void {
    this.themeService.isToggled$.subscribe(isToggled => {
      this.isToggled = isToggled;
    });
    this.isSubmitted = false;
  }

  public geLogeventsById(logeventsId: number) {
    if (logeventsId) {
      this.loadingService.showLoading();
      this.logeventsService.getById(logeventsId)
        .pipe(takeUntil(this.destroy$))
        .subscribe(result => {
          if (result) {
            this.logevents = ObjectUtility.deepClone(result);
            this.logeventsOriginal = ObjectUtility.deepClone(result);
          }
          this.loadingService.hideLoading();
        });
    }
  }

  public ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  get f() { return this.frmGroup.controls; }

  public toggleClass(): void {
    this.classApplied.emit(false);
  }

  public getLogLevelBadgeClass(level: string | null): string {
    switch (level?.toLowerCase()) {
      case 'verbose':
      case 'debug':
        return 'trezo-badge pPending'; // Purple for low-priority logs
      case 'information':
        return 'trezo-badge inProgress'; // Green for normal logs
      case 'warning':
        return 'trezo-badge pending'; // Yellow for warnings
      case 'error':
      case 'fatal':
        return 'trezo-badge rejected'; // Red for errors
      default:
        return 'trezo-badge'; // Default style for unknown levels
    }
  }


}