import { BaseRecord } from "./BaseRecord";
import { District } from "./district";
import { Llg } from "./llg";
import { Province } from "./province";
import { Suburb } from "./suburb";
import { Town } from "./town";
import { Ward } from "./ward";


export class Roads extends BaseRecord<number> {
    public name: string;
    public provinceId: number;
    public province: Province | null;
    public townId: number;
    public town: Town | null;
    public districtId: number;
    public district: District | null;
    public llgId: number;
    public llg: Llg | null;
    public wardId: number;
    public ward: Ward | null;
    public suburbId: number;
    public suburb: Suburb | null;

    constructor() {
        super();
        this.id = 0;
        this.provinceId = 0;
        this.townId = 0;
        this.suburbId = 0;
        this.districtId = 0;
        this.llgId = 0;
        this.wardId = 0;
        this.name = "";
    }
}
