import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { RouterLink } from '@angular/router';
import { NgClass, NgIf } from '@angular/common';
import { CustomizerSettingsService } from '../../../../../customizer-settings/customizer-settings.service';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { PngIdentityUser } from '../../../../../models/domain/pngIdentityUser';
import { ObjectUtility } from '../../../../core/utilities/ObjectUtility';
import { catchError, forkJoin, of, Subject, takeUntil } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { emailValidation, phoneFieldValidation, whitespaceValidator } from '../../../../core/validators/ValidationService';
import { LoadingService } from '../../../../core/services/loading.service';
import { Business } from '../../../../../models/domain/business';
import { BaseSearchOptions } from '../../../../../models/core/table/BaseSearchOptions';
import { BusinessService } from '../../../services/business.service';
import { UserService } from '../../../services/user.service';

@Component({
    selector: 'app-account-settings',
    templateUrl: './account-settings.component.html',
    styleUrl: './account-settings.component.scss'
})
export class AccountSettingsComponent implements OnInit {

    @Output() classApplied = new EventEmitter<boolean>();
    @Output() saveConfirmationEvent = new EventEmitter<boolean>();

    // isToggled
    isToggled = false;
    frmGroup: FormGroup;

    private destroy$: Subject<boolean> = new Subject<boolean>();
    public pngIdentityUserOriginal = new PngIdentityUser;
    public pngIdentityUser: PngIdentityUser = new PngIdentityUser;
    public titleText = "";
    public titleButton = "";
    public errorMessage = "";
    public currentPngIdentityUserId: string = '';
    isSubmitted = false;

    public businesses: Business[];
    public pageIndex: number = 0;
    public pageSize: number = 1000;
    public searchOptions: BaseSearchOptions;

    constructor(
        public themeService: CustomizerSettingsService,
        private formBuilder: FormBuilder,
        private pngIdentityUserService: UserService,
        private toasterService: ToastrService,
        private loadingService: LoadingService,
        private businessService: BusinessService,
    ) {
        this.initialize();
    }

    public ngOnInit(): void {
        this.pngIdentityUserService.getUser().pipe(takeUntil(this.destroy$)).subscribe(result => {
            this.pngIdentityUser = ObjectUtility.deepClone(result);
            this.pngIdentityUserOriginal = ObjectUtility.deepClone(result);
            this.setForm(this.pngIdentityUser);
        });
    }

    private initialize(): void {
        this.themeService.isToggled$.subscribe(isToggled => {
            this.isToggled = isToggled;
        });
        this.isSubmitted = false;
        this.searchOptions = new BaseSearchOptions(undefined, 'name', 0, this.pageIndex, this.pageSize);
        this.createForm();
    }

    public ngAfterViewInit(): void {
        this.frmGroup.controls['firstNameControl'].valueChanges.subscribe(value => {
            this.pngIdentityUser.firstName = value;
        });
        this.frmGroup.controls['lastNameControl'].valueChanges.subscribe(value => {
            this.pngIdentityUser.lastName = value;
        });
        this.frmGroup.controls['emailControl'].valueChanges.subscribe(value => {
            this.pngIdentityUser.email = value;
        });
        this.frmGroup.controls['phoneControl'].valueChanges.subscribe(value => {
            this.pngIdentityUser.phoneNumber = value;
        });


    }

    public ngOnDestroy(): void {
        this.destroy$.next(true);
        this.destroy$.unsubscribe();
    }

    get f() { return this.frmGroup.controls; }

    private createForm(): void {
        this.frmGroup = this.formBuilder.group({
            firstNameControl: new FormControl('', [Validators.required, whitespaceValidator()]),
            lastNameControl: new FormControl('', [Validators.required, whitespaceValidator()]),
            phoneControl: new FormControl('', [Validators.required, whitespaceValidator(), phoneFieldValidation()]),
            emailControl: new FormControl('', [Validators.required, whitespaceValidator(), emailValidation()]),

        })
    }

    private setForm(pngIdentityUser: PngIdentityUser): void {
        if (pngIdentityUser) {
            this.frmGroup.patchValue({
                firstNameControl: pngIdentityUser?.firstName,
                lastNameControl: pngIdentityUser?.lastName,
                emailControl: pngIdentityUser?.email,
                phoneControl: pngIdentityUser?.phoneNumber
            });
        }
        this.titleText = `${this.currentPngIdentityUserId === '' ? "Add" : "Edit"} Profile`;
        this.titleButton = `${this.currentPngIdentityUserId === '' ? "Create" : "Update"}`;
    }

    public onSave(): void {
        this.isSubmitted = true;
        this.loadingService.showLoading();

        if (this.frmGroup.valid) {
            this.addEditPngIdentityUser();
        }
    }

    private addEditPngIdentityUser(): void {
        this.loadingService.showLoading();
        this.pngIdentityUserService.updateUser(this.pngIdentityUser)
            .pipe(
                catchError((err: any) => {
                    this.loadingService.hideLoading();
                    if (err && err.error) {
                        this.isSubmitted = false;
                        if (err.error.isCustom) {
                            this.errorMessage = err.error.message;
                            this.toasterService.warning(err.error.message);
                        } else {
                            this.toasterService.error('Something went wrong!');
                        }
                    } else {
                        this.isSubmitted = false;
                        this.toasterService.error('Something went wrong!');
                    }
                    return of(null);
                }),
                takeUntil(this.destroy$))
            .subscribe(response => {
                this.loadingService.hideLoading();
                if (response) {
                    this.isSubmitted = false;
                    this.pngIdentityUser = response?.body != null ? response?.body : new PngIdentityUser;
                    this.saveConfirmationEvent.emit(true);
                    this.toasterService.success("Update successfully");
                    this.toggleClass();
                }
                this.loadingService.hideLoading();
            });
    }

    public toggleClass(): void {
        this.classApplied.emit(false);
    }

    public dataHasChanged(): boolean {
        const baseEqual = ObjectUtility.deepEqual(this.pngIdentityUser, this.pngIdentityUserOriginal);
        return !baseEqual;
    }

    public formIsValid(): boolean {
        let isValid = this.frmGroup.controls['firstNameControl'].valid
            && this.frmGroup.controls['lastNameControl'].valid
            && this.frmGroup.controls['emailControl'].valid
            && this.frmGroup.controls['phoneControl'].valid

        return isValid;
    }



}