import { Component, EventEmitter, Input, OnInit, Output, AfterViewInit, OnDestroy } from '@angular/core';
import { RouterLink } from '@angular/router';
import { NgClass, NgIf } from '@angular/common';
import { CustomizerSettingsService } from '../../../../../../customizer-settings/customizer-settings.service';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ServiceTypeService } from '../../../../services/serviceType.service';
import { ServiceType } from '../../../../../../models/domain/serviceType';
import { ObjectUtility } from '../../../../../core/utilities/ObjectUtility';
import { catchError, of, Subject, takeUntil } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { whitespaceValidator } from '../../../../../core/validators/ValidationService';
import { LoadingService } from '../../../../../core/services/loading.service';

@Component({
  selector: 'app-serviceType-details',
  templateUrl: './serviceType-details.component.html',
  styleUrl: './serviceType-details.component.scss'
})
export class ServiceTypeDetailsComponent implements OnInit, AfterViewInit, OnDestroy {

  @Output() classApplied = new EventEmitter<boolean>();
  @Output() saveConfirmationEvent = new EventEmitter<boolean>();
  @Input() data!: ServiceType;
  // isToggled
  isToggled = false;
  frmGroup: FormGroup;

  private destroy$: Subject<boolean> = new Subject<boolean>();
  public serviceTypeOriginal = new ServiceType;
  public serviceType: ServiceType = new ServiceType;
  public titleText = "";
  public titleButton = "";
  public errorMessage = "";
  public currentServiceTypeId = 0;
  isSubmitted = false;

  constructor(
    public themeService: CustomizerSettingsService,
    private formBuilder: FormBuilder,
    private serviceTypeService: ServiceTypeService,
    private toasterService: ToastrService,
    private loadingService: LoadingService
  ) {
    this.initialize();
  }

  public ngOnInit(): void {
    if (this.data) {
      this.currentServiceTypeId = this.data.id ? this.data.id : 0;
      this.serviceType = ObjectUtility.deepClone(this.data);
      this.serviceTypeOriginal = ObjectUtility.deepClone(this.data);
      this.setForm(this.serviceType);
    }
    else {
      this.currentServiceTypeId = 0;
      const newServiceType = new ServiceType();
      this.serviceType = ObjectUtility.deepClone(newServiceType);
      this.serviceTypeOriginal = ObjectUtility.deepClone(newServiceType);
      this.setForm(this.serviceType);
    }
  }

  private initialize(): void {
    this.themeService.isToggled$.subscribe(isToggled => {
      this.isToggled = isToggled;
    });
    this.isSubmitted = false;
    this.createForm();
  }

  public ngAfterViewInit(): void {

    this.frmGroup.controls['nameControl'].valueChanges.subscribe(value => {
      this.serviceType.name = value;
    });
    this.frmGroup.controls['descriptionControl'].valueChanges.subscribe(value => {
      this.serviceType.description = value;
    });


  }

  public ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  get f() { return this.frmGroup.controls; }

  private createForm(): void {
    this.frmGroup = this.formBuilder.group({
      nameControl: new FormControl('', [Validators.required, whitespaceValidator()]),
      descriptionControl: new FormControl('', [whitespaceValidator()]),
    })
  }

  private setForm(serviceType: ServiceType): void {
    if (serviceType) {
      this.frmGroup.patchValue({
        nameControl: serviceType.name,
        descriptionControl: serviceType.description,
      });
    }
    this.titleText = `${this.currentServiceTypeId === 0 ? "Add" : "Edit"} Service Type`;
    this.titleButton = `${this.currentServiceTypeId === 0 ? "Create" : "Update"}`;
  }

  public onSave(): void {
    this.isSubmitted = true;
    this.loadingService.showLoading();

    if (this.frmGroup.valid) {
      this.addEditServiceType();
    }
  }

  private addEditServiceType(): void {

    if (this.serviceType?.id === 0) {
      this.serviceTypeService.create(this.serviceType)
        .pipe(
          catchError((err: any) => {
            if (err && err.error) {
              this.isSubmitted = false;
              if (err.error.isCustom) {
                this.errorMessage = err.error.message;
                this.toasterService.warning(err.error.message);
              } else {
                this.toasterService.error('Something went wrong!');
              }
            } else {
              this.isSubmitted = false;
              this.toasterService.error('Something went wrong!');
            }

            return of(null);
          }),
          takeUntil(this.destroy$))
        .subscribe(response => {
          if (response) {
            this.isSubmitted = false;
            this.serviceType = response?.body != null ? response?.body : new ServiceType;
            this.saveConfirmationEvent.emit(true);
            this.toggleClass();
            this.toasterService.success("Save successfully");
          }
        });
    } else {
      this.serviceTypeService.update(this.serviceType)
        .pipe(
          catchError((err: any) => {
            if (err && err.error) {
              this.isSubmitted = false;
              if (err.error.isCustom) {
                this.errorMessage = err.error.message;
                this.toasterService.warning(err.error.message);
              } else {
                this.toasterService.error('Something went wrong!');
              }
            } else {
              this.isSubmitted = false;
              this.toasterService.error('Something went wrong!');
            }
            return of(null);
          }),
          takeUntil(this.destroy$))
        .subscribe(response => {
          if (response) {
            this.isSubmitted = false;
            this.serviceType = response?.body != null ? response?.body : new ServiceType;
            this.saveConfirmationEvent.emit(true);
            this.toasterService.success("Update successfully");
            this.toggleClass();
          }
        });
    }
  }


  public toggleClass(): void {
    this.classApplied.emit(false);
  }

  public dataHasChanged(): boolean {
    const baseEqual = ObjectUtility.deepEqual(this.serviceType, this.serviceTypeOriginal);
    return !baseEqual;
  }

  public formIsValid(): boolean {
    const isValid = this.frmGroup.controls['nameControl'].valid && this.frmGroup.controls['descriptionControl'].valid
    return isValid;
  }



}