import { BaseRecord } from "./BaseRecord";
import { Street } from "./street";


export class SectionNumber extends BaseRecord<number> {
    public number: string;
    public streetId: number;
    public street: Street | null;

    constructor() {
        super();
        this.id = 0;
        this.streetId = 0;
        this.number = "";
        this.street = new Street;
    }
}
