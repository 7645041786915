import { Component, OnInit } from '@angular/core';
import { CustomizerSettingsService } from '../../../../customizer-settings/customizer-settings.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
    selector: 'app-forgot-password',
    templateUrl: './forgot-password.component.html',
    styleUrl: './forgot-password.component.scss'
})
export class ForgotPasswordComponent  implements OnInit {

    // isToggled
    isToggled = false;
    forgotForm!: FormGroup;
    submitted = false;

    constructor(
        public themeService: CustomizerSettingsService,
        private formBuilder: FormBuilder
    ) {
        this.themeService.isToggled$.subscribe(isToggled => {
            this.isToggled = isToggled;
        });
    }

    ngOnInit(): void {
        this.initializer();
      }

    initializer(){
        this.forgotForm = this.formBuilder.group({
            email: ['', [Validators.required, Validators.email]],
          });
    }

     // convenience getter for easy access to form fields
     get f() { return this.forgotForm.controls; }

        /**
   * Form submit
   */
  onSubmit() {
    this.submitted = true;
    // stop here if form is invalid
    if (this.forgotForm.invalid) {
      return;
    }
  }
    
}