import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { BehaviorSubject, Observable, tap } from "rxjs";

import { BaseService } from "../../shared/services/base.service";
import { environment } from "../../../../environments/environment";
import { PngIdentityUser } from "../../../models/domain/pngIdentityUser";
import { LoadingService } from "../../core/services/loading.service";
import { ChangePassword } from "../../../models/domain/changePassword";

@Injectable({
    providedIn: "root"
})
export class UserService extends BaseService<PngIdentityUser> {
    private readonly rowCountSubject: BehaviorSubject<number>;
    public readonly rowCount$: Observable<number>;
    private http: HttpClient;


    constructor(http: HttpClient, loadingService: LoadingService) {
        super(http, `${environment.apiUrl}/v1/identity`, loadingService);
        this.http = http;
        this.rowCountSubject = new BehaviorSubject<number>(0);
        this.rowCount$ = this.rowCountSubject.asObservable();
    }

    public getUser() {
        return this.httpClient.get<PngIdentityUser>(`${this.baseApiUrl}/info`)
    }

    public updateUser(pngIdentityUser: PngIdentityUser) {
        return this.httpClient.put<PngIdentityUser>(`${this.baseApiUrl}/update-profile`, pngIdentityUser, { observe: "response" })
    }

    public changePassword(changePassword: ChangePassword) {
        return this.httpClient.post<any>(`${this.baseApiUrl}/change-password`, changePassword, { observe: "response" })
    }

    public resetPassword(resetPassword: ChangePassword) {
        return this.httpClient.post<any>(`${this.baseApiUrl}/resetPassword`, resetPassword, { observe: "response" })
    }



}
