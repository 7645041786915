import { BaseRecord } from "./BaseRecord";

export class ClientConfiguration extends BaseRecord<number> {
    public name: string;
    public email: string;
    public website: string;
    public serverUrl: string;
    public apiKeyName: string;
    public apiKey: string;
    public maxRequests: number;
    public timeWindowSeconds: number;



    constructor() {
        super();
        this.id = 0;
        this.name = "";
        this.email = "";
        this.website = "";
        this.serverUrl = "";
        this.apiKeyName = "";
        this.apiKey = "";
        this.maxRequests = 0;
        this.timeWindowSeconds = 0;
    }
}
