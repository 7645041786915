import { Component, EventEmitter, Input, OnInit, Output, AfterViewInit, OnDestroy } from '@angular/core';
import { RouterLink } from '@angular/router';
import { NgClass, NgIf } from '@angular/common';
import { CustomizerSettingsService } from '../../../../../../customizer-settings/customizer-settings.service';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Address } from '../../../../../../models/domain/address';
import { ObjectUtility } from '../../../../../core/utilities/ObjectUtility';
import { catchError, forkJoin, of, Subject, takeUntil } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { whitespaceValidator } from '../../../../../core/validators/ValidationService';
import { Province } from '../../../../../../models/domain/province';
import { ProvinceService } from '../../../../services/province.service';
import { AddressService } from '../../../../services/address.service';
import { LoadingService } from '../../../../../core/services/loading.service';
import { BaseSearchOptions } from '../../../../../../models/core/table/BaseSearchOptions';
import { District } from '../../../../../../models/domain/district';
import { Llg } from '../../../../../../models/domain/llg';
import { Ward } from '../../../../../../models/domain/ward';
import { Town } from '../../../../../../models/domain/town';
import { Suburb } from '../../../../../../models/domain/suburb';
import { SectionNumber } from '../../../../../../models/domain/sectionNumber';
import { AllotmentNumber } from '../../../../../../models/domain/allotmentNumber';
import { DistrictService } from '../../../../services/district.service';
import { LlgService } from '../../../../services/llg.service';
import { WardService } from '../../../../services/ward.service';
import { TownService } from '../../../../services/town.service ';
import { SuburbService } from '../../../../services/suburb.service';
import { StreetService } from '../../../../services/street.service';
import { SectionNumberService } from '../../../../services/sectionNumber.service';
import { AllotmentNumberService } from '../../../../services/allotmentNumber.service';
import { StreetRoad } from '../../../../../../models/domain/streetRoad';
import { Masters } from '../../../../../../models/enums/Masters';
import { Business } from '../../../../../../models/domain/business';
import { BusinessService } from '../../../../services/business.service';
import { BusinessAddressMapping } from '../../../../../../models/domain/businessAddressMapping';
import { AddressTypes } from '../../../../../../models/enums/AddressTypes';
import { PostOffice } from '../../../../../../models/domain/postOffice';
import { PostOfficeService } from '../../../../services/postOffice.service';

@Component({
  selector: 'app-address-details',
  templateUrl: './address-details.component.html',
  styleUrl: './address-details.component.scss'
})
export class AddressDetailsComponent implements OnInit, AfterViewInit, OnDestroy {

  @Output() classApplied = new EventEmitter<boolean>();
  @Output() saveConfirmationEvent = new EventEmitter<boolean>();
  @Input() currentAddressId = 0;
  // isToggled
  isToggled = false;
  frmGroup: FormGroup;

  private destroy$: Subject<boolean> = new Subject<boolean>();
  public addressOriginal = new Address;
  public address: Address = new Address;
  public titleText = "";
  public titleButton = "";
  public errorMessage = "";

  isSubmitted = false;
  addressTypes = Object.values(AddressTypes);
  selectedAddressType: string;
  addressType = AddressTypes;
  // drop down bind data
  public businesses: Business[];
  public provinces: Province[];
  public pageIndex = 0;
  public pageSize = 1000;
  public searchOptions: BaseSearchOptions;

  public districts: District[];
  public llgs: Llg[];
  public wards: Ward[];
  public towns: Town[];
  public suburbs: Suburb[];
  public streets: StreetRoad[];
  public sectionNumbers: SectionNumber[];
  public allotmentNumbers: AllotmentNumber[];
  public postOffice: PostOffice[];

  constructor(
    public themeService: CustomizerSettingsService,
    private formBuilder: FormBuilder,
    private addressService: AddressService,
    private toasterService: ToastrService,
    private provinceService: ProvinceService,
    private loadingService: LoadingService,
    private districtService: DistrictService,
    private llgService: LlgService,
    private wardService: WardService,
    private townService: TownService,
    private suburbService: SuburbService,
    private streetService: StreetService,
    private postOfficeService: PostOfficeService,
    private sectionNumberService: SectionNumberService,
    private allotmentNumberService: AllotmentNumberService,
    private businessService: BusinessService,
  ) {
    this.initialize();
  }

  public ngOnInit(): void {
    if (this.currentAddressId > 0) {
      this.getDropdownData();

    }
    else {
      this.currentAddressId = 0;
      this.getDropdownData();
      const newAddress = new Address();
      this.address = ObjectUtility.deepClone(newAddress);
      this.addressOriginal = ObjectUtility.deepClone(newAddress);
      this.setForm(this.address);
    }
  }

  private initialize(): void {
    this.themeService.isToggled$.subscribe(isToggled => {
      this.isToggled = isToggled;
    });
    this.isSubmitted = false;
    this.searchOptions = new BaseSearchOptions(undefined, 'name', 0, this.pageIndex, this.pageSize);
    this.createForm();
  }

  public ngAfterViewInit(): void {

    this.frmGroup.controls['businessControl'].valueChanges.subscribe(value => {
      this.address.businessAddressMapping = [];
      const _businessAddressMapping = new BusinessAddressMapping;
      _businessAddressMapping.business = value;
      this.address.businessAddressMapping.push(_businessAddressMapping);
    });

    this.frmGroup.controls['addressTypeControl'].valueChanges.subscribe(value => {
      this.address.addressType = value;
      this.onAddressTypeChange(value);
    });

    this.frmGroup.controls['provinceControl'].valueChanges.subscribe(value => {
      this.address.province = value;

    });

    this.frmGroup.controls['districtControl'].valueChanges.subscribe(value => {
      this.address.district = value;
    });

    this.frmGroup.controls['llgControl'].valueChanges.subscribe(value => {
      this.address.llg = value;
    });

    this.frmGroup.controls['wardControl'].valueChanges.subscribe(value => {
      this.address.ward = value;
    });

    this.frmGroup.controls['townControl'].valueChanges.subscribe(value => {
      this.address.town = value;
    });

    this.frmGroup.controls['suburbControl'].valueChanges.subscribe(value => {
      this.address.suburb = value;

    });
    this.frmGroup.controls['streetControl'].valueChanges.subscribe(value => {
      if (value && value?.streetOrRoad == "Street") {
        this.address.street = value;
      } else {
        this.address.roads = value;
      }

    });

    this.frmGroup.controls['smallVillageAndBuildingNameControl'].valueChanges.subscribe(value => {
      this.address.smallVillageAndBuildingName = value;
    });

    this.frmGroup.controls['sectionNumberControl'].valueChanges.subscribe(value => {
      this.address.sectionNumber = value;
    });

    this.frmGroup.controls['allotmentNumberControl'].valueChanges.subscribe(value => {
      this.address.allotmentNumber = value;
    });

    this.frmGroup.controls['postOfficeControl'].valueChanges.subscribe(value => {
      this.address.postOffice = value;
    });

    this.frmGroup.controls['postalCodeControl'].valueChanges.subscribe(value => {
      this.address.postalCode = value;
    });

    this.frmGroup.controls['landmarkControl'].valueChanges.subscribe(value => {
      this.address.landmark = value;
    });

    this.frmGroup.controls['boxNumberControl'].valueChanges.subscribe(value => {
      this.address.boxNumber = value;
    });

  }

  public ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  get f() { return this.frmGroup.controls; }

  private createForm(): void {
    this.frmGroup = this.formBuilder.group({
      businessControl: new FormControl(null, [Validators.required]),
      provinceControl: new FormControl(null, [Validators.required]),
      districtControl: new FormControl(null, [Validators.required]),
      llgControl: new FormControl(null, [Validators.required]),
      wardControl: new FormControl(null, [Validators.required]),
      townControl: new FormControl({ value: null, disabled: true }, [Validators.required]),
      suburbControl: new FormControl(null, [Validators.required]),
      streetControl: new FormControl(null, [Validators.required]),
      addressTypeControl: [null, Validators.required],
      // smallVillageNameControl: new FormControl('', [Validators.required, whitespaceValidator()]),
      // sectionNumberControl: new FormControl('', []),
      // allotmentNumberControl: new FormControl('', []),
      // postalCodeControl: new FormControl('', [whitespaceValidator()]),
      // landmarkControl: new FormControl('', [whitespaceValidator()])
      smallVillageAndBuildingNameControl: new FormControl('', []),
      sectionNumberControl: new FormControl(null, []),
      allotmentNumberControl: new FormControl(null, []),
      postOfficeControl: new FormControl(null, []),
      postalCodeControl: new FormControl('', []),
      landmarkControl: new FormControl('', []),
      boxNumberControl: new FormControl('', [])
    })
  }

  private setForm(address: Address): void {
    if (address && address.id != undefined && address.id > 0) {


      const stRoad = new StreetRoad();
      stRoad.id = address?.street != null ? address.street.id : address.roads?.id;
      stRoad.name = address?.street != null ? address.street.name : address.roads?.name;
      stRoad.streetOrRoad = address?.street != null ? 'Street' : 'Road';

      this.frmGroup.patchValue({
        addressTypeControl: address?.addressType,
        businessControl: address?.businessAddressMapping[0]?.business,
        provinceControl: address?.province,
        districtControl: address?.district,
        llgControl: address?.llg,
        wardControl: address?.ward,
        townControl: address?.town,
        suburbControl: address?.suburb,
        streetControl: stRoad,
        smallVillageAndBuildingNameControl: address?.smallVillageAndBuildingName,
        sectionNumberControl: address?.sectionNumber,
        allotmentNumberControl: address?.allotmentNumber,
        postOfficeControl: address?.postOffice,
        postalCodeControl: address?.postalCode,
        landmarkControl: address?.landmark,
        boxNumberControl: address?.boxNumber

      });

    }
    this.titleText = `${this.currentAddressId === 0 ? "Add" : "Edit"} Address`;
    this.titleButton = `${this.currentAddressId === 0 ? "Create" : "Update"}`;
  }

  public onSave(): void {
    this.isSubmitted = true;
    this.loadingService.showLoading();

    if (this.frmGroup.valid) {
      this.addEditAddress();
    }
  }

  private addEditAddress(): void {
    if (this.address?.id === 0) {
      this.addressService.create(this.sanitizeAddress(this.address))
        .pipe(
          catchError((err: any) => {
            if (err && err.error) {
              this.isSubmitted = false;
              if (err.error.isCustom) {
                this.errorMessage = err.error.message;
                this.toasterService.warning(err.error.message);
              } else {
                this.toasterService.error('Something went wrong!');
              }

            } else {
              this.isSubmitted = false;
              this.toasterService.error('Something went wrong!');
            }
            return of(null);
          }),
          takeUntil(this.destroy$))
        .subscribe(response => {
          if (response) {
            this.isSubmitted = false;
            this.address = response?.body != null ? response?.body : new Address;
            this.saveConfirmationEvent.emit(true);
            this.toggleClass();
            this.toasterService.success("Save successfully");
            // close popup
          }
        });
    } else {
      this.addressService.update(this.sanitizeAddress(this.address))
        .pipe(
          catchError((err: any) => {
            if (err && err.error) {
              this.isSubmitted = false;
              if (err.error.isCustom) {
                this.errorMessage = err.error.message;
                this.toasterService.warning(err.error.message);
              } else {
                this.toasterService.error('Something went wrong!');
              }
              //this.toasterService.error(err.error.mesage);
            } else {
              this.isSubmitted = false;
              this.toasterService.error('Something went wrong!');
            }
            return of(null);
          }),
          takeUntil(this.destroy$))
        .subscribe(response => {
          if (response) {
            this.isSubmitted = false;
            this.address = response?.body != null ? response?.body : new Address;
            this.saveConfirmationEvent.emit(true);
            this.toasterService.success("Update successfully");
            this.toggleClass();
          }
        });
    }
  }


  public toggleClass(): void {
    this.classApplied.emit(false);
  }

  private getDropdownData() {
    const province = this.provinceService.get(this.searchOptions);
    const business = this.businessService.get(this.searchOptions);
    this.loadingService.showLoading();
    forkJoin([province, business])
      .pipe(takeUntil(this.destroy$)).subscribe((result: any) => {
        if (result) {
          this.provinces = result[0].body.values;
          this.businesses = result[1].body.values;
          this.getAddressById(this.currentAddressId);
        }
        this.loadingService.hideLoading();
      });
  }

  private sanitizeAddress(address: Address): Address {
    //address.businessAddressMapping = null;
    if (address.businessAddressMapping.length > 0) {
      address.businessAddressMapping[0].business = null;
      address.businessAddressMapping[0].businessId = this.frmGroup.controls['businessControl'].value.id;
    }

    address.province = null;
    address.provinceId = this.frmGroup.controls['provinceControl'].value.id;

    address.district = null;
    address.districtId = this.frmGroup.controls['districtControl'].value.id;

    address.llg = null;
    address.llgId = this.frmGroup.controls['llgControl'].value.id;

    address.ward = null;
    address.wardId = this.frmGroup.controls['wardControl'].value.id;

    address.town = null;
    address.townId = this.frmGroup.controls['townControl'].value.id;

    address.suburb = null;
    address.suburbId = this.frmGroup.controls['suburbControl'].value.id;

    address.street = null;
    address.roads = null;

    if (this.frmGroup.controls['streetControl'].value.streetOrRoad == "Street") {
      address.streetId = this.frmGroup.controls['streetControl'].value.id == 0 ? null : this.frmGroup.controls['streetControl'].value.id;
      address.roadsId = null;
    } else {
      address.roadsId = this.frmGroup.controls['streetControl'].value.id == 0 ? null : this.frmGroup.controls['streetControl'].value.id;
      address.streetId = null;
    }

    address.sectionNumber = null;
    address.sectionNumberId = this.frmGroup.controls['sectionNumberControl'].value == null || this.frmGroup.controls['sectionNumberControl'].value.id == 0 ? null : this.frmGroup.controls['sectionNumberControl'].value.id;

    address.allotmentNumber = null;
    address.allotmentNumberId = this.frmGroup.controls['allotmentNumberControl'].value == null || this.frmGroup.controls['allotmentNumberControl'].value.id == 0 ? null : this.frmGroup.controls['allotmentNumberControl'].value.id;

    address.postOffice = null;
    if (this.selectedAddressType == AddressTypes.PostalAddress) {
      address.postOfficeId = this.frmGroup.controls['postOfficeControl'].value.id;
    } else {
      address.postOfficeId = null;
    }
    return address;
  }

  //#region Comparer Functions
  public businessComparer(t1: Business, t2: Business): boolean {
    return t1 && t2 ? (t1.id === t2.id || t1.name === t2.name) : t1 === t2;
  }

  public provinceComparer(t1: Province, t2: Province): boolean {
    return t1 && t2 ? (t1.id === t2.id || t1.code === t2.code) : t1 === t2;
  }

  public districtComparer(t1: District, t2: District): boolean {
    return t1 && t2 ? (t1.id === t2.id || t1.name === t2.name) : t1 === t2;
  }

  public llgComparer(t1: Llg, t2: Llg): boolean {
    return t1 && t2 ? (t1.id === t2.id || t1.name === t2.name) : t1 === t2;
  }

  public wardComparer(t1: Ward, t2: Ward): boolean {
    return t1 && t2 ? (t1.id === t2.id || t1.name === t2.name) : t1 === t2;
  }

  public townComparer(t1: Town, t2: Town): boolean {
    return t1 && t2 ? (t1.id === t2.id || t1.name === t2.name) : t1 === t2;
  }

  public suburbComparer(t1: Suburb, t2: Suburb): boolean {
    return t1 && t2 ? (t1.id === t2.id || t1.name === t2.name) : t1 === t2;
  }

  public streetComparer(t1: StreetRoad, t2: StreetRoad): boolean {
    return t1 && t2 ? (t1.id === t2.id && t1.name === t2.name) : t1 === t2;
  }

  public sectionNumberComparer(t1: SectionNumber, t2: SectionNumber): boolean {
    return t1 && t2 ? (t1.id === t2.id || t1.number === t2.number) : t1 === t2;
  }

  public allotmentNumberComparer(t1: AllotmentNumber, t2: AllotmentNumber): boolean {
    return t1 && t2 ? (t1.id === t2.id || t1.number === t2.number) : t1 === t2;
  }

  public addressTypeComparer(t1: string, t2: string): boolean {
    return t1 && t2 ? (t1 === t2) : t1 === t2;
  }

  public postOfficeComparer(t1: PostOffice, t2: PostOffice): boolean {
    return t1 && t2 ? (t1 === t2) : t1 === t2;
  }

  //#endregion Comparer Functions

  //#region Get Dropdown Data
  public getDistrictByProvince(provinceId: number | undefined) {
    if (provinceId && provinceId > 0) {
      this.loadingService.showLoading();
      this.districtService.getDistrictsByProvince(provinceId).subscribe(response => {
        this.districts = response;
        this.loadingService.hideLoading();
      });
    }
  }

  public GetLlgByDistrict(districtId: number | undefined) {
    if (districtId && districtId > 0) {
      this.loadingService.showLoading();
      this.llgService.GetLlgByDistrict(districtId).subscribe(response => {
        this.llgs = response;
        this.loadingService.hideLoading();
      });
    }
  }

  public GetWardByLlg(llgId: number | undefined) {
    if (llgId && llgId > 0) {
      this.loadingService.showLoading();
      this.wardService.GetWardByLlg(llgId).subscribe(response => {
        this.wards = response;
        this.loadingService.hideLoading();
      });
    }
  }

  public GetTownByProvince(provinceId: number | undefined) {
    if (provinceId && provinceId > 0) {
      this.loadingService.showLoading();
      this.townService.GetTownByProvince(provinceId).subscribe(response => {
        this.towns = response;
        const firsttown = this.towns[0] || '';
        this.frmGroup.patchValue({ townControl: firsttown });
        this.address.town = firsttown;
        this.GetSuburbByTown(firsttown.id);
        this.loadingService.hideLoading();
      });
    }
  }

  public GetPostOfficeByProvince(provinceId: number | undefined) {
    if (provinceId && provinceId > 0) {
      this.loadingService.showLoading();
      this.postOfficeService.GetPostOfficeByProvince(provinceId).subscribe(response => {
        this.postOffice = response;
        const firstPost = this.postOffice[0] || '';
        this.frmGroup.patchValue({ postOfficeControl: firstPost });
        this.address.postOffice = firstPost;
        this.frmGroup.patchValue({ postalCodeControl: firstPost.boxNumber });
        this.address.postalCode = firstPost.boxNumber;
        this.loadingService.hideLoading();
      });
    }
  }

  public GetSuburbByTown(townId: number | undefined) {
    if (townId && townId > 0) {
      this.loadingService.showLoading();
      this.suburbService.GetSuburbByTown(townId).subscribe(response => {
        this.suburbs = response;
        this.loadingService.hideLoading();
      });
    }
  }

  public GetstreetAndRoadsBySuburb(suburbId: number | undefined) {
    if (suburbId && suburbId > 0) {
      this.loadingService.showLoading();
      this.streetService.GetstreetAndRoadsBySuburb(suburbId).subscribe(response => {
        this.streets = response;
        this.loadingService.hideLoading();
      });
    }
  }

  public GetSectionNumberByStreet(streetId: number | undefined) {
    if (streetId && streetId > 0) {
      this.loadingService.showLoading();
      this.sectionNumberService.GetSectionNumberByStreet(streetId).subscribe(response => {
        this.sectionNumbers = response;
        this.loadingService.hideLoading();
      });
    }
  }
  public GetSectionNumberByRoad(roadId: number | undefined | null) {
    if (roadId && roadId > 0) {
      this.loadingService.showLoading();
      this.sectionNumberService.GetSectionNumberByRoad(roadId).subscribe(response => {
        this.sectionNumbers = response;
        this.loadingService.hideLoading();
      });
    }
  }

  public GetAllotmentNumberBySectionNumber(sectionNumberId: number | undefined) {
    if (sectionNumberId && sectionNumberId > 0) {
      this.loadingService.showLoading();
      this.allotmentNumberService.GetAllotmentNumberBySectionNumber(sectionNumberId).subscribe(response => {
        this.allotmentNumbers = response;
        this.loadingService.hideLoading();
      });
    }
  }

  public GetAllDropDownData(address: Address) {
    if (this.address.addressType == AddressTypes.PostalAddress) {
      this.GetPostOfficeByProvince(this.address.provinceId);
    }

    this.getDistrictByProvince(this.address.provinceId);
    this.GetLlgByDistrict(this.address.districtId);
    this.GetWardByLlg(this.address.llgId);
    this.GetTownByProvince(this.address.provinceId);
    this.GetSuburbByTown(this.address.townId);
    this.GetstreetAndRoadsBySuburb(this.address.suburbId);
    if (this.address.streetId) {
      this.GetSectionNumberByStreet(this.address.streetId);
    } else {
      this.GetSectionNumberByRoad(this.address.roadsId);
    }
    this.GetAllotmentNumberBySectionNumber(this.address.sectionNumberId);
  }

  //#endregion Get Dropdown Data

  public dataHasChanged(): boolean {
    const baseEqual = ObjectUtility.deepEqual(this.address, this.addressOriginal);
    return !baseEqual;
  }

  public formIsValid(): boolean {
    const isValid = this.frmGroup.controls['businessControl'].valid
      && this.frmGroup.controls['provinceControl'].valid
      && this.frmGroup.controls['districtControl'].valid
      && this.frmGroup.controls['llgControl'].valid
      && this.frmGroup.controls['wardControl'].valid
      //&& this.frmGroup.controls['townControl'].valid
      && this.frmGroup.controls['suburbControl'].valid
      && this.frmGroup.controls['streetControl'].valid
      //&& this.frmGroup.controls['smallVillageAndBuildingNameControl'].valid
      && this.frmGroup.controls['sectionNumberControl'].valid
      && this.frmGroup.controls['allotmentNumberControl'].valid
      && this.frmGroup.controls['postOfficeControl'].valid

      && (this.address.town?.id != 0
        || this.address.town?.id != null)
    return isValid;
  }

  public clearDropDownData(masterName: Masters): void {
    if (masterName == Masters.Province) {

      this.frmGroup.controls['districtControl'].setValue(null);
      this.districts = [];
      this.address.district = null;

      this.frmGroup.controls['llgControl'].setValue(null);
      this.llgs = [];
      this.address.llg = null;

      this.frmGroup.controls['wardControl'].setValue(null);
      this.wards = [];
      this.address.ward = null;

      this.frmGroup.controls['townControl'].setValue(null);
      this.towns = [];
      this.address.town = null;

      this.frmGroup.controls['suburbControl'].setValue(null);
      this.suburbs = [];
      this.address.suburb = null;

      this.frmGroup.controls['streetControl'].setValue(null);
      this.streets = [];
      this.address.street = null;

      this.frmGroup.controls['sectionNumberControl'].setValue(null);
      this.sectionNumbers = [];
      this.address.sectionNumber = null;

      this.frmGroup.controls['allotmentNumberControl'].setValue(null);
      this.allotmentNumbers = [];
      this.address.allotmentNumber = null;

      this.frmGroup.controls['postOfficeControl'].setValue(null);
      this.postOffice = [];
      this.address.postOffice = null;

    } else if (masterName == Masters.District) {
      this.frmGroup.controls['llgControl'].setValue(null);
      this.llgs = [];
      this.address.llg = null;

      this.frmGroup.controls['wardControl'].setValue(null);
      this.wards = [];
      this.address.ward = null;

      this.frmGroup.controls['streetControl'].setValue(null);
      this.streets = [];
      this.address.street = null;

      this.frmGroup.controls['sectionNumberControl'].setValue(null);
      this.sectionNumbers = [];
      this.address.sectionNumber = null;

      this.frmGroup.controls['allotmentNumberControl'].setValue(null);
      this.allotmentNumbers = [];
      this.address.allotmentNumber = null;

    } else if (masterName == Masters.Llg) {
      this.frmGroup.controls['wardControl'].setValue(null);
      this.wards = [];
      this.address.ward = null;

      this.frmGroup.controls['streetControl'].setValue(null);
      this.streets = [];
      this.address.street = null;

      this.frmGroup.controls['sectionNumberControl'].setValue(null);
      this.sectionNumbers = [];
      this.address.sectionNumber = null;

      this.frmGroup.controls['allotmentNumberControl'].setValue(null);
      this.allotmentNumbers = [];
      this.address.allotmentNumber = null;

    } else if (masterName == Masters.Town) {
      this.frmGroup.controls['suburbControl'].setValue(null);
      this.suburbs = [];
      this.address.suburb = null;

      this.frmGroup.controls['streetControl'].setValue(null);
      this.streets = [];
      this.address.street = null;

      this.frmGroup.controls['sectionNumberControl'].setValue(null);
      this.sectionNumbers = [];
      this.address.sectionNumber = null;

      this.frmGroup.controls['allotmentNumberControl'].setValue(null);
      this.allotmentNumbers = [];
      this.address.allotmentNumber = null;

    } else if (masterName == Masters.Suburb) {
      this.frmGroup.controls['streetControl'].setValue(null);
      this.streets = [];
      this.address.street = null;

      this.frmGroup.controls['sectionNumberControl'].setValue(null);
      this.sectionNumbers = [];
      this.address.sectionNumber = null;

      this.frmGroup.controls['allotmentNumberControl'].setValue(null);
      this.allotmentNumbers = [];
      this.address.allotmentNumber = null;

    } else if (masterName == Masters.Street) {
      this.frmGroup.controls['sectionNumberControl'].setValue(null);
      this.sectionNumbers = [];
      this.address.sectionNumber = null;

      this.frmGroup.controls['allotmentNumberControl'].setValue(null);
      this.allotmentNumbers = [];
      this.address.allotmentNumber = null;

    } else if (masterName == Masters.SectionNumber) {
      this.frmGroup.controls['allotmentNumberControl'].setValue(null);
      this.allotmentNumbers = [];
      this.address.allotmentNumber = null;
    }

  }

  public getAddressById(addressId: number) {
    if (addressId) {
      this.loadingService.showLoading();
      this.addressService.getById(addressId)
        .pipe(takeUntil(this.destroy$))
        .subscribe(result => {
          if (result) {
            this.address = ObjectUtility.deepClone(result);
            this.addressOriginal = ObjectUtility.deepClone(result);
            this.GetAllDropDownData(this.address);
            this.setForm(this.address);
          }
          this.loadingService.hideLoading();
        });
    }

  }

  //#region Change events
  public onAddressTypeChange(value: AddressTypes) {
    this.selectedAddressType = value.toString();
    if (value == this.addressType.PostalAddress) {
      this.frmGroup.controls['postalCodeControl'].disable({ emitEvent: false });
    } else {
      this.frmGroup.controls['postalCodeControl'].enable({ emitEvent: false });
    }
  }

  public onProvinceChange(provinceId: number) {
    this.clearDropDownData(Masters.Province);
    this.getDistrictByProvince(provinceId);
    this.GetTownByProvince(provinceId);
    this.GetPostOfficeByProvince(provinceId);
  }

  public onDistrictChange(districtId: number) {
    this.clearDropDownData(Masters.District);
    this.GetLlgByDistrict(districtId);
  }

  public onLlgChange(llgId: number) {
    this.clearDropDownData(Masters.Llg);
    this.GetWardByLlg(llgId);
  }

  public onSuburbChange(suburbId: number) {
    this.clearDropDownData(Masters.Suburb);
    this.GetstreetAndRoadsBySuburb(suburbId);
  }

  public onTownChange(townId: number) {
    this.clearDropDownData(Masters.Town);
    this.GetSuburbByTown(townId);
  }

  public onStreetChange(streetRoad: StreetRoad) {
    this.clearDropDownData(Masters.Street);
    if (streetRoad.streetOrRoad == "Street") {
      if (this.address != null && this.address.street) {
        this.GetSectionNumberByStreet(this.address.street.id);
      }

    } else {
      if (this.address != null && this.address.roads) {
        this.GetSectionNumberByRoad(this.address.roads.id);
      }
    }

  }

  public onSectionNumberChange(sectionNumberId: number) {
    this.clearDropDownData(Masters.SectionNumber);
    this.GetAllotmentNumberBySectionNumber(sectionNumberId);
  }

  public onPostOfficeChange(postOffice: PostOffice) {
    this.frmGroup.patchValue({ postalCodeControl: postOffice.boxNumber });
    this.address.postalCode = postOffice.boxNumber
  }

  //#endregion Change events

}