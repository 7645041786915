import { NgModule } from '@angular/core';
import { CommonModule, NgClass, NgIf } from '@angular/common';
import { HeaderComponent } from './components/header/header.component';
import { RouterLink, RouterLinkActive } from '@angular/router';
import { SidebarComponent } from './components/sidebar/sidebar.component';
import { NgScrollbarModule } from 'ngx-scrollbar';
import { FooterComponent } from './components/footer/footer.component';



@NgModule({
  declarations: [HeaderComponent, SidebarComponent, 
    FooterComponent],
  imports: [
    CommonModule,
    RouterLink,
     NgClass, 
     NgIf,
     NgScrollbarModule,
     RouterLinkActive
  ],
  exports:[HeaderComponent, SidebarComponent, 
    FooterComponent]
})
export class SharedModule { }
