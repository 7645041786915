export enum Masters {
  Province,
  District,
  Llg,
  Ward,
  Town,
  Suburb,
  Street,
  SectionNumber,
  AllotmentNumber
}
