import { BaseRecord } from "../domain/BaseRecord";


export class TownView extends BaseRecord<number> {
    public name: string;
    public provinceName: string;
    public districtName: string;

    constructor() {
        super();
        this.name = "";
        this.provinceName = "";
        this.districtName = "";
    }
}
