import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { RouterLink } from '@angular/router';
import {
    ApexGrid,
    ApexFill,
    ApexYAxis,
    ApexXAxis,
    ApexChart,
    ApexLegend,
    ApexStroke,
    ApexTooltip,
    ChartComponent,
    ApexDataLabels,
    ApexPlotOptions,
    NgApexchartsModule,
    ApexAxisChartSeries,
    ApexTitleSubtitle
} from "ng-apexcharts";
import { CustomizerSettingsService } from '../../../../../../customizer-settings/customizer-settings.service';
import { DashboardService } from '../../../../services/dashboard.service';

export type ChartOptions = {
    fill: ApexFill;
    grid: ApexGrid;
    chart: ApexChart;
    yaxis: ApexYAxis;
    xaxis: ApexXAxis;
    colors: string[];
    stroke: ApexStroke;
    legend: ApexLegend;
    tooltip: ApexTooltip;
    dataLabels: ApexDataLabels;
    series: ApexAxisChartSeries;
    plotOptions: ApexPlotOptions;
    title: ApexTitleSubtitle
};

@Component({
    selector: 'png-table-data-count',
    standalone: true,
    imports: [RouterLink, NgApexchartsModule],
    templateUrl: './table-data-count.component.html',
    styleUrl: './table-data-count.component.scss'
})
export class TableDataCountComponent implements OnInit {

    @ViewChild("chart") chart: ChartComponent;
    public chartOptions: Partial<ChartOptions>;

    // isToggled
    isToggled = false;
    totalRecords: number = 0;

    constructor(
        public themeService: CustomizerSettingsService,
        public dashboardService: DashboardService,
        private cdr: ChangeDetectorRef
    ) {

        this.chartOptions = {
            series: [
                {
                    name: "Total Records",
                    data: [],//[2.3, 3.1, 4.0, 10.1, 4.0, 3.6, 3.2, 2.3, 1.4, 0.8, 0.5, 0.2]
                },
            ],
            chart: {
                height: 350,
                type: "bar"
            },
            plotOptions: {
                bar: {
                    dataLabels: {
                        position: "top" // top, center, bottom
                    }
                }
            },
            dataLabels: {
                enabled: true,
                formatter: function (val) {
                    return val.toString();
                },
                offsetY: -20,
                style: {
                    fontSize: "12px",
                    colors: ["#304758"]
                }
            },
            xaxis: {
                type: "category",
                categories: [],
                /*categories: [
                    "Jan",
                    "Feb",
                    "Mar",
                    "Apr",
                    "May",
                    "Jun",
                    "Jul",
                    "Aug",
                    "Sep",
                    "Oct",
                    "Nov",
                    "Dec"
                ],*/
                position: "top",
                labels: {
                    offsetY: -18
                },
                axisBorder: {
                    show: false
                },
                axisTicks: {
                    show: false
                },
                crosshairs: {
                    fill: {
                        type: "gradient",
                        gradient: {
                            colorFrom: "#D8E3F0",
                            colorTo: "#BED1E6",
                            stops: [0, 100],
                            opacityFrom: 0.4,
                            opacityTo: 0.5
                        }
                    }
                },
                tooltip: {
                    enabled: true,
                    offsetY: -35
                }
            },
            fill: {
                type: "gradient",
                gradient: {
                    shade: "light",
                    type: "horizontal",
                    shadeIntensity: 0.25,
                    gradientToColors: undefined,
                    inverseColors: true,
                    opacityFrom: 1,
                    opacityTo: 1,
                    stops: [50, 0, 100, 100]
                }
            },
            yaxis: {
                axisBorder: {
                    show: false
                },
                axisTicks: {
                    show: false
                },
                labels: {
                    show: false,
                    formatter: function (val) {
                        return val.toString();
                    }
                }
            },
            title: {
                text: "All Table Data Records",
                floating: false,
                offsetY: 320,
                align: "center",
                style: {
                    color: "#444"
                }
            }
        };

        this.themeService.isToggled$.subscribe(isToggled => {
            this.isToggled = isToggled;
        });
    }

    ngOnInit(): void {
        this.dashboardService.getAllTableDataCount().subscribe(response => {
            if (!response.body) {
                return;
            }

            this.totalRecords = response.body.map((item) => Number(item.total)).reduce((a, b) => a + b, 0);
            const data = response.body.map((item) => Number(item.total));
            const rawDates = response.body.map((item) => (item.tableName));

            this.chartOptions = {
                ...this.chartOptions,
                series: [{
                    name: "Count",
                    data: data
                }],
                xaxis: {
                    categories: rawDates
                }
            };

            // Trigger change detection to update the chart
            this.cdr.detectChanges();

        });

    }
}