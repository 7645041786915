import { BaseRecord } from "./BaseRecord";
import { PostOffice } from "./postOffice";
import { Province } from "./province";
import { Roads } from "./roads";
import { Street } from "./street";
import { Suburb } from "./suburb";
import { Town } from "./town";


export class StreetDetails extends BaseRecord<number> {
    public name: string;
    public provinceId: number;
    public province: Province | null;
    public townId: number;
    public town: Town | null;
    public suburbId: number;
    public suburb: Suburb | null;
    public streetId: number | null;
    public street: Street | null;
    public roadsId: number | null;
    public roads: Roads | null;

    constructor() {
        super();
        this.id = 0;
        this.provinceId = 0;
        this.townId = 0;
        this.suburbId = 0;
        this.streetId = 0;
        this.roadsId = 0;
        this.name = "";
    }
}
