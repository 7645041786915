import { HttpClient, HttpParams, HttpResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable, throwError } from "rxjs";
import { catchError, tap } from "rxjs/operators";
import { PagedResponse } from "../../../models/domain/PagedResponse";
import { BaseSearchOptions } from "../../../models/core/table/BaseSearchOptions";
import { LoadingService } from "../../core/services/loading.service";

@Injectable({
    providedIn: "root"
})
export abstract class BaseService<T> {
    protected currentParameters: BaseSearchOptions;
    protected readonly baseApiUrl: string;
    protected dataSubject: BehaviorSubject<T[]>;

    private dataCountSubject: BehaviorSubject<number>;
    public dataCount$: Observable<number>;
    public dataCount: number;

    public data$: Observable<T[]>;
    private data: T[] | undefined;

    protected constructor(protected httpClient: HttpClient, baseApiUrl: string,
        private loadingService: LoadingService,
        baseQueryParams?: BaseSearchOptions
    ) {
        this.data = [];
        this.dataSubject = new BehaviorSubject<T[]>([]);
        this.data$ = this.dataSubject.asObservable();
        this.currentParameters = baseQueryParams ?? new BaseSearchOptions('', '', 0, 0, 10, true);
        this.baseApiUrl = baseApiUrl;

        this.dataCountSubject = new BehaviorSubject<number>(0);
        this.dataCount$ = this.dataCountSubject.asObservable();
        this.getRecordCount();
    }

    public getById(id: number): Observable<T> {
        this.loadingService.showLoading();
        return this.httpClient.get<any>(`${this.baseApiUrl}/${id}`)
            .pipe(tap(response => {
                this.loadingService.hideLoading();

                // this.data?.push(response);
                // this.dataSubjectUpdate(this.data ?? []);
            }),
                catchError(error => {
                    // Propagate the error properly to the ErrorInterceptor
                    this.loadingService.hideLoading();

                    return throwError(() => error); // Make sure to throw the error properly
                })
            );
    }

    public get(parameters?: BaseSearchOptions): Observable<HttpResponse<PagedResponse<T>>> {
        if (parameters) {
            this.currentParameters.updateByObject(parameters);
        }
        const params = new HttpParams()
            .set("filter", this.currentParameters.filter)
            .set("orderBy", this.currentParameters.orderBy)
            .set("pageSize", this.currentParameters.pageSize)
            .set("pageIndex", this.currentParameters.pageIndex)
            .set("orderByDirection", this.currentParameters.orderByDirection)
            .set("expand", this.currentParameters.expand);

        this.loadingService.showLoading();

        return this.httpClient.get<PagedResponse<T>>(this.baseApiUrl, { params, observe: "response" })
            .pipe(tap(response => {
                this.loadingService.hideLoading();

                this.data = response?.body?.values;
                this.dataSubjectUpdate(this.data ?? []);
            }),
                catchError(error => {
                    // Propagate the error properly to the ErrorInterceptor
                    this.loadingService.hideLoading();

                    return throwError(() => error); // Make sure to throw the error properly
                })
            );
    }

    /*public getAdvance(advanceSearchCriteria?: AdvanceSearchCriteria): Observable<HttpResponse<PagedResponse<T>>> {
        return this.httpClient.post<PagedResponse<T>>(`${this.baseApiUrl}/`, advanceSearchCriteria, { observe: "response" })
        .pipe(tap(response => {
           // this.data = response.body.items;
            //this.dataSubjectUpdate(this.data);
        }));
    }*/

    public create(obj: T): Observable<HttpResponse<T>> {
        this.loadingService.showLoading();
        return this.httpClient.post<T>(this.baseApiUrl, obj, { observe: "response" })
            .pipe(tap(response => {
                this.loadingService.hideLoading();

                if (response.ok) {
                    this.data?.push(response.body as T);
                    this.dataSubject.next(this.data ?? []);
                }
            }),
                catchError(error => {
                    // Propagate the error properly to the ErrorInterceptor
                    this.loadingService.hideLoading();

                    return throwError(() => error); // Make sure to throw the error properly
                })
            );
    }

    public update(obj: T): Observable<HttpResponse<T>> {
        this.loadingService.showLoading();

        return this.httpClient.put<T>(this.baseApiUrl, obj, { observe: "response" })
            .pipe(tap((response: HttpResponse<T>) => {
                this.loadingService.hideLoading();

                if (response.ok) {
                    const idx = this.data?.indexOf(this.data.find(x => x) as T);
                    if (this.data != undefined) {
                        this.data[idx ?? 0] = response.body as T;
                    }
                    this.dataSubject.next(this.data ?? []);
                }
            }),
                catchError(error => {
                    // Propagate the error properly to the ErrorInterceptor
                    this.loadingService.hideLoading();

                    return throwError(() => error); // Make sure to throw the error properly
                })
            );
    }

    public delete(id: any): Observable<HttpResponse<any>> {
        this.loadingService.showLoading();

        return this.httpClient.delete(`${this.baseApiUrl}/${id}`, { observe: "response" })
            .pipe(tap((response: HttpResponse<any>) => {
                this.loadingService.hideLoading();

                if (response.ok) {
                    const idx = this.data != null ? this.data?.indexOf(id) : 0;
                    this.data?.splice(idx, 1);
                    this.dataSubjectUpdate(this.data ?? []);
                }
            }),
                catchError(error => {
                    // Propagate the error properly to the ErrorInterceptor
                    this.loadingService.hideLoading();

                    return throwError(() => error); // Make sure to throw the error properly
                })
            );
    }

    public validateValue(fieldName: string, id: number, value: string): Observable<boolean> {
        this.loadingService.showLoading();

        const params = new HttpParams()
            .set("fieldName", fieldName)
            .set("id", id)
            .set("value", value);

        return this.httpClient.get<any>(`${this.baseApiUrl}/checkExisting`, { params })
            .pipe(tap(response => {
                this.loadingService.hideLoading();

                this.data?.push(response);
                this.dataSubjectUpdate(this.data ?? []);
            }),
                catchError(error => {
                    // Propagate the error properly to the ErrorInterceptor
                    this.loadingService.hideLoading();

                    return throwError(() => error); // Make sure to throw the error properly
                })
            );
    }

    public removeDataById(id: number): void {
        // Filter out the item with the specified ID from the current data
        const updatedData = this.data?.filter(item => (item as any).id !== id) ?? [];

        // Update the BehaviorSubject with the new filtered data
        this.dataSubject.next(updatedData);
        this.dataCountSubject.next(updatedData.length);
    }

    private getRecordCount(): void {
        this.dataCount$.subscribe(x => {
            this.dataCount = x;
        });
    }

    public dataSubjectUpdate(data: T[]): void {
        this.dataSubject.next(data);
    }

    public clearSearchCriteria(): void {
        this.currentParameters = new BaseSearchOptions();
    }

    public get queryParameters(): BaseSearchOptions {
        return this.currentParameters;
    }
}
