
export class SearchExpression {

    /*public static Equals = "=" ;
    public static LessThan = "<" ;
    public static GreaterThan = ">" ;
    public static LessThanOrEqual = "<=" ;
    public static GreaterThanOrEqual = ">=" ;
    public static NotEqual = "!=" ;
    public static And = "&&" ;
    public static Or = "||" ;
    public static Like = "LIKE" ;
    public static In = "IN" ;
    public static NotLike = "NOTLIKE" ;*/

    public static In = "In";
    public static Equals = "Equals"
    public static NotEquals = "NotEquals";
    public static Between = "Between";
    public static LessThan = "LessThan";
    public static LessThanOrEqual = "LessThanOrEqual";
    public static GreaterThan = "GreaterThan";
    public static GreaterThanOrEqual = "GreaterThanOrEqual";
    public static StartsWith = "StartsWith";
    public static EndsWith = "EndsWith";
    public static Contains = "Contains";
    public static NotContains = "NotContains";
    //--------------note supported------------------
    public static IsEmpty = "IsEmpty";
    public static IsNotEmpty = "IsNotEmpty";
    public static IsNull = "IsNull";
    public static IsNotNull = "IsNotNull";
}
