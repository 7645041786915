import { Component, OnInit } from '@angular/core';
import { CustomizerSettingsService } from '../../../../../../customizer-settings/customizer-settings.service';
import { ServiceService } from '../../../../services/service.service';
import { TableColumn } from '../../../../../../models/core/table/TableColumn';
import { BaseSearchOptions } from '../../../../../../models/core/table/BaseSearchOptions';
import { TableActionButton } from '../../../../../../models/core/table/TableActionButton';
import { SearchExpression } from '../../../../../../models/core/table/SearchExpression';
import { QueryBuilderParams } from '../../../../../../models/core/table/QueryBuilderParams';
import { EventTypes } from '../../../../../../models/enums/EventTypes';
import { GroupType } from '../../../../../../models/enums/GroupType';
import { TableEvent } from '../../../../../../models/core/table/TableEvent';
import { Service } from '../../../../../../models/domain/service';
import { catchError, take } from 'rxjs/operators';
import { ModalText } from '../../../../../../models/enums/ModalText';
import { ModalService } from '../../../../../core/services/modal.service';
import { TableSearchEventData } from '../../../../../../models/core/table/TableSearchEventData';
import { FilterDataType } from '../../../../../../models/core/table/FilterDataType';
import { getSearchOperator } from '../../../../../core/utilities/common-functions';
import { ToastrService } from 'ngx-toastr';
import { of } from 'rxjs';
import { LoadingService } from '../../../../../core/services/loading.service';
import { RoleService } from '../../../../../shared/services/roleService';
import { ServiceView } from '../../../../../../models/viewmodel/ServiceView';

@Component({
    selector: 'app-service-list',
    templateUrl: './service-list.component.html',
    styleUrl: './service-list.component.scss'
})
export class ServiceListComponent implements OnInit {

    //#region Table Variables
    public columns: TableColumn[];
    public actions: TableActionButton[];
    public totalRecords = 0;
    public pageIndex = 0;
    public pageSize = 10;
    public pageSizeOptions: number[] = [10, 50, 100];
    public searchOptions: BaseSearchOptions;
    //#endregion

    serviceId = 0;
    service: Service;


    // Popup Trigger
    classApplied = false;
    // isToggled
    isToggled = false;
    showModal = false;
    deleteModalData: Service;
    ConfirmText = ModalText.ConfirmYes
    CancelText = ModalText.ConfirmNo

    constructor(
        public themeService: CustomizerSettingsService,
        public serviceService: ServiceService,
        private modalService: ModalService,
        private toasterService: ToastrService,
        private loadingService: LoadingService,
        private roleService: RoleService
    ) {
        this.initialize();
    }

    ngOnInit(): void {
        this.getData();
    }

    private initialize(): void {
        this.themeService.isToggled$.subscribe(isToggled => {
            this.isToggled = isToggled;
        });

        this.searchOptions = new BaseSearchOptions(undefined, 'name', 0, this.pageIndex, this.pageSize);

        this.buildTable();
    }

    private getData(): void {
        this.loadingService.showLoading();
        this.serviceService.get(this.searchOptions).pipe(
            take(1),
            catchError((error) => {
                if (error && error.error) {
                    if (error.error.isCustom) {
                        this.toasterService.warning(error.error.message);
                    } else {
                        this.toasterService.error('Something went wrong!');
                    }
                } else {
                    this.toasterService.error('Something went wrong!');
                }
                return of(null);
            })
        ).subscribe(result => {
            if (result) {
                this.totalRecords = result.body?.totalCount ?? 0;
            }
            this.loadingService.hideLoading();
        });
    }

    public buildTable(): void {
        this.columns = [
            new TableColumn('name', 'Name', FilterDataType.String, true, false, (data: ServiceView) => `${data.name}`, true, { tooltip: 'Service Name' }),
            new TableColumn('description', 'Description', FilterDataType.String, true, false, (data: ServiceView) => `${data.description}`, true, { tooltip: 'Service Description' }),
            new TableColumn('serviceType.name', 'Service Type', FilterDataType.String, true, false, (data: ServiceView) => `${data?.serviceTypeName}`, true, { tooltip: 'ServiceType' }),
        ];


        this.actions = [];
        if (this.roleService.canUpdate()) {
            this.actions.push(new TableActionButton("edit", (event, value) => this.onEditClick(event, value), (value) => this.onDisabledClick(), "Edit", "Edit"))
        } if (this.roleService.canDelete()) {
            this.actions.push(new TableActionButton("delete", (event, value) => this.onDeleteClick(event, value), (value) => this.onDisabledClick(), "Delete", "Delete"))
        }
    }

    public onAddClick(): void {
        this.service = new Service;
        this.serviceId = 0;
        this.toggleClass();

    }

    public onEditClick(event: MouseEvent, data: Service): void {
        event.stopPropagation();
        this.service = data;
        this.serviceId = data.id!;
        this.toggleClass();
    }

    public onDeleteClick(event: MouseEvent, data: Service): void {
        event.stopPropagation();
        this.modalService.openModal(
            'Delete Confirmation',
            `Are you sure you want to delete <strong> ${data.name} </strong> ?`,
            ModalText.ConfirmYes,
            ModalText.ConfirmNo
        );
        this.modalService.onConfirm.subscribe((isConfirm) => {
            if (isConfirm && data.id) {
                this.loadingService.showLoading();
                this.serviceService.delete(data.id).pipe(
                    take(1),
                    catchError((error) => {
                        if (error && error.error) {
                            if (error.error.isCustom) {
                                this.toasterService.warning(error.error.message);
                            } else {
                                this.toasterService.error('Something went wrong!');
                            }
                        } else {
                            this.toasterService.error('Something went wrong!');
                        }
                        return of(null);
                    })
                ).subscribe(result => {
                    if (result?.body) {
                        this.toasterService.info("Record delete successfully.");
                        this.getData();
                    }
                    this.loadingService.hideLoading();
                });
            }
        });

    }



    public onDisabledClick(): boolean {
        return false;
    }

    public tableEvent(event: TableEvent<any>): void {
        if (event.type === EventTypes.TABLE) {
            const eventValue = event.data as TableSearchEventData;

            this.searchOptions.updateByObject(eventValue.baseSearchOptions);

            if (eventValue.columnFilterValues) {
                this.createFilter(eventValue);
            }
            else {
                this.searchOptions.filter = '';
            }
            this.getData();
        }
        if (event.type === EventTypes.CLEAR) {
            this.clearFilters();
            this.getData();
        }
    }

    public clearFilters(): void {
        this.pageIndex = 0;
        this.pageSize = 10;
        this.searchOptions = new BaseSearchOptions(undefined, 'name', 0, this.pageIndex, this.pageSize);
    }

    private createFilter(eventValue: TableSearchEventData): void {
        const queryParams: QueryBuilderParams[] = [];

        for (const [columnName, { dataType, opType, searchValue }] of Object.entries(eventValue.columnFilterValues)) {
            const searchOperator = getSearchOperator(opType.toString());
            if (!searchOperator) continue; // Skip if no valid operator

            queryParams.push({
                fieldName: columnName, // Adjust as necessary for your field mapping
                dataType: dataType,
                searchOperator,
                searchValue: searchValue,
                groupType: GroupType.OR
            });
        }

        this.searchOptions.filter = this.searchOptions.generateQuery(queryParams);
    }

    public toggleClass(): void {
        this.classApplied = !this.classApplied;
    }

    public classAppliedEvent(flag: boolean): void {
        this.classApplied = flag;
    }

    public saveConfirmationEvent(isSaved: boolean): void {
        if (isSaved) {
            this.getData();
        }

    }



}
