import { Component, OnInit } from '@angular/core';
import { LoadingService } from '../../services/loading.service';
import { CommonModule } from '@angular/common';

@Component({
    selector: 'app-loader',
    templateUrl: './loader.component.html',
    styleUrls: ['./loader.component.css'],
    standalone: true,
    imports: [CommonModule],
})
export class LoaderComponent implements OnInit {
    constructor(public loadingService: LoadingService) { }

    ngOnInit(): void {
    }
}
