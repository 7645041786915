import { TableCellAlignmentEnum } from "../../enums/TableCellAlignmentEnum";
import { FilterDataType } from "./FilterDataType";
import { SearchExpression } from "./SearchExpression";

export class ColumnOptions {
  public ellipseLength?: number;
  public alignment?: TableCellAlignmentEnum;
  public tooltip?: string;
  public styleExpression?: string;
}

export class TableColumn {
  public definition: string;
  public header: string ;
  public dataType: FilterDataType; 
  public showFilterDropdown?:boolean;
  public isDropdownOpen?: boolean;
  public display?: (row: any) => any;
  public sortable: boolean;
  public options?: ColumnOptions;
  public showTextBox?: boolean;
  public showHyperLink?: boolean;
  public callback?: (event: Event, row: any) => any;
  public selectedFilterType: SearchExpression;
  public searchValue?: string;  // New property to hold the search value for each column


  constructor(
    def: string,
    header: string,
    dataType: FilterDataType,
    showFilterDropdown?:boolean,
    isDropdownOpen?: boolean,
    display?: (row: any) => any,
    sortable = false,
    options: ColumnOptions = {},
    showTextBox?: boolean,
    showHyperLink?: boolean,
    callback?: (event: any, row: any) => void,
    selectedFilterType: string = SearchExpression.Equals,
    searchValue = ''  // Default search value is empty
  ) {

    this.definition = def;
    this.header = header;
    this.dataType = dataType;
    this.showFilterDropdown = showFilterDropdown;
    this.isDropdownOpen = isDropdownOpen;
    this.sortable = sortable;
    this.display = display;
    this.options = {
      ...options,
    };
    this.showTextBox = showTextBox;

    //Hyperlink in row
    this.showHyperLink = showHyperLink;
    this.callback = callback;

    this.selectedFilterType = selectedFilterType;  // Default filter type
    this.searchValue = searchValue;  // Initialize search value
  }
}
