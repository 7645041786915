import { Component, EventEmitter, Input, OnInit, Output, AfterViewInit, OnDestroy } from '@angular/core';
import { RouterLink } from '@angular/router';
import { NgClass, NgIf } from '@angular/common';
import { CustomizerSettingsService } from '../../../../../../customizer-settings/customizer-settings.service';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Roads } from '../../../../../../models/domain/roads';
import { ObjectUtility } from '../../../../../core/utilities/ObjectUtility';
import { catchError, forkJoin, of, Subject, takeUntil } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { whitespaceValidator } from '../../../../../core/validators/ValidationService';
import { Suburb } from '../../../../../../models/domain/suburb';
import { SuburbService } from '../../../../services/suburb.service';
import { LoadingService } from '../../../../../core/services/loading.service';
import { RoadsService } from '../../../../services/roads.service';
import { BaseSearchOptions } from '../../../../../../models/core/table/BaseSearchOptions';
import { Province } from '../../../../../../models/domain/province';
import { Town } from '../../../../../../models/domain/town';
import { ProvinceService } from '../../../../services/province.service';
import { TownService } from '../../../../services/town.service ';
import { District } from '../../../../../../models/domain/district';
import { Llg } from '../../../../../../models/domain/llg';
import { Ward } from '../../../../../../models/domain/ward';
import { DistrictService } from '../../../../services/district.service';
import { LlgService } from '../../../../services/llg.service';
import { WardService } from '../../../../services/ward.service';

@Component({
  selector: 'app-roads-details',
  templateUrl: './roads-details.component.html',
  styleUrl: './roads-details.component.scss'
})
export class RoadsDetailsComponent implements OnInit, AfterViewInit, OnDestroy {

  @Output() classApplied = new EventEmitter<boolean>();
  @Output() saveConfirmationEvent = new EventEmitter<boolean>();
  @Input() currentRoadsId = 0;
  // isToggled
  isToggled = false;
  frmGroup: FormGroup;

  private destroy$: Subject<boolean> = new Subject<boolean>();
  public roadsOriginal = new Roads;
  public roads: Roads = new Roads;
  public titleText = "";
  public titleButton = "";
  public errorMessage = "";

  isSubmitted = false;

  // drop down bind data
  public districts: District[];
  public llgs: Llg[];
  public wards: Ward[];
  public towns: Town[];
  public suburbs: Suburb[];
  public pageIndex = 0;
  public pageSize = 1000;
  public searchOptions: BaseSearchOptions;

  constructor(
    public themeService: CustomizerSettingsService,
    private formBuilder: FormBuilder,
    private roadsService: RoadsService,
    private toasterService: ToastrService,
    private suburbService: SuburbService,
    private loadingService: LoadingService,
    private townService: TownService,
    private districtService: DistrictService,
    private llgService: LlgService,
    private wardService: WardService,
  ) {
    this.initialize();
  }

  public ngOnInit(): void {
    if (this.currentRoadsId > 0) {
      this.getDropdownData();

    }
    else {
      this.currentRoadsId = 0;
      this.getDropdownData();
      const newRoads = new Roads();
      this.roads = ObjectUtility.deepClone(newRoads);
      this.roadsOriginal = ObjectUtility.deepClone(newRoads);
      this.setForm(this.roads);
    }
  }

  private initialize(): void {
    this.themeService.isToggled$.subscribe(isToggled => {
      this.isToggled = isToggled;
    });
    this.isSubmitted = false; this.searchOptions = new BaseSearchOptions(undefined, 'name', 0, this.pageIndex, this.pageSize);
    this.searchOptions = new BaseSearchOptions(undefined, 'name', 0, this.pageIndex, this.pageSize);
    this.createForm();
  }

  public ngAfterViewInit(): void {

    this.frmGroup.controls['nameControl'].valueChanges.subscribe(value => {
      this.roads.name = value;
    });
    this.frmGroup.controls['llgControl'].valueChanges.subscribe(value => {
      this.roads.llg = value;
    });
    this.frmGroup.controls['wardControl'].valueChanges.subscribe(value => {
      this.roads.ward = value;
    });
    this.frmGroup.controls['townControl'].valueChanges.subscribe(value => {
      this.roads.town = value;
    });

    this.frmGroup.controls['suburbControl'].valueChanges.subscribe(value => {
      this.roads.suburb = value;
    });

  }

  public ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  get f() { return this.frmGroup.controls; }

  private createForm(): void {
    this.frmGroup = this.formBuilder.group({
      nameControl: new FormControl('', [Validators.required, whitespaceValidator()]),
      districtControl: new FormControl({ value: null, disabled: true }, [Validators.required]),
      llgControl: new FormControl(null, [Validators.required]),
      wardControl: new FormControl(null, [Validators.required]),
      townControl: new FormControl(null, [Validators.required]),
      suburbControl: new FormControl(null, [Validators.required]),
    })
  }

  private setForm(roads: Roads): void {
    if (roads && roads.id != undefined && roads.id > 0) {
      this.frmGroup.patchValue({
        nameControl: roads?.name,
        districtControl: roads?.district,
        llgControl: roads?.llg,
        wardControl: roads?.ward,
        townControl: roads?.town,
        suburbControl: roads?.suburb,
      });
    }
    this.titleText = `${this.currentRoadsId === 0 ? "Add" : "Edit"} Roads`;
    this.titleButton = `${this.currentRoadsId === 0 ? "Create" : "Update"}`;
  }

  public onSave(): void {
    this.isSubmitted = true;
    if (this.frmGroup.valid) {
      this.loadingService.showLoading();
      this.addEditRoads();
    }
  }

  private addEditRoads(): void {
    if (this.roads?.id === 0) {
      this.roadsService.create(this.sanitizeRoads(this.roads))
        .pipe(
          catchError((err: any) => {
            if (err && err.error) {
              this.isSubmitted = false;
              if (err.error.isCustom) {
                this.errorMessage = err.error.message;
                this.toasterService.warning(err.error.message);
              } else {
                this.toasterService.error('Something went wrong!');
              }
            } else {
              this.isSubmitted = false;
              this.toasterService.error('Something went wrong!');
            }
            return of(null);
          }),
          takeUntil(this.destroy$))
        .subscribe(response => {
          if (response) {
            this.isSubmitted = false;
            this.roads = response?.body != null ? response?.body : new Roads;
            this.saveConfirmationEvent.emit(true);
            this.toggleClass();
            this.toasterService.success("Save successfully");
          }
        });
    } else {
      this.roadsService.update(this.sanitizeRoads(this.roads))
        .pipe(
          catchError((err: any) => {
            if (err && err.error) {
              this.isSubmitted = false;
              if (err.error.isCustom) {
                this.errorMessage = err.error.message;
                this.toasterService.warning(err.error.message);
              } else {
                this.toasterService.error('Something went wrong!');
              }
            } else {
              this.isSubmitted = false;
              this.toasterService.error('Something went wrong!');
            }
            return of(null);
          }),
          takeUntil(this.destroy$))
        .subscribe(response => {
          if (response) {
            this.isSubmitted = false;
            this.roads = response?.body != null ? response?.body : new Roads;
            this.saveConfirmationEvent.emit(true);
            this.toasterService.success("Update successfully");
            this.toggleClass();
          }
        });
    }
  }


  public toggleClass(): void {
    this.classApplied.emit(false);
  }

  private getDropdownData() {
    const town = this.townService.get(this.searchOptions);
    this.loadingService.showLoading();
    forkJoin([town])
      .pipe(takeUntil(this.destroy$)).subscribe((result: any) => {
        if (result) {
          this.towns = result[0].body.values;
          this.getRoadsById(this.currentRoadsId);
        }
        this.loadingService.hideLoading();
      });
  }

  private sanitizeRoads(roads: Roads): Roads {
    roads.town = null;
    roads.townId = this.frmGroup.controls['townControl'].value.id;
    roads.district = null;
    roads.districtId = this.frmGroup.controls['districtControl'].value.id;
    roads.llg = null;
    roads.llgId = this.frmGroup.controls['llgControl'].value.id;
    roads.ward = null;
    roads.wardId = this.frmGroup.controls['wardControl'].value.id;
    roads.suburb = null;
    roads.suburbId = this.frmGroup.controls['suburbControl'].value.id;

    return roads;
  }


  public townComparer(t1: Town, t2: Town): boolean {
    return t1 && t2 ? (t1.id === t2.id || t1.name === t2.name) : t1 === t2;
  }
  public districtComparer(t1: District, t2: District): boolean {
    return t1 && t2 ? (t1.id === t2.id || t1.name === t2.name) : t1 === t2;
  }
  public llgComparer(t1: Llg, t2: Llg): boolean {
    return t1 && t2 ? (t1.id === t2.id || t1.name === t2.name) : t1 === t2;
  }
  public wardComparer(t1: Ward, t2: Ward): boolean {
    return t1 && t2 ? (t1.id === t2.id || t1.name === t2.name) : t1 === t2;
  }
  public suburbComparer(t1: Suburb, t2: Suburb): boolean {
    return t1 && t2 ? (t1.id === t2.id || t1.name === t2.name) : t1 === t2;
  }


  public GetSuburbByWard(wardId: number | undefined) {
    if (wardId && wardId > 0) {
      this.loadingService.showLoading();
      this.suburbService.GetSuburbByWard(wardId).subscribe(response => {
        this.suburbs = response;
        this.loadingService.hideLoading();
        // const firstSuburbs = this.suburbs[0] || '';
        // this.frmGroup.patchValue({ suburbControl: firstSuburbs });
      });
    }
  }
  public getDistrictByTown(townId: number | undefined) {
    if (townId && townId > 0) {
      this.loadingService.showLoading();
      this.districtService.getDistrictsByTown(townId).subscribe(response => {
        this.districts = response;
        const firstdistrict = this.districts[0] || '';
        this.frmGroup.patchValue({ districtControl: firstdistrict });
        this.roads.district = firstdistrict;
        this.loadingService.hideLoading();
        if (this.roads != null && this.roads.district) {
          this.GetLlgByDistrict(this.roads.district.id);
        }

      });
    }
  }

  public GetLlgByDistrict(districtId: number | undefined) {
    if (districtId && districtId > 0) {
      this.loadingService.showLoading();
      this.llgService.GetLlgByDistrict(districtId).subscribe(response => {
        this.llgs = response;
        this.loadingService.hideLoading();
      });
    }
  }

  public GetWardByLlg(llgId: number | undefined) {
    if (llgId && llgId > 0) {
      this.loadingService.showLoading();
      this.wardService.GetWardByLlg(llgId).subscribe(response => {
        this.wards = response;
        this.loadingService.hideLoading();
      });
    }
  }

  public GetAllDropDownData(roads: Roads) {
    this.getDistrictByTown(roads.townId);
    this.GetLlgByDistrict(roads.districtId);
    this.GetWardByLlg(roads.llgId);
    this.GetSuburbByWard(roads.wardId);
  }

  public dataHasChanged(): boolean {
    const baseEqual = ObjectUtility.deepEqual(this.roads, this.roadsOriginal);
    return !baseEqual;
  }

  public formIsValid(): boolean {
    const isValid = this.frmGroup.controls['llgControl'].valid
      && this.frmGroup.controls['wardControl'].valid
      && this.frmGroup.controls['suburbControl'].valid
      && this.frmGroup.controls['townControl'].valid
      && this.frmGroup.controls['nameControl'].valid
      && (this.roads.district?.id != 0
        || this.roads.district?.id != null)

    return isValid;
  }

  public clearValue(change: string) {
    if (change == 'Town') {
      this.frmGroup.controls['districtControl'].setValue(null);
      this.districts = [];
      this.roads.district = null;
      this.frmGroup.controls['llgControl'].setValue(null);
      this.roads.llg = null;
      this.llgs = [];
      this.frmGroup.controls['wardControl'].setValue(null);
      this.roads.ward = null;
      this.wards = [];
      this.frmGroup.controls['suburbControl'].setValue(null);
      this.roads.suburb = null;
      this.suburbs = [];
    } else if (change == 'Llg') {
      this.frmGroup.controls['wardControl'].setValue(null);
      this.roads.ward = null;
      this.wards = [];
      this.frmGroup.controls['suburbControl'].setValue(null);
      this.roads.suburb = null;
      this.suburbs = [];
    } else if (change == 'Ward') {
      this.frmGroup.controls['suburbControl'].setValue(null);
      this.roads.suburb = null;
      this.suburbs = [];
    }
  }

  public getRoadsById(roadsId: number) {
    if (roadsId) {
      this.loadingService.showLoading();
      this.roadsService.getById(roadsId)
        .pipe(takeUntil(this.destroy$))
        .subscribe(result => {
          if (result) {
            this.roads = ObjectUtility.deepClone(result);
            this.roadsOriginal = ObjectUtility.deepClone(result);
            this.GetAllDropDownData(this.roads);
            this.setForm(this.roads);
          }
          this.loadingService.hideLoading();
        });
    }

  }

  public onTownChange(townId: number) {
    this.clearValue('Town');
    this.getDistrictByTown(townId);
  }
  public onLlgChange(llgId: number) {
    this.clearValue('Llg');
    this.GetWardByLlg(llgId);
  }
  public onWardChange(wardId: number) {
    this.clearValue('Ward');
    this.GetSuburbByWard(wardId);
  }

}