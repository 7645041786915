import { BaseRecord } from "./BaseRecord";
import { District } from "./district";
import { Llg } from "./llg";
import { Province } from "./province";


export class Ward extends BaseRecord<number> {
    public name: string;
    public provinceId: number;
    public province: Province | null;
    public districtId: number;
    public district: District | null;
    public llgId: number;
    public llg: Llg | null;
    public wardNo: number;


    constructor() {
        super();
        this.id = 0;
        this.provinceId = 0;
        this.districtId = 0;
        this.llgId = 0;
        this.name = "";
        this.wardNo = 0;
    }
}
