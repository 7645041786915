import { BaseRecord } from "./BaseRecord";
import { District } from "./district";
import { Llg } from "./llg";
import { Province } from "./province";
import { Town } from "./town";
import { Ward } from "./ward";


export class Suburb extends BaseRecord<number> {
    public name: string;
    public townId: number;
    public town: Town | null;
    public districtId: number;
    public district: District | null;
    public llgId: number;
    public llg: Llg | null;
    public wardId: number;
    public ward: Ward | null;

    constructor() {
        super();
        this.id = 0;
        this.townId = 0;
        this.name = "";
        this.districtId = 0;
        this.llgId = 0;
        this.wardId = 0;
    }
}
