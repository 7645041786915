import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';

@Component({
  selector: 'app-pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.css']
})
export class PaginationComponent implements OnInit {
  @Input() totalRecords: number = 0;
  @Input() pageSize: number = 10;
  @Input() pageSizeOptions: number[] = [10, 50, 100];

  @Output() pageChange: EventEmitter<{ pageIndex: number; pageSize: number }> = new EventEmitter();

  currentPage: number = 0;

  ngOnInit() {}

  get totalPages(): number {
    return Math.ceil(this.totalRecords / this.pageSize);
  }

  setPage(page: number) {
    if (page >= 0 && page < this.totalPages) {
      this.currentPage = page;
      this.pageChange.emit({ pageIndex: this.currentPage, pageSize: this.pageSize });
    }
  }

  nextPage() {
    this.setPage(this.currentPage + 1);
  }

  previousPage() {
    this.setPage(this.currentPage - 1);
  }

  get startRecord(): number {
    return this.currentPage * this.pageSize + 1;
  }

  get endRecord(): number {
    return Math.min(this.startRecord + this.pageSize - 1, this.totalRecords);
  }

  changePageSize(event: Event) {
    const selectElement = event.target as HTMLSelectElement;
    const size = Number(selectElement.value);
    
    this.pageSize = size;
    this.currentPage = 0; // Reset to the first page
    this.pageChange.emit({ pageIndex: this.currentPage, pageSize: this.pageSize });
  }

  get displayedPages(): number[] {
    const pages: number[] = [];
    const maxPagesToShow = 10;
    const startPage = Math.max(0, this.currentPage - Math.floor(maxPagesToShow / 2));
    const endPage = Math.min(this.totalPages - 1, startPage + maxPagesToShow - 1);

    if (startPage > 0) {
      pages.push(0); // Show the first page
      if (startPage > 1) {
        pages.push(-1); // Ellipsis
      }
    }

    for (let i = startPage; i <= endPage; i++) {
      pages.push(i);
    }

    if (endPage < this.totalPages - 1) {
      if (endPage < this.totalPages - 2) {
        pages.push(-1); // Ellipsis
      }
      pages.push(this.totalPages - 1); // Show the last page
    }

    return pages;
  }
}


/*
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
    selector: 'app-pagination',
    templateUrl: './pagination.component.html',
    styleUrl: './pagination.component.scss'
})

export class PaginationComponent implements OnInit {
    @Input() totalRecords: number = 0;
    @Input() pageSize: number = 10;
    @Input() pageSizeOptions: number[] = [10, 50, 100];

    @Output() pageChange: EventEmitter<{ pageIndex: number; pageSize: number }> = new EventEmitter();

    currentPage: number = 0;

    ngOnInit() {
    }

    get totalPages(): number {
        return Math.ceil(this.totalRecords / this.pageSize);
    }

    setPage(page: number) {
        if (page >= 0 && page <= this.totalPages) {
            this.currentPage = page;
            this.pageChange.emit({ pageIndex: this.currentPage, pageSize: this.pageSize });
        }
    }

    nextPage() {
        this.setPage(this.currentPage + 1);
    }

    previousPage() {
        this.setPage(this.currentPage - 1);
    }

    get startRecord(): number {
        return this.currentPage * this.pageSize + 1;
    }

    get endRecord(): number {
        return Math.min(this.startRecord + this.pageSize - 1, this.totalRecords);
    }

    changePageSize(event: Event) {
        const selectElement = event.target as HTMLSelectElement;
        const size = Number(selectElement.value); // Convert to number
    
        this.pageSize = size;
        this.currentPage = 0; // Reset to the first page
        this.pageChange.emit({ pageIndex: this.currentPage, pageSize: this.pageSize });
      }
}
*/