import { BaseRecord } from "./BaseRecord";

export class Province extends BaseRecord<number> {
    public name: string;
    public code: string;
   

    constructor() {
        super();
        this.id = 0;
        this.name = "";
        this.code = "";
    }
}
