import { EventTypes } from "../../enums/EventTypes";

export class TableEvent<T> {
  public type: EventTypes;
  public data: T | null;
  constructor(type: EventTypes, data: T | null = null) {
    this.type = type;
    this.data = data;
  }
}
