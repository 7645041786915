<!-- Breadcrumb -->
<div class="breadcrumb-card mb-25 d-md-flex align-items-center justify-content-between">
    <h5 class="mb-0">

    </h5>
    <ol class="breadcrumb list-unstyled mt-0 mb-0 p-0">
        <li class="breadcrumb-item d-inline-block position-relative">
            <a routerLink="/dashboard" class="d-inline-block position-relative">
                <i class="material-symbols-outlined">
                    home
                </i>
                Dashboard
            </a>
        </li>
        <li class="breadcrumb-item d-inline-block position-relative">
            Location
        </li>
        <li class="breadcrumb-item d-inline-block position-relative">
            Province
        </li>
    </ol>
</div>

<div class="apps-to-do-list-card trezo-card table-style-two mb-25 border-radius d-block bg-white border-0 shadow-none"
    [class.component-rtl-enabled]="themeService.isRTLEnabled()">
    <div class="trezo-card-header d-sm-flex align-items-center justify-content-between">
        <div class="trezo-card-title">
            <h5 class="mb-0">
                Province
            </h5>
        </div>
        <div class="trezo-card-subtitle">
            <button class="add-btn d-inline-block transition fw-medium border-radius" type="button"
            appRoleCheck="'add'" (click)="onAddClick()">
                <span class="d-inline-block position-relative">
                    <i class="material-symbols-outlined">
                        add
                    </i>
                    Province
                </span>
            </button>
        </div>
    </div>
    <div class="trezo-card-content">
        <app-table [datasource$]="provinceService.data$" [columns]="columns" [pageIndex]="pageIndex"
            [pageSize]="pageSize" [pageSizeOptions]="pageSizeOptions" [actionButtons]="actions" [sortable]="true"
            [usePagination]="true" [useFilter]="false" [queryParameters]="searchOptions" [resultLength]="totalRecords"
            [useStickyHeaders]="false" [allowRowSelection]="true" [allowDragDrop]="false"
            (eventHandler)="tableEvent($event)">
        </app-table>
    </div>
</div>

<!-- Add New Popup -->
<div class="add-new-popup" [class.active]="classApplied">
    <app-province-details *ngIf="classApplied" [data]="province" (saveConfirmationEvent)="saveConfirmationEvent($event)"
        (classApplied)="classAppliedEvent($event)" />

</div>