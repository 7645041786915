import { Component, OnInit } from '@angular/core';
import { LoadingService } from '../../services/loading.service';

@Component({
    selector: 'app-loader',
    templateUrl: './loader.component.html',
    styleUrls: ['./loader.component.css'],
})
export class LoaderComponent implements OnInit {
    loading = false;

    constructor(private loadingService: LoadingService) { }

    ngOnInit(): void {
        // Subscribe to loading observable to reactively show the loader
        this.loadingService.loading$.subscribe(
            (isLoading: any) => (this.loading = isLoading)
        );
    }
}
