import { Component, EventEmitter, Input, OnInit, Output, AfterViewInit, OnDestroy } from '@angular/core';
import { RouterLink } from '@angular/router';
import { NgClass, NgIf } from '@angular/common';
import { CustomizerSettingsService } from '../../../../../../customizer-settings/customizer-settings.service';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { PostOffice } from '../../../../../../models/domain/postOffice';
import { ObjectUtility } from '../../../../../core/utilities/ObjectUtility';
import { catchError, forkJoin, of, Subject, takeUntil } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { whitespaceValidator } from '../../../../../core/validators/ValidationService';
import { PostOfficeService } from '../../../../services/postOffice.service';
import { Province } from '../../../../../../models/domain/province';
import { ProvinceService } from '../../../../services/province.service';
import { LoadingService } from '../../../../../core/services/loading.service';
import { BaseSearchOptions } from '../../../../../../models/core/table/BaseSearchOptions';

@Component({
  selector: 'app-postOffice-details',
  templateUrl: './postOffice-details.component.html',
  styleUrl: './postOffice-details.component.scss'
})
export class PostOfficeDetailsComponent implements OnInit, AfterViewInit, OnDestroy {

  @Output() classApplied = new EventEmitter<boolean>();
  @Output() saveConfirmationEvent = new EventEmitter<boolean>();
  @Input() currentPostOfficeId = 0;
  // isToggled
  isToggled = false;
  frmGroup: FormGroup;

  private destroy$: Subject<boolean> = new Subject<boolean>();
  public postOfficeOriginal = new PostOffice;
  public postOffice: PostOffice = new PostOffice;
  public titleText = "";
  public titleButton = "";
  public errorMessage = "";

  isSubmitted = false;

  // drop down bind data
  public provinces: Province[];
  public pageIndex = 0;
  public pageSize = 1000;
  public searchOptions: BaseSearchOptions;

  constructor(
    public themeService: CustomizerSettingsService,
    private formBuilder: FormBuilder,
    private postOfficeService: PostOfficeService,
    private toasterService: ToastrService,
    private provinceService: ProvinceService,
    private loadingService: LoadingService
  ) {
    this.initialize();
  }

  public ngOnInit(): void {
    if (this.currentPostOfficeId > 0) {
      this.getDropdownData();
      this.getPostOfficeById(this.currentPostOfficeId);
    }
    else {
      this.currentPostOfficeId = 0;
      this.getDropdownData();
      const newPostOffice = new PostOffice();
      this.postOffice = ObjectUtility.deepClone(newPostOffice);
      this.postOfficeOriginal = ObjectUtility.deepClone(newPostOffice);
      this.setForm(this.postOffice);
    }
  }

  private initialize(): void {
    this.themeService.isToggled$.subscribe(isToggled => {
      this.isToggled = isToggled;
    });
    this.isSubmitted = false;
    this.searchOptions = new BaseSearchOptions(undefined, 'name', 0, this.pageIndex, this.pageSize);
    this.createForm();
  }

  public ngAfterViewInit(): void {

    this.frmGroup.controls['nameControl'].valueChanges.subscribe(value => {
      this.postOffice.name = value;
    });
    this.frmGroup.controls['provinceControl'].valueChanges.subscribe(value => {
      this.postOffice.province = value;
    });
    this.frmGroup.controls['boxNumberControl'].valueChanges.subscribe(value => {
      this.postOffice.boxNumber = value;
    });

  }

  public ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  get f() { return this.frmGroup.controls; }

  private createForm(): void {
    this.frmGroup = this.formBuilder.group({
      nameControl: new FormControl('', [Validators.required, whitespaceValidator()]),
      provinceControl: new FormControl(null, [Validators.required]),
      boxNumberControl: new FormControl('', []),
    })
  }

  private setForm(postOffice: PostOffice): void {
    if (postOffice && postOffice.id != undefined && postOffice.id > 0) {
      this.frmGroup.patchValue({
        nameControl: postOffice?.name,
        provinceControl: postOffice?.province,
        boxNumberControl: postOffice?.boxNumber,
      });
    }
    this.titleText = `${this.currentPostOfficeId === 0 ? "Add" : "Edit"} Post Office`;
    this.titleButton = `${this.currentPostOfficeId === 0 ? "Create" : "Update"}`;
  }

  public onSave(): void {
    this.isSubmitted = true;
    this.loadingService.showLoading();

    if (this.frmGroup.valid) {
      this.addEditPostOffice();
    }
  }

  private addEditPostOffice(): void {
    if (this.postOffice?.id === 0) {
      this.postOfficeService.create(this.sanitizePostOffice(this.postOffice))
        .pipe(
          catchError((err: any) => {
            if (err && err.error) {
              this.isSubmitted = false;
              if (err.error.isCustom) {
                this.errorMessage = err.error.message;
                this.toasterService.warning(err.error.message);
              } else {
                this.toasterService.error('Something went wrong!');
              }
            } else {
              this.isSubmitted = false;
              this.toasterService.error('Something went wrong!');
            }
            return of(null);
          }),
          takeUntil(this.destroy$))
        .subscribe(response => {
          if (response) {
            this.isSubmitted = false;
            this.postOffice = response?.body != null ? response?.body : new PostOffice;
            this.saveConfirmationEvent.emit(true);
            this.toggleClass();
            this.toasterService.success("Save successfully");
          }
        });
    } else {
      this.postOfficeService.update(this.sanitizePostOffice(this.postOffice))
        .pipe(
          catchError((err: any) => {
            if (err && err.error) {
              this.isSubmitted = false;
              if (err.error.isCustom) {
                this.errorMessage = err.error.message;
                this.toasterService.warning(err.error.message);
              } else {
                this.toasterService.error('Something went wrong!');
              }
            } else {
              this.isSubmitted = false;
              this.toasterService.error('Something went wrong!');
            }
            return of(null);
          }),
          takeUntil(this.destroy$))
        .subscribe(response => {
          if (response) {
            this.isSubmitted = false;
            this.postOffice = response?.body != null ? response?.body : new PostOffice;
            this.saveConfirmationEvent.emit(true);
            this.toasterService.success("Update successfully");
            this.toggleClass();
          }
        });
    }
  }


  public toggleClass(): void {
    this.classApplied.emit(false);
  }

  private getDropdownData() {
    this.loadingService.showLoading();
    const province = this.provinceService.get(this.searchOptions);
    forkJoin([province])
      .pipe(takeUntil(this.destroy$)).subscribe((result: any) => {
        if (result) {
          this.provinces = result[0].body.values;
        }
        this.loadingService.hideLoading();
      });
  }

  private sanitizePostOffice(postOffice: PostOffice): PostOffice {
    postOffice.province = null;
    postOffice.provinceId = this.frmGroup.controls['provinceControl'].value.id;

    return postOffice;
  }

  public provinceComparer(t1: Province, t2: Province): boolean {
    return t1 && t2 ? (t1.id === t2.id || t1.code === t2.code) : t1 === t2;
  }

  public dataHasChanged(): boolean {
    const baseEqual = ObjectUtility.deepEqual(this.postOffice, this.postOfficeOriginal);
    return !baseEqual;
  }

  public formIsValid(): boolean {
    const isValid = this.frmGroup.controls['provinceControl'].valid
      && this.frmGroup.controls['nameControl'].valid
      && this.frmGroup.controls['boxNumberControl'].valid
    return isValid;
  }

  public getPostOfficeById(postOfficeId: number) {
    this.loadingService.showLoading();
    this.postOfficeService.getById(postOfficeId)
      .pipe(takeUntil(this.destroy$))
      .subscribe(result => {
        if (result) {
          this.postOffice = ObjectUtility.deepClone(result);
          this.postOfficeOriginal = ObjectUtility.deepClone(result);
          this.setForm(this.postOffice);
        }
        this.loadingService.hideLoading();
      });
  }



}