import { Component, OnInit } from '@angular/core';
import { CustomizerSettingsService } from '../../../../../../customizer-settings/customizer-settings.service';
import { TableColumn } from '../../../../../../models/core/table/TableColumn';
import { BaseSearchOptions } from '../../../../../../models/core/table/BaseSearchOptions';
import { TableActionButton } from '../../../../../../models/core/table/TableActionButton';
import { SearchExpression } from '../../../../../../models/core/table/SearchExpression';
import { QueryBuilderParams } from '../../../../../../models/core/table/QueryBuilderParams';
import { EventTypes } from '../../../../../../models/enums/EventTypes';
import { GroupType } from '../../../../../../models/enums/GroupType';
import { TableEvent } from '../../../../../../models/core/table/TableEvent';
import { Address } from '../../../../../../models/domain/address';
import { catchError, filter, switchMap, take } from 'rxjs/operators';
import { ModalText } from '../../../../../../models/enums/ModalText';
import { ModalService } from '../../../../../core/services/modal.service';
import { AddressService } from '../../../../services/address.service';
import { LoadingService } from '../../../../../core/services/loading.service';
import { FilterDataType } from '../../../../../../models/core/table/FilterDataType';
import { Province } from '../../../../../../models/domain/province';
import { TableSearchEventData } from '../../../../../../models/core/table/TableSearchEventData';
import { getSearchOperator } from '../../../../../core/utilities/common-functions';
import { ToastrService } from 'ngx-toastr';
import { of } from 'rxjs';
import { RoleService } from '../../../../../shared/services/roleService';
import { AddressView } from '../../../../../../models/viewmodel/AddressView';

@Component({
    selector: 'app-address-list',
    templateUrl: './address-list.component.html',
    styleUrl: './address-list.component.scss'
})
export class AddressListComponent implements OnInit {

    //#region Table Variables
    public columns: TableColumn[];
    public actions: TableActionButton[];
    public totalRecords = 0;
    public pageIndex = 0;
    public pageSize = 10;
    public pageSizeOptions: number[] = [10, 50, 100];
    public searchOptions: BaseSearchOptions;
    //#endregion

    addressId = 0;
    address: Address;


    // Popup Trigger
    classApplied = false;
    // isToggled
    isToggled = false;
    showModal = false;
    deleteModalData: Address;
    ConfirmText = ModalText.ConfirmYes
    CancelText = ModalText.ConfirmNo

    constructor(
        public themeService: CustomizerSettingsService,
        public addressService: AddressService,
        private modalService: ModalService,
        private loadingService: LoadingService,
        private toasterService: ToastrService,
        private roleService: RoleService

    ) {
        this.initialize();
    }

    ngOnInit(): void {
        this.getData();
    }

    private initialize(): void {
        this.themeService.isToggled$.subscribe(isToggled => {
            this.isToggled = isToggled;
        });

        this.searchOptions = new BaseSearchOptions(undefined, 'smallVillageAndBuildingName', 0, this.pageIndex, this.pageSize);

        this.buildTable();
    }

    private getData(): void {
        this.loadingService.showLoading();
        this.addressService.get(this.searchOptions).pipe(
            take(1),
            catchError((error) => {
                if (error && error.error) {
                    if (error.error.isCustom) {
                        this.toasterService.warning(error.error.message);
                    } else {
                        this.toasterService.error('Something went wrong!');
                    }
                } else {
                    this.toasterService.error('Something went wrong!');
                }
                return of(null);
            })
        ).subscribe(result => {
            if (result) {
                this.totalRecords = result.body?.totalCount ?? 0;
            }
            this.loadingService.hideLoading();
        });
    }

    public buildTable(): void {
        this.columns = [
            new TableColumn('addressType', 'Address Type', FilterDataType.String, true, false, (data: AddressView) => `${data?.addressType}`, true, { tooltip: 'Address Type' }),
            new TableColumn('province.name', 'Province', FilterDataType.String, true, false, (data: AddressView) => `${data?.provinceName}`, true, { tooltip: 'Province' }),
            new TableColumn('district.name', 'District', FilterDataType.String, true, false, (data: AddressView) => `${data?.districtName}`, true, { tooltip: 'District' }),
            new TableColumn('llg.name', 'LLG', FilterDataType.String, true, false, (data: AddressView) => `${data?.llgName}`, true, { tooltip: 'LLG' }),
            new TableColumn('ward.name', 'Ward', FilterDataType.String, true, false, (data: AddressView) => `${data?.wardName}`, true, { tooltip: 'Ward' }),
            new TableColumn('town.name', 'Town', FilterDataType.String, true, false, (data: AddressView) => `${data?.townName}`, true, { tooltip: 'Town' }),
            new TableColumn('suburb.name', 'Suburb', FilterDataType.String, true, false, (data: AddressView) => `${data?.suburbName}`, true, { tooltip: 'Suburb' }),
            new TableColumn('street.name', 'Street', FilterDataType.String, true, false, (data: AddressView) => `${data?.streetName}`, true, { tooltip: 'Street' }),
            new TableColumn('road.name', 'Roads', FilterDataType.String, true, false, (data: AddressView) => `${data?.roadName}`, true, { tooltip: 'Roads' }),
            new TableColumn('smallVillageAndBuildingName', 'Small Village/Building/Unit Name', FilterDataType.String, true, false, (data: AddressView) => `${data?.smallVillageAndBuildingName}`, true, { tooltip: 'Small Village/Building/Unit Name' }),
            new TableColumn('postoffice.name', 'PostOffice', FilterDataType.String, true, false, (data: AddressView) => `${data?.postOfficeName}`, true, { tooltip: 'PostOffice Name' }),
            new TableColumn('boxNumber', 'BoxNumber', FilterDataType.String, true, false, (data: AddressView) => `${data?.boxNumber}`, true, { tooltip: 'BoxNumber' }),

        ];



        this.actions = [];
        if (this.roleService.canUpdate()) {
            this.actions.push(new TableActionButton("edit", (event, value) => this.onEditClick(event, value), (value) => this.onDisabledClick(), "Edit", "Edit"))
        }
        if (this.roleService.canDelete()) {
            this.actions.push(new TableActionButton("delete", (event, value) => this.onDeleteClick(event, value), (value) => this.onDisabledClick(), "Delete", "Delete"))
        }
    }

    public onAddClick(): void {
        this.address = new Address;
        this.addressId = 0;
        this.toggleClass();
    }

    public onEditClick(event: MouseEvent, data: Address): void {
        event.stopPropagation();
        this.address = data;
        this.addressId = data.id!;
        this.toggleClass();
    }

    public onDeleteClick(event: MouseEvent, data: Address): void {
        event.stopPropagation();
        this.modalService.openModal(
            'Delete Confirmation',
            `Are you sure you want to delete this address?`,
            ModalText.ConfirmYes,
            ModalText.ConfirmNo
        );

        this.modalService.onConfirm.pipe(
            take(1),
            filter((isConfirm) => isConfirm && !!data.id),
            switchMap(() => {
                this.loadingService.showLoading();
                return this.addressService.delete(data.id).pipe(
                    catchError((error) => {
                        const message = error?.error?.isCustom
                            ? error.error.message
                            : 'Something went wrong!';
                        this.toasterService.error(message);
                        return of(null); // Gracefully handle errors
                    })
                )
            }
            )
        ).subscribe((result) => {
            if (result?.body) {
                this.toasterService.info('Record deleted successfully.');
                // Optimized: Remove the deleted record from the list instead of refetching
                // Filter the data in the service and update the BehaviorSubject
                this.addressService.removeDataById(data.id as number);
                this.totalRecords = this.addressService.dataCount;//result.body?.totalCount ?? 0;
            }
            this.loadingService.hideLoading();
        });

    }


    public onDisabledClick(): boolean {
        return false;
    }

    public tableEvent(event: TableEvent<any>): void {
        if (event.type === EventTypes.TABLE) {
            const eventValue = event.data as TableSearchEventData;

            this.searchOptions.updateByObject(eventValue.baseSearchOptions);

            if (eventValue.columnFilterValues) {
                this.createFilter(eventValue);
            }
            else {
                this.searchOptions.filter = '';
            }
            this.getData();
        }
        if (event.type === EventTypes.CLEAR) {
            this.clearFilters();
            this.getData();
        }
    }

    public clearFilters(): void {
        this.pageIndex = 0;
        this.pageSize = 10;
        this.searchOptions = new BaseSearchOptions(undefined, 'smallVilllageName', 0, this.pageIndex, this.pageSize);
    }

    private createFilter(eventValue: TableSearchEventData): void {
        const queryParams: QueryBuilderParams[] = [];
        for (const [columnName, { dataType, opType, searchValue }] of Object.entries(eventValue.columnFilterValues)) {
            const searchOperator = getSearchOperator(opType.toString());
            if (!searchOperator) continue; // Skip if no valid operator

            queryParams.push({
                fieldName: columnName, // Adjust as necessary for your field mapping
                dataType: dataType,
                searchOperator,
                searchValue: searchValue,
                groupType: GroupType.OR
            });
        }
        ///queryParams.push({ fieldName: "Name", searchOperator: SearchExpression.Like, searchValue: data.filter, groupType: GroupType.OR });
        ///queryParams.push({ fieldName: "Province", searchOperator: SearchExpression.Like, searchValue: data.filter, groupType: GroupType.OR });
        this.searchOptions.filter = this.searchOptions.generateQuery(queryParams);
    }

    public toggleClass(): void {
        this.classApplied = !this.classApplied;
    }

    public classAppliedEvent(flag: boolean): void {
        this.classApplied = flag;
    }

    public saveConfirmationEvent(isSaved: boolean): void {
        if (isSaved) {
            this.getData();
        }

    }




}