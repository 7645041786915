<div
    class="reset-password-area"
    [class.dark-theme]="themeService.isDark()"
>
    <div
        class="trezo-form mx-auto"
        [class.component-dark-theme]="themeService.isDark()"
        [class.component-rtl-enabled]="themeService.isRTLEnabled()"
    >
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12 order-2 order-lg-1">
                <div class="form-image">
                    <img src="images/reset-password.jpg" alt="reset-password-image">
                </div>
            </div>
            <div class="col-lg-6 col-md-12 order-1 order-lg-2">
                <div class="form-content">
                    <div class="logo">
                        <img src="images/logo-big.svg" alt="logo">
                        <img src="images/white-logo-big.svg" class="d-none" alt="logo">
                    </div>
                    <div class="title">
                        <h1 class="fw-semibold">
                            Reset Password?
                        </h1>
                        <p class="fw-medium">
                            Enter your new password and confirm it another time in the field below.
                        </p>
                    </div>
                    <form [formGroup]="resetPassForm" (ngSubmit)="onSubmit()">
                        <div class="form-group position-relative">
                            <label for="oldPass" class="main-label d-block fw-medium text-black">
                                Old Password
                            </label>
                            <div class="position-relative">
                                <input [type]="isOldPasswordVisible ? 'text' : 'password'" (input)="onOldPasswordInput($event)" class="form-control" placeholder="Type password" formControlName="oldPassword"
                                [ngClass]="{ 'is-invalid': submitted && f['oldPassword'].errors }">
                                <button type="button" (click)="toggleOldPasswordVisibility()" class="icon-button p-0 border-0 bg-transparent transition">
                                    <i [ngClass]="isOldPasswordVisible ? 'ri-eye-line' : 'ri-eye-off-line'"></i>
                                </button>
                            </div>
                            <div class="form-text text-danger" *ngIf="f['oldPassword']!.hasError('required') && f['oldPassword']!.touched">
                                Old Password is required.
                            </div>
                        </div>
                        <div class="form-group position-relative">
                            <label for="newPassword" class="main-label d-block fw-medium text-black">
                                New Password
                            </label>
                            <div class="position-relative">
                                <input [type]="isNewPasswordVisible ? 'text' : 'password'" (input)="onNewPasswordInput($event)" class="form-control" placeholder="Type password" formControlName="newPassword"
                                [ngClass]="{ 'is-invalid': submitted && f['newPassword'].errors }">
                                <button type="button" (click)="toggleNewPasswordVisibility()" class="icon-button p-0 border-0 bg-transparent transition">
                                    <i [ngClass]="isNewPasswordVisible ? 'ri-eye-line' : 'ri-eye-off-line'"></i>
                                </button>
                            </div>
                            <div class="form-text text-danger" *ngIf="f['newPassword']!.hasError('required') && f['newPassword']!.touched">
                                New Password is required.
                            </div>
                        </div>
                        <div class="form-group position-relative">
                            <label for="cnfrmPassword" class="main-label d-block fw-medium text-black">
                                Confrim Password
                            </label>
                            <div class="position-relative">
                                <input [type]="isConfirmPasswordVisible ? 'text' : 'password'" (input)="onConfirmPasswordInput($event)" class="form-control" placeholder="Type password" formControlName="confirmPassword"
                                [ngClass]="{ 'is-invalid': submitted && f['confirmPassword'].errors }">
                                <button type="button" (click)="toggleConfirmPasswordVisibility()" class="icon-button p-0 border-0 bg-transparent transition">
                                    <i [ngClass]="isConfirmPasswordVisible ? 'ri-eye-line' : 'ri-eye-off-line'"></i>
                                </button>
                             </div>
                            <div class="form-text text-danger" *ngIf="f['confirmPassword']!.hasError('required') && f['confirmPassword']!.touched">
                                Confirm Password is required.
                            </div>
                            <div class="form-text text-danger" *ngIf="f['confirmPassword']!.hasError('mustMatch') && f['confirmPassword']!.touched">
                                Confirm Password must be match with new password.
                            </div>
                            
                        </div>
                        <button type="submit" class="btn-box d-block w-100 border-0 transition fw-medium border-radius">
                            <span class="d-flex align-items-center justify-content-center">
                                <i class="material-symbols-outlined">
                                    autorenew
                                </i>
                                Send
                            </span>
                        </button>
                        <p class="info">
                            Back to <a routerLink="" class="fw-semibold text-primary">Sign In</a>
                        </p>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>