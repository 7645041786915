import { BaseRecord } from "./BaseRecord";
import { SectionNumber } from "./sectionNumber";


export class AllotmentNumber extends BaseRecord<number> {
    public number: string;
    public sectionNumberId: number;
    public sectionNumber: SectionNumber | null;

    constructor() {
        super();
        this.id = 0;
        this.sectionNumberId = 0;
        this.number = "";
        this.sectionNumber = new SectionNumber;
    }
}
