//import { DateTime } from "luxon";
import { EqualityUtility } from "./EqualityUtility";


export class ObjectUtility {
    public static deepClone<T>(source: T): any {
        if (Array.isArray(source)) {
            return source.map(item => ObjectUtility.deepClone(item));
        }
        // if (source instanceof Date) {
        //     return DateTime.fromJSDate(source);
        // }
        if (source && typeof source === "object") {
            return Object.getOwnPropertyNames(source).reduce((o, prop) => {
                Object.defineProperty(o, prop, Object.getOwnPropertyDescriptor(source, prop)!);
                o[prop] = ObjectUtility.deepClone((source as { [key: string]: any })[prop]);
                return o;
            }, Object.create(Object.getPrototypeOf(source)));
        }

        if (typeof source === "number") {
            return source as T;
        }

        if (typeof source === "string") {
            // check that it's not an ISO-like date string
            const isoDateLikePattern = new RegExp("\\d{4}-\\d{2}-\\d{2}T");
            // if (isoDateLikePattern.test(source)) {
            //     return DateTime.fromISO(source);
            // }
        }

        return source as T;
    }

    public static deepEqual(obj1: any, obj2: any): boolean {
        //Null checks
        if (obj1 === null && obj2 === null) {
            return true;
        }

        if (obj1 === null || obj2 === null) {
            return false;
        }

        //Keys check
        const keys1 = Object.keys(obj1);
        const keys2 = Object.keys(obj2);

        if (keys1.length !== keys2.length) {
            return false;
        }

        //Values check
        for (const key of keys1) {
            const val1 = obj1[key];
            const val2 = obj2[key];

            const func = EqualityUtility.getEqualityFunction(val1);

            if (!func(val1, val2)) {
                return false;
            }
        }

        return true;
    }

    public static isObject(obj: any): boolean {
        return obj !== null && typeof obj === "object";
    }
}
