import { Component, HostListener, OnInit } from '@angular/core';
import { ToggleService } from './toggle.service';
import { CustomizerSettingsService } from '../../../../customizer-settings/customizer-settings.service';
import { AuthenticationService } from '../../services/authentication.service';
import { PngIdentityUser } from '../../../../models/domain/pngIdentityUser';

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrl: './header.component.scss'
})
export class HeaderComponent implements OnInit {

    // isSidebarToggled
    isSidebarToggled = false;

    // isToggled
    isToggled = false;

    user = new PngIdentityUser();

    constructor(
        private toggleService: ToggleService,
        public themeService: CustomizerSettingsService,
        public authService: AuthenticationService
    ) {
        this.toggleService.isSidebarToggled$.subscribe(isSidebarToggled => {
            this.isSidebarToggled = isSidebarToggled;
        });
        this.themeService.isToggled$.subscribe(isToggled => {
            this.isToggled = isToggled;
        });
        const currentUser = authService.getCurrentUser();
        if (currentUser) {
            this.user = currentUser;
        }
    }

    // Burger Menu Toggle
    toggle() {
        this.toggleService.toggle();
    }

    // Settings Button Toggle
    settingsButtonToggle() {
        this.themeService.toggle();
    }

    // Dark Mode
    toggleTheme() {
        this.themeService.toggleTheme();
    }

    // Header Sticky
    isSticky: boolean = false;
    @HostListener('window:scroll', ['$event'])
    checkScroll() {
        const scrollPosition = window.scrollY || document.documentElement.scrollTop || document.body.scrollTop || 0;
        if (scrollPosition >= 50) {
            this.isSticky = true;
        } else {
            this.isSticky = false;
        }
    }

    // Dropdown Menu
    isConnectedAppsDropdownOpen = false;
    isLanguageDropdownOpen = false;
    isNotificationsDropdownOpen = false;
    isProfileDropdownOpen = false;

    toggleConnectedAppsDropdown() {
        this.isConnectedAppsDropdownOpen = !this.isConnectedAppsDropdownOpen;
    }

    toggleLanguageDropdown() {
        this.isLanguageDropdownOpen = !this.isLanguageDropdownOpen;
    }

    toggleNotificationsDropdown() {
        this.isNotificationsDropdownOpen = !this.isNotificationsDropdownOpen;
    }

    toggleProfileDropdown() {
        this.isProfileDropdownOpen = !this.isProfileDropdownOpen;
    }

    @HostListener('document:click', ['$event'])
    handleClickOutside(event: Event) {
        const target = event.target as HTMLElement;
        if (!target.closest('.connected-apps-menu')) {
            this.isConnectedAppsDropdownOpen = false;
        }
        if (!target.closest('.language-menu')) {
            this.isLanguageDropdownOpen = false;
        }
        if (!target.closest('.notifications-menu')) {
            this.isNotificationsDropdownOpen = false;
        }
        if (!target.closest('.profile-menu')) {
            this.isProfileDropdownOpen = false;
        }
    }

    // Fullscreen
    isFullscreen: boolean = false;
    ngOnInit() {
        // Listen for fullscreen change events to update the button text
        document.addEventListener('fullscreenchange', this.onFullscreenChange.bind(this));
        document.addEventListener('webkitfullscreenchange', this.onFullscreenChange.bind(this));
        document.addEventListener('mozfullscreenchange', this.onFullscreenChange.bind(this));
        document.addEventListener('MSFullscreenChange', this.onFullscreenChange.bind(this));
    }

    toggleFullscreen() {
        if (!this.isFullscreen) {
            this.openFullscreen();
        } else {
            this.closeFullscreen();
        }
    }

    openFullscreen() {
        const element = document.documentElement as HTMLElement & {
            mozRequestFullScreen?: () => Promise<void>;
            webkitRequestFullscreen?: () => Promise<void>;
            msRequestFullscreen?: () => Promise<void>;
        };
        if (element.requestFullscreen) {
            element.requestFullscreen();
        } else if (element.mozRequestFullScreen) { // Firefox
            element.mozRequestFullScreen();
        } else if (element.webkitRequestFullscreen) { // Chrome, Safari, and Opera
            element.webkitRequestFullscreen();
        } else if (element.msRequestFullscreen) { // IE/Edge
            element.msRequestFullscreen();
        }
    }

    closeFullscreen() {
        const doc = document as Document & {
            mozCancelFullScreen?: () => Promise<void>;
            webkitExitFullscreen?: () => Promise<void>;
            msExitFullscreen?: () => Promise<void>;
        };
        if (document.exitFullscreen) {
            document.exitFullscreen();
        } else if (doc.mozCancelFullScreen) { // Firefox
            doc.mozCancelFullScreen();
        } else if (doc.webkitExitFullscreen) { // Chrome, Safari, and Opera
            doc.webkitExitFullscreen();
        } else if (doc.msExitFullscreen) { // IE/Edge
            doc.msExitFullscreen();
        }
    }

    onFullscreenChange() {
        const doc = document as Document & {
            webkitFullscreenElement?: Element;
            mozFullScreenElement?: Element;
            msFullscreenElement?: Element;
        };
        this.isFullscreen = !!(document.fullscreenElement || doc.webkitFullscreenElement || doc.mozFullScreenElement || doc.msFullscreenElement);
    }

    public logout() {
        this.authService.logout();
    }

}