import { Injectable } from '@angular/core';
import { AuthenticationService } from './authentication.service';
import { RoleEnum } from '../../../models/enums/RoleEnum';

@Injectable({
  providedIn: 'root'
})
export class RoleService {
  constructor(private authService: AuthenticationService) {}

  // Check if the user has a specific role
  canAdd(): boolean {
    return this.authService.hasRole(RoleEnum.Admin) || this.authService.hasRole(RoleEnum.Supervisor) || this.authService.hasRole(RoleEnum.Clerk);
  }

  canUpdate(): boolean {
    return this.authService.hasRole(RoleEnum.Admin) || this.authService.hasRole(RoleEnum.Supervisor);
  }

  canDelete(): boolean {
    return this.authService.hasRole(RoleEnum.Admin);
  }

  // Generic method to check access for any feature
  canAccessFeature(feature: string): boolean {
    const roles = this.authService.getUserRoles();
    switch (feature) {
      case 'add':
        return roles.includes(RoleEnum.Admin) || roles.includes(RoleEnum.Supervisor) || roles.includes(RoleEnum.Clerk);
      case 'update':
        return roles.includes(RoleEnum.Admin) || roles.includes(RoleEnum.Supervisor);
      case 'delete':
        return roles.includes(RoleEnum.Admin);
      default:
        return false;
    }
  }
}
