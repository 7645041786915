import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { RouterLink } from '@angular/router';
import { NgClass, NgIf } from '@angular/common';
import { CustomizerSettingsService } from '../../../../../customizer-settings/customizer-settings.service';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { SectionNumber } from '../../../../../models/domain/sectionNumber';
import { ObjectUtility } from '../../../../core/utilities/ObjectUtility';
import { catchError, forkJoin, of, Subject, takeUntil } from 'rxjs';
import { ToasterService } from '../../../../core/services/toaster.service';
import { whitespaceValidator } from '../../../../core/validators/ValidationService';
import { Street } from '../../../../../models/domain/street';
import { StreetService } from '../../../services/street.service';
import { LoadingService } from '../../../../core/services/loading.service';
import { SectionNumberService } from '../../../services/sectionNumber.service';
import { BaseSearchOptions } from '../../../../../models/core/table/BaseSearchOptions';

@Component({
  selector: 'app-sectionNumber-details',
  templateUrl: './sectionNumber-details.component.html',
  styleUrl: './sectionNumber-details.component.scss'
})
export class SectionNumberDetailsComponent implements OnInit {

  @Output() classApplied = new EventEmitter<boolean>();
  @Output() saveConfirmationEvent = new EventEmitter<boolean>();
  @Input() data!: SectionNumber;
  // isToggled
  isToggled = false;
  frmGroup: FormGroup;

  private destroy$: Subject<boolean> = new Subject<boolean>();
  public sectionNumberOriginal = new SectionNumber;
  public sectionNumber: SectionNumber = new SectionNumber;
  public titleText = "";
  public titleButton = "";
  public errorMessage = "";
  public currentSectionNumberId: number = 0;
  isSubmitted = false;

  // drop down bind data
  public streets: Street[];
  public pageIndex: number = 0;
  public pageSize: number = 1000;
  public searchOptions: BaseSearchOptions;

  constructor(
    public themeService: CustomizerSettingsService,
    private formBuilder: FormBuilder,
    private sectionNumberService: SectionNumberService,
    private toasterService: ToasterService,
    private streetService: StreetService,
    private loadingService: LoadingService
  ) {
    this.initialize();
  }

  public ngOnInit(): void {
    if (this.data) {
      this.currentSectionNumberId = this.data.id ? this.data.id : 0;
      this.getDropdownData();
      this.sectionNumber = ObjectUtility.deepClone(this.data);
      this.sectionNumberOriginal = ObjectUtility.deepClone(this.data);
      this.setForm(this.sectionNumber);
    }
    else {
      this.currentSectionNumberId = 0;
      this.getDropdownData();
      const newSectionNumber = new SectionNumber();
      this.sectionNumber = ObjectUtility.deepClone(newSectionNumber);
      this.sectionNumberOriginal = ObjectUtility.deepClone(newSectionNumber);
      this.setForm(this.sectionNumber);
    }
  }

  private initialize(): void {
    this.themeService.isToggled$.subscribe(isToggled => {
      this.isToggled = isToggled;
    });
    this.isSubmitted = false;
    this.searchOptions = new BaseSearchOptions(undefined, undefined, 0, this.pageIndex, this.pageSize);
    this.createForm();
  }

  public ngAfterViewInit(): void {

    this.frmGroup.controls['numberControl'].valueChanges.subscribe(value => {
      this.sectionNumber.number = value;
    });
    this.frmGroup.controls['streetControl'].valueChanges.subscribe(value => {
      this.sectionNumber.street = value;
    });

  }

  public ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  get f() { return this.frmGroup.controls; }

  private createForm(): void {
    this.frmGroup = this.formBuilder.group({
      numberControl: new FormControl('', [Validators.required, whitespaceValidator()]),
      streetControl: new FormControl('', [Validators.required]),
    })
  }

  private setForm(sectionNumber: SectionNumber): void {
    if (sectionNumber) {
      this.frmGroup.patchValue({
        numberControl: sectionNumber?.number,
        streetControl: sectionNumber?.street,
      });
    }
    this.titleText = `${this.currentSectionNumberId === 0 ? "Add" : "Edit"} Section Number`;
    this.titleButton = `${this.currentSectionNumberId === 0 ? "Create" : "Update"}`;
  }

  public onSave(): boolean {
    this.isSubmitted = true;
    this.loadingService.start(true);

    if (this.frmGroup.valid) {
      this.addEditSectionNumber();
      return true;
    }
    else {
      this.loadingService.start(false);
      return false;
    }
  }

  private addEditSectionNumber(): void {
    if (this.sectionNumber?.id === 0) {
      this.sectionNumberService.create(this.sanitizeSectionNumber(this.sectionNumber))
        .pipe(
          catchError((err: any) => {
            if (err && err.error) {
              this.loadingService.start(false);
              this.isSubmitted = false;
              if (err.error.isCustom) {
                this.errorMessage = err.error.message;
                this.toasterService.warning(err.error.message);
              } else {
                this.toasterService.error('Something went wrong!');
              }
              //this.toasterService.error(err.error.mesage);
            } else {
              this.loadingService.start(false);
              this.isSubmitted = false;
              this.toasterService.error('Something went wrong!');
            }
            return of(null);
          }),
          takeUntil(this.destroy$))
        .subscribe(response => {
          if (response) {
            this.isSubmitted = false;
            this.sectionNumber = response?.body != null ? response?.body : new SectionNumber;
            this.saveConfirmationEvent.emit(true);
            this.toggleClass();
            this.toasterService.success("Save successfully");
            // close popup
            this.loadingService.start(false);
          }
        });
    } else {
      this.sectionNumberService.update(this.sanitizeSectionNumber(this.sectionNumber))
        .pipe(
          catchError((err: any) => {
            if (err && err.error) {
              this.loadingService.start(false);
              this.isSubmitted = false;
              if (err.error.isCustom) {
                this.errorMessage = err.error.message;
                this.toasterService.warning(err.error.message);
              } else {
                this.toasterService.error('Something went wrong!');
              }
              //this.toasterService.error(err.error.mesage);
            } else {
              this.loadingService.start(false);
              this.isSubmitted = false;
              this.toasterService.error('Something went wrong!');
            }
            return of(null);
          }),
          takeUntil(this.destroy$))
        .subscribe(response => {
          if (response) {
            this.isSubmitted = false;
            this.sectionNumber = response?.body != null ? response?.body : new SectionNumber;
            this.saveConfirmationEvent.emit(true);
            this.toasterService.success("Update successfully");
            this.toggleClass();
            this.loadingService.start(false);
          }
        });
    }
  }


  public toggleClass(): void {
    this.classApplied.emit(false);
  }

  private getDropdownData() {
    this.loadingService.start(true);
    const street = this.streetService.get(this.searchOptions);

    forkJoin([street])
      .pipe(takeUntil(this.destroy$)).subscribe((result: any) => {
        if (result) {
          this.streets = result[0].body.values;
        }

        this.loadingService.start(false);
      });
  }

  private sanitizeSectionNumber(sectionNumber: SectionNumber): SectionNumber {
    sectionNumber.street = null;
    sectionNumber.streetId = this.frmGroup.controls['streetControl'].value.id;

    return sectionNumber;
  }

  public streetComparer(t1: Street, t2: Street): boolean {
    return t1 && t2 ? (t1.id === t2.id || t1.name === t2.name) : t1 === t2;
  }

}