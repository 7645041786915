import { BaseRecord } from "./BaseRecord";
import { Suburb } from "./suburb";


export class Street extends BaseRecord<number> {
    public name: string;
    public suburbId: number;
    public suburb: Suburb | null;

    constructor() {
        super();
        this.id = 0;
        this.suburbId = 0;
        this.name = "";
        this.suburb = new Suburb;
    }
}
