import { NgModule } from '@angular/core';
import { SignInComponent } from './components/sign-in/sign-in.component';
import { CoreRoutingModule } from './core.routes';
import { SignUpComponent } from './components/sign-up/sign-up.component';
import { ForgotPasswordComponent } from './components/forgot-password/forgot-password.component';
import { ResetPasswordComponent } from './components/reset-password/reset-password.component';
import { LogoutComponent } from './components/logout/logout.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { TableComponent } from './components/table/table.component';
import { EllipsesPipe } from './pipe/ellipses.pipe';
import { PaginationComponent } from './components/pagination/pagination.component';
import { ConfirmationModalComponent } from './components/confirmation-modal/confirmation-modal.component';
import { ToasterComponent } from './components/toaster/toaster.component';
import { LoaderComponent } from './components/loader/loader.component';



@NgModule({
  declarations: [
    SignInComponent,
    SignUpComponent,
    ForgotPasswordComponent,
    ResetPasswordComponent,
    LogoutComponent,
    TableComponent,
    EllipsesPipe,
    PaginationComponent,
    ConfirmationModalComponent,
    ToasterComponent,
    LoaderComponent
  ],
  imports: [
    CoreRoutingModule,
    ReactiveFormsModule,
    FormsModule,
    CommonModule,
  ],
  exports: [TableComponent, EllipsesPipe, ConfirmationModalComponent, ToasterComponent, LoaderComponent],

})
export class CoreModule { }
