import { BaseRecord } from "./BaseRecord";
import { District } from "./district";
import { Province } from "./province";


export class Town extends BaseRecord<number> {
    public name: string;
    public provinceId: number;
    public province: Province | null;
    public districtId: number;
    public district: District | null;

    constructor() {
        super();
        this.id = 0;
        this.provinceId = 0;
        this.districtId = 0;
        this.name = "";
    }
}
