import { Injectable, EventEmitter } from '@angular/core';
import { ModalText } from '../../../models/enums/ModalText';
import { BehaviorSubject } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class LoadingService {
    // public showLoading: boolean = false;

    // Loading(isLoading: boolean) {
    //     debugger;
    //     this.showLoading = isLoading;
    // }

    private loadingSubject = new BehaviorSubject<boolean>(false);
    loading$ = this.loadingSubject.asObservable();

    start(isLoading: boolean): void {
        this.loadingSubject.next(isLoading);
    }


}