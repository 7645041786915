import { Component, OnInit } from '@angular/core';
import { ModalService } from '../../services/modal.service';

@Component({
    selector: 'app-confirmation-modal',
    templateUrl: './confirmation-modal.component.html',
    styleUrls: ['./confirmation-modal.component.css']
})
export class ConfirmationModalComponent implements OnInit {
    constructor(public modalService: ModalService) { }

    ngOnInit() {
        this.modalService.onConfirm.subscribe(() => {
            this.modalService.closeModal();
        });
    }

    public onConfirm(): void {
        this.modalService.onConfirm.emit(true);
        this.modalService.closeModal();
    }

    public onCancel(): void {
        this.modalService.onConfirm.emit(false);
        this.modalService.closeModal();
    }
}