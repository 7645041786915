import { Component, EventEmitter, Input, OnInit, Output, AfterViewInit, OnDestroy } from '@angular/core';
import { CustomizerSettingsService } from '../../../../../../customizer-settings/customizer-settings.service';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { StreetDetails } from '../../../../../../models/domain/streetDetails';
import { ObjectUtility } from '../../../../../core/utilities/ObjectUtility';
import { catchError, forkJoin, of, Subject, takeUntil } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { whitespaceValidator } from '../../../../../core/validators/ValidationService';
import { Street } from '../../../../../../models/domain/street';
import { StreetService } from '../../../../services/street.service';
import { LoadingService } from '../../../../../core/services/loading.service';
import { StreetDetailsService } from '../../../../services/streetDetails.service';
import { BaseSearchOptions } from '../../../../../../models/core/table/BaseSearchOptions';
import { Province } from '../../../../../../models/domain/province';
import { Suburb } from '../../../../../../models/domain/suburb';
import { Town } from '../../../../../../models/domain/town';
import { ProvinceService } from '../../../../services/province.service';
import { SuburbService } from '../../../../services/suburb.service';
import { TownService } from '../../../../services/town.service ';
import { StreetRoad } from '../../../../../../models/domain/streetRoad';

@Component({
  selector: 'app-streetDetails-details',
  templateUrl: './streetDetails-details.component.html',
  styleUrl: './streetDetails-details.component.scss'
})
export class StreetDetailsDetailsComponent implements OnInit, AfterViewInit, OnDestroy {

  @Output() classApplied = new EventEmitter<boolean>();
  @Output() saveConfirmationEvent = new EventEmitter<boolean>();
  @Input() currentStreetDetailsId = 0;
  // isToggled
  isToggled = false;
  frmGroup: FormGroup;

  private destroy$: Subject<boolean> = new Subject<boolean>();
  public streetDetailsOriginal = new StreetDetails;
  public streetDetails: StreetDetails = new StreetDetails;
  public titleText = "";
  public titleButton = "";
  public errorMessage = "";

  isSubmitted = false;

  // drop down bind data
  public provinces: Province[];
  public towns: Town[];
  public suburbs: Suburb[];
  public streets: StreetRoad[];
  public pageIndex = 0;
  public pageSize = 1000;
  public searchOptions: BaseSearchOptions;

  constructor(
    public themeService: CustomizerSettingsService,
    private formBuilder: FormBuilder,
    private streetDetailsService: StreetDetailsService,
    private toasterService: ToastrService,
    private streetService: StreetService,
    private loadingService: LoadingService,
    private provinceService: ProvinceService,
    private townService: TownService,
    private suburbService: SuburbService,
  ) {
    this.initialize();
  }

  public ngOnInit(): void {
    if (this.currentStreetDetailsId > 0) {
      this.getDropdownData();

    }
    else {
      this.currentStreetDetailsId = 0;
      this.getDropdownData();
      const newStreetDetails = new StreetDetails();
      this.streetDetails = ObjectUtility.deepClone(newStreetDetails);
      this.streetDetailsOriginal = ObjectUtility.deepClone(newStreetDetails);
      this.setForm(this.streetDetails);
    }
  }

  private initialize(): void {
    this.themeService.isToggled$.subscribe(isToggled => {
      this.isToggled = isToggled;
    });
    this.isSubmitted = false;
    this.searchOptions = new BaseSearchOptions(undefined, 'name', 0, this.pageIndex, this.pageSize);
    this.createForm();
  }

  public ngAfterViewInit(): void {

    this.frmGroup.controls['nameControl'].valueChanges.subscribe(value => {
      this.streetDetails.name = value;
    });
    this.frmGroup.controls['provinceControl'].valueChanges.subscribe(value => {
      this.streetDetails.province = value;
    });
    this.frmGroup.controls['townControl'].valueChanges.subscribe(value => {
      this.streetDetails.town = value;
    });
    this.frmGroup.controls['suburbControl'].valueChanges.subscribe(value => {
      this.streetDetails.suburb = value;
    });
    this.frmGroup.controls['streetControl'].valueChanges.subscribe(value => {
      if (value && value?.streetOrRoad == "Street") {
        this.streetDetails.street = value;
      } else {
        this.streetDetails.roads = value;
      }
    });

  }

  public ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  get f() { return this.frmGroup.controls; }

  private createForm(): void {
    this.frmGroup = this.formBuilder.group({
      nameControl: new FormControl('', [Validators.required, whitespaceValidator()]),
      provinceControl: new FormControl(null, [Validators.required]),
      townControl: new FormControl({ value: null, disabled: true }, [Validators.required]),
      suburbControl: new FormControl(null, [Validators.required]),
      streetControl: new FormControl(null, [Validators.required]),
    })
  }

  private setForm(streetDetails: StreetDetails): void {
    if (streetDetails && streetDetails.id != undefined && streetDetails.id > 0) {


      const stRoad = new StreetRoad();
      stRoad.id = streetDetails?.street != null ? streetDetails.street.id : streetDetails.roads?.id;
      stRoad.name = streetDetails?.street != null ? streetDetails.street.name : streetDetails.roads?.name;
      stRoad.streetOrRoad = streetDetails?.street != null ? 'Street' : 'Road';

      this.frmGroup.patchValue({
        nameControl: streetDetails?.name,
        provinceControl: streetDetails?.province,
        townControl: streetDetails?.town,
        suburbControl: streetDetails?.suburb,
        streetControl: stRoad,
      });
    }
    this.titleText = `${this.currentStreetDetailsId === 0 ? "Add" : "Edit"} Street Detail`;
    this.titleButton = `${this.currentStreetDetailsId === 0 ? "Create" : "Update"}`;
  }

  public onSave(): void {
    this.isSubmitted = true;
    if (this.frmGroup.valid) {
      this.loadingService.showLoading();
      this.addEditStreetDetails();
    }
  }

  private addEditStreetDetails(): void {
    if (this.streetDetails?.id === 0) {
      this.streetDetailsService.create(this.sanitizeStreetDetails(this.streetDetails))
        .pipe(
          catchError((err: any) => {
            if (err && err.error) {
              this.isSubmitted = false;
              if (err.error.isCustom) {
                this.errorMessage = err.error.message;
                this.toasterService.warning(err.error.message);
              } else {
                this.toasterService.error('Something went wrong!');
              }
            } else {
              this.isSubmitted = false;
              this.toasterService.error('Something went wrong!');
            }
            return of(null);
          }),
          takeUntil(this.destroy$))
        .subscribe(response => {
          if (response) {
            this.isSubmitted = false;
            this.streetDetails = response?.body != null ? response?.body : new StreetDetails;
            this.saveConfirmationEvent.emit(true);
            this.toggleClass();
            this.toasterService.success("Save successfully");
          }
        });
    } else {
      this.streetDetailsService.update(this.sanitizeStreetDetails(this.streetDetails))
        .pipe(
          catchError((err: any) => {
            if (err && err.error) {
              this.isSubmitted = false;
              if (err.error.isCustom) {
                this.errorMessage = err.error.message;
                this.toasterService.warning(err.error.message);
              } else {
                this.toasterService.error('Something went wrong!');
              }
            } else {
              this.isSubmitted = false;
              this.toasterService.error('Something went wrong!');
            }
            return of(null);
          }),
          takeUntil(this.destroy$))
        .subscribe(response => {
          if (response) {
            this.isSubmitted = false;
            this.streetDetails = response?.body != null ? response?.body : new StreetDetails;
            this.saveConfirmationEvent.emit(true);
            this.toasterService.success("Update successfully");
            this.toggleClass();
          }
        });
    }
  }


  public toggleClass(): void {
    this.classApplied.emit(false);
  }

  private getDropdownData() {
    this.loadingService.showLoading();
    const province = this.provinceService.get(this.searchOptions);
    forkJoin([province])
      .pipe(takeUntil(this.destroy$)).subscribe((result: any) => {
        if (result) {
          this.provinces = result[0].body.values;
          this.getStreetDetailsById(this.currentStreetDetailsId);
        }
        this.loadingService.hideLoading();
      });
  }

  public GetTownByProvince(provinceId: number | undefined) {
    if (provinceId && provinceId > 0) {
      this.loadingService.showLoading();
      this.townService.GetTownByProvince(provinceId).subscribe(response => {
        this.towns = response;
        const firsttown = this.towns[0] || '';
        this.frmGroup.patchValue({ townControl: firsttown });
        this.streetDetails.town = firsttown;

        if (this.streetDetails != null && this.streetDetails.town) {
          this.GetSuburbByTown(this.streetDetails.town.id);
        }

        this.loadingService.hideLoading();
      });
    }
  }

  public GetSuburbByTown(townId: number | undefined) {
    if (townId && townId > 0) {
      this.loadingService.showLoading();
      this.suburbService.GetSuburbByTown(townId).subscribe(response => {
        this.suburbs = response;
        this.loadingService.hideLoading();
      });
    }
  }
  public GetstreetAndRoadsBySuburb(suburbId: number | undefined) {
    if (suburbId && suburbId > 0) {
      this.loadingService.showLoading();
      this.streetService.GetstreetAndRoadsBySuburb(suburbId).subscribe(response => {
        this.streets = response;
        this.loadingService.hideLoading();
      });
    }
  }

  public GetAllDropDownData(streetDetails: StreetDetails) {
    this.GetTownByProvince(this.streetDetails.provinceId);
    this.GetSuburbByTown(this.streetDetails.townId);
    this.GetstreetAndRoadsBySuburb(this.streetDetails.suburbId);
  }

  private sanitizeStreetDetails(streetDetails: StreetDetails): StreetDetails {
    streetDetails.province = null;
    streetDetails.provinceId = this.frmGroup.controls['provinceControl'].value.id;
    streetDetails.town = null;
    streetDetails.townId = this.frmGroup.controls['townControl'].value.id;
    streetDetails.suburb = null;
    streetDetails.suburbId = this.frmGroup.controls['suburbControl'].value.id;
    streetDetails.street = null;
    streetDetails.roads = null;

    if (this.frmGroup.controls['streetControl'].value.streetOrRoad == "Street") {
      streetDetails.streetId = this.frmGroup.controls['streetControl'].value.id == 0 ? null : this.frmGroup.controls['streetControl'].value.id;
      streetDetails.roadsId = null;
    } else {
      streetDetails.roadsId = this.frmGroup.controls['streetControl'].value.id == 0 ? null : this.frmGroup.controls['streetControl'].value.id;
      streetDetails.streetId = null;
    }

    return streetDetails;
  }

  public provinceComparer(t1: Province, t2: Province): boolean {
    return t1 && t2 ? (t1.id === t2.id || t1.code === t2.code) : t1 === t2;
  }
  public townComparer(t1: Town, t2: Town): boolean {
    return t1 && t2 ? (t1.id === t2.id || t1.name === t2.name) : t1 === t2;
  }
  public suburbComparer(t1: Suburb, t2: Suburb): boolean {
    return t1 && t2 ? (t1.id === t2.id || t1.name === t2.name) : t1 === t2;
  }
  public streetComparer(t1: StreetRoad, t2: StreetRoad): boolean {
    return t1 && t2 ? (t1.id === t2.id && t1.name === t2.name) : t1 === t2;
  }


  public dataHasChanged(): boolean {
    const baseEqual = ObjectUtility.deepEqual(this.streetDetails, this.streetDetailsOriginal);
    return !baseEqual;
  }

  public formIsValid(): boolean {
    const isValid = this.frmGroup.controls['provinceControl'].valid
      && this.frmGroup.controls['suburbControl'].valid
      && this.frmGroup.controls['streetControl'].valid
      && this.frmGroup.controls['nameControl'].valid
      && this.streetDetails.town?.id != 0
      && this.streetDetails.town?.id != null
    return isValid;
  }

  public clearValue(change: string) {
    if (change == 'Province') {
      this.frmGroup.controls['townControl'].setValue(null);
      this.towns = [];
      this.streetDetails.town = null;
      this.frmGroup.controls['suburbControl'].setValue(null);
      this.streetDetails.suburb = null;
      this.suburbs = [];
      this.frmGroup.controls['streetControl'].setValue(null);
      this.streetDetails.street = null;
      this.streets = [];

    } else if (change == 'Suburb') {
      this.frmGroup.controls['streetControl'].setValue(null);
      this.streetDetails.street = null;
      this.streets = [];
    }

  }

  public getStreetDetailsById(streetDetailsId: number) {
    if (streetDetailsId) {
      this.loadingService.showLoading();
      this.streetDetailsService.getById(streetDetailsId)
        .pipe(takeUntil(this.destroy$))
        .subscribe(result => {
          if (result) {
            this.streetDetails = ObjectUtility.deepClone(result);
            this.streetDetailsOriginal = ObjectUtility.deepClone(result);
            this.GetAllDropDownData(this.streetDetails);
            this.setForm(this.streetDetails);
          }
          this.loadingService.hideLoading();
        });
    }

  }

  public onProvinceChange(provinceId: number) {
    this.clearValue('Province');
    this.GetTownByProvince(provinceId);

  }
  public onSuburbChange(suburbId: number) {
    this.clearValue('Suburb');
    this.GetstreetAndRoadsBySuburb(suburbId);
  }


}