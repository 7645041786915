import { Injectable } from "@angular/core";
import { HttpClient, HttpParams, HttpResponse } from "@angular/common/http";
import { BehaviorSubject, Observable, catchError, tap, throwError } from "rxjs";
import { environment } from "../../../../environments/environment";
import { LoadingService } from "../../core/services/loading.service";
import { BaseSearchOptions } from "../../../models/core/table/BaseSearchOptions";
import { ClientCountViewDto } from "../../../models/viewmodel/ClientCountViewDto";
import { AllTableDataCountViewDto } from "../../../models/viewmodel/AllTableDataCountViewDto";

@Injectable({
    providedIn: "root"
})
export class DashboardService {
    private readonly rowCountSubject: BehaviorSubject<number>;
    public readonly rowCount$: Observable<number>;
    private httpClient: HttpClient;
    private baseApiUrl: string;

    constructor(http: HttpClient, public loadingService: LoadingService) {
        this.baseApiUrl = `${environment.apiUrl}/v1/dashboard`;
        this.httpClient = http;
        this.rowCountSubject = new BehaviorSubject<number>(0);
        this.rowCount$ = this.rowCountSubject.asObservable();
    }

    public getClientCount(parameters: BaseSearchOptions, dataRage:string): Observable<HttpResponse<ClientCountViewDto[]>> {
        
        const params = new HttpParams()
            .set("filter", parameters.filter)
            .set("orderBy",parameters.orderBy)
            .set("pageSize", parameters.pageSize)
            .set("pageIndex", parameters.pageIndex)
            .set("orderByDirection", parameters.orderByDirection)
            .set("expand", parameters.expand);

        this.loadingService.showLoading();

        return this.httpClient.get<ClientCountViewDto[]>(`${this.baseApiUrl}/ClientCount?dataRage=${dataRage}`, { params, observe: "response" })
            .pipe(tap(response => {
                this.loadingService.hideLoading();
            }),
                catchError(error => {
                    // Propagate the error properly to the ErrorInterceptor
                    this.loadingService.hideLoading();
                    return throwError(() => error); // Make sure to throw the error properly
                })
            );
    }

    public getAllTableDataCount(): Observable<HttpResponse<AllTableDataCountViewDto[]>> {
        this.loadingService.showLoading();

        return this.httpClient.get<AllTableDataCountViewDto[]>(`${this.baseApiUrl}/AllTableDataCount`, { observe: "response" })
            .pipe(tap(response => {
                this.loadingService.hideLoading();
            }),
                catchError(error => {
                    // Propagate the error properly to the ErrorInterceptor
                    this.loadingService.hideLoading();
                    return throwError(() => error); // Make sure to throw the error properly
                })
            );
    }
}
