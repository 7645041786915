import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { RouterLink } from '@angular/router';
import { NgClass, NgIf } from '@angular/common';
import { CustomizerSettingsService } from '../../../../../customizer-settings/customizer-settings.service';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Town } from '../../../../../models/domain/town';
import { ObjectUtility } from '../../../../core/utilities/ObjectUtility';
import { catchError, forkJoin, of, Subject, takeUntil } from 'rxjs';
import { ToasterService } from '../../../../core/services/toaster.service';
import { whitespaceValidator } from '../../../../core/validators/ValidationService';
import { TownService } from '../../../services/town.service ';
import { Province } from '../../../../../models/domain/province';
import { ProvinceService } from '../../../services/province.service';
import { LoadingService } from '../../../../core/services/loading.service';
import { BaseSearchOptions } from '../../../../../models/core/table/BaseSearchOptions';

@Component({
  selector: 'app-town-details',
  templateUrl: './town-details.component.html',
  styleUrl: './town-details.component.scss'
})
export class TownDetailsComponent implements OnInit {

  @Output() classApplied = new EventEmitter<boolean>();
  @Output() saveConfirmationEvent = new EventEmitter<boolean>();
  @Input() data!: Town;
  // isToggled
  isToggled = false;
  frmGroup: FormGroup;

  private destroy$: Subject<boolean> = new Subject<boolean>();
  public townOriginal = new Town;
  public town: Town = new Town;
  public titleText = "";
  public titleButton = "";
  public errorMessage = "";
  public currentTownId: number = 0;
  isSubmitted = false;

  // drop down bind data
  public provinces: Province[];
  public pageIndex: number = 0;
  public pageSize: number = 1000;
  public searchOptions: BaseSearchOptions;

  constructor(
    public themeService: CustomizerSettingsService,
    private formBuilder: FormBuilder,
    private townService: TownService,
    private toasterService: ToasterService,
    private provinceService: ProvinceService,
    private loadingService: LoadingService
  ) {
    this.initialize();
  }

  public ngOnInit(): void {
    if (this.data) {
      this.currentTownId = this.data.id ? this.data.id : 0;
      this.getDropdownData();
      this.town = ObjectUtility.deepClone(this.data);
      this.townOriginal = ObjectUtility.deepClone(this.data);
      this.setForm(this.town);
    }
    else {
      this.currentTownId = 0;
      this.getDropdownData();
      const newTown = new Town();
      this.town = ObjectUtility.deepClone(newTown);
      this.townOriginal = ObjectUtility.deepClone(newTown);
      this.setForm(this.town);
    }
  }

  private initialize(): void {
    this.themeService.isToggled$.subscribe(isToggled => {
      this.isToggled = isToggled;
    });
    this.isSubmitted = false;
    this.searchOptions = new BaseSearchOptions(undefined, undefined, 0, this.pageIndex, this.pageSize);
    this.createForm();
  }

  public ngAfterViewInit(): void {

    this.frmGroup.controls['nameControl'].valueChanges.subscribe(value => {
      this.town.name = value;
    });
    this.frmGroup.controls['provinceControl'].valueChanges.subscribe(value => {
      this.town.province = value;
    });

  }

  public ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  get f() { return this.frmGroup.controls; }

  private createForm(): void {
    this.frmGroup = this.formBuilder.group({
      nameControl: new FormControl('', [Validators.required, whitespaceValidator()]),
      provinceControl: new FormControl('', [Validators.required]),
    })
  }

  private setForm(town: Town): void {
    if (town) {
      this.frmGroup.patchValue({
        nameControl: town?.name,
        provinceControl: town?.province,
      });
    }
    this.titleText = `${this.currentTownId === 0 ? "Add" : "Edit"} Town`;
    this.titleButton = `${this.currentTownId === 0 ? "Create" : "Update"}`;
  }

  public onSave(): boolean {
    this.isSubmitted = true;
    this.loadingService.start(true);

    if (this.frmGroup.valid) {
      this.addEditTown();
      return true;
    }
    else {
      this.loadingService.start(false);
      return false;
    }
  }

  private addEditTown(): void {
    if (this.town?.id === 0) {
      this.townService.create(this.sanitizeTown(this.town))
        .pipe(
          catchError((err: any) => {
            if (err && err.error) {
              this.loadingService.start(false);
              this.isSubmitted = false;
              if (err.error.isCustom) {
                this.errorMessage = err.error.message;
                this.toasterService.warning(err.error.message);
              } else {
                this.toasterService.error('Something went wrong!');
              }
              //this.toasterService.error(err.error.mesage);
            } else {
              this.loadingService.start(false);
              this.isSubmitted = false;
              this.toasterService.error('Something went wrong!');
            }
            return of(null);
          }),
          takeUntil(this.destroy$))
        .subscribe(response => {
          if (response) {
            this.isSubmitted = false;
            this.town = response?.body != null ? response?.body : new Town;
            this.saveConfirmationEvent.emit(true);
            this.toggleClass();
            this.toasterService.success("Save successfully");
            // close popup
            this.loadingService.start(false);
          }
        });
    } else {
      this.townService.update(this.sanitizeTown(this.town))
        .pipe(
          catchError((err: any) => {
            if (err && err.error) {
              this.loadingService.start(false);
              this.isSubmitted = false;
              if (err.error.isCustom) {
                this.errorMessage = err.error.message;
                this.toasterService.warning(err.error.message);
              } else {
                this.toasterService.error('Something went wrong!');
              }
              //this.toasterService.error(err.error.mesage);
            } else {
              this.loadingService.start(false);
              this.isSubmitted = false;
              this.toasterService.error('Something went wrong!');
            }
            return of(null);
          }),
          takeUntil(this.destroy$))
        .subscribe(response => {
          if (response) {
            this.isSubmitted = false;
            this.town = response?.body != null ? response?.body : new Town;
            this.saveConfirmationEvent.emit(true);
            this.toasterService.success("Update successfully");
            this.toggleClass();
            this.loadingService.start(false);
          }
        });
    }
  }


  public toggleClass(): void {
    this.classApplied.emit(false);
  }

  private getDropdownData() {
    this.loadingService.start(true);
    const province = this.provinceService.get(this.searchOptions);

    forkJoin([province])
      .pipe(takeUntil(this.destroy$)).subscribe((result: any) => {
        if (result) {
          this.provinces = result[0].body.values;
        }

        this.loadingService.start(false);
      });
  }

  private sanitizeTown(town: Town): Town {
    town.province = null;
    town.provinceId = this.frmGroup.controls['provinceControl'].value.id;

    return town;
  }

  public provinceComparer(t1: Province, t2: Province): boolean {
    return t1 && t2 ? (t1.id === t2.id || t1.code === t2.code) : t1 === t2;
  }

}