<div class="popup-dialog">
    <div class="trezo-card w-100 border-radius d-block bg-white border-0 shadow-none">
        <div class="trezo-card-header d-flex align-items-center justify-content-between">
            <div class="trezo-card-title">
                <h5 class="mb-0">
                    {{titleText}}
                </h5>
            </div>
            <div class="trezo-card-subtitle">
                <button (click)="toggleClass()" class="p-0 border-0 lh-1 bg-transparent transition">
                    <i class="ri-close-fill"></i>
                </button>
            </div>
        </div>
        <form [formGroup]="frmGroup" (ngSubmit)="onSave()">
            <div class="trezo-card-content">
                <div class="row">
                    <div class="col-md-12">
                        <div class="form-group">
                            <label class="main-label d-block fw-medium text-black">
                                Name <span class="text-danger">*</span>
                            </label>
                            <input type="text" class="form-control" placeholder="Name" formControlName="nameControl"
                                maxlength="200">
                            <div class="form-text text-danger"
                                *ngIf="isSubmitted && f['nameControl']!.hasError('required')">
                                Province Name is required.
                            </div>
                            <div class="form-text text-danger"
                                *ngIf="isSubmitted && f['nameControl']!.hasError('onlyWhitespace')">
                                Province Name can not be only whitespace.
                            </div>



                        </div>
                    </div>
                    <div class=" col-md-12">
                        <div class="form-group">
                            <label class="main-label d-block fw-medium text-black">
                                Code
                            </label>
                            <input type="text" class="form-control" placeholder="Code" formControlName="codeControl"
                                maxlength="10">
                            <!-- <div class="form-text text-danger"
                                *ngIf="isSubmitted && f['codeControl']!.hasError('required') ">
                                Province Code is required.
                            </div> -->
                            <div class="form-text text-danger"
                                *ngIf="isSubmitted && f['codeControl']!.hasError('onlyWhitespace')">
                                Province Code can not be only whitespace.
                            </div>
                        </div>
                    </div>

                </div>
                <div class="btn-box">
                    <button (click)="toggleClass()"
                        class="warn d-inline-block fw-medium border-0 border-radius transition">
                        Cancel
                    </button>
                    <button class="primary d-inline-block fw-medium border-0 border-radius transition"
                        [disabled]="!formIsValid() || !dataHasChanged()">
                        <span class="position-relative d-inline-block">
                            <i class="material-symbols-outlined">
                                add
                            </i>
                            {{titleButton}}
                        </span>
                    </button>
                </div>
            </div>
        </form>
    </div>
</div>