import { BaseRecord } from "./BaseRecord";
import { Province } from "./province";
import { Roads } from "./roads";
import { SectionNumber } from "./sectionNumber";
import { Street } from "./street";
import { Suburb } from "./suburb";
import { Town } from "./town";


export class AllotmentNumber extends BaseRecord<number> {
    public number: string;
    public provinceId: number;
    public province: Province | null;
    public townId: number;
    public town: Town | null;
    public suburbId: number;
    public suburb: Suburb | null;
    public streetId: number | null;
    public street: Street | null;
    public sectionNumberId: number;
    public sectionNumber: SectionNumber | null;
    public roadsId: number | null;
    public roads: Roads | null;

    constructor() {
        super();
        this.id = 0;
        this.provinceId = 0;
        this.townId = 0;
        this.suburbId = 0;
        this.streetId = 0;
        this.sectionNumberId = 0;
        this.roadsId = 0;
        this.number = "";
    }
}
