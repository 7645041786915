import { ObjectUtility } from "./ObjectUtility";
import { ArrayUtility } from "./ArrayUtility";
//import { DateUtility } from "./DateUtility";

export class EqualityUtility {
    public static getEqualityFunction(value: unknown): (a: unknown, b: unknown) => boolean {
        if (ObjectUtility.isObject(value)) {
            switch (true) {
                case value instanceof Array:
                    return ArrayUtility.deepEqual;
                // case value instanceof Date:
                //     return DateUtility.equal;
                default:
                    return ObjectUtility.deepEqual;
            }
        } else {
            return EqualityUtility.valueEqual;
        }
    }

    private static valueEqual(a: unknown, b: unknown): boolean {
        return a === b;
    }
}
