import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterLink, RouterOutlet } from '@angular/router';
import { CustomizerSettingsComponent } from '../../customizer-settings/customizer-settings.component';
import { SharedModule } from '../shared/shared.module';
import { AdminRoutingModule } from './module/admin.routes';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { ProvinceListComponent } from './components/province/province-list/province-list.component';
import { ProvinceDetailsComponent } from './components/province/province-details/province-details.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CoreModule } from "../core/core.module";
import { TownDetailsComponent } from './components/town/town-details/town-details.component';
import { TownListComponent } from './components/town/town-list/town-list.component';
import { DistrictListComponent } from './components/district/district-list/district-list.component';
import { DistrictDetailsComponent } from './components/district/district-details/district-details.component';
import { LlgListComponent } from './components/llg/llg-list/llg-list.component';
import { LlgDetailsComponent } from './components/llg/llg-details/llg-details.component';
import { WardListComponent } from './components/ward/ward-list/ward-list.component';
import { WardDetailsComponent } from './components/ward/ward-details/ward-details.component';
import { SuburbListComponent } from './components/suburb/suburb-list/suburb-list.component';
import { SuburbDetailsComponent } from './components/suburb/suburb-details/suburb-details.component';
import { RoadsListComponent } from './components/roads/roads-list/roads-list.component';
import { RoadsDetailsComponent } from './components/roads/roads-details/roads-details.component';
import { StreetDetailsComponent } from './components/street/street-details/street-details.component';
import { StreetListComponent } from './components/street/street-list/street-list.component';
import { SectionNumberListComponent } from './components/sectionNumber/sectionNumber-list/sectionNumber-list.component';
import { SectionNumberDetailsComponent } from './components/sectionNumber/sectionNumber-details/sectionNumber-details.component';
import { AllotmentNumberListComponent } from './components/allotmentNumber/allotmentNumber-list/allotmentNumber-list.component';
import { AllotmentNumberDetailsComponent } from './components/allotmentNumber/allotmentNumber-details/allotmentNumber-details.component';
import { ServiceTypeListComponent } from './components/serviceType/serviceType-list/serviceType-list.component';
import { ServiceTypeDetailsComponent } from './components/serviceType/serviceType-details/serviceType-details.component';
import { ServiceListComponent } from './components/service/service-list/service-list.component';
import { ServiceDetailsComponent } from './components/service/service-details/service-details.component';



@NgModule({
  declarations: [
    DashboardComponent,
    ProvinceListComponent,
    ProvinceDetailsComponent,
    TownListComponent,
    TownDetailsComponent,
    DistrictListComponent,
    DistrictDetailsComponent,
    LlgListComponent,
    LlgDetailsComponent,
    WardListComponent,
    WardDetailsComponent,
    SuburbListComponent,
    SuburbDetailsComponent,
    RoadsListComponent,
    RoadsDetailsComponent,
    StreetListComponent,
    StreetDetailsComponent,
    SectionNumberListComponent,
    SectionNumberDetailsComponent,
    AllotmentNumberListComponent,
    AllotmentNumberDetailsComponent,
    ServiceTypeListComponent,
    ServiceTypeDetailsComponent,
    ServiceListComponent,
    ServiceDetailsComponent

  ],
  imports: [
    AdminRoutingModule,
    CommonModule,
    RouterOutlet,
    SharedModule,
    CustomizerSettingsComponent,
    RouterLink,
    ReactiveFormsModule,
    FormsModule,
    CoreModule
  ]
})
export class AdminModule { }
