import { AllotmentNumber } from "./allotmentNumber";
import { BaseRecord } from "./BaseRecord";
import { Business } from "./business";
import { BusinessAddressMapping } from "./businessAddressMapping";
import { District } from "./district";
import { Llg } from "./llg";
import { PostOffice } from "./postOffice";
import { Province } from "./province";
import { Roads } from "./roads";
import { SectionNumber } from "./sectionNumber";
import { Street } from "./street";
import { Suburb } from "./suburb";
import { Town } from "./town";
import { Ward } from "./ward";


export class Address extends BaseRecord<number> {
    public provinceId: number;
    public province: Province | null;
    public districtId: number;
    public district: District | null;
    public llgId: number;
    public llg: Llg | null;
    public wardId: number;
    public ward: Ward | null;
    public townId: number;
    public town: Town | null;
    public suburbId: number;
    public suburb: Suburb | null;
    public streetId: number | null;
    public street: Street | null;
    public roadsId: number | null;
    public roads: Roads | null;
    public smallVillageAndBuildingName: string | null;
    public sectionNumberId: number;
    public sectionNumber: SectionNumber | null;
    public allotmentNumberId: number;
    public allotmentNumber: AllotmentNumber | null;
    public postOfficeId: number | null;
    public postOffice: PostOffice | null;
    public postalCode: string;
    public landmark: string;
    public addressType: string;
    public businessAddressMapping: BusinessAddressMapping[];
    public boxNumber: string | null;


    constructor() {
        super();
        this.id = 0;
        this.provinceId = 0;
        this.province = new Province;
        this.districtId = 0;
        this.district = new District;
        this.llgId = 0;
        this.llg = new Llg;
        this.wardId = 0;
        this.ward = new Ward;
        this.townId = 0;
        this.town = new Town;
        this.suburbId = 0;
        this.suburb = new Suburb;
        this.streetId = 0;
        this.street = new Street;
        this.roadsId = 0;
        this.roads = new Roads;
        this.smallVillageAndBuildingName = "";
        this.sectionNumberId = 0;
        this.sectionNumber = new SectionNumber;
        this.allotmentNumberId = 0;
        this.allotmentNumber = new AllotmentNumber;
        this.postOfficeId = 0;
        this.postOffice = new PostOffice;
        this.postalCode = "";
        this.landmark = "";
        this.businessAddressMapping = [];
        this.addressType = "";
        this.boxNumber = "";
    }
}
