import { Address } from "./address";
import { BaseRecord } from "./BaseRecord";
import { Business } from "./business";


export class BusinessAddressMapping extends BaseRecord<number> {

    public addressId: number;
    public address: Address | null;
    public businessId: number | null | undefined;
    public business: Business | null;


    constructor() {
        super();
        this.id = 0;
        this.businessId = 0
        this.addressId = 0;
    }
}
