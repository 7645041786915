<div
    class="total-orders-card trezo-card mb-25 border-radius d-block bg-white border-0 shadow-none"
    [class.component-rtl-enabled]="themeService.isRTLEnabled()"
>
    <div class="trezo-card-content">
        <div class="title">
            <span class="d-block">
                Total Data
            </span>
            <h5 class="mb-0">
                {{totalRecords}}
            </h5>
        </div>
        <div class="chart-container">
            <apx-chart
                [series]="chartOptions.series!"
                [chart]="chartOptions.chart!"
                [dataLabels]="chartOptions.dataLabels!"
                [plotOptions]="chartOptions.plotOptions!"
                [yaxis]="chartOptions.yaxis!"
                [xaxis]="chartOptions.xaxis!"
                [fill]="chartOptions.fill!"
                [title]="chartOptions.title!"
                
            ></apx-chart>
        </div>
        <!-- <div class="d-flex align-items-center justify-content-between">
            <span class="info-badge d-inline-block">
                +25%
            </span>
            <span class="days d-block">
                Last 90 days
            </span>
        </div> -->
    </div>
</div>