import { Component, OnInit } from '@angular/core';
import { CustomizerSettingsService } from '../../../../customizer-settings/customizer-settings.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthenticationService } from '../../../shared/services/authentication.service';
import { first } from 'rxjs';
import { LoadingService } from '../../services/loading.service';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrl: './forgot-password.component.scss'
})
export class ForgotPasswordComponent implements OnInit {

  // isToggled
  isToggled = false;
  forgotForm!: FormGroup;
  submitted = false;
  error = '';
  isSuccess: boolean;

  constructor(
    public themeService: CustomizerSettingsService,
    private formBuilder: FormBuilder,
    private authService: AuthenticationService,
    private loadingService: LoadingService
  ) {
    this.themeService.isToggled$.subscribe(isToggled => {
      this.isToggled = isToggled;
    });
  }

  ngOnInit(): void {
    this.initializer();
  }

  initializer() {
    this.forgotForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email]],
    });
  }

  // convenience getter for easy access to form fields
  get f() { return this.forgotForm.controls; }

  /**
* Form submit
*/
  onSubmit() {
    this.loadingService.showLoading();
    this.error = "";
    this.submitted = true;
    // stop here if form is invalid
    if (this.forgotForm.invalid) {
      return;
    }

    this.authService.forgotPassword(this.f['email'].value)
      .pipe(first())
      .subscribe((result: string) => {
        this.error = "";
        if (result) {
          this.isSuccess = true;
        }
        this.loadingService.hideLoading();
      },
        error => {
          if (error?.error != undefined && error?.error?.message != undefined) {
            this.error = error?.error?.message;
          } else {
            this.error = "PNG address unreachable, IT has been notified";
          }
          this.loadingService.hideLoading();
        })
  }

}