import { Component, EventEmitter, Input, OnInit, Output, AfterViewInit, OnDestroy } from '@angular/core';
import { CustomizerSettingsService } from '../../../../../../customizer-settings/customizer-settings.service';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Suburb } from '../../../../../../models/domain/suburb';
import { ObjectUtility } from '../../../../../core/utilities/ObjectUtility';
import { catchError, forkJoin, of, Subject, takeUntil } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { whitespaceValidator } from '../../../../../core/validators/ValidationService';
import { Town } from '../../../../../../models/domain/town';
import { LoadingService } from '../../../../../core/services/loading.service';
import { SuburbService } from '../../../../services/suburb.service';
import { TownService } from '../../../../services/town.service ';
import { BaseSearchOptions } from '../../../../../../models/core/table/BaseSearchOptions';
import { Province } from '../../../../../../models/domain/province';
import { District } from '../../../../../../models/domain/district';
import { Llg } from '../../../../../../models/domain/llg';
import { Ward } from '../../../../../../models/domain/ward';
import { DistrictService } from '../../../../services/district.service';
import { LlgService } from '../../../../services/llg.service';
import { ProvinceService } from '../../../../services/province.service';
import { WardService } from '../../../../services/ward.service';

@Component({
  selector: 'app-suburb-details',
  templateUrl: './suburb-details.component.html',
  styleUrl: './suburb-details.component.scss'
})
export class SuburbDetailsComponent implements OnInit, AfterViewInit, OnDestroy {

  @Output() classApplied = new EventEmitter<boolean>();
  @Output() saveConfirmationEvent = new EventEmitter<boolean>();
  @Input() currentSuburbId = 0;
  // isToggled
  isToggled = false;
  frmGroup: FormGroup;

  private destroy$: Subject<boolean> = new Subject<boolean>();
  public suburbOriginal = new Suburb;
  public suburb: Suburb = new Suburb;
  public titleText = "";
  public titleButton = "";
  public errorMessage = "";

  isSubmitted = false;

  // drop down bind data
  public districts: District[];
  public llgs: Llg[];
  public wards: Ward[];
  public towns: Town[];
  public pageIndex = 0;
  public pageSize = 1000;
  public searchOptions: BaseSearchOptions;

  constructor(
    public themeService: CustomizerSettingsService,
    private formBuilder: FormBuilder,
    private suburbService: SuburbService,
    private toasterService: ToastrService,
    private townService: TownService,
    private loadingService: LoadingService,
    private districtService: DistrictService,
    private llgService: LlgService,
    private wardService: WardService,
  ) {
    this.initialize();
  }

  public ngOnInit(): void {

    if (this.currentSuburbId > 0) {
      this.getDropdownData();
      this.setForm(this.suburb);
    }
    else {
      this.currentSuburbId = 0;
      this.getDropdownData();
      const newSuburb = new Suburb();
      this.suburb = ObjectUtility.deepClone(newSuburb);
      this.suburbOriginal = ObjectUtility.deepClone(newSuburb);
      this.setForm(this.suburb);
    }
  }

  private initialize(): void {
    this.themeService.isToggled$.subscribe(isToggled => {
      this.isToggled = isToggled;
    });
    this.isSubmitted = false;
    this.searchOptions = new BaseSearchOptions(undefined, 'name', 0, this.pageIndex, this.pageSize);
    this.createForm();
  }

  public ngAfterViewInit(): void {

    this.frmGroup.controls['nameControl'].valueChanges.subscribe(value => {
      this.suburb.name = value;
    });

    this.frmGroup.controls['llgControl'].valueChanges.subscribe(value => {
      this.suburb.llg = value;
    });
    this.frmGroup.controls['wardControl'].valueChanges.subscribe(value => {
      this.suburb.ward = value;
    });
    this.frmGroup.controls['townControl'].valueChanges.subscribe(value => {
      this.suburb.town = value;
    });

  }

  public ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  get f() { return this.frmGroup.controls; }

  private createForm(): void {
    this.frmGroup = this.formBuilder.group({
      nameControl: new FormControl('', [Validators.required, whitespaceValidator()]),
      districtControl: new FormControl({ value: null, disabled: true }, [Validators.required]),
      llgControl: new FormControl(null, [Validators.required]),
      wardControl: new FormControl(null, [Validators.required]),
      townControl: new FormControl(null, [Validators.required]),
    })
  }

  private setForm(suburb: Suburb): void {
    if (suburb && suburb.id != undefined && suburb.id > 0) {
      this.frmGroup.patchValue({
        nameControl: suburb?.name,
        districtControl: suburb?.district,
        llgControl: suburb?.llg,
        wardControl: suburb?.ward,
        townControl: suburb?.town,
      });
    }
    this.titleText = `${this.currentSuburbId === 0 ? "Add" : "Edit"} Suburb`;
    this.titleButton = `${this.currentSuburbId === 0 ? "Create" : "Update"}`;
  }

  public onSave(): void {
    this.isSubmitted = true;


    if (this.frmGroup.valid) {
      this.loadingService.showLoading();
      this.addEditSuburb();
    }
  }

  private addEditSuburb(): void {
    if (this.suburb?.id === 0) {
      this.suburbService.create(this.sanitizeSuburb(this.suburb))
        .pipe(
          catchError((err: any) => {
            if (err && err.error) {
              this.isSubmitted = false;
              if (err.error.isCustom) {
                this.errorMessage = err.error.message;
                this.toasterService.warning(err.error.message);
              } else {
                this.toasterService.error('Something went wrong!');
              }
            } else {
              this.isSubmitted = false;
              this.toasterService.error('Something went wrong!');
            }
            return of(null);
          }),
          takeUntil(this.destroy$))
        .subscribe(response => {
          if (response) {
            this.isSubmitted = false;
            this.suburb = response?.body != null ? response?.body : new Suburb;
            this.saveConfirmationEvent.emit(true);
            this.toggleClass();
            this.toasterService.success("Save successfully");
          }
        });
    } else {
      this.suburbService.update(this.sanitizeSuburb(this.suburb))
        .pipe(
          catchError((err: any) => {
            if (err && err.error) {
              this.isSubmitted = false;
              if (err.error.isCustom) {
                this.errorMessage = err.error.message;
                this.toasterService.warning(err.error.message);
              } else {
                this.toasterService.error('Something went wrong!');
              }
            } else {
              this.isSubmitted = false;
              this.toasterService.error('Something went wrong!');
            }
            return of(null);
          }),
          takeUntil(this.destroy$))
        .subscribe(response => {
          if (response) {
            this.isSubmitted = false;
            this.suburb = response?.body != null ? response?.body : new Suburb;
            this.saveConfirmationEvent.emit(true);
            this.toasterService.success("Update successfully");
            this.toggleClass();
          }
        });
    }
  }


  public toggleClass(): void {
    this.classApplied.emit(false);
  }

  private getDropdownData() {
    const town = this.townService.get(this.searchOptions);
    this.loadingService.showLoading();
    forkJoin([town])
      .pipe(takeUntil(this.destroy$)).subscribe((result: any) => {
        if (result) {
          this.towns = result[0].body.values;
          this.getSuburbById(this.currentSuburbId);
        }
        this.loadingService.hideLoading();
      });
  }

  private sanitizeSuburb(suburb: Suburb): Suburb {
    suburb.district = null;
    suburb.districtId = this.frmGroup.controls['districtControl'].value.id;
    suburb.llg = null;
    suburb.llgId = this.frmGroup.controls['llgControl'].value.id;
    suburb.ward = null;
    suburb.wardId = this.frmGroup.controls['wardControl'].value.id;
    suburb.town = null;
    suburb.townId = this.frmGroup.controls['townControl'].value.id;

    return suburb;
  }


  public districtComparer(t1: District, t2: District): boolean {
    return t1 && t2 ? (t1.id === t2.id || t1.name === t2.name) : t1 === t2;
  }
  public llgComparer(t1: Llg, t2: Llg): boolean {
    return t1 && t2 ? (t1.id === t2.id || t1.name === t2.name) : t1 === t2;
  }
  public wardComparer(t1: Ward, t2: Ward): boolean {
    return t1 && t2 ? (t1.id === t2.id || t1.name === t2.name) : t1 === t2;
  }
  public townComparer(t1: Town, t2: Town): boolean {
    return t1 && t2 ? (t1.id === t2.id || t1.name === t2.name) : t1 === t2;
  }

  public dataHasChanged(): boolean {
    const baseEqual = ObjectUtility.deepEqual(this.suburb, this.suburbOriginal);
    return !baseEqual;
  }

  public formIsValid(): boolean {
    const isValid = this.frmGroup.controls['llgControl'].valid
      && this.frmGroup.controls['wardControl'].valid
      && this.frmGroup.controls['townControl'].valid
      && this.frmGroup.controls['nameControl'].valid
      && (this.suburb.district?.id != 0
        || this.suburb.district?.id != null)

    return isValid;
  }

  public getDistrictByTown(townId: number | undefined) {
    if (townId && townId > 0) {
      this.districtService.getDistrictsByTown(townId).subscribe(response => {
        this.loadingService.showLoading();
        this.districts = response;
        const firstdistrict = this.districts[0] || '';
        this.frmGroup.patchValue({ districtControl: firstdistrict });
        this.suburb.district = firstdistrict;
        if (this.suburb != null && this.suburb.district) {
          this.GetLlgByDistrict(this.suburb.district.id);
        }
        this.loadingService.hideLoading();
      });
    }
  }

  public GetLlgByDistrict(districtId: number | undefined) {
    if (districtId && districtId > 0) {
      this.loadingService.showLoading();
      this.llgService.GetLlgByDistrict(districtId).subscribe(response => {
        this.llgs = response;
        this.loadingService.hideLoading();
      });
    }
  }

  public GetWardByLlg(llgId: number | undefined) {
    if (llgId && llgId > 0) {
      this.loadingService.showLoading();
      this.wardService.GetWardByLlg(llgId).subscribe(response => {
        this.wards = response;
        this.loadingService.hideLoading();
      });
    }
  }



  public GetAllDropDownData(suburb: Suburb) {
    this.getDistrictByTown(this.suburb.townId);
    this.GetLlgByDistrict(this.suburb.districtId);
    this.GetWardByLlg(this.suburb.llgId);
  }

  public clearValue(change: string) {
    if (change == 'Town') {
      this.frmGroup.controls['districtControl'].setValue(null);
      this.districts = [];
      this.suburb.district = null;
      this.frmGroup.controls['llgControl'].setValue(null);
      this.suburb.llg = null;
      this.llgs = [];
      this.frmGroup.controls['wardControl'].setValue(null);
      this.suburb.ward = null;
      this.wards = [];
    } else if (change == 'Llg') {
      this.frmGroup.controls['wardControl'].setValue(null);
      this.suburb.ward = null;
      this.wards = [];
    }
  }

  public getSuburbById(suburbId: number) {
    if (suburbId) {
      this.loadingService.showLoading();
      this.suburbService.getById(suburbId)
        .pipe(takeUntil(this.destroy$))
        .subscribe(result => {
          if (result) {
            this.suburb = ObjectUtility.deepClone(result);
            this.suburbOriginal = ObjectUtility.deepClone(result);
            this.GetAllDropDownData(this.suburb);
            this.setForm(this.suburb);
          }
          this.loadingService.hideLoading();
        });
    }

  }

  public onTownChange(townId: number) {
    this.clearValue('Town');
    this.getDistrictByTown(townId);
  }
  public onLlgChange(llgId: number) {
    this.clearValue('Llg');
    this.GetWardByLlg(llgId);
  }


}