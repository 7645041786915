import { BaseRecord } from "./BaseRecord";
import { District } from "./district";
import { Llg } from "./llg";
import { Province } from "./province";
import { Roads } from "./roads";
import { Suburb } from "./suburb";
import { Town } from "./town";
import { Ward } from "./ward";


export class Street extends BaseRecord<number> {
    public name: string;
    public provinceId: number | null;
    public province: Province | null;
    public districtId: number | null;
    public district: District | null;
    public llgId: number | null;
    public llg: Llg | null;
    public wardId: number | null;
    public ward: Ward | null;
    public suburbId: number | null;
    public suburb: Suburb | null;
    public townId: number | null;
    public town: Town | null;
    public roadsId: number | null;
    public roads: Roads | null;
    public type: string;

    constructor() {
        super();
        this.id = 0;
        this.suburbId = null;
        this.wardId = null;
        this.townId = null;
        this.provinceId = null;
        this.districtId = null;
        this.roadsId = null;
        this.llgId = null;
        this.name = "";
        this.type = "";
    }
}
