import { Component, OnInit } from '@angular/core';
//import { CustomizerSettingsService } from '../../customizer-settings/customizer-settings.service';
import { CustomizerSettingsService } from '../../../../customizer-settings/customizer-settings.service';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Validators } from '@angular/forms';
import { LoadingService } from '../../services/loading.service';
import { LocalStorageService, StorageKeys } from '../../../shared/services/local-storage.service';
import { AuthenticationService } from '../../../shared/services/authentication.service';
import { first } from 'rxjs';
import { AuthenticationResult } from '../../../../models/other/AuthenticationResult';
import { Router } from '@angular/router';

@Component({
  selector: 'app-sign-in',
  templateUrl: './sign-in.component.html',
  styleUrl: './sign-in.component.scss'
})
export class SignInComponent implements OnInit {

  // isToggled
  isToggled = false;
  loginForm!: FormGroup;
  isPasswordVisible = false;
  submitted = false;
  isLoginSuccess: boolean = false;
  error = '';
  returnUrl!: string;

  constructor(
    public themeService: CustomizerSettingsService,
    private formBuilder: FormBuilder,
    private loadingService: LoadingService,
    private localStorage: LocalStorageService,
    private authService: AuthenticationService,
    private router: Router
  ) {
    this.themeService.isToggled$.subscribe((isToggled: boolean) => {
      this.isToggled = isToggled;
    });

  }

  ngOnInit(): void {
    this.initializer();

    if (this.authService.isUserAuthenticated()) {
      this.router.navigate(['admin/dashboard']);
    }
  }

  togglePasswordVisibility(): void {
    this.isPasswordVisible = !this.isPasswordVisible;
  }
  onPasswordInput(event: Event): void {
    const inputElement = event.target as HTMLInputElement;
    this.f['password'].setValue(inputElement.value);
  }

  private initializer(): void {
    this.loginForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email]],
      password: ['', Validators.required],
    });
  }

  // convenience getter for easy access to form fields
  get f() { return this.loginForm.controls; }

  /**
* Form submit
*/
  onSubmit(resent: boolean = false) {
    this.submitted = true;
    this.error = "";
    // stop here if form is invalid
    if (this.loginForm.invalid) {
      return;
    } else {
      this.localStorage.remove(StorageKeys.CurrentAuth);
      this.localStorage.remove(StorageKeys.Token);
      this.loadingService.showLoading();
      this.authService.login(this.f['email'].value, this.f['password'].value, resent)
        .pipe(first())
        .subscribe((result: AuthenticationResult) => {
          if (result.isSuccess) {
            this.isLoginSuccess = result.isSuccess;
            this.error = "Login success";
            this.loadingService.hideLoading();
            if (this.returnUrl) {
              window.location.href = this.returnUrl;
            } else {
              this.router.navigate(['admin/dashboard']);
            }
            this.loadingService.hideLoading();
          } else {
            this.error = result.errors[0];
            this.isLoginSuccess = false;
            this.loadingService.hideLoading();
          }
        },
          error => {
            this.isLoginSuccess = false;
            if (error?.error != undefined && error?.error?.message != undefined) {
              this.error = error?.error?.message;
            } else {
              this.error = "PNG address unreachable, IT has been notified";
            }
            this.loadingService.hideLoading();
          })
    }
  }
  onForgotPasswordClick(event: Event): void {
    event.preventDefault();
  }

}