import { Component } from '@angular/core';
import { ToasterService } from '../../services/toaster.service';

@Component({
    selector: 'app-toaster',
    templateUrl: './toaster.component.html',
    styleUrls: ['./toaster.component.css']
})
export class ToasterComponent {
    visible = false;
    message = '';
    type: 'success' | 'warning' | 'error' | 'info' = 'success';

    constructor(private toasterService: ToasterService) { }

    ngOnInit() {
        this.toasterService.register(this);
    }

    show(message: string, type: 'success' | 'warning' | 'error' | 'info') {
        this.message = message;
        this.type = type;
        this.visible = true;

        setTimeout(() => {
            this.close();
        }, 3000); // Auto close after 3 seconds
    }

    close() {
        this.visible = false;
    }

    getToastClass() {

        // if (this.type == "success") {
        //     return "alert alert-bg-success"
        // }

        // if (this.type == "warning") {
        //     return "alert alert-bg-warning"
        // }

        // if (this.type == "error") {
        //     return "alert alert-bg-danger"
        // }

        // return null;
        return `toast ${this.type}`;
    }
}