import { BaseSearchOptions } from "./BaseSearchOptions";
import { FilterDataType } from "./FilterDataType";
import { SearchExpression } from "./SearchExpression";

export class TableSearchEventData {
    public baseSearchOptions: BaseSearchOptions;
    public columnFilterValues: { [columnName: string]: { dataType: FilterDataType, opType: SearchExpression, searchValue: any } } = {};

    constructor(baseSearchOptions: BaseSearchOptions, columnFilterValues: { [columnName: string]: { dataType: FilterDataType, opType: SearchExpression, searchValue: any } }) {
        this.baseSearchOptions = baseSearchOptions;
        this.columnFilterValues = columnFilterValues;
    }
}
