import { HttpClient, HttpParams, HttpResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";
import { tap } from "rxjs/operators";
import { PagedResponse } from "../../../models/domain/PagedResponse";
import { BaseSearchOptions } from "../../../models/core/table/BaseSearchOptions";

@Injectable({
    providedIn: "root"
})
export abstract class BaseService<T> {
    protected currentParameters: BaseSearchOptions;
    protected readonly baseApiUrl: string;
    protected dataSubject: BehaviorSubject<T[]>;
    public loading$: Observable<boolean>;
    protected loadingSubject: BehaviorSubject<boolean>;
    
    private dataCountSubject: BehaviorSubject<number>;
    public dataCount$: Observable<number>;

    public data$: Observable<T[]>;
    private data: T[] | undefined;

    protected constructor(protected httpClient: HttpClient, baseApiUrl: string, baseQueryParams?: BaseSearchOptions) {
        this.data = [];
        this.dataSubject = new BehaviorSubject<T[]>([]);
        this.data$ = this.dataSubject.asObservable();
        this.currentParameters = baseQueryParams ?? new BaseSearchOptions('', '', 0, 0, 10, true);
        this.baseApiUrl = baseApiUrl;
        
        this.dataCountSubject = new BehaviorSubject<number>(0);
        this.dataCount$ = this.dataCountSubject.asObservable();

        this.loadingSubject = new BehaviorSubject<boolean>(false);
        this.loading$ = this.loadingSubject.asObservable();
    }

    public getById(id: number): Observable<T> {
        this.loadingSubject.next(true);
        return this.httpClient.get<any>(`${this.baseApiUrl}/${id}`)
            .pipe(tap(response => {
                debugger
                this.loadingSubject.next(false);
                this.data?.push(response);
                this.dataSubjectUpdate(this.data ?? []);
            }));
    }

    public get(parameters?: BaseSearchOptions): Observable<HttpResponse<PagedResponse<T>>> {
        debugger
        if (parameters) {
            this.currentParameters.updateByObject(parameters);
        }
        const params = new HttpParams()
            .set("filter", this.currentParameters.filter)
            .set("orderBy", this.currentParameters.orderBy)
            .set("pageSize", this.currentParameters.pageSize)
            .set("pageIndex", this.currentParameters.pageIndex)
            .set("orderByDirection", this.currentParameters.orderByDirection)
            .set("expand", this.currentParameters.expand);
        this.loadingSubject.next(true);
        return this.httpClient.get<PagedResponse<T>>(this.baseApiUrl, { params, observe: "response" })
            .pipe(tap(response => {
                debugger
                this.loadingSubject.next(false);
                this.data = response?.body?.values;
                this.dataSubjectUpdate(this.data ?? []);
            }));
    }

    /*public getAdvance(advanceSearchCriteria?: AdvanceSearchCriteria): Observable<HttpResponse<PagedResponse<T>>> {
        this.loadingSubject.next(true);
        return this.httpClient.post<PagedResponse<T>>(`${this.baseApiUrl}/`, advanceSearchCriteria, { observe: "response" })
        .pipe(tap(response => {
            this.loadingSubject.next(false);
           // this.data = response.body.items;
            //this.dataSubjectUpdate(this.data);
        }));
    }*/

    public create(obj: T): Observable<HttpResponse<T>> {
        return this.httpClient.post<T>(this.baseApiUrl, obj, { observe: "response" })
            .pipe(tap(response => {
                if (response.ok) {
                    this.data?.push(response.body as T);
                    this.dataSubject.next(this.data ?? []);
                }
            }));
    }

    public update(obj: T): Observable<HttpResponse<T>> {
        return this.httpClient.put<T>(this.baseApiUrl, obj, { observe: "response" })
            .pipe(tap((response: HttpResponse<T>) => {
                if (response.ok) {
                    const idx = this.data?.indexOf(this.data.find(x => x) as T);
                    if (this.data != undefined) {
                        this.data[idx ?? 0] = response.body as T;
                    }
                    this.dataSubject.next(this.data ?? []);
                }
            }));
    }

    public delete(id: any): Observable<HttpResponse<any>> {
        return this.httpClient.delete(`${this.baseApiUrl}/${id}`, { observe: "response" })
            .pipe(tap((response: HttpResponse<any>) => {
                if (response.ok) {
                    const idx = this.data != null ? this.data?.indexOf(id) : 0;
                    this.data?.splice(idx, 1);
                    this.dataSubjectUpdate(this.data ?? []);
                }
            }));
    }

    public validateValue(fieldName: string, id: number, value: string): Observable<boolean> {
        const url = `${this.baseApiUrl}/checkExisting`;
        const params = new HttpParams()
            .set("fieldName", value)
            .set("id", id)
            .set("value", value);

        return this.httpClient.get<boolean>(url, { params });
    }

    public dataSubjectUpdate(data: T[]): void {
        this.dataSubject.next(data);
    }

    public clearSearchCriteria(): void {
        this.currentParameters = new BaseSearchOptions();
    }

    public get queryParameters(): BaseSearchOptions {
        return this.currentParameters;
    }
}
