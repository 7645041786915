import { BaseRecord } from "./BaseRecord";

export class ChangePassword extends BaseRecord<string> {
    public email: string | null;
    public resetCode: string | null;
    public newPassword: string;
    public currentPassword: string;


    constructor() {
        super();
        this.id = "";
        this.email = "";
        this.resetCode = "";
        this.newPassword = "";
        this.currentPassword = "";

    }
}
