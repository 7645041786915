import { RouterModule, Routes } from "@angular/router";
import { NgModule } from "@angular/core";
import { DashboardComponent } from "../components/dashboard/dashboard.component";
import { ProvinceListComponent } from "../components/province/province-list/province-list.component";
import { TownListComponent } from "../components/town/town-list/town-list.component";
import { DistrictListComponent } from "../components/district/district-list/district-list.component";
import { LlgListComponent } from "../components/llg/llg-list/llg-list.component";
import { WardListComponent } from "../components/ward/ward-list/ward-list.component";
import { SuburbListComponent } from "../components/suburb/suburb-list/suburb-list.component";
import { RoadsListComponent } from "../components/roads/roads-list/roads-list.component";
import { StreetListComponent } from "../components/street/street-list/street-list.component";
import { SectionNumberListComponent } from "../components/sectionNumber/sectionNumber-list/sectionNumber-list.component";
import { AllotmentNumberListComponent } from "../components/allotmentNumber/allotmentNumber-list/allotmentNumber-list.component";
import { ServiceTypeListComponent } from "../components/serviceType/serviceType-list/serviceType-list.component";
import { ServiceListComponent } from "../components/service/service-list/service-list.component";

export const routes: Routes = [
    {
        path: '', component: DashboardComponent,
        children: [
            { path: 'province', component: ProvinceListComponent },
            { path: 'town', component: TownListComponent },
            { path: 'district', component: DistrictListComponent },
            { path: 'llg', component: LlgListComponent },
            { path: 'ward', component: WardListComponent },
            { path: 'suburb', component: SuburbListComponent },
            { path: 'roads', component: RoadsListComponent },
            { path: 'street', component: StreetListComponent },
            { path: 'sectionNumber', component: SectionNumberListComponent },
            { path: 'allotmentNumber', component: AllotmentNumberListComponent },
            { path: 'serviceType', component: ServiceTypeListComponent },
            { path: 'service', component: ServiceListComponent },
        ]
    },


    //{path: '**', component: NotFoundComponent} // This line will remain down from the whole pages component list
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class AdminRoutingModule { }