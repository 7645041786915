import { Address } from "./address";
import { BaseRecord } from "./BaseRecord";
import { BusinessServiceMapping } from "./businessServiceMapping";
import { BusinessType } from "./businessType";
import { Contact } from "./contact";


export class Business extends BaseRecord<number> {
    public name: string;
    public businessTypeId: number;
    public businessType: BusinessType | null;
    public registrationNumber: string;
    public taxNumber: string;
    public isActive: boolean;
    public dateOfIncorporation: Date;
    public businessServiceMapping: BusinessServiceMapping[];


    constructor() {
        super();
        this.id = 0;
        this.businessTypeId = 0;
        this.businessType = new BusinessType;
        this.name = "";
        this.registrationNumber = "";
        this.taxNumber = "";
        this.isActive = true;
        this.dateOfIncorporation = new Date();
        this.businessServiceMapping = [];

    }
}
