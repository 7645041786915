import { Component, Input, EventEmitter, Output, OnInit } from '@angular/core';
import { Service } from '../../../../models/domain/service';
import { Subject, debounceTime } from 'rxjs';

@Component({
  selector: 'app-ttpl-chipview',
  templateUrl: './ttpl-chipview.component.html',
  styleUrls: ['./ttpl-chipview.component.scss'],

})
export class TtplChipviewComponent implements OnInit {

  @Input() isEditMode: boolean; // Input data from the parent component
  @Input() filteredItems: Service[]; // Input data from the parent component
  @Output() serviceSearchEvent: EventEmitter<string> = new EventEmitter<string>();
  @Output() selectedServicesEvent: EventEmitter<Service[]> = new EventEmitter<Service[]>();

  searchQuery: string = ''; // User input
  selectedChips: Service[] = []; // Store the selected item

  private searchSubject: Subject<string> = new Subject<string>();

  ngOnInit(): void {
    if (this.isEditMode) {
      this.selectedChips.push(...this.filteredItems);
      this.filteredItems = [];
    }

    // Emit search query with debounce time
    this.searchSubject.pipe(debounceTime(800)).subscribe(query => {
      this.serviceSearchEvent.emit(query);
    });

  }

  // Method to handle input changes and search the API
  onSearchChange() {
    if (this.searchQuery.trim()) {
      //this.serviceSearchEvent.emit(this.searchQuery); // Emit the search query to the parent component
      this.searchSubject.next(this.searchQuery);
    } else {
      this.filteredItems = [];  // Clear filtered items if the input is empty
    }
  }

  // Method to select an item and add it as a chip
  selectChip(item: any) {
    //if (!this.selectedChips.includes(item)) {
    const exists = this.selectedChips.some(chip => chip.id === item.id && chip.name === item.name);
    if (!exists) {
      this.selectedChips.push(item);  // Add the item to selectedChips
      this.selectedService();  // Emit the selected chips to the parent component
    }
    this.searchQuery = '';  // Clear the input field after selection
    this.filteredItems = [];  // Hide the filtered items after selection
  }

  // Method to remove a chip
  removeChip(chip: any) {
    const index = this.selectedChips.indexOf(chip);
    if (index !== -1) {
      this.selectedChips.splice(index, 1);  // Remove the chip from the selectedChips array
    }
    this.selectedService();  // Emit the selected chips to the parent component
  }

  selectedService() {
    this.selectedServicesEvent.emit(this.selectedChips); // Emit the selected chips to the parent component
  }
}
