import { Injectable } from '@angular/core';
import { ToasterComponent } from '../components/toaster/toaster.component';

@Injectable({
    providedIn: 'root'
})
export class ToasterService {
    private toaster: ToasterComponent;

    public register(toaster: ToasterComponent): void {
        this.toaster = toaster;
    }

    public success(message: string): void {
        this.toaster.show(message, 'success');
    }

    public warning(message: string): void {
        this.toaster.show(message, 'warning');
    }

    public error(message: string): void {
        this.toaster.show(message, 'error');
    }

    public info(message: string): void {
        this.toaster.show(message, 'info');
    }
}