import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AuthenticationService } from '../modules/shared/services/authentication.service';
import { LocalStorageService, StorageKeys } from '../modules/shared/services/local-storage.service';
import { environment } from '../../environments/environment';


@Injectable()
export class JwtInterceptor implements HttpInterceptor {
    constructor(private authenticationService: AuthenticationService, private localStorage: LocalStorageService) {
    }

    intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
        // add auth header with jwt if user is logged in and request is to api url
        const user = this.authenticationService.getCurrentUser();
        const isLoggedIn = user && this.authenticationService.isUserAuthenticated();
        const isApiUrl = request.url.startsWith(environment.apiUrl);
        if (isLoggedIn && isApiUrl) {
            request = request.clone({
                setHeaders: {
                    Authorization: `Bearer ${this.localStorage.get(StorageKeys.Token)}`
                }
            });
        }

        return next.handle(request);
    }
}
