import { EqualityUtility } from "./EqualityUtility";

export class ArrayUtility {
    public static deepEqual(arr1: unknown, arr2: unknown): boolean {
        //Array Check
        if (!Array.isArray(arr1) || !Array.isArray(arr2)) {
            return false;
        }

        // Length Check
        if (arr1.length !== arr2.length) {
            return false;
        }

        // Null Check
        if (arr1.filter(a => a === null).length !== arr2.filter(a => a === null).length) {
            return false;
        }

        // Undefined Check
        if (arr1.filter(a => a === undefined).length !== arr2.filter(a => a === undefined).length) {
            return false;
        }

        // Values Check
        for (const val1 of arr1) {
            const func = EqualityUtility.getEqualityFunction(val1);

            if (!arr2.some(val2 => func(val1, val2))) {
                return false;
            }
        }

        return true;
    }
}
