import { Injectable, EventEmitter } from '@angular/core';
import { ModalText } from '../../../models/enums/ModalText';

@Injectable({
    providedIn: 'root'
})
export class ModalService {
    public onConfirm: EventEmitter<any> = new EventEmitter();
    public showModal: boolean = false;
    public title: string = 'Confirm Action';
    public message: string = 'Are you sure you want to proceed?';
    public confirmText: string = ModalText.ConfirmYes;
    public cancelText: string = ModalText.ConfirmNo;

    openModal(title?: string, message?: string, confirmText?: string, cancelText?: string) {
        this.title = title || this.title;
        this.message = message || this.message;
        this.confirmText = confirmText || this.confirmText;
        this.cancelText = cancelText || this.cancelText;
        this.showModal = true;
    }

    closeModal() {
        this.showModal = false;
    }
}