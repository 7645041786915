import { ChangeDetectorRef, Component, Input, OnInit, ViewChild } from '@angular/core';
import { RouterLink } from '@angular/router';
import {
    ApexGrid,
    ApexChart,
    ApexXAxis,
    ApexYAxis,
    ApexStroke,
    ApexLegend,
    ChartComponent,
    ApexDataLabels,
    ApexTitleSubtitle,
    NgApexchartsModule,
    ApexAxisChartSeries
} from "ng-apexcharts";
import { CustomizerSettingsService } from '../../../../../../customizer-settings/customizer-settings.service';
import { DashboardService } from '../../../../services/dashboard.service';
import { BaseSearchOptions } from '../../../../../../models/core/table/BaseSearchOptions';
import { CommonModule, DecimalPipe } from '@angular/common';

export type ChartOptions = {
    grid: ApexGrid;
    colors: string[];
    yaxis: ApexYAxis;
    labels: string[];
    chart: ApexChart;
    xaxis: ApexXAxis;
    stroke: ApexStroke;
    legend: ApexLegend;
    title: ApexTitleSubtitle;
    dataLabels: ApexDataLabels;
    subtitle: ApexTitleSubtitle;
    series: ApexAxisChartSeries;
};

@Component({
    selector: 'png-client-growth',
    standalone: true,
    imports: [RouterLink, NgApexchartsModule, CommonModule],
    templateUrl: './client-growth.component.html',
    styleUrl: './client-growth.component.scss'
})
export class ClientGrowthComponent implements OnInit {

    @Input() dataRage: string = "7days"

    @ViewChild("chart") chart: ChartComponent;
    public chartOptions: Partial<ChartOptions>;

    // isToggled
    isToggled = false;

    public searchOptions: BaseSearchOptions;
    public pageIndex: number = 0;
    public pageSize: number = 1000;

    totalClients: number = 0;
    percentageChange: number = 0;

    constructor(
        public themeService: CustomizerSettingsService,
        public dashboardService: DashboardService,
        private cdr: ChangeDetectorRef
    ) {
        this.searchOptions = new BaseSearchOptions(undefined, '', 0, this.pageIndex, this.pageSize);

        this.chartOptions = {
            series: [
                {
                    name: "Total Client",
                    data: [50, 75, 65, 80, 95, 120, 325]
                }
            ],
            chart: {
                type: "area",
                height: 100,
                zoom: {
                    enabled: false
                },
                toolbar: {
                    show: false
                }
            },
            colors: [
                "#3584FC"
            ],
            dataLabels: {
                enabled: false
            },
            grid: {
                show: false,
                borderColor: "#ECEEF2"
            },
            stroke: {
                curve: "straight",
                width: 1
            },
            xaxis: {
                type: "category",
                categories: [],
                // categories: [
                //     "Mon",
                //     "Tue",
                //     "Wed",
                //     "Thu",
                //     "Fri",
                //     "Sat",
                //     "Sun"
                // ],
                axisTicks: {
                    show: false,
                    color: '#ECEEF2'
                },
                axisBorder: {
                    show: false,
                    color: '#ECEEF2'
                },
                labels: {
                    show: true,
                    style: {
                        colors: "#8695AA",
                        fontSize: "12px"
                    }
                }
            },
            yaxis: {
                // tickAmount: 6,
                show: true,
                // max: 150,
                // min: 0,
                labels: {
                    formatter: (val) => {
                        return val.toString();
                    },
                    style: {
                        colors: "#64748B",
                        fontSize: "12px"
                    }
                },
                axisBorder: {
                    show: false,
                    color: '#ECEEF2'
                },
                axisTicks: {
                    show: false,
                    color: '#ECEEF2'
                }
            },
            legend: {
                show: false,
                position: 'top',
                fontSize: '12px',
                horizontalAlign: 'left',
                itemMargin: {
                    horizontal: 8,
                    vertical: 0
                },
                labels: {
                    colors: '#64748B'
                },
                markers: {
                    width: 9,
                    height: 9,
                    offsetX: -2,
                    offsetY: -.5,
                    radius: 2
                }
            }
        };

        this.themeService.isToggled$.subscribe(isToggled => {
            this.isToggled = isToggled;
        });
    }
    ngOnInit(): void {
        this.dashboardService.getClientCount(this.searchOptions, this.dataRage).subscribe(response => {
            if (!response.body) {
                return;
            }

            this.totalClients = response.body.map((item) => Number(item.totalClients)).reduce((a, b) => a + b, 0);
            const data = response.body.map((item) => Number(item.totalClients));
            /*const categories = response.body
                .map((item) => new Date(item.date).toLocaleDateString('en-US', { weekday: 'short' }))

            // Ensure categories are sorted from Monday to Sunday
            const daysOfWeek = ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"];
            const sortedCategories = daysOfWeek.filter(day => categories.includes(day));*/

            const rawDates = response.body.map((item) => new Date(item.date));
            const sortedCategories = this.dataRage == "7days" ? this.generateDate7DaysRange(rawDates) : this.generateDate365DaysRange(rawDates);

            this.chartOptions = {
                ...this.chartOptions,
                series: [{
                    name: "Total Client",
                    data: data
                }],
                xaxis: {
                    categories: sortedCategories
                }
            };

            // Calculate percentage change
            if (data.length > 1) {
                const lastDayCount = data[data.length - 1];
                const previousDayCount = data[data.length - 2];
                this.percentageChange = ((lastDayCount - previousDayCount) / previousDayCount) * 100;
            } else {
                this.percentageChange = 0;
            }

            // Trigger change detection to update the chart
            this.cdr.detectChanges();

        });

    }

    // Generate dynamic date range for X-axis

    generateDate7DaysRange(dates: Date[]): string[] {
        const dateRange: string[] = [];
        const startDate = new Date(dates[0]);

        // Generate dates dynamically depending on the selected range
        for (let i = 0; i < dates.length; i++) {
            const currentDate = new Date(startDate);
            currentDate.setDate(currentDate.getDate() + i);  // Adjust the date for the range
            dateRange.push(currentDate.toLocaleDateString('en-US', { weekday: 'short', month: 'short', day: 'numeric' }));
        }

        return dateRange;
    }

    generateDate365DaysRange(dates: Date[]): string[] {

        /*return dates.map(date => {
            const month = date.toLocaleString('default', { month: 'short' });  // Get short month name (e.g., "Jan", "Feb")
            return `${month} 25`;  // Format as "Jan 25"
        });
*/
        return dates.map(date => {
            const month = date.toLocaleString('default', { month: 'short' });  // Jan, Feb, etc.
            const year = date.getFullYear().toString().slice(-2);
            return `${month} ${year}`;  // Format: "Jan 25"
        });

        const dateRange: string[] = [];
        const startDate = new Date(dates[0]);

        // Generate dates dynamically depending on the selected range
        for (let i = 0; i < dates.length; i++) {
            const currentDate = new Date(startDate);
            currentDate.setMonth(currentDate.getMonth() + i);  // Adjust the month for the range
            dateRange.push(currentDate.toLocaleDateString('en-US', { month: 'short', year: 'numeric' }));
        }

        return dateRange;
    }
}