import { Component } from '@angular/core';
import { CustomizerSettingsService } from '../../../../../customizer-settings/customizer-settings.service';
import { ServiceService } from '../../../services/service.service';
import { TableColumn } from '../../../../../models/core/table/TableColumn';
import { BaseSearchOptions } from '../../../../../models/core/table/BaseSearchOptions';
import { TableActionButton } from '../../../../../models/core/table/TableActionButton';
import { SearchExpression } from '../../../../../models/core/table/SearchExpression';
import { QueryBuilderParams } from '../../../../../models/core/table/QueryBuilderParams';
import { EventTypes } from '../../../../../models/enums/EventTypes';
import { GroupType } from '../../../../../models/enums/GroupType';
import { TableEvent } from '../../../../../models/core/table/TableEvent';
import { Service } from '../../../../../models/domain/service';
import { take } from 'rxjs/operators';
import { ModalText } from '../../../../../models/enums/ModalText';
import { ModalService } from '../../../../core/services/modal.service';
import { LoadingService } from '../../../../core/services/loading.service';
import { TableSearchEventData } from '../../../../../models/core/table/TableSearchEventData';
import { FilterDataType } from '../../../../../models/core/table/FilterDataType';

@Component({
    selector: 'app-service-list',
    templateUrl: './service-list.component.html',
    styleUrl: './service-list.component.scss'
})
export class ServiceListComponent {

    //#region Table Variables
    public columns: TableColumn[];
    public actions: TableActionButton[];
    public totalRecords: number = 0;
    public pageIndex: number = 0;
    public pageSize: number = 10;
    public pageSizeOptions: number[] = [10, 50, 100];
    public searchOptions: BaseSearchOptions;
    //#endregion

    serviceId = 0;
    service: Service;


    // Popup Trigger
    classApplied = false;
    // isToggled
    isToggled = false;
    showModal = false;
    deleteModalData: Service;
    ConfirmText = ModalText.ConfirmYes
    CancelText = ModalText.CancelNo

    constructor(
        public themeService: CustomizerSettingsService,
        public serviceService: ServiceService,
        private modalService: ModalService,
        private loadingService: LoadingService


    ) {
        this.initialize();
    }

    ngOnInit(): void {
        this.getData();
    }

    private initialize(): void {
        this.themeService.isToggled$.subscribe(isToggled => {
            this.isToggled = isToggled;
        });

        this.searchOptions = new BaseSearchOptions(undefined, undefined, 0, this.pageIndex, this.pageSize);

        this.buildTable();
    }

    private getData(): void {
        this.loadingService.start(true);
        this.serviceService.get(this.searchOptions).pipe(take(1)).subscribe(result => {
            if (result) {
                this.totalRecords = result.body?.totalCount ?? 0;
            }
            this.loadingService.start(false);
        });
    }

    public buildTable(): void {
        this.columns = [
            new TableColumn('name', 'Name', FilterDataType.String, true, false, (data: Service) => `${data.name}`, true, { tooltip: 'Service Name' }),
            new TableColumn('description', 'Description', FilterDataType.String, true, false, (data: Service) => `${data.description}`, true, { tooltip: 'Service Description' }),
            new TableColumn('serviceType', 'ServiceType', FilterDataType.String, true, false, (data: Service) => `${data.serviceType ? data.serviceType.name : ''}`, true, { tooltip: 'ServiceType' }),
        ];

        this.actions = [];
        this.actions.push(new TableActionButton("visibility", (event, value) => this.onEditClick(event, value), (value) => this.onDisabledClick(), "Edit", "Edit"))
        this.actions.push(new TableActionButton("edit", (event, value) => this.onEditClick(event, value), (value) => this.onDisabledClick(), "Edit", "Edit"))
        this.actions.push(new TableActionButton("delete", (event, value) => this.onDeleteClick(event, value), (value) => this.onDisabledClick(), "Delete", "Delete"))
    }

    public onAddClick(): void {
        this.service = new Service;
        this.toggleClass();

    }

    public onEditClick(event: MouseEvent, data: Service): void {
        event.stopPropagation();
        this.service = data;
        this.toggleClass();
    }

    public onDeleteClick(event: MouseEvent, data: Service): void {
        event.stopPropagation();
        this.deleteModalData = data;
        this.modalService.onConfirm.subscribe((data) => this.confirmEvent(data));
        this.deleteItem(data);
    }

    deleteItem(data: Service) {
        this.modalService.openModal(
            data,
            'Delete Confirmation',
            `Are you sure you want to delete <strong> ${data.name} </strong> ?`,
            ModalText.ConfirmYes,
            ModalText.CancelNo
        );
    }

    public onDisabledClick(): boolean {
        return false;
    }

    public tableEvent(event: TableEvent<any>): void {
        if (event.type === EventTypes.TABLE) {
            let eventValue = event.data as TableSearchEventData;

            this.searchOptions.updateByObject(eventValue.baseSearchOptions);

            if (eventValue.columnFilterValues) {
                this.createFilter(eventValue);
            }
            else {
                this.searchOptions.filter = '';
            }
            this.getData();
        }
        if (event.type === EventTypes.CLEAR) {
            this.clearFilters();
            this.getData();
        }
    }

    public clearFilters(): void {
        this.pageIndex = 0;
        this.pageSize = 10;
        this.searchOptions = new BaseSearchOptions(undefined, undefined, 0, 0, 10);
    }

    private createFilter(eventValue: TableSearchEventData): void {
        const queryParams: QueryBuilderParams[] = [];

        for (const [columnName, { dataType, opType, searchValue }] of Object.entries(eventValue.columnFilterValues)) {
            const searchOperator = this.getSearchOperator(opType.toString());
            if (!searchOperator) continue; // Skip if no valid operator

            queryParams.push({
                fieldName: columnName, // Adjust as necessary for your field mapping
                dataType: dataType,
                searchOperator,
                searchValue: searchValue,
                groupType: GroupType.OR
            });
        }

        this.searchOptions.filter = this.searchOptions.generateQuery(queryParams);
    }

    private getSearchOperator(type: string): SearchExpression | undefined {
        const operatorMap: Record<string, SearchExpression> = {
            In: SearchExpression.In,
            Equals: SearchExpression.Equals,
            NotEquals: SearchExpression.NotEquals,
            Between: SearchExpression.Between,
            LessThan: SearchExpression.LessThan,
            LessThanOrEqual: SearchExpression.LessThanOrEqual,
            GreaterThan: SearchExpression.GreaterThan,
            GreaterThanOrEqual: SearchExpression.GreaterThanOrEqual,
            StartsWith: SearchExpression.StartsWith,
            EndsWith: SearchExpression.EndsWith,
            Contains: SearchExpression.Contains,
        };
        return operatorMap[type];
    }

    public toggleClass(): void {
        this.classApplied = !this.classApplied;
    }

    public classAppliedEvent(flag: boolean): void {
        this.classApplied = flag;
    }

    public saveConfirmationEvent(isSaved: boolean): void {
        if (isSaved) {
            this.getData();
        }

    }

    public confirmEvent(data: any): void {
        if (data && data.id) {
            this.serviceService.delete(data.id).subscribe();
            this.getData();
        }
    }


}
