import { BaseRecord } from "./BaseRecord";

export class ServiceType extends BaseRecord<number> {
    public name: string;
    public description: string;


    constructor() {
        super();
        this.id = 0;
        this.name = "";
        this.description = "";
    }
}
