<div class="account-settings-card">
    <h5>
        Profile
    </h5>
    <p>
        Update your personal details here.
    </p>
    <form [formGroup]="frmGroup" (ngSubmit)="onSave()">
        <div class="row">
            <div class="col-sm-6">
                <div class="form-group">
                    <label class="main-label d-block fw-medium text-black">
                        First Name <span class="text-danger">*</span>
                    </label>
                    <input type="text" class="form-control" placeholder="First Name" formControlName="firstNameControl"
                        maxlength="200">
                    <div class="form-text text-danger"
                        *ngIf="isSubmitted && f['firstNameControl']!.hasError('required')">
                        First Name is required.
                    </div>
                    <div class="form-text text-danger"
                        *ngIf="isSubmitted && f['firstNameControl']!.hasError('onlyWhitespace')">
                        First Name can not be only whitespace.
                    </div>
                </div>
            </div>
            <div class="col-sm-6">
                <div class="form-group">
                    <label class="main-label d-block fw-medium text-black">
                        Last Name <span class="text-danger">*</span>
                    </label>
                    <input type="text" class="form-control" placeholder="Last Name" formControlName="lastNameControl"
                        maxlength="200">
                    <div class="form-text text-danger"
                        *ngIf="isSubmitted && f['lastNameControl']!.hasError('required')">
                        Last Name is required.
                    </div>
                    <div class="form-text text-danger"
                        *ngIf="isSubmitted && f['lastNameControl']!.hasError('onlyWhitespace')">
                        Last Name can not be only whitespace.
                    </div>
                </div>
            </div>
            <div class="col-sm-6">
                <div class="form-group">
                    <label class="main-label d-block fw-medium text-black">
                        Email <span class="text-danger">*</span>
                    </label>
                    <input type="text" class="form-control" placeholder="Email" formControlName="emailControl"
                        maxlength="200">
                    <div class="form-text text-danger" *ngIf="isSubmitted && f['emailControl']!.hasError('required') ">
                        Email is required.
                    </div>
                    <div class="form-text text-danger"
                        *ngIf="isSubmitted && f['emailControl']!.hasError('onlyWhitespace')">
                        Email can not be only whitespace.
                    </div>
                    <div class="form-text text-danger" *ngIf="isSubmitted && f['phoneControl']!.hasError('email')">
                        Please enter valid email.
                    </div>
                </div>
            </div>
            <div class="col-sm-6">
                <div class="form-group">
                    <label class="main-label d-block fw-medium text-black">
                        Phone <span class="text-danger">*</span>
                    </label>
                    <input type="text" class="form-control" placeholder="Phone" formControlName="phoneControl"
                        maxlength="10">
                    <div class="form-text text-danger" *ngIf="isSubmitted && f['phoneControl']!.hasError('required') ">
                        Phone is required.
                    </div>
                    <div class="form-text text-danger"
                        *ngIf="isSubmitted && f['phoneControl']!.hasError('onlyWhitespace')">
                        Phone can not be only whitespace.
                    </div>
                    <div class="form-text text-danger"
                        *ngIf="isSubmitted && f['phoneControl']!.hasError('phoneInvalidFormat')">
                        Please enter valid phone.
                    </div>
                </div>
            </div>

        </div>

        <div class="form-btn-box">
            <!-- <button type="button" class="warn d-inline-block fw-medium border-radius border-0 transition">
                Cancel
            </button> -->

            <button [disabled]="!formIsValid() || !dataHasChanged()"
                class="primary d-inline-block fw-medium border-radius border-0 transition">
                <span class="d-inline-block position-relative">
                    <i class="material-symbols-outlined">
                        check
                    </i>
                    Update Profile
                </span>
            </button>
        </div>
    </form>
</div>