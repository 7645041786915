import { Injectable } from "@angular/core";
import { HttpClient, HttpParams } from "@angular/common/http";
import { BehaviorSubject, Observable } from "rxjs";
import { ServiceType } from "../../../models/domain/serviceType";
import { environment } from "../../../../environments/environment";
import { BaseService } from "../../shared/services/base.service";

@Injectable({
    providedIn: "root"
})
export class ServiceTypeService extends BaseService<ServiceType> {
    private readonly rowCountSubject: BehaviorSubject<number>;
    public readonly rowCount$: Observable<number>;
    private http: HttpClient;

    constructor(http: HttpClient) {
        super(http, `${environment.apiUrl}/v1/serviceType`);
        this.http = http;
        this.rowCountSubject = new BehaviorSubject<number>(0);
        this.rowCount$ = this.rowCountSubject.asObservable();
    }

    public validateServiceTypeCode(serviceTypeId: number, code: string): Observable<boolean> {
        const url = `${this.baseApiUrl}/checkExisting`;
        const params = new HttpParams()
            .set("id", serviceTypeId)
            .set("code", code);

        return this.http.get<boolean>(url, { params });
    }
}
