import { BaseRecord } from "./BaseRecord";

export class Logevents extends BaseRecord<number> {
    public name: string | null;
    public message: string | null;
    public messageTemplate: string | null;
    public level: string | null;
    public timeStamp: string | null;
    public exception: string | null;
    public logEvent: string | null;
    public traceId: string | null;
    public userId: string | null;
    public userName: string | null;

    constructor() {
        super();
        this.id = 0;
        this.name = "";
        this.message = "";
        this.messageTemplate = "";
        this.level = "";
        this.timeStamp = "";
        this.exception = "";
        this.logEvent = "";
        this.traceId = "";
        this.userId = "";
        this.userName = "";
    }
}
