<div class="change-password-card">
    <form [formGroup]="frmGroup" (ngSubmit)="onSave()">
        <div class="row">
            <div class="col-sm-6">
                <div class="form-group position-relative">
                    <label class="main-label d-block fw-medium text-black">
                        Old Password <span class="text-danger">*</span>
                    </label>
                    <div class="position-relative">
                        <input [type]="isPassword1Visible ? 'text' : 'password'"
                            formControlName="currentPasswordControl" class="form-control" placeholder="Type password">
                        <button type="button" (click)="togglePassword1Visibility()"
                            class="icon-button p-0 border-0 bg-transparent transition">
                            <i [ngClass]="isPassword1Visible ? 'ri-eye-line' : 'ri-eye-off-line'"></i>
                        </button>
                    </div>
                    <div class="form-text text-danger"
                        *ngIf="isSubmitted && f['currentPasswordControl']!.hasError('required')">
                        Current Password is required.
                    </div>
                    <div class="form-text text-danger"
                        *ngIf="isSubmitted && f['currentPasswordControl']!.hasError('onlyWhitespace')">
                        Current Password can not be only whitespace.
                    </div>

                </div>
            </div>
            <div class="col-sm-6">
                <div class="form-group position-relative">
                    <label class="main-label d-block fw-medium text-black">
                        New Password <span class="text-danger">*</span>
                    </label>
                    <div class="position-relative">
                        <input [type]="isPassword2Visible ? 'text' : 'password'" formControlName="newPasswordControl"
                            class="form-control" placeholder="Type password">
                        <button type="button" (click)="togglePassword2Visibility()"
                            class="icon-button p-0 border-0 bg-transparent transition">
                            <i [ngClass]="isPassword2Visible ? 'ri-eye-line' : 'ri-eye-off-line'"></i>
                        </button>
                    </div>
                    <div class="form-text text-danger"
                        *ngIf="isSubmitted && f['newPasswordControl']!.hasError('required')">
                        Current Password is required.
                    </div>
                    <div class="form-text text-danger"
                        *ngIf="isSubmitted && f['newPasswordControl']!.hasError('onlyWhitespace')">
                        Current Password can not be only whitespace.
                    </div>
                    <div class="form-text text-danger"
                        *ngIf="isSubmitted && f['newPasswordControl']!.hasError('hasCharacters')">
                        Minimum Password length should be 8 characters
                    </div>
                    <div class="form-text text-danger"
                        *ngIf="isSubmitted && f['newPasswordControl']!.hasError('hasUpper')">
                        Password must contain at least one uppercase
                    </div>
                    <div class="form-text text-danger"
                        *ngIf="isSubmitted && f['newPasswordControl']!.hasError('hasLower')">
                        Password must contain at least one lowercase
                    </div>
                    <div class="form-text text-danger"
                        *ngIf="isSubmitted && f['newPasswordControl']!.hasError('hasNumber')">
                        Password must contain at least one number
                    </div>
                    <div class="form-text text-danger"
                        *ngIf="isSubmitted && f['newPasswordControl']!.hasError('hasSpecialChar')">
                        Password must contain at least one special character
                    </div>

                </div>

            </div>
            <div class="col-sm-12">
                <div class="form-group position-relative">
                    <label class="main-label d-block fw-medium text-black">
                        Confrim Password <span class="text-danger">*</span>
                    </label>
                    <div class="position-relative">
                        <input [type]="isPassword3Visible ? 'text' : 'password'"
                            formControlName="confirmPasswordControl" class="form-control" placeholder="Type password">
                        <button type="button" (click)="togglePassword3Visibility()"
                            class="icon-button p-0 border-0 bg-transparent transition">
                            <i [ngClass]="isPassword3Visible ? 'ri-eye-line' : 'ri-eye-off-line'"></i>
                        </button>
                    </div>
                    <div class="form-text text-danger"
                        *ngIf="isSubmitted && f['confirmPasswordControl']!.hasError('required')">
                        Confirm Password is required.
                    </div>
                    <div class="form-text text-danger"
                        *ngIf="isSubmitted && f['confirmPasswordControl']!.hasError('onlyWhitespace')">
                        Confirm Password can not be only whitespace.
                    </div>
                    <div class="form-text text-danger"
                        *ngIf="isSubmitted && f['confirmPasswordControl']!.hasError('mustMatch')">
                        Confirm Password must be match with new password..
                    </div>
                </div>

            </div>
        </div>
        <div class="form-btn-box">
            <button class="primary d-inline-block fw-medium border-radius border-0 transition">
                <span class="d-inline-block position-relative">
                    <i class="material-symbols-outlined">
                        check
                    </i>
                    Change Password
                </span>
            </button>
            <!-- <a routerLink="/dashboard/authentication/forgot-password" class="fs-14 text-danger">
            Forgot Password?
        </a> -->
        </div>
    </form>
</div>