<div class="popup-dialog" *ngIf="logevents">
    <div class="trezo-card w-100 border-radius d-block bg-white border-0 shadow-none">
        <div class="trezo-card-header d-flex align-items-center justify-content-between">
            <div class="trezo-card-title">
                <h5 class="mb-0">Log Details</h5>
            </div>
            <div class="trezo-card-subtitle">
                <button (click)="toggleClass()" class="p-0 border-0 lh-1 bg-transparent transition">
                    <i class="ri-close-fill"></i>
                </button>
            </div>
        </div>

        <div class="trezo-card-content">
            <!-- Top Section (Small Fields) -->
            <div class="row">
                <div class="col-md-6">
                    <div class="form-group">
                        <label class="main-label d-block fw-medium text-black">Message:</label>
                        <p class="form-control-static">{{ logevents.message || 'N/A' }}</p>
                    </div>
                </div>

                <div class="col-md-6">
                    <div class="form-group">
                        <label class="main-label d-block fw-medium text-black">Message Template:</label>
                        <p class="form-control-static">{{ logevents.messageTemplate || 'N/A' }}</p>
                    </div>
                </div>

                <div class="col-md-6">
                    <div class="form-group">
                        <label class="main-label d-block fw-medium text-black">Level:</label>
                        <span [ngClass]="getLogLevelBadgeClass(logevents.level ?? '')">
                            {{ logevents.level }}
                        </span>
                    </div>
                </div>
                
                
                <div class="col-md-6">
                    <div class="form-group">
                        <label class="main-label d-block fw-medium text-black">Time Stamp:</label>
                        <p class="form-control-static">
                            {{ logevents.timeStamp ? (logevents.timeStamp | date: 'MMM d, y h:mm:ss a') : 'N/A' }}
                        </p>
                    </div>
                </div>

                <div class="col-md-6">
                    <div class="form-group">
                        <label class="main-label d-block fw-medium text-black">Trace ID:</label>
                        <p class="form-control-static">{{ logevents.traceId || 'N/A' }}</p>
                    </div>
                </div>

                <div class="col-md-6">
                    <div class="form-group">
                        <label class="main-label d-block fw-medium text-black">User:</label>
                        <p class="form-control-static">{{ logevents.userName || 'N/A' }} (ID: {{ logevents.userId || 'N/A' }})</p>
                    </div>
                </div>
            </div>

            <!-- Large Fields (Full Width) -->
            <div *ngIf="logevents.exception" class="full-width mt-3">
                <label class="main-label d-block fw-medium text-black">Exception:</label>
                <pre class="form-control-static full-width">{{ logevents.exception }}</pre>
            </div>

            <div *ngIf="logevents.logEvent" class="full-width mt-3">
                <label class="main-label d-block fw-medium text-black">Log Event:</label>
                <pre class="form-control-static full-width">{{ logevents.logEvent }}</pre>
            </div>

            <!-- Cancel Button -->
            <div class="btn-box mt-3">
                <button (click)="toggleClass()" class="warn d-inline-block fw-medium border-0 border-radius transition">
                    Cancel
                </button>
            </div>
        </div>
    </div>
</div>
