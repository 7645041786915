import {Injectable} from "@angular/core";

export enum StorageKeys {
    CurrentAuth = "currentAuth",
    Token = "token",
}

@Injectable({
    providedIn: "root"
})
export class LocalStorageService {
    public set(key: string, value: string): void {
        localStorage.setItem(key, value);
    }

    public get(key: string): string | null {
        return localStorage.getItem(key);
    }

    public remove(key: string): void {
        localStorage.removeItem(key);
    }
}
