import { RouterModule, Routes } from "@angular/router";
import { NgModule } from "@angular/core";
import { DashboardComponent } from "./components/dashboard/dashboard.component";
import { ProvinceListComponent } from "./components/LocationSection/province/province-list/province-list.component";
import { TownListComponent } from "./components/LocationSection/town/town-list/town-list.component";
import { DistrictListComponent } from "./components/LocationSection/district/district-list/district-list.component";
import { LlgListComponent } from "./components/LocationSection/llg/llg-list/llg-list.component";
import { WardListComponent } from "./components/LocationSection/ward/ward-list/ward-list.component";
import { SuburbListComponent } from "./components/LocationSection/suburb/suburb-list/suburb-list.component";
import { RoadsListComponent } from "./components/LocationSection/roads/roads-list/roads-list.component";
import { StreetListComponent } from "./components/LocationSection/street/street-list/street-list.component";
import { SectionNumberListComponent } from "./components/LocationSection/sectionNumber/sectionNumber-list/sectionNumber-list.component";
import { AllotmentNumberListComponent } from "./components/LocationSection/allotmentNumber/allotmentNumber-list/allotmentNumber-list.component";
import { ServiceTypeListComponent } from "./components/BusinessSection/serviceType/serviceType-list/serviceType-list.component";
import { ServiceListComponent } from "./components/BusinessSection/service/service-list/service-list.component";
import { BusinessTypeListComponent } from "./components/BusinessSection/businessType/businessType-list/businessType-list.component";
import { ContactListComponent } from "./components/BusinessSection/contact/contact-list/contact-list.component";
import { AddressListComponent } from "./components/BusinessSection/address/address-list/address-list.component";
import { BusinessListComponent } from "./components/BusinessSection/business/business-list/business-list.component";
import { PostOfficeListComponent } from "./components/LocationSection/postOffice/postOffice-list/postOffice-list.component";
import { StreetDetailsListComponent } from "./components/LocationSection/streetDetails/streetDetails-list/streetDetails-list.component";
import { ClientConfigurationListComponent } from "./components/ConfigurationSection/clientConfiguration/clientConfiguration-list/clientConfiguration-list.component";
import { SettingsComponent } from "./components/settings/settings.component";
import { AccountSettingsComponent } from "./components/settings/account-settings/account-settings.component";
import { ChangePasswordComponent } from "./components/settings/change-password/change-password.component";
import { AuthorizeGuard } from "../../guards/auth.guard";
import { RoleEnum } from "../../models/enums/RoleEnum";
import { CrmComponent } from "./components/dashboard/crm/crm.component";
import { LogeventsListComponent } from "./components/ConfigurationSection/logevents/logevents-list/logevents-list.component";

export const routes: Routes = [
    {
        path: '', component: DashboardComponent, canActivate: [AuthorizeGuard],
        data: {
            roles: [RoleEnum.Clerk, RoleEnum.Supervisor]
        },
        children: [
            {
                path: 'logevents', component: LogeventsListComponent, canActivate: [AuthorizeGuard],
                data: {
                    roles: [RoleEnum.Admin]
                },
            },
            {
                path: 'dashboard', component: CrmComponent, canActivate: [AuthorizeGuard],
                data: {
                    roles: [RoleEnum.Clerk, RoleEnum.Supervisor]
                },
            },
            {
                path: 'province', component: ProvinceListComponent, canActivate: [AuthorizeGuard],
                data: {
                    roles: [RoleEnum.Clerk, RoleEnum.Supervisor]
                },
            },
            {
                path: 'town', component: TownListComponent, canActivate: [AuthorizeGuard],
                data: {
                    roles: [RoleEnum.Clerk, RoleEnum.Supervisor]
                },
            },
            {
                path: 'district', component: DistrictListComponent, canActivate: [AuthorizeGuard],
                data: {
                    roles: [RoleEnum.Clerk, RoleEnum.Supervisor]
                },
            },
            {
                path: 'llg', component: LlgListComponent, canActivate: [AuthorizeGuard],
                data: {
                    roles: [RoleEnum.Clerk, RoleEnum.Supervisor]
                },
            },
            {
                path: 'ward', component: WardListComponent, canActivate: [AuthorizeGuard],
                data: {
                    roles: [RoleEnum.Clerk, RoleEnum.Supervisor]
                },
            },
            {
                path: 'suburb', component: SuburbListComponent, canActivate: [AuthorizeGuard],
                data: {
                    roles: [RoleEnum.Clerk, RoleEnum.Supervisor]
                },
            },
            {
                path: 'roads', component: RoadsListComponent, canActivate: [AuthorizeGuard],
                data: {
                    roles: [RoleEnum.Clerk, RoleEnum.Supervisor]
                },
            },
            {
                path: 'street', component: StreetListComponent, canActivate: [AuthorizeGuard],
                data: {
                    roles: [RoleEnum.Clerk, RoleEnum.Supervisor]
                },
            },
            {
                path: 'sectionNumber', component: SectionNumberListComponent, canActivate: [AuthorizeGuard],
                data: {
                    roles: [RoleEnum.Clerk, RoleEnum.Supervisor]
                },
            },
            {
                path: 'allotmentNumber', component: AllotmentNumberListComponent, canActivate: [AuthorizeGuard],
                data: {
                    roles: [RoleEnum.Clerk, RoleEnum.Supervisor]
                },
            },
            {
                path: 'postOffice', component: PostOfficeListComponent, canActivate: [AuthorizeGuard],
                data: {
                    roles: [RoleEnum.Clerk, RoleEnum.Supervisor]
                },
            },
            {
                path: 'streetDetails', component: StreetDetailsListComponent, canActivate: [AuthorizeGuard],
                data: {
                    roles: [RoleEnum.Clerk, RoleEnum.Supervisor]
                },
            },
            {
                path: 'businessType', component: BusinessTypeListComponent, canActivate: [AuthorizeGuard],
                data: {
                    roles: [RoleEnum.Clerk, RoleEnum.Supervisor]
                },
            },
            {
                path: 'serviceType', component: ServiceTypeListComponent, canActivate: [AuthorizeGuard],
                data: {
                    roles: [RoleEnum.Clerk, RoleEnum.Supervisor]
                },
            },
            {
                path: 'service', component: ServiceListComponent, canActivate: [AuthorizeGuard],
                data: {
                    roles: [RoleEnum.Clerk, RoleEnum.Supervisor]
                },
            },
            {
                path: 'contact', component: ContactListComponent, canActivate: [AuthorizeGuard],
                data: {
                    roles: [RoleEnum.Clerk, RoleEnum.Supervisor]
                },
            },
            {
                path: 'address', component: AddressListComponent, canActivate: [AuthorizeGuard],
                data: {
                    roles: [RoleEnum.Clerk, RoleEnum.Supervisor]
                },
            },
            {
                path: 'business', component: BusinessListComponent, canActivate: [AuthorizeGuard],
                data: {
                    roles: [RoleEnum.Clerk, RoleEnum.Supervisor]
                },
            },
            {
                path: 'clientConfiguration', component: ClientConfigurationListComponent, canActivate: [AuthorizeGuard],
                data: {
                    roles: [RoleEnum.Admin]
                },
            },
            {
                path: 'settings',
                component: SettingsComponent,
                children: [
                    {
                        path: '', component: AccountSettingsComponent, canActivate: [AuthorizeGuard],
                        data: {
                            roles: [RoleEnum.Clerk, RoleEnum.Supervisor]
                        },
                    },
                    {
                        path: 'change-password', component: ChangePasswordComponent, canActivate: [AuthorizeGuard],
                        data: {
                            roles: [RoleEnum.Clerk, RoleEnum.Supervisor]
                        },
                    },
                ]
            }
        ]
    },


    //{path: '**', component: NotFoundComponent} // This line will remain down from the whole pages component list
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class AdminRoutingModule { }