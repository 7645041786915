import {
  Component,
  OnInit,
  Input,
  EventEmitter,
  Output,
  ViewChild,
  ContentChild,
  TemplateRef,
} from '@angular/core';
import { Observable, Subject, Subscription } from 'rxjs';
import { FormControl } from '@angular/forms';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
// import { NgbPagination } from '@ng-bootstrap/ng-bootstrap';


import { trigger, state, style, transition, animate } from '@angular/animations';
import { BaseSearchOptions } from '../../../../models/core/table/BaseSearchOptions';
import { TableActionButton } from '../../../../models/core/table/TableActionButton';
import { TableColumn } from '../../../../models/core/table/TableColumn';
import { TableExpandColumn } from '../../../../models/core/table/TableExpandColumn';
import { EventTypes } from '../../../../models/enums/EventTypes';
import { TableEvent } from '../../../../models/core/table/TableEvent';
import { TableSearchEventData } from '../../../../models/core/table/TableSearchEventData';
import { FilterDataType } from '../../../../models/core/table/FilterDataType';
import { SearchExpression } from '../../../../models/core/table/SearchExpression';

@Component({
  selector: 'app-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss'],
  animations: [
    trigger("expand", [
      state("collapsed", style({ height: "0px", minHeight: "0" })),
      state("expanded", style({ height: "*" })),
      transition("expanded <=> collapsed", animate("225ms cubic-bezier(0.4, 0.0, 0.2, 1)"))
    ])
  ]
})

/**
 * Datatable Component
 */
export class TableComponent implements OnInit {
  @ContentChild(TemplateRef)
  public expandedTemplateRef: TemplateRef<any>;

  @Input() datasource$: Observable<any[]>;
  public datasourceData: any[];
  @Input() columns: TableColumn[];
  @Input() sortable: boolean;
  @Input() usePagination: boolean;
  @Input() allowRowSelection: boolean = false;
  @Input() useFilter: boolean;
  @Input() resultLength: number;
  @Input() pageIndex: number = 0;
  @Input() pageSize: number = 10;
  @Input() pageSizeOptions: number[] = [10, 50, 100];
  @Input() actionButtons: TableActionButton[];
  @Input() queryParameters: BaseSearchOptions;
  @Input() useStickyHeaders: boolean;
  @Input() allowReorderRow: boolean = false;
  @Input() allowDragDrop: boolean = false;
  @Input() tableId: string;

  @Input() isExpandable: boolean = false;
  @Input() expandColumn?: TableExpandColumn;
  public expandDatasourceData: any;

  @Input() validationFunctions: ((row: any) => boolean)[];

  @Output() eventHandler: EventEmitter<TableEvent<any>> = new EventEmitter<TableEvent<any>>();
  @Output() orderChangeEventHandler: EventEmitter<TableEvent<any>> = new EventEmitter<TableEvent<any>>();
  @Output() textBoxChangeEventHandler: EventEmitter<TableEvent<any>> = new EventEmitter<TableEvent<any>>();
  @Output() onDropChangeEventHandler: EventEmitter<TableEvent<any>> = new EventEmitter<TableEvent<any>>();

  // Subject to track filter changes
  private filterChangeSubject = new Subject<TableSearchEventData>();

  // @ViewChild('paginator') paginator: NgbPagination;

  public selectedPageSize: number;
  public displayedColumns: any;

  public filterInputControl: FormControl;
  private subscriptions: Subscription[];
  private tableParameters: BaseSearchOptions;
  column: any = '';
  isDesc: boolean = false;
  public selectedRow: any;
  public draggedRow: any;

  public columnFilterValues: { [columnName: string]: { dataType: FilterDataType, opType: SearchExpression, searchValue: any } } = {};

  constructor() {
    this.subscriptions = [];
    this.filterInputControl = new FormControl('');
    this.tableParameters = this.queryParameters ?? new BaseSearchOptions();
    this.selectedPageSize = 10;

    // Debounce filter changes by 300ms before sending to parent component
    this.filterChangeSubject.pipe(
      debounceTime(800), // Delay of 800ms after the last emission
      distinctUntilChanged() // Only emit when the filter value actually changes
    ).subscribe((searchData: TableSearchEventData) => {
      this.eventHandler.emit(
        new TableEvent(EventTypes.TABLE, searchData)
      );
    });
  }

  ngOnInit(): void {

    this.columns.forEach(col => {
      // Initialize selectedFilterType to "Equals" if it's not already set
      if (!col.selectedFilterType && this.getFilterOptions(col.dataType).includes('Equals')) {
        col.selectedFilterType = 'Equals';
      }
    });

    this.displayedColumns = this.columns?.map((col, i) => ({
      key: col.definition,
      index: i,
      dis: col.display,
      val: col,
      callback: col.callback
    }));

    this.filterInputControl = new FormControl(this.tableParameters.filter);
    this.subscriptions.push(
      this.filterInputControl.valueChanges
        .pipe(debounceTime(700))
        .subscribe(value => {
          this.onFilterChange(value?.toLowerCase());
        })
    );

    if (this.actionButtons?.length > 0) {
      this.columns.push(
        new TableColumn('actions', 'Actions', FilterDataType.Object, false, false, undefined, false, {
          styleExpression: 'width: 100px; max-width: 150px;',
        })
      );
    }

    //Drag and Drop
    this.datasource$?.subscribe(item => {
      if (item) {
        this.datasourceData = item;
      }
    })
  }

  public displayFilterdData(row: any) {
    if (
      row == undefined ||
      row === null ||
      row === 'null' ||
      row === 'undefined' ||
      row === 'undefined - undefined'
    ) {
      return '';
    } else {
      return row;
    }
  }

  //Below function is use when filter page size option dropdown is in table component
  /*public pageSizeOptionChange(value: number) {
    debugger
    this.selectedPageSize = value;
    this.tableParameters.pageSize = this.selectedPageSize;
    this.eventHandler.emit(
      new TableEvent(EventTypes.TABLE, this.tableParameters)
    );
  }*/

  public onRowSelected(event: any, row: any): void {
    event.stopPropagation();
    if (this.allowRowSelection && event.target.getAttribute('data-bs-toggle') != "dropdown") {
      this.selectedRow = row;
      this.eventHandler.emit(new TableEvent<any>(EventTypes.SELECT, new TableSearchEventData(this.tableParameters, row)));
    }
  }

  private onFilterChange(value: string): void {
    this.tableParameters.filter = value;
    this.tableParameters.pageIndex = 0;
    this.tableParameters.pageSize = this.selectedPageSize;
    this.pageIndex = 0;
    this.eventHandler.emit(
      new TableEvent(EventTypes.TABLE, new TableSearchEventData(this.tableParameters, this.columnFilterValues))
    );
  }

  public onPageChange($event: any): void {
    this.tableParameters.pageIndex = $event.pageIndex;
    this.tableParameters.pageSize = $event.pageSize;
    this.eventHandler.emit(
      new TableEvent(EventTypes.TABLE, new TableSearchEventData(this.tableParameters, this.columnFilterValues))
    );
  }

  public calculateStyle(column: TableColumn): string {
    return `overflow: hidden; justify-items: flex-start; ${column.options?.styleExpression}`;
  }

  public onSortChange(col: TableColumn) {
    if (col.sortable && this.sortable) {
      this.isDesc = !this.isDesc; //change the direction
      this.column = col.definition;
      let direction = this.isDesc ? 1 : 0;

      this.tableParameters.orderBy = col.definition;
      this.tableParameters.orderByDirection = direction;

      this.tableParameters.pageIndex = 0;
      this.tableParameters.pageSize = this.selectedPageSize;
      this.pageIndex = 0;
      this.eventHandler.emit(
        new TableEvent(EventTypes.TABLE, new TableSearchEventData(this.tableParameters, this.columnFilterValues))
      );
    }
  }

  //#region Drag and Drop and ReOrder

  public onDropReorder(event: DragEvent) {
    event.preventDefault();

    //Re-order row
    if (this.allowReorderRow) {
      const targetRow = this.draggedRow;
      const dropRowIndex = this.datasourceData.indexOf(targetRow);
      const [insertRowIndex, targetRowValue] = this.getInsertRowIndex(event);

      if (dropRowIndex !== insertRowIndex) {
        this.datasourceData.splice(dropRowIndex, 1);
        this.datasourceData.splice(insertRowIndex, 0, targetRow);
      }

      this.draggedRow = null;

      //Raise the event for update latest value
      let source = targetRow;
      let desti = targetRowValue;

      this.orderChangeEventHandler.emit(
        new TableEvent(EventTypes.REORDER, { sourceId: source, destiId: desti })
      );
    }
    //end Re-order
  }
  private getInsertRowIndex(event: DragEvent): [number, HTMLTableRowElement | null] {
    const target = event.target as HTMLElement;
    const targetRow = target.closest('tr');

    if (targetRow) {
      const rows = Array.from(targetRow.parentNode!.children) as HTMLElement[];
      return [rows.indexOf(targetRow), targetRow];
    }

    return [-1, targetRow];
  }

  public onDragStart(event: DragEvent, row: any) {

    if (this.allowReorderRow) {//Re-order
      this.draggedRow = row;
    }
    else {
      event.dataTransfer!.setData('text/plain', JSON.stringify({ tableId: this.tableId, row: row }));
    }
  }

  public onDragOver(event: DragEvent) {
    event.preventDefault();
  }

  public onDrop(event: DragEvent) {
    event.preventDefault();
    const data = event.dataTransfer!.getData('text/plain');
    if (this.allowDragDrop && data) {
      const droppedRow = JSON.parse(data);
      this.onDropChangeEventHandler.emit(
        new TableEvent(EventTypes.DRAGDROP, droppedRow)
      );
    }
  }

  //#endregion


  //Save Comment
  public saveComment(event: any, row: any) {
    if (event?.target?.value) {
      this.textBoxChangeEventHandler.emit(
        new TableEvent(EventTypes.TEXTBOXCHANGE, { event: event.target.value, row: row })
      );
    }
  }
  //end 

  expanded(item: any) {
    item.expanded = !item.expanded;

    if (item.expanded) {
      this.expandDatasourceData = item;
    }
    else {
      this.expandDatasourceData = undefined;
    }
  }

  private isInvalidRow(row: any): boolean {
    if (!this.validationFunctions || this.validationFunctions.length === 0) {
      return false; // No validation functions provided, so row is considered valid
    }
    return this.validationFunctions.some(func => func(row));
  }

  public getRowClass(row: any): string {
    return this.isInvalidRow(row) ? 'invalid-row' : '';
  }


  //-------------------------
  public toggleFilterDropdown(col: TableColumn): void {
    // Close all other dropdowns
    this.columns.forEach(column => {
      if (column !== col) {
        column.isDropdownOpen = false;
      }
    });
    // Toggle the current dropdown
    col.isDropdownOpen = !col.isDropdownOpen;
  }

  public updateFilterValue(col: TableColumn, event: Event): void {
    const inputElement = event.target as HTMLInputElement;
    const value = inputElement.value;

    if (this.columnFilterValues[col.definition]) {
      this.columnFilterValues[col.definition].searchValue = value; // Update the value
    } else {
      this.columnFilterValues[col.definition] = { dataType: col.dataType, searchValue: value, opType: col.selectedFilterType.toString() || '' }; // Store both value and type
    }

    if (!value) {
      delete this.columnFilterValues[col.definition];
    }

    this.tableParameters.pageIndex = 0;
    this.tableParameters.pageSize = this.selectedPageSize;
    this.pageIndex = 0;

    this.filterChangeSubject.next(new TableSearchEventData(this.tableParameters, this.columnFilterValues));  // Trigger debounced emission
    // this.eventHandler.emit(
    //   new TableEvent(EventTypes.TABLE, new TableSearchEventData(this.tableParameters, this.columnFilterValues))
    // );
  }

  clearFilter(col: any) {
    delete this.columnFilterValues[col.definition];
    col.isDropdownOpen = false;

    col.searchValue = '';  // Clear the search value for the specific column
    col.selectedFilterType = SearchExpression.Equals.toString();  // Optionally reset the filter type to the default
    
    if (this.columnFilterValues) {
      this.eventHandler.emit(
        new TableEvent(EventTypes.TABLE, new TableSearchEventData(this.tableParameters, this.columnFilterValues))
      );
    }
    else {
      this.eventHandler.emit(
        new TableEvent(EventTypes.CLEAR, new TableSearchEventData(this.tableParameters, this.columnFilterValues))
      );
    }
  }


  updateFilterType(column: any, event: Event) {
    const selectElement = event.target as HTMLSelectElement;
    const filterType = selectElement.value.toString();

    column.selectedFilterType = filterType; // Store the selected filter type
    column.isDropdownOpen = false;
    //this.filterData(); // Call to update the filtered data based on current filters
  }

  getFilterOptions(type: FilterDataType): string[] {
    const options: Record<FilterDataType, string[]> = {
      i: ['Equals', 'NotEquals', 'LessThan', 'LessThanOrEqual', 'GreaterThan', 'GreaterThanOrEqual', 'Between'],
      l: ['Equals', 'NotEquals', 'LessThan', 'LessThanOrEqual', 'GreaterThan', 'GreaterThanOrEqual', 'Between'],
      d: ['Equals', 'NotEquals', 'LessThan', 'LessThanOrEqual', 'GreaterThan', 'GreaterThanOrEqual', 'Between'],
      db: ['Equals', 'NotEquals', 'LessThan', 'LessThanOrEqual', 'GreaterThan', 'GreaterThanOrEqual', 'Between'],
      f: ['Equals', 'NotEquals', 'LessThan', 'LessThanOrEqual', 'GreaterThan', 'GreaterThanOrEqual', 'Between'],

      dt: ['Between', 'Equals', 'NotEquals', 'LessThan', 'LessThanOrEqual', 'GreaterThan', 'GreaterThanOrEqual'],

      b: ['Equals', 'NotEquals'],

      bt: ['Equals', 'NotEquals'],

      sh: ['Equals', 'NotEquals'],

      s: ['Equals', 'NotEquals', 'Contains', 'NotContains', 'StartsWith', 'EndsWith'],

      c: ['Equals', 'NotEquals'],

      o: ['Equals', 'NotEquals'],

      g: ['Equals', 'NotEquals'],

      arr: ['Contains', 'NotContains'],

    };
    return options[type] || [];
  }


  // Method to determine the button inline styles
  getButtonStyles(button: TableActionButton): string {
    const colorMap: Record<string, string> = {
      visibility: 'btn-1',   // Blue for visibility
      edit: 'btn-2',          // Red for edit
      delete: 'btn-3',        // Green for delete
    };
  
    // Ensure button.icon is defined and return the corresponding class, fallback to 'btn-1' if undefined
    if (!button.icon) {
      return 'btn-1'; // Fallback if icon is undefined
    }
  
    return colorMap[button.icon] || 'btn-1';  // Default to 'btn-1' if not found in colorMap
  }

}