import { Component, EventEmitter, Input, OnInit, Output, AfterViewInit, OnDestroy } from '@angular/core';
import { CustomizerSettingsService } from '../../../../../../customizer-settings/customizer-settings.service';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Business } from '../../../../../../models/domain/business';
import { ObjectUtility } from '../../../../../core/utilities/ObjectUtility';
import { catchError, forkJoin, of, Subject, take, takeUntil } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { whitespaceValidator } from '../../../../../core/validators/ValidationService';
import { BusinessType } from '../../../../../../models/domain/businessType';
import { BusinessTypeService } from '../../../../services/businessType.service';
import { BusinessService } from '../../../../services/business.service';
import { LoadingService } from '../../../../../core/services/loading.service';
import { BaseSearchOptions } from '../../../../../../models/core/table/BaseSearchOptions';
import { BusinessServiceMapping } from '../../../../../../models/domain/businessServiceMapping';
import { Service } from '../../../../../../models/domain/service';
import { ServiceService } from '../../../../services/service.service';
import { TableSearchEventData } from '../../../../../../models/core/table/TableSearchEventData';
import { getSearchOperator } from '../../../../../core/utilities/common-functions';
import { QueryBuilderParams } from '../../../../../../models/core/table/QueryBuilderParams';
import { GroupType } from '../../../../../../models/enums/GroupType';
import { FilterDataType } from '../../../../../../models/core/table/FilterDataType';
import { SearchExpression } from '../../../../../../models/core/table/SearchExpression';
import { debounceTime } from 'rxjs/operators';

@Component({
  selector: 'app-business-details',
  templateUrl: './business-details.component.html',
  styleUrl: './business-details.component.scss'
})
export class BusinessDetailsComponent implements OnInit, AfterViewInit, OnDestroy {

  @Output() classApplied = new EventEmitter<boolean>();
  @Output() saveConfirmationEvent = new EventEmitter<boolean>();
  @Input() currentBusinessId = 0;
  // isToggled
  isToggled = false;
  frmGroup: FormGroup;

  private destroy$: Subject<boolean> = new Subject<boolean>();
  public businessOriginal = new Business;
  public business: Business = new Business;
  public titleText = "";
  public titleButton = "";
  public errorMessage = "";

  isSubmitted = false;

  // drop down bind data
  public businessTypes: BusinessType[];
  public services: Service[];

  public pageIndex = 0;
  public pageSize = 1000;
  public searchOptions: BaseSearchOptions;


  //Search for auto complete
  public pageIndexFilter = 0;
  public pageSizeFilter = 10;
  public pageSizeOptions: number[] = [10, 50, 100];
  public searchOptionsFilter: BaseSearchOptions;
  public serviceFilterData: Service[] = [];
  public isLoadChipView = false;
  //end

  constructor(
    public themeService: CustomizerSettingsService,
    private formBuilder: FormBuilder,
    private businessService: BusinessService,
    private toasterService: ToastrService,
    private businessTypeService: BusinessTypeService,
    private loadingService: LoadingService,
    private serviceService: ServiceService,

  ) {
    this.initialize();
  }

  public ngOnInit(): void {
    if (this.currentBusinessId > 0) {
      this.getDropdownData();
      this.getBusinessById(this.currentBusinessId);
    }
    else {
      this.currentBusinessId = 0;
      this.getDropdownData();
      const newBusiness = new Business();
      this.business = ObjectUtility.deepClone(newBusiness);
      this.businessOriginal = ObjectUtility.deepClone(newBusiness);
      this.setForm(this.business);
      this.isLoadChipView = true;
    }
  }

  private initialize(): void {
    this.themeService.isToggled$.subscribe(isToggled => {
      this.isToggled = isToggled;
    });
    this.isSubmitted = false;
    this.searchOptions = new BaseSearchOptions(undefined, 'name', 0, this.pageIndex, this.pageSize);
    this.searchOptionsFilter = new BaseSearchOptions(undefined, 'name', 0, this.pageIndexFilter, this.pageSizeFilter);

    this.createForm();
  }

  public ngAfterViewInit(): void {


    this.frmGroup.controls['businessTypeControl'].valueChanges.subscribe(value => {
      this.business.businessType = value;
    });
    this.frmGroup.controls['nameControl'].valueChanges.subscribe(value => {
      this.business.name = value;
    });


    this.frmGroup.controls['registrtionNumberControl'].valueChanges.subscribe(value => {
      this.business.registrationNumber = value;
    });
    this.frmGroup.controls['taxNumberControl'].valueChanges.subscribe(value => {
      this.business.taxNumber = value;
    });

    this.frmGroup.controls['isActiveControl'].valueChanges.subscribe(value => {
      this.business.isActive = value;
    });

  }


  public ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  get f() { return this.frmGroup.controls; }

  private createForm(): void {
    this.frmGroup = this.formBuilder.group({
      businessTypeControl: new FormControl(null, [Validators.required]),
      nameControl: new FormControl('', [Validators.required, whitespaceValidator()]),
      registrtionNumberControl: new FormControl('', [Validators.required, whitespaceValidator()]),
      taxNumberControl: new FormControl('', [Validators.required, whitespaceValidator()]),
      isActiveControl: new FormControl('', []),
      dateOfIncorporationControl: new FormControl(null, [Validators.required]),
    })
  }

  private setForm(business: Business): void {
    if (business && business.id != undefined && business.id > 0) {
      let date = null;
      let formattedDate = null;
      if (business.id != 0) {
        date = new Date(business?.dateOfIncorporation);  // Create a Date object
        formattedDate = date.toISOString().split('T')[0]; //TODO: BT need to ask krunal
      }
      this.frmGroup.patchValue({

        businessTypeControl: business?.businessType,
        nameControl: business?.name,
        registrtionNumberControl: business?.registrationNumber,
        taxNumberControl: business?.taxNumber,
        isActiveControl: business?.isActive,
        dateOfIncorporationControl: formattedDate,
      });

      this.serviceFilterData = business.businessServiceMapping
        .map((x: BusinessServiceMapping) => x.service)
        .filter((service): service is Service => service !== null);
      this.isLoadChipView = true;
    }

    this.titleText = `${this.currentBusinessId === 0 ? "Add" : "Edit"} Business`;
    this.titleButton = `${this.currentBusinessId === 0 ? "Create" : "Update"}`;
  }

  public onSave(): void {
    this.isSubmitted = true;
    this.loadingService.showLoading();
    if (this.frmGroup.valid) {
      this.addEditBusiness();
    }
  }

  private addEditBusiness(): void {
    if (this.business?.id === 0) {
      this.businessService.create(this.sanitizeBusiness(this.business))
        .pipe(
          catchError((err: any) => {
            if (err && err.error) {
              this.isSubmitted = false;
              if (err.error.isCustom) {
                this.errorMessage = err.error.message;
                this.toasterService.warning(err.error.message);
              } else {
                this.toasterService.error('Something went wrong!');
              }
            } else {
              this.isSubmitted = false;
              this.toasterService.error('Something went wrong!');
            }
            return of(null);
          }),
          takeUntil(this.destroy$))
        .subscribe(response => {
          if (response) {
            this.isSubmitted = false;
            this.business = response?.body != null ? response?.body : new Business;
            this.saveConfirmationEvent.emit(true);
            this.toggleClass();
            this.toasterService.success("Save successfully");
          }
        });
    } else {
      this.businessService.update(this.sanitizeBusiness(this.business))
        .pipe(
          catchError((err: any) => {
            if (err && err.error) {
              this.isSubmitted = false;
              if (err.error.isCustom) {
                this.errorMessage = err.error.message;
                this.toasterService.warning(err.error.message);
              } else {
                this.toasterService.error('Something went wrong!');
              }
            } else {
              this.isSubmitted = false;
              this.toasterService.error('Something went wrong!');
            }
            return of(null);
          }),
          takeUntil(this.destroy$))
        .subscribe(response => {
          if (response) {
            this.isSubmitted = false;
            this.business = response?.body != null ? response?.body : new Business;
            this.saveConfirmationEvent.emit(true);
            this.toasterService.success("Update successfully");
            this.toggleClass();
          }
        });
    }
  }

  public toggleClass(): void {
    this.classApplied.emit(false);
  }

  private getDropdownData() {
    const businessType = this.businessTypeService.get(this.searchOptions);
    const services = this.serviceService.get(this.searchOptions);

    this.loadingService.showLoading();
    forkJoin([businessType, services])
      .pipe(takeUntil(this.destroy$)).subscribe((result: any) => {
        if (result) {
          this.businessTypes = result[0].body.values;
          this.services = result[1].body.values;

        }
        this.loadingService.hideLoading();
      });
  }

  private sanitizeBusiness(business: Business): Business {
    business.businessType = null;
    business.businessTypeId = this.frmGroup.controls['businessTypeControl'].value.id;

    return business;
  }

  public businessTypeComparer(t1: BusinessType, t2: BusinessType): boolean {
    return t1 && t2 ? (t1.id === t2.id || t1.name === t2.name) : t1 === t2;
  }

  public dataHasChanged(): boolean {
    const baseEqual = ObjectUtility.deepEqual(this.business, this.businessOriginal);
    return !baseEqual;
  }

  public formIsValid(): boolean {
    const isValid = this.frmGroup.controls['businessTypeControl'].valid
      && this.frmGroup.controls['nameControl'].valid
      && this.frmGroup.controls['registrtionNumberControl'].valid
      && this.frmGroup.controls['taxNumberControl'].valid
      && this.frmGroup.controls['dateOfIncorporationControl'].valid
      && this.business.businessServiceMapping.length > 0;
    return isValid;
  }

  onDateChange(event: any): void {
    const selectedDate = event.target.value;  // This gives the selected date in 'YYYY-MM-DD' format
    this.business.dateOfIncorporation = selectedDate;
  }

  isValidDate(date: any): boolean {
    return date instanceof Date && !isNaN(date.getTime());
  }

  public getBusinessById(businessId: number) {
    this.loadingService.showLoading();
    this.businessService.getById(businessId)
      .pipe(takeUntil(this.destroy$))
      .subscribe(result => {
        if (result) {
          this.business = ObjectUtility.deepClone(result);
          this.businessOriginal = ObjectUtility.deepClone(result);
          this.setForm(this.business);
        }
        this.loadingService.hideLoading();
      });
  }

  //#region Chipview logic
  public selectedServicesEvent(event: Service[]): void {
    this.business.businessServiceMapping = [];
    if (event) {
      event.forEach((service: Service) => {
        const businessServiceMapping = new BusinessServiceMapping();
        businessServiceMapping.serviceId = service.id!;
        businessServiceMapping.businessId = this.business.id;
        this.business.businessServiceMapping.push(businessServiceMapping);
      });
    }
  }

  public serviceSearchEvent(event: any): void {
    this.loadingService.showLoading();
    if (event) {
      this.createFilter(new TableSearchEventData(this.searchOptions,
        { 'name': { dataType: FilterDataType.String, opType: SearchExpression.Contains, searchValue: event } }));
    }
    else {
      this.searchOptions.filter = '';
    }

    this.serviceService.get(this.searchOptionsFilter).pipe(
      debounceTime(2000),
      take(1),
      catchError((error) => {
        if (error && error.error) {
          if (error.error.isCustom) {
            this.toasterService.warning(error.error.message);
          } else {
            this.toasterService.error('Something went wrong!');
          }
        } else {
          this.toasterService.error('Something went wrong!');
        }
        return of(null);
      })
    ).subscribe(result => {
      if (result) {
        this.serviceFilterData = result.body?.values ?? [];
      }
      this.loadingService.hideLoading();
    });
  }

  private createFilter(eventValue: TableSearchEventData): void {
    const queryParams: QueryBuilderParams[] = [];
    for (const [columnName, { dataType, opType, searchValue }] of Object.entries(eventValue.columnFilterValues)) {
      const searchOperator = getSearchOperator(opType.toString());
      if (!searchOperator) continue; // Skip if no valid operator

      queryParams.push({
        fieldName: columnName, // Adjust as necessary for your field mapping
        dataType: dataType,
        searchOperator,
        searchValue: searchValue,
        groupType: GroupType.OR
      });
    }
    this.searchOptionsFilter.filter = this.searchOptionsFilter.generateQuery(queryParams);
  }

  //#endregion

}