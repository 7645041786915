<div class="basic-table table-responsive">
  <div class="col-lg-12">
    <div class="row row-usefilter" *ngIf="usePagination || useFilter">
      <!-- <div class="col-sm-12 col-md-6" *ngIf="usePagination">
      <div class="dataTables_length">
        <label class="d-inline-flex align-items-center">Show
          <select name="tickets-table_length" aria-controls="tickets-table" class="form-select mx-2" name="pageSize"
            (change)="pageSizeOptionChange(selectedPageSize)" [(ngModel)]="selectedPageSize">
            <option *ngFor="let data of pageSizeOptions" [ngValue]="data">
              {{ data }}
            </option>
          </select>
          entries
        </label>
      </div>
    </div> -->
      <div class="col-sm-12 col-md-12" *ngIf="useFilter">
        <div class="dataTables_filter mb-3" [ngClass]="{'text-md-end': usePagination}">
          <label class="d-inline-flex align-items-center">
            <input type="text" name="searchTerm" class="form-control" placeholder="Search"
              [formControl]="filterInputControl" aria-controls="tickets-table">
          </label>
        </div>
      </div>
    </div>
    <table [id]="tableId" class="table mb-0" (dragover)="onDragOver($event)" (drop)="onDrop($event)">
      <thead>
        <tr>
          <th *ngIf="isExpandable"></th>
          <th *ngIf="allowReorderRow"></th>
          <th *ngFor="let col of columns; let i=index" (click)="onSortChange(col)" [style]="calculateStyle(col)"
            class="fw-medium" [ngClass]="{
                                    'th-cursor-pointer': sortable,
                                    'th-cursor-stop': !sortable,
                                    'th-sticky-header':useStickyHeaders
                                }">
            <div class="d-flex">
              <i class="material-symbols-outlined" *ngIf="!isDesc && column === col.definition">arrow_downward</i>
              <i class="material-symbols-outlined" *ngIf="isDesc && column === col.definition">arrow_upward</i>
              {{col.header}}
            </div>
          </th>
        </tr>
        <tr>
          <th class="bg-light" *ngFor="let col of columns; let i = index">
            <div *ngIf="col.showFilterDropdown" class="d-flex align-items-center">
              <input type="text" [(ngModel)]="col.searchValue" (input)="updateFilterValue(col, $event)"
                placeholder="Enter value" class="form-control me-2 filterTxt" />
              <div class="position-relative d-flex dropdown">
                <i class="material-symbols-outlined" id="filter-icon-{{col.definition}}"
                  (click)="toggleFilterDropdown(col, $event)">filter_alt</i>
                <div *ngIf="col.isDropdownOpen" id="dropdown-{{col.definition}}" class="dropdown-menu show m-0 p-0">
                  <ul class="m-0 p-0 list-unstyled">
                    <li *ngFor="let option of getFilterOptions(col.dataType)" class="fw-normal"
                      [ngClass]="{ 'active_menu': option == col.selectedFilterType}"
                      (click)="updateFilterType(col, option)">
                      {{ option }}
                    </li>
                  </ul>
                </div>
              </div>
              <i *ngIf="col.searchValue" class="material-symbols-outlined" (click)="clearFilter(col)">filter_alt_off</i>
            </div>
          </th>
        </tr>
      </thead>
      <tbody>
        <ng-container *ngFor="let row of datasource$ | async;let i=index">
          <tr (click)="onRowSelected($event, row)" [draggable]="allowDragDrop || allowReorderRow"
            (dragstart)="onDragStart($event, row)" (dragover)="onDragOver($event)" (drop)="onDropReorder($event)"
            class="invalid-row" [ngClass]="{
                'table-active': selectedRow === row,
                'row-cursor-pointer': allowRowSelection,
                'row-cursor-stop': !allowRowSelection,
                'invalid-row': getRowClass(row) === 'invalid-row'               
                }">

            <!-- Expandable row -->
            <td *ngIf="isExpandable" (click)="expanded(row)">
              <i *ngIf="!row.expanded" class="bi-chevron-right"></i>
              <i *ngIf="row.expanded" class="bi-chevron-up"></i>
            </td>
            <!-- end -->

            <!-- only for reorder -->
            <td *ngIf="allowReorderRow" [ngClass]="{'draggable':allowReorderRow}">
              <i class="bi bi-arrows-move"></i>
            </td>
            <!-- end -->

            <td *ngFor="let col of displayedColumns" [style]="calculateStyle(col.val)">
              <!-- show textbox-->
              <ng-container *ngIf="col?.val?.showTextBox">
                <textarea type="text" class="form-control textboxStyle" (keydown.enter)="saveComment($event, row)"
                  rows="1" cols="10">{{displayFilterdData(col.dis(row))}}</textarea>
              </ng-container>

              <!-- show hyperlink -->
              <ng-container *ngIf="!col?.val?.showTextBox && col?.val?.showHyperLink">
                <a hr="#" (click)="col?.val?.callback($event, row)">{{ displayFilterdData(col.dis(row)) | ellipses:
                  col?.val?.options.ellipseLength}}</a>
              </ng-container>

              <ng-container *ngIf="!col?.val?.showTextBox && !col?.val?.showHyperLink">
                {{ displayFilterdData(col.dis(row)) | ellipses: col?.val?.options.ellipseLength}}
              </ng-container>
            </td>

            <!-- <ng-container *ngIf="actionButtons?.length === 1;then  single; else mltiple">
          </ng-container> -->

            <ng-container *ngIf="actionButtons?.length === 1;then  mltiple; else mltiple">
            </ng-container>

            <!-- Display if only one button -->
            <ng-template #single>
              <td *ngIf="actionButtons?.length === 1" class="action-icons">
                <button type="button" (click)="actionButtons[0].callback($event, row)"
                  [disabled]="actionButtons[0].disabled(row)">
                  <ng-container *ngIf="actionButtons[0].title">
                    {{actionButtons[0].title}}
                  </ng-container>
                  <ng-container *ngIf="actionButtons[0].icon">
                    <i class="bi bi-{{actionButtons[0].icon}}"></i>
                  </ng-container>
                </button>
              </td>
            </ng-template>

            <!-- Display multiple buttons -->
            <ng-template #mltiple>
              <td *ngIf="actionButtons.length > 0">
                <div class="action-buttons d-flex align-items-center">
                  <ng-template ngFor let-button [ngForOf]="actionButtons">
                    <button type="button" class="p-0 bg-transparent d-inline-block lh-1 border-0"
                      [ngClass]="getButtonStyles(button)" *ngIf="!button.disabled(row)"
                      (click)="button.callback($event, row)">
                      <i class="material-symbols-outlined"> {{button.icon}} </i>
                    </button>
                  </ng-template>
                </div>

                <!-- <div class="dropdown" ngbDropdown>
                <button class="btn btn-light btn-sm dropdown-toggle" type="button" data-bs-toggle="dropdown"
                  aria-label="Action Dropdown" aria-expanded="false" ngbDropdownToggle>
                  <i class="uil uil-ellipsis-h"></i>
                </button>
                <ul class="dropdown-menu" ngbDropdownMenu>
                  <ng-template ngFor let-button [ngForOf]="actionButtons">
                    <li *ngIf="!button.disabled(row)">
                      <a class="dropdown-item" name="{{button.title}}" (click)="button.callback($event, row)"
                        href="javascript:void(0);">
                        {{button.title}}
                      </a>
                    </li>
                  </ng-template>
                </ul>
              </div> -->
              </td>
            </ng-template>
          </tr>

          <!--Expanded row-->
          <ng-container *ngIf="isExpandable && row.expanded">
            <ng-container>
              <tr>
                <td [colSpan]="expandColumn?.colSkip ?? 0"></td>
                <td [colSpan]="expandColumn?.colSpan ?? 0">
                  <table class="table table-striped table-bordered dt-responsive nowrap w-100 datatables"
                    [@expand]="row === expandDatasourceData ? 'expanded' : 'collapsed'">
                    <ng-template [ngTemplateOutlet]="expandedTemplateRef" [ngTemplateOutletContext]="{$implicit: row}">
                    </ng-template>
                  </table>
                </td>
              </tr>
            </ng-container>
          </ng-container>
          <!-- end Expanded -->

        </ng-container>

        <tr *ngIf="datasource$ | async as row;">
          <td colspan="100" class="nodata">
            <ng-container *ngIf="row.length<=0">
              No data found!
            </ng-container>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>

<!-- <div class="col-sm-12 col-md-5">
      <div class="dataTables_info mb-2" role="status" aria-live="polite">
        Showing {{pageIndex === 0 ? 1 : (pageIndex - 1) * selectedPageSize + 1}} to {{pageIndex === 0 ?
        selectedPageSize: pageIndex * selectedPageSize}} of {{resultLength}} entries
      </div>
    </div> -->
<!-- Pagination -->
<!-- ngIf="datasourceData.length>0    datasource$ | async as row; -->
<app-pagination *ngIf="datasourceData.length>0 && usePagination" [totalRecords]="resultLength" [pageSize]="pageSize"
  [pageSizeOptions]="pageSizeOptions" (pageChange)="onPageChange($event)">
</app-pagination>
<!-- End Pagination -->