import { Injectable } from "@angular/core";
import { HttpClient, HttpParams } from "@angular/common/http";
import { BehaviorSubject, Observable, tap } from "rxjs";
import { environment } from "../../../../environments/environment";
import { BaseService } from "../../shared/services/base.service";
import { District } from "../../../models/domain/district";
import { LoadingService } from "../../core/services/loading.service";

@Injectable({
    providedIn: "root"
})
export class DistrictService extends BaseService<District> {
    private readonly rowCountSubject: BehaviorSubject<number>;
    public readonly rowCount$: Observable<number>;
    private http: HttpClient;

    constructor(http: HttpClient, loadingService: LoadingService) {
        super(http, `${environment.apiUrl}/v1/district`, loadingService);
        this.http = http;
        this.rowCountSubject = new BehaviorSubject<number>(0);
        this.rowCount$ = this.rowCountSubject.asObservable();
    }

    public getDistrictsByProvince(provinceId: number): Observable<District[]> {
        return this.httpClient.get<District[]>(`${this.baseApiUrl}/${provinceId}/DistrictsByProvince`)
    }

    public getDistrictsByTown(townId: number): Observable<District[]> {
        return this.httpClient.get<District[]>(`${this.baseApiUrl}/${townId}/DistrictsByTown`)
    }
}
