import { Component, EventEmitter, Input, OnInit, Output, AfterViewInit, OnDestroy } from '@angular/core';
import { RouterLink } from '@angular/router';
import { NgClass, NgIf } from '@angular/common';
import { CustomizerSettingsService } from '../../../../../../customizer-settings/customizer-settings.service';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Street } from '../../../../../../models/domain/street';
import { ObjectUtility } from '../../../../../core/utilities/ObjectUtility';
import { catchError, forkJoin, of, Subject, takeUntil } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { whitespaceValidator } from '../../../../../core/validators/ValidationService';
import { Suburb } from '../../../../../../models/domain/suburb';
import { SuburbService } from '../../../../services/suburb.service';
import { LoadingService } from '../../../../../core/services/loading.service';
import { StreetService } from '../../../../services/street.service';
import { BaseSearchOptions } from '../../../../../../models/core/table/BaseSearchOptions';
import { WardService } from '../../../../services/ward.service';
import { Ward } from '../../../../../../models/domain/ward';
import { Town } from '../../../../../../models/domain/town';
import { Roads } from '../../../../../../models/domain/roads';
import { TownService } from '../../../../services/town.service ';
import { RoadsService } from '../../../../services/roads.service';
import { Province } from '../../../../../../models/domain/province';
import { District } from '../../../../../../models/domain/district';
import { Llg } from '../../../../../../models/domain/llg';
import { ProvinceService } from '../../../../services/province.service';
import { DistrictService } from '../../../../services/district.service';
import { LlgService } from '../../../../services/llg.service';

@Component({
  selector: 'app-street-details',
  templateUrl: './street-details.component.html',
  styleUrl: './street-details.component.scss'
})
export class StreetDetailsComponent implements OnInit, AfterViewInit, OnDestroy {

  @Output() classApplied = new EventEmitter<boolean>();
  @Output() saveConfirmationEvent = new EventEmitter<boolean>();
  @Input() currentStreetId = 0;
  // isToggled
  isToggled = false;
  frmGroup: FormGroup;

  private destroy$: Subject<boolean> = new Subject<boolean>();
  public streetOriginal = new Street;
  public street: Street = new Street;
  public titleText = "";
  public titleButton = "";
  public errorMessage = "";

  isSubmitted = false;

  // drop down bind data
  public provinces: Province[];
  public districts: District[];
  public llgs: Llg[];
  public wards: Ward[];
  public roads: Roads[];
  public towns: Town[];
  public suburbs: Suburb[];
  public pageIndex = 0;
  public pageSize = 1000;
  public searchOptions: BaseSearchOptions;

  constructor(
    public themeService: CustomizerSettingsService,
    private formBuilder: FormBuilder,
    private streetService: StreetService,
    private toasterService: ToastrService,
    private suburbService: SuburbService,
    private wardService: WardService,
    private townService: TownService,
    private loadingService: LoadingService,
    private provinceService: ProvinceService,
    private districtService: DistrictService,
    private llgService: LlgService,
    private roadService: RoadsService,
  ) {
    this.initialize();
  }

  public ngOnInit(): void {
    if (this.currentStreetId > 0) {
      this.getTownDropdownData();

    }
    else {
      this.currentStreetId = 0;
      this.getTownDropdownData();
      const newStreet = new Street();
      this.street = ObjectUtility.deepClone(newStreet);
      this.streetOriginal = ObjectUtility.deepClone(newStreet);
      this.setForm(this.street);
    }
  }

  private initialize(): void {
    this.themeService.isToggled$.subscribe(isToggled => {
      this.isToggled = isToggled;
    });
    this.isSubmitted = false;
    this.searchOptions = new BaseSearchOptions(undefined, 'name', 0, this.pageIndex, this.pageSize);
    this.createForm();
  }

  public ngAfterViewInit(): void {

    this.frmGroup.controls['provinceControl'].valueChanges.subscribe(value => {
      this.street.province = value;
    });

    this.frmGroup.controls['isVillageControl']?.valueChanges.subscribe((isVillage) => {
      if (isVillage) {
        //this.getProvinceDropdownData();
        this.frmGroup.controls['districtControl']?.enable();
      } else {
        this.frmGroup.controls['districtControl']?.disable();
        //this.getTownDropdownData();
      }
    });
    this.frmGroup.controls['districtControl'].valueChanges.subscribe(value => {
      this.street.district = value;
    });
    this.frmGroup.controls['llgControl'].valueChanges.subscribe(value => {
      this.street.llg = value;
    });

    this.frmGroup.controls['nameControl'].valueChanges.subscribe(value => {
      this.isSubmitted = false
      this.street.name = value;
    });
    this.frmGroup.controls['wardControl'].valueChanges.subscribe(value => {
      this.isSubmitted = false
      this.street.ward = value;
    });
    this.frmGroup.controls['suburbControl'].valueChanges.subscribe(value => {
      this.isSubmitted = false
      this.street.suburb = value;
    });

    this.frmGroup.controls['townControl'].valueChanges.subscribe(value => {
      this.isSubmitted = false
      this.street.town = value;
    });

    this.frmGroup.controls['isVillageControl'].valueChanges.subscribe(value => {
      this.street.type = value == true ? 'V' : 'S';
      // this.clearValue(this.street.type);
    });
    this.frmGroup.controls['roadControl'].valueChanges.subscribe(value => {
      this.isSubmitted = false
      this.street.roads = value;
    });

  }

  public ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  get f() { return this.frmGroup.controls; }

  private createForm(): void {
    this.frmGroup = this.formBuilder.group({
      nameControl: new FormControl('', [Validators.required, whitespaceValidator()]),
      provinceControl: new FormControl(null, []),
      districtControl: new FormControl({ value: null, disabled: true }, []),
      llgControl: new FormControl(null, []),
      suburbControl: new FormControl(null, []),
      wardControl: new FormControl(null, []),
      townControl: new FormControl(null, []),
      roadControl: new FormControl(null, []),
      isVillageControl: new FormControl('', []),
    })

  }

  private setForm(street: Street): void {
    this.street.type = this.street.type == '' ? 'S' : this.street.type;
    if (street && street.id != undefined && street.id > 0) {


      this.frmGroup.patchValue({
        provinceControl: street?.province,
        districtControl: street?.district,
        llgControl: street?.llg,
        nameControl: street?.name,
        suburbControl: street?.suburb,
        wardControl: street?.ward,
        townControl: street?.town,
        roadControl: street?.roads,
        isVillageControl: street?.type == "V" ? true : false
      });
      //this.clearValue(this.street.type);
    } else {
      this.frmGroup.patchValue({ isVillageControl: street?.type == "V" ? true : false });
    }
    this.titleText = `${this.currentStreetId === 0 ? "Add" : "Edit"}  Village/Street`;
    this.titleButton = `${this.currentStreetId === 0 ? "Create" : "Update"}`;
    if (this.currentStreetId > 0)
      this.frmGroup.controls['isVillageControl'].disable();

  }

  public onSave(): void {
    this.isSubmitted = true;


    if (this.frmGroup.valid && this.formIsValid()) {
      this.loadingService.showLoading();
      this.addEditStreet();
    }
  }

  private addEditStreet(): void {
    if (this.street?.id === 0) {
      this.streetService.create(this.sanitizeStreet(this.street))
        .pipe(
          catchError((err: any) => {
            if (err && err.error) {
              this.isSubmitted = false;
              if (err.error.isCustom) {
                this.errorMessage = err.error.message;
                this.toasterService.warning(err.error.message);
              } else {
                this.toasterService.error('Something went wrong!');
              }
            } else {
              this.isSubmitted = false;
              this.toasterService.error('Something went wrong!');
            }
            return of(null);
          }),
          takeUntil(this.destroy$))
        .subscribe(response => {
          if (response) {
            this.isSubmitted = false;
            this.street = response?.body != null ? response?.body : new Street;
            this.saveConfirmationEvent.emit(true);
            this.toggleClass();
            this.toasterService.success("Save successfully");
          }
        });
    } else {
      this.streetService.update(this.sanitizeStreet(this.street))
        .pipe(
          catchError((err: any) => {
            if (err && err.error) {
              this.isSubmitted = false;
              if (err.error.isCustom) {
                this.errorMessage = err.error.message;
                this.toasterService.warning(err.error.message);
              } else {
                this.toasterService.error('Something went wrong!');
              }
            } else {
              this.isSubmitted = false;
              this.toasterService.error('Something went wrong!');
            }
            return of(null);
          }),
          takeUntil(this.destroy$))
        .subscribe(response => {
          if (response) {
            this.isSubmitted = false;
            this.street = response?.body != null ? response?.body : new Street;
            this.saveConfirmationEvent.emit(true);
            this.toasterService.success("Update successfully");
            this.toggleClass();
          }
        });
    }
  }


  public toggleClass(): void {
    this.classApplied.emit(false);
  }

  private getProvinceDropdownData() {
    this.loadingService.showLoading();
    const province = this.provinceService.get(this.searchOptions);
    forkJoin([province])
      .pipe(takeUntil(this.destroy$)).subscribe((result: any) => {
        if (result) {
          this.provinces = result[0].body.values;
        }
        this.loadingService.hideLoading();
      });
  }

  private getTownDropdownData() {
    this.loadingService.showLoading();
    const town = this.townService.get(this.searchOptions);
    forkJoin([town])
      .pipe(takeUntil(this.destroy$)).subscribe((result: any) => {
        if (result) {
          this.towns = result[0].body.values;
          this.getStreetById(this.currentStreetId);
        }
        this.loadingService.hideLoading();
      });
  }

  private sanitizeStreet(street: Street): Street {
    street.suburb = null;
    street.ward = null;
    street.town = null;
    street.province = null;
    street.district = null;
    street.llg = null;
    street.roads = null;

    if (this.frmGroup.controls['suburbControl'].value) {
      street.suburbId = this.frmGroup.controls['suburbControl'].value.id == 0 ? null : this.frmGroup.controls['suburbControl'].value.id;
    } else {
      street.suburbId = null;
    }
    if (this.frmGroup.controls['wardControl'].value) {
      street.wardId = this.frmGroup.controls['wardControl'].value.id == 0 ? null : this.frmGroup.controls['wardControl'].value.id;
    } else {
      street.wardId = null;
    }
    if (this.frmGroup.controls['townControl'].value) {
      street.townId = this.frmGroup.controls['townControl'].value.id == 0 ? null : this.frmGroup.controls['townControl'].value.id;
    } else {
      street.townId = null;
    }

    if (this.frmGroup.controls['provinceControl'].value) {
      street.provinceId = this.frmGroup.controls['provinceControl'].value.id == 0 ? null : this.frmGroup.controls['provinceControl'].value.id;
    } else {
      street.provinceId = null;
    }
    if (this.frmGroup.controls['districtControl'].value) {
      street.districtId = this.frmGroup.controls['districtControl'].value.id == 0 ? null : this.frmGroup.controls['districtControl'].value.id;
    } else {
      street.districtId = null;
    }
    if (this.frmGroup.controls['llgControl'].value) {
      street.llgId = this.frmGroup.controls['llgControl'].value.id == 0 ? null : this.frmGroup.controls['llgControl'].value.id;
    } else {
      street.llgId = null;
    }

    if (this.frmGroup.controls['roadControl'].value) {
      street.roadsId = this.frmGroup.controls['roadControl'].value.id == 0 ? null : this.frmGroup.controls['roadControl'].value.id;
    } else {
      street.roadsId = null;
    }


    return street;
  }

  public provinceComparer(t1: Province, t2: Province): boolean {
    return t1 && t2 ? (t1.id === t2.id || t1.code === t2.code) : t1 === t2;
  }
  public districtComparer(t1: District, t2: District): boolean {
    return t1 && t2 ? (t1.id === t2.id || t1.name === t2.name) : t1 === t2;
  }
  public llgComparer(t1: Llg, t2: Llg): boolean {
    return t1 && t2 ? (t1.id === t2.id || t1.name === t2.name) : t1 === t2;
  }

  public suburbComparer(t1: Suburb, t2: Suburb): boolean {
    return t1 && t2 ? (t1.id === t2.id || t1.name === t2.name) : t1 === t2;
  }
  public wardComparer(t1: Ward, t2: Ward): boolean {
    return t1 && t2 ? (t1.id === t2.id || t1.name === t2.name) : t1 === t2;
  }
  public townComparer(t1: Town, t2: Town): boolean {
    return t1 && t2 ? (t1.id === t2.id || t1.name === t2.name) : t1 === t2;
  }
  public roadComparer(t1: Roads, t2: Roads): boolean {
    return t1 && t2 ? (t1.id === t2.id || t1.name === t2.name) : t1 === t2;
  }

  public dataHasChanged(): boolean {
    const baseEqual = ObjectUtility.deepEqual(this.street, this.streetOriginal);
    return !baseEqual;
  }

  public formIsValid(): boolean {
    let isValid = true;

    if (this.street.type == 'S' && (
      !this.frmGroup.controls['townControl'].value
      || !this.frmGroup.controls['llgControl'].value
      || !this.frmGroup.controls['wardControl'].value
      || !this.frmGroup.controls['suburbControl'].value
      || !this.frmGroup.controls['nameControl'].value
      || this.street.district?.id == 0
      || this.street.district?.id == null

    )) {
      isValid = false;
    } else if (this.street.type == 'V' && (!this.frmGroup.controls['provinceControl'].value
      || !this.frmGroup.controls['districtControl'].value
      || !this.frmGroup.controls['llgControl'].value
      || !this.frmGroup.controls['wardControl'].value
      || !this.frmGroup.controls['nameControl'].value)) {
      isValid = false;
    }


    return isValid;
  }

  public clearValue(type: string) {
    if (type == 'V') {
      this.frmGroup.controls['townControl'].setValue(null);
      this.frmGroup.controls['districtControl'].setValue(null);
      this.frmGroup.controls['llgControl'].setValue(null);
      this.frmGroup.controls['wardControl'].setValue(null);
      this.frmGroup.controls['suburbControl'].setValue(null);
      this.frmGroup.controls['roadControl'].setValue(null);
      this.street.townId = null;
      this.street.districtId = null;
      this.street.llgId = null;
      this.street.wardId = null;
      this.street.suburbId = null;
      this.street.roadsId = null;
      this.getProvinceDropdownData();
      this.clearValueDropdownChange('Province');
    } else if (type == 'S') {
      this.frmGroup.controls['provinceControl'].setValue(null);
      this.frmGroup.controls['districtControl'].setValue(null);
      this.frmGroup.controls['llgControl'].setValue(null);
      this.frmGroup.controls['wardControl'].setValue(null);
      this.street.provinceId = null;
      this.street.districtId = null;
      this.street.llgId = null;
      this.street.wardId = null;
      this.getTownDropdownData();
      this.clearValueDropdownChange('Town');
    }
  }

  public clearValueDropdownChange(change: string) {
    if (change == 'Town') {
      this.frmGroup.controls['districtControl'].setValue(null);
      this.districts = [];
      this.street.district = null;
      this.frmGroup.controls['llgControl'].setValue(null);
      this.street.llg = null;
      this.llgs = [];
      this.frmGroup.controls['wardControl'].setValue(null);
      this.street.ward = null;
      this.wards = [];
      this.frmGroup.controls['suburbControl'].setValue(null);
      this.street.suburb = null;
      this.suburbs = [];
      this.frmGroup.controls['roadControl'].setValue(null);
      this.street.roads = null;
      this.roads = [];
    } else if (change == 'Llg') {
      this.frmGroup.controls['wardControl'].setValue(null);
      this.street.ward = null;
      this.wards = [];
      this.frmGroup.controls['suburbControl'].setValue(null);
      this.street.suburb = null;
      this.suburbs = [];
      this.frmGroup.controls['roadControl'].setValue(null);
      this.street.roads = null;
      this.roads = [];
    } else if (change == 'Ward') {
      this.frmGroup.controls['suburbControl'].setValue(null);
      this.street.suburb = null;
      this.suburbs = [];
      this.frmGroup.controls['roadControl'].setValue(null);
      this.street.roads = null;
      this.roads = [];
    } else if (change == 'Suburb') {
      this.frmGroup.controls['roadControl'].setValue(null);
      this.street.roads = null;
      this.roads = [];
    } else if (change == 'Province') {
      this.frmGroup.controls['districtControl'].setValue(null);
      this.districts = [];
      this.street.district = null;
      this.frmGroup.controls['llgControl'].setValue(null);
      this.street.llg = null;
      this.llgs = [];
      this.frmGroup.controls['wardControl'].setValue(null);
      this.street.ward = null;
      this.wards = [];
    } else if (change == 'District') {
      this.frmGroup.controls['llgControl'].setValue(null);
      this.street.llg = null;
      this.llgs = [];
      this.frmGroup.controls['wardControl'].setValue(null);
      this.street.ward = null;
      this.wards = [];
    }
  }

  public GetAllDropDownData() {
    if (this.street.type == 'V') {
      this.getProvinceDropdownData();
      //this.GetTownByProvince(this.street.provinceId);
      this.getDistrictByProvince(this.street.provinceId);
      this.GetLlgByDistrict(this.street.districtId);
      this.GetWardByLlg(this.street.llgId);
    }
    else {
      //this.getTownDropdownData();
      this.getDistrictByTown(this.street.townId);
      this.GetLlgByDistrict(this.street.districtId);
      this.GetWardByLlg(this.street.llgId);
      this.GetSuburbByWard(this.street.wardId);
      this.GetRoadBySuburb(this.street.suburbId);
    }


  }

  public getDistrictByProvince(provinceId: number | null | undefined) {
    if (provinceId != null) {
      this.loadingService.showLoading();
      this.districtService.getDistrictsByProvince(provinceId).subscribe(response => {
        this.districts = response;
        this.frmGroup.patchValue({ districtControl: this.street.district });
        this.loadingService.hideLoading();
      });
    }
  }

  public getDistrictByTown(townId: number | null | undefined) {
    if (townId != null) {
      this.loadingService.showLoading();
      this.districtService.getDistrictsByTown(townId).subscribe(response => {
        this.districts = response;
        if (this.street.type == 'S') {
          const firstdistrict = this.districts[0] || '';
          this.frmGroup.patchValue({ districtControl: firstdistrict });
          this.street.district = firstdistrict;
          if (this.street != null && this.street.district) {
            this.GetLlgByDistrict(this.street.district.id);
          }
        }
        this.loadingService.hideLoading();
      });
    }
  }

  public GetLlgByDistrict(districtId: number | null | undefined) {
    if (districtId != null) {
      this.loadingService.showLoading();
      this.llgService.GetLlgByDistrict(districtId).subscribe(response => {
        this.llgs = response;
        this.loadingService.hideLoading();
      });
    }
  }

  public GetWardByLlg(llgId: number | null | undefined) {
    if (llgId != null) {
      this.loadingService.showLoading();
      this.wardService.GetWardByLlg(llgId).subscribe(response => {
        this.wards = response;
        this.frmGroup.patchValue({ wardControl: this.street.ward });
        this.loadingService.hideLoading();
      });
    }
  }

  public GetTownByProvince(provinceId: number | null | undefined) {
    if (provinceId != null) {
      this.loadingService.showLoading();
      this.townService.GetTownByProvince(provinceId).subscribe(response => {
        this.towns = response;
        this.loadingService.hideLoading();
        // const firsttown = this.towns[0] || '';
        // this.frmGroup.patchValue({ townC+ontrol: firsttown });
        // this.street.town = firsttown;
        // this.GetSuburbByTown(firsttown.id);
      });
    }
  }

  public GetSuburbByWard(wardId: number | null | undefined) {
    if (wardId != undefined) {
      this.loadingService.showLoading();
      this.suburbService.GetSuburbByWard(wardId).subscribe(response => {
        this.suburbs = response;
        this.loadingService.hideLoading();
        // const firstsuburbs = this.suburbs[0] || '';
        // this.frmGroup.patchValue({ suburbControl: firstsuburbs });
      });
    }
  }
  public GetRoadBySuburb(suburbId: number | null | undefined) {
    if (suburbId != undefined) {
      this.loadingService.showLoading();
      this.roadService.GetRoadsBySuburb(suburbId).subscribe(response => {
        this.roads = response;
        this.loadingService.hideLoading();
      });
    }
  }

  public getStreetById(streetId: number) {
    if (streetId) {
      this.loadingService.showLoading();
      this.streetService.getById(streetId)
        .pipe(takeUntil(this.destroy$))
        .subscribe(result => {
          if (result) {
            if (result.type == "V") {
              this.frmGroup.controls['districtControl']?.enable();
            }
            this.street = ObjectUtility.deepClone(result);
            this.streetOriginal = ObjectUtility.deepClone(result);
            this.GetAllDropDownData();
            this.setForm(this.street);
          }
          this.loadingService.hideLoading();
        });
    }

  }

  public onProvinceChange(provinceId: number) {
    this.clearValueDropdownChange('Province');
    this.getDistrictByProvince(provinceId);
  }
  public onTownChange(townId: number) {
    this.clearValueDropdownChange('Town');
    this.getDistrictByTown(townId);
  }
  public onDistrictChange(districtId: number) {
    this.clearValueDropdownChange('District');
    this.GetLlgByDistrict(districtId);
  }
  public onLlgChange(llgId: number) {
    this.clearValueDropdownChange('Llg');
    this.GetWardByLlg(llgId);
  }
  public onSuburbChange(suburbId: number) {
    this.clearValueDropdownChange('Suburb');
    this.GetRoadBySuburb(suburbId);
  }
  public onWardChange(wardId: number) {
    this.clearValueDropdownChange('Ward');
    this.GetSuburbByWard(wardId);
  }



}