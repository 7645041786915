import { BaseRecord } from "./BaseRecord";
import { Province } from "./province";


export class District extends BaseRecord<number> {
    public name: string;
    public provinceId: number;
    public province: Province | null;

    constructor() {
        super();
        this.id = 0;
        this.provinceId = 0;
        this.name = "";
    }
}
