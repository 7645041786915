import { Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';
import { RoleService } from '../../shared/services/roleService';

@Directive({
  selector: '[appAppRoleCheck]',
})
export class AppRoleCheckDirective {
  @Input() set appRoleCheck(role: string) {
    // Check if the user has the role
    if (this.roleService.canAccessFeature(role)) {
      // If the user has the role, insert the element into the view
      this.viewContainer.createEmbeddedView(this.templateRef);
    } else {
      // Otherwise, clear the element from the view
      this.viewContainer.clear();
    }
  }

  constructor(
    private templateRef: TemplateRef<any>,  // Template reference of the element the directive is attached to
    private viewContainer: ViewContainerRef, // View container to insert/remove the element
    private roleService: RoleService // Inject the RoleService to check roles
  ) {}
}