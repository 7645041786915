import { Component, OnInit } from '@angular/core';
//import { CustomizerSettingsService } from '../../customizer-settings/customizer-settings.service';
import { CustomizerSettingsService } from '../../../../customizer-settings/customizer-settings.service';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Validators } from '@angular/forms';

@Component({
    selector: 'app-sign-in',
    templateUrl: './sign-in.component.html',
    styleUrl: './sign-in.component.scss'
})
export class SignInComponent implements OnInit {

    // isToggled
    isToggled = false;
    loginForm!: FormGroup;
    isPasswordVisible = false;
    submitted = false;

    constructor(
        public themeService: CustomizerSettingsService,
        private formBuilder: FormBuilder
    ) {
        this.themeService.isToggled$.subscribe((isToggled: boolean) => {
            this.isToggled = isToggled;
        });
        
    }

    ngOnInit(): void {
        this.initializer();
      }

   

    togglePasswordVisibility(): void {
        this.isPasswordVisible = !this.isPasswordVisible;
    }
    onPasswordInput(event: Event): void {
        const inputElement = event.target as HTMLInputElement;
        this.f['password'].setValue(inputElement.value);
    }

    private initializer(): void {
        this.loginForm = this.formBuilder.group({
            email: ['', [Validators.required, Validators.email]],
            password: ['', Validators.required],
          });
    }

     // convenience getter for easy access to form fields
    get f() { return this.loginForm.controls; }

     /**
   * Form submit
   */
  onSubmit() {
    this.submitted = true;
    // stop here if form is invalid
    if (this.loginForm.invalid) {
      return;
    }
  }

}