<div class="reset-password-area" [class.dark-theme]="themeService.isDark()">
    <div class="trezo-form mx-auto" [class.component-dark-theme]="themeService.isDark()"
        [class.component-rtl-enabled]="themeService.isRTLEnabled()">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12 order-2 order-lg-1">
                <div class="form-image">
                    <img src="images/reset-password1.jpg" alt="reset-password-image">
                </div>
            </div>
            <div class="col-lg-6 col-md-12 order-1 order-lg-2">
                <div class="form-content">
                    <div class="logo">
                        <img src="images/logo/FullLogo_Transparent.png" alt="logo">
                        <!-- <img src="images/logo-big.svg" alt="logo">
                        <img src="images/white-logo-big.svg" class="d-none" alt="logo"> -->
                    </div>
                    <div class="title" *ngIf="!isSuccess">
                        <h1 class="fw-semibold">
                            Reset Password?
                        </h1>
                        <p class="fw-medium">
                            Enter your new password and confirm it another time in the field below.
                        </p>
                    </div>
                    <form [formGroup]="frmGroup" (ngSubmit)="onSave()">
                        <div class="form-group position-relative" *ngIf="!isSuccess">
                            <label class="main-label d-block fw-medium text-black">
                                New Password <span class="text-danger">*</span>
                            </label>
                            <div class="position-relative">
                                <input [type]="isPassword2Visible ? 'text' : 'password'"
                                    formControlName="newPasswordControl" class="form-control"
                                    placeholder="Type password">
                                <button type="button" (click)="togglePassword2Visibility()"
                                    class="icon-button p-0 border-0 bg-transparent transition">
                                    <i [ngClass]="isPassword2Visible ? 'ri-eye-line' : 'ri-eye-off-line'"></i>
                                </button>
                            </div>
                            <div class="form-text text-danger"
                                *ngIf="isSubmitted && f['newPasswordControl']!.hasError('required')">
                                Current Password is required.
                            </div>
                            <div class="form-text text-danger"
                                *ngIf="isSubmitted && f['newPasswordControl']!.hasError('onlyWhitespace')">
                                Current Password can not be only whitespace.
                            </div>
                            <div class="form-text text-danger"
                                *ngIf="isSubmitted && f['newPasswordControl']!.hasError('hasCharacters')">
                                Minimum Password length should be 8 characters
                            </div>
                            <div class="form-text text-danger"
                                *ngIf="isSubmitted && f['newPasswordControl']!.hasError('hasUpper')">
                                Password must contain at least one uppercase
                            </div>
                            <div class="form-text text-danger"
                                *ngIf="isSubmitted && f['newPasswordControl']!.hasError('hasLower')">
                                Password must contain at least one lowercase
                            </div>
                            <div class="form-text text-danger"
                                *ngIf="isSubmitted && f['newPasswordControl']!.hasError('hasNumber')">
                                Password must contain at least one number
                            </div>
                            <div class="form-text text-danger"
                                *ngIf="isSubmitted && f['newPasswordControl']!.hasError('hasSpecialChar')">
                                Password must contain at least one special character
                            </div>

                        </div>
                        <div class="form-group position-relative" *ngIf="!isSuccess">
                            <label class="main-label d-block fw-medium text-black">
                                Confrim Password <span class="text-danger">*</span>
                            </label>
                            <div class="position-relative">
                                <input [type]="isPassword3Visible ? 'text' : 'password'"
                                    formControlName="confirmPasswordControl" class="form-control"
                                    placeholder="Type password">
                                <button type="button" (click)="togglePassword3Visibility()"
                                    class="icon-button p-0 border-0 bg-transparent transition">
                                    <i [ngClass]="isPassword3Visible ? 'ri-eye-line' : 'ri-eye-off-line'"></i>
                                </button>
                            </div>
                            <div class="form-text text-danger"
                                *ngIf="isSubmitted && f['confirmPasswordControl']!.hasError('required')">
                                Confirm Password is required.
                            </div>
                            <div class="form-text text-danger"
                                *ngIf="isSubmitted && f['confirmPasswordControl']!.hasError('onlyWhitespace')">
                                Confirm Password can not be only whitespace.
                            </div>
                            <div class="form-text text-danger"
                                *ngIf="isSubmitted && f['confirmPasswordControl']!.hasError('mustMatch')">
                                Confirm Password must be match with new password..
                            </div>
                        </div>
                        <button type="submit" *ngIf="!isSuccess"
                            class="btn-box d-block w-100 border-0 transition fw-medium border-radius">
                            <span class="d-flex align-items-center justify-content-center">
                                <i class="material-symbols-outlined">
                                    autorenew
                                </i>
                                Reset
                            </span>
                        </button>
                        <div class="form-group mt-5" *ngIf="isSuccess">
                            <h4 class="link-success">Password Reset Successfully</h4>
                            <p>
                                Your password has been reset successfully. Please sign in with your new password now.
                            </p>
                        </div>
                        <p class="info">
                            Back to <a routerLink="" class="fw-semibold text-primary">Sign In</a>
                        </p>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>