<footer
    [class.card-without-border-radius]="themeService.isCardWithoutBorderRadius()"
    [class.card-with-box-shadow]="themeService.isCardWithBoxShadow()"
    class="footer-area text-center bg-white border-top-radius"
    [class.card-borderd]="themeService.isCardBordered()"
>
    <div class="d-flex justify-content-between">
        <p>
            Copyright &#64; {{ currentYear }} PNG Address Hub All rights reserved.
        </p>
        <p>
            Designed and Developed by Kwila Creek
        </p>
    </div>
</footer>