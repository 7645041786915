<!-- 
<nav aria-label="Page navigation">
    <ul class="pagination">
      <li class="page-item" [class.disabled]="currentPage === 1">
        <a class="page-link" (click)="goToPage(currentPage - 1)" href="javascript:void(0);" aria-label="Previous">
          <span aria-hidden="true">&laquo;</span>
        </a>
      </li>
      <li *ngFor="let page of pages" class="page-item" [class.active]="page === currentPage">
        <a class="page-link" (click)="goToPage(page)" href="javascript:void(0);">{{ page }}</a>
      </li>
      <li class="page-item" [class.disabled]="currentPage === totalPages">
        <a class="page-link" (click)="goToPage(currentPage + 1)" href="javascript:void(0);" aria-label="Next">
          <span aria-hidden="true">&raquo;</span>
        </a>
      </li>
    </ul>
  </nav>

  <div class="trezo-card-pagination">
    <div class="d-md-flex align-items-center justify-content-between">
        <p class="mb-0" *ngIf="totalRecords > 0">
            Showing {{ startRecord }} to {{ endRecord }} of {{ totalRecords }} entries
        </p>
        <p class="mb-0">
            <select id="pageSize" class="form-select" (change)="changePageSize($event)">
                <option *ngFor="let option of pageSizeOptions" [value]="option" [selected]="option === pageSize">
                  {{ option }}
                </option>
              </select>
        </p>
        <ol class="list-unstyled mb-0 p-0">
            <li class="d-inline-block" [class.disabled]="currentPage === 0">
                <a class="prev" routerLink="." (click)="previousPage()">
                    <span class="opacity-0">
                        0
                    </span>
                    <i class="material-symbols-outlined">
                        chevron_left
                    </i>
                </a>
            </li>
            <li class="d-inline-block" *ngFor="let page of [].constructor(totalPages); let i = index">
                <a routerLink="." [class.active]="i === currentPage" 
                    (click)="setPage(i)">
                    {{ i + 1 }}
                </a>
            </li>
            <li class="d-inline-block" [class.disabled]="currentPage === totalPages - 1">
                <a class="next" routerLink="." (click)="nextPage()">
                    <span class="opacity-0">
                        0
                    </span>
                    <i class="material-symbols-outlined">
                        chevron_right
                    </i>
                </a>
            </li>
        </ol>
    </div>
</div> 
-->

<div class="trezo-card-pagination">
    <div class="d-md-flex align-items-center justify-content-between">
        <p class="mb-0" *ngIf="totalRecords > 0">
            Showing {{ startRecord }} to {{ endRecord }} of {{ totalRecords }} entries
        </p>
        <div class="col-md-1">
            <select id="pageSize" class="form-select" (change)="changePageSize($event)">
                <option *ngFor="let option of pageSizeOptions" [value]="option" [selected]="option === pageSize">
                    {{ option }}
                </option>
            </select>
        </div>
        <ol class="list-unstyled mb-0 p-0">
            <li class="d-inline-block" [class.disabled]="currentPage === 0">
                <a class="prev" routerLink="." (click)="currentPage > 0 ? previousPage() : $event.preventDefault()">
                    <span class="opacity-0">
                        0
                    </span>
                    <i class="material-symbols-outlined">
                        chevron_left
                    </i>
                </a>
            </li>
            <li class="d-inline-block" *ngFor="let page of displayedPages">
                <span *ngIf="page === -1">...</span>
                <a class="page-link-hover" *ngIf="page !== -1" (click)="setPage(page)"
                    [class.active]="page === currentPage">
                    {{ page + 1 }}
                </a>
            </li>

            <!-- <ng-container *ngFor="let page of displayedPages">
                <span *ngIf="page === -1">...</span>
                <span *ngIf="page !== -1" 
                      (click)="setPage(page)" 
                      [class.active]="page === currentPage">
                  {{ page + 1 }}
                </span>
              </ng-container> -->
            <li class="d-inline-block" [class.disabled]="currentPage === totalPages - 1">
                <a class="next" routerLink="."
                    (click)="currentPage < totalPages - 1 ? nextPage() : $event.preventDefault()">
                    <span class="opacity-0">
                        0
                    </span>
                    <i class="material-symbols-outlined">
                        chevron_right
                    </i>
                </a>
            </li>
        </ol>
    </div>
</div>