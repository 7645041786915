import { SearchExpression } from "../../../models/core/table/SearchExpression";

  export function getSearchOperator(type: string): SearchExpression | undefined {
    const operatorMap: Record<string, SearchExpression> = {
        In: SearchExpression.In,
        Equals: SearchExpression.Equals,
        NotEquals: SearchExpression.NotEquals,
        Between: SearchExpression.Between,
        LessThan: SearchExpression.LessThan,
        LessThanOrEqual: SearchExpression.LessThanOrEqual,
        GreaterThan: SearchExpression.GreaterThan,
        GreaterThanOrEqual: SearchExpression.GreaterThanOrEqual,
        StartsWith: SearchExpression.StartsWith,
        EndsWith: SearchExpression.EndsWith,
        Contains: SearchExpression.Contains,
        NotContains: SearchExpression.NotContains,
    };
    return operatorMap[type];
}