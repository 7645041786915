import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthenticationService } from '../modules/shared/services/authentication.service';

@Injectable({ providedIn: 'root' })
export class AuthorizeGuard implements CanActivate {

    constructor(private authService: AuthenticationService,
        private router: Router) {
    }

    canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        //return true;//TODO: added for testing purpose only 
        if (this.authService.isUserAuthenticated() && this.authService.isUserRoleAuthorized(route.data['roles'])) {
            return true;
        }
        this.authService.logout();
        this.router.navigate(["/account/login"], { queryParams: { returnUrl: state.url } });
        return false;
        //return true;
    }
}
