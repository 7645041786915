<div class="row">
    <div class="col-xxl-6 col-xl-6 col-lg-3 col-sm-6">
        <!-- Last 7 days Total Clients -->
        <png-client-growth [dataRage]="'7days'"  />

    </div>
    <div class="col-xxl-6 col-xl-6 col-lg-3 col-sm-6">

        <!-- Last 30 days Total Clients -->
        <png-client-growth [dataRage]="'365days'" />

        <!-- Lead Conversion -->
        <!-- <png-lead-conversion /> -->

    </div>
    
    <div class="col-lg-12">
        <png-table-data-count />
    </div>
    
    <div class="col-lg-4">

        <!-- Leads by Source -->
        <!-- <app-leads-by-source /> -->

    </div>
    <div class="col-lg-4">

        <!-- Top Performers -->
        <!-- <app-top-performers /> -->

    </div>
    <div class="col-lg-8">

        <!-- Recent Leads -->
        <!-- <app-recent-leads /> -->

    </div>
    <div class="col-lg-8">

        <!-- Sales Report -->
        <!-- <app-sales-report /> -->

    </div>
    <div class="col-lg-4">

        <!-- Top Products by Sales -->
        <!-- <app-top-products-by-sales /> -->

    </div>
</div>