<div
    class="forgot-password-area"
    [class.dark-theme]="themeService.isDark()"
>
    <div
        class="trezo-form mx-auto"
        [class.component-dark-theme]="themeService.isDark()"
        [class.component-rtl-enabled]="themeService.isRTLEnabled()"
    >
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12 order-2 order-lg-1">
                <div class="form-image">
                    <img src="images/forgot-password.jpg" alt="forgot-password-image">
                </div>
            </div>
            <div class="col-lg-6 col-md-12 order-1 order-lg-2">
                <div class="form-content">
                    <div class="logo">
                        <img src="images/logo-big.svg" alt="logo">
                        <img src="images/white-logo-big.svg" class="d-none" alt="logo">
                    </div>
                    <div class="title">
                        <h1 class="fw-semibold">
                            Forgot your password?
                        </h1>
                        <p class="fw-medium">
                            Enter the email address you used when you joined and we’ll send you instructions to reset your password.
                        </p>
                    </div>
                    <form [formGroup]="forgotForm" (ngSubmit)="onSubmit()">
                        <div class="form-group">
                            <label for="email" class="main-label d-block fw-medium text-black">
                                Email Address
                            </label>
                            <input type="text" class="form-control" placeholder="example&#64;trezo.com" id="input-email" formControlName="email"
                            [ngClass]="{ 'is-invalid': submitted && f['email'].errors }">

                            <div class="form-text text-danger" *ngIf="f['email']!.hasError('required') && f['email']!.touched">
                                Email is required.
                            </div>
                            <div class="form-text text-danger" *ngIf="f['email']!.hasError('email') && f['email']!.touched">
                                Please enter a valid email address.
                            </div>
                            

                        </div>
                        <button type="submit" class="btn-box d-block w-100 border-0 transition fw-medium border-radius">
                            <span class="d-flex align-items-center justify-content-center">
                                <i class="material-symbols-outlined">
                                    autorenew
                                </i>
                                Sign In
                            </span>
                        </button>
                        <p class="info">
                            Back to <a routerLink="" class="fw-semibold text-primary">Sign In</a>
                        </p>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>