import { NgClass } from '@angular/common';
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { RouterLink } from '@angular/router';
import { catchError, of, Subject, takeUntil } from 'rxjs';
import { ChangePassword } from '../../../../../models/domain/changePassword';
import { CustomizerSettingsService } from '../../../../../customizer-settings/customizer-settings.service';
import { UserService } from '../../../services/user.service';
import { ToastrService } from 'ngx-toastr';
import { LoadingService } from '../../../../core/services/loading.service';
import { confirmPasswordValidator, passwordValidator, whitespaceValidator } from '../../../../core/validators/ValidationService';

@Component({
    selector: 'app-change-password',
    templateUrl: './change-password.component.html',
    styleUrl: './change-password.component.scss'
})
export class ChangePasswordComponent implements OnInit {


    @Output() classApplied = new EventEmitter<boolean>();
    @Output() saveConfirmationEvent = new EventEmitter<boolean>();
    isToggled = false;
    frmGroup: FormGroup;

    private destroy$: Subject<boolean> = new Subject<boolean>();
    public changePasswordOriginal = new ChangePassword;
    public changePassword: ChangePassword = new ChangePassword;

    public errorMessage = "";
    isSubmitted = false;


    constructor(
        public themeService: CustomizerSettingsService,
        private formBuilder: FormBuilder,
        private userService: UserService,
        private toasterService: ToastrService,
        private loadingService: LoadingService,
    ) {
        this.initialize();
    }

    public ngOnInit(): void {


    }

    private initialize(): void {
        this.themeService.isToggled$.subscribe(isToggled => {
            this.isToggled = isToggled;
        });
        this.isSubmitted = false;
        this.createForm();
    }

    public ngAfterViewInit(): void {
        this.frmGroup.controls['currentPasswordControl'].valueChanges.subscribe(value => {
            this.isSubmitted = false;
            this.changePassword.currentPassword = value;
        });
        this.frmGroup.controls['confirmPasswordControl'].valueChanges.subscribe(value => {
            this.isSubmitted = false;
            this.changePassword.newPassword = value;
        });
        this.frmGroup.controls['newPasswordControl'].valueChanges.subscribe(value => {
            this.isSubmitted = false;
        });

    }

    public ngOnDestroy(): void {
        this.destroy$.next(true);
        this.destroy$.unsubscribe();
    }


    get f() { return this.frmGroup.controls; }

    private createForm(): void {
        this.frmGroup = this.formBuilder.group({
            currentPasswordControl: new FormControl('', [Validators.required, whitespaceValidator(), passwordValidator()]),
            confirmPasswordControl: new FormControl('', [Validators.required, whitespaceValidator()]),
            newPasswordControl: new FormControl('', [Validators.required, whitespaceValidator(), passwordValidator()]),
        },
            {
                validator: confirmPasswordValidator('newPasswordControl', 'confirmPasswordControl')
            })
    }

    public onSave(): void {
        this.isSubmitted = true;
        if (this.frmGroup.valid && this.formIsValid()) {
            this.loadingService.showLoading();
            this.updatePassword();
        }
    }

    private updatePassword(): void {
        this.loadingService.showLoading();
        this.userService.changePassword(this.changePassword)
            .pipe(
                catchError((err: any) => {
                    this.loadingService.hideLoading();
                    if (err && err.error) {
                        this.isSubmitted = false;
                        if (err.error.isCustom) {
                            this.errorMessage = err.error.message;
                            this.toasterService.warning(err.error.message);
                        } else {
                            this.toasterService.error('Something went wrong!');
                        }
                    } else {
                        this.isSubmitted = false;
                        this.toasterService.error('Something went wrong!');
                    }
                    return of(null);
                }),
                takeUntil(this.destroy$))
            .subscribe(response => {
                this.loadingService.hideLoading();
                if (response) {
                    this.isSubmitted = false;
                    //this.pngIdentityUser = response?.body != null ? response?.body : new PngIdentityUser;
                    this.saveConfirmationEvent.emit(true);
                    this.toasterService.success("Update successfully");
                    this.frmGroup.reset();
                }
                this.loadingService.hideLoading();
            });
    }

    public formIsValid(): boolean {
        let isValid = this.frmGroup.controls['currentPasswordControl'].valid
            && this.frmGroup.controls['newPasswordControl'].valid
            && this.frmGroup.controls['confirmPasswordControl'].valid

        return isValid;
    }

    isPassword1Visible: boolean = false;
    isPassword2Visible: boolean = false;
    isPassword3Visible: boolean = false;
    togglePassword1Visibility(): void {
        this.isPassword1Visible = !this.isPassword1Visible;
    }
    togglePassword2Visibility(): void {
        this.isPassword2Visible = !this.isPassword2Visible;
    }
    togglePassword3Visibility(): void {
        this.isPassword3Visible = !this.isPassword3Visible;
    }

}