import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
    name: "ellipses"
})
export class EllipsesPipe implements PipeTransform {

    // by default text limit is 50 and ellipsis are 3 dots
    // usage: <div>{{longStr | ellipses }}</div>
    // usage: <div>{{longStr | ellipses : 10 :  '--' }}</div>
    transform(value: string, limit:any, completeWords = false, ellipsis = "...", skipEllipse: boolean = false) {        
        if(skipEllipse)
            return value;
        if (completeWords) {
            limit = value.substring(0, limit).lastIndexOf(" ");
        }
        return (!!value && value.length > limit) ? `${value.substring(0, limit)} ${ellipsis}` : value;
    }
}
