import { Component, EventEmitter, Input, OnInit, Output, AfterViewInit, OnDestroy } from '@angular/core';
import { CustomizerSettingsService } from '../../../../../../customizer-settings/customizer-settings.service';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { SectionNumber } from '../../../../../../models/domain/sectionNumber';
import { ObjectUtility } from '../../../../../core/utilities/ObjectUtility';
import { catchError, forkJoin, of, Subject, takeUntil } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { whitespaceValidator } from '../../../../../core/validators/ValidationService';
import { Street } from '../../../../../../models/domain/street';
import { StreetService } from '../../../../services/street.service';
import { LoadingService } from '../../../../../core/services/loading.service';
import { SectionNumberService } from '../../../../services/sectionNumber.service';
import { BaseSearchOptions } from '../../../../../../models/core/table/BaseSearchOptions';
import { Province } from '../../../../../../models/domain/province';
import { Town } from '../../../../../../models/domain/town';
import { Suburb } from '../../../../../../models/domain/suburb';
import { ProvinceService } from '../../../../services/province.service';
import { SuburbService } from '../../../../services/suburb.service';
import { TownService } from '../../../../services/town.service ';
import { StreetRoad } from '../../../../../../models/domain/streetRoad';

@Component({
  selector: 'app-sectionNumber-details',
  templateUrl: './sectionNumber-details.component.html',
  styleUrl: './sectionNumber-details.component.scss'
})
export class SectionNumberDetailsComponent implements OnInit, AfterViewInit, OnDestroy {

  @Output() classApplied = new EventEmitter<boolean>();
  @Output() saveConfirmationEvent = new EventEmitter<boolean>();
  @Input() currentSectionNumberId = 0;
  // isToggled
  isToggled = false;
  frmGroup: FormGroup;

  private destroy$: Subject<boolean> = new Subject<boolean>();
  public sectionNumberOriginal = new SectionNumber;
  public sectionNumber: SectionNumber = new SectionNumber;
  public titleText = "";
  public titleButton = "";
  public errorMessage = "";

  isSubmitted = false;

  // drop down bind data
  public provinces: Province[];
  public towns: Town[];
  public suburbs: Suburb[];
  public streets: StreetRoad[];
  public pageIndex = 0;
  public pageSize = 1000;
  public searchOptions: BaseSearchOptions;

  constructor(
    public themeService: CustomizerSettingsService,
    private formBuilder: FormBuilder,
    private sectionNumberService: SectionNumberService,
    private toasterService: ToastrService,
    private streetService: StreetService,
    private loadingService: LoadingService,
    private provinceService: ProvinceService,
    private townService: TownService,
    private suburbService: SuburbService,
  ) {
    this.initialize();
  }

  public ngOnInit(): void {
    if (this.currentSectionNumberId > 0) {
      this.getDropdownData();

    }
    else {
      this.currentSectionNumberId = 0;
      this.getDropdownData();
      const newSectionNumber = new SectionNumber();
      this.sectionNumber = ObjectUtility.deepClone(newSectionNumber);
      this.sectionNumberOriginal = ObjectUtility.deepClone(newSectionNumber);
      this.setForm(this.sectionNumber);
    }
  }

  private initialize(): void {
    this.themeService.isToggled$.subscribe(isToggled => {
      this.isToggled = isToggled;
    });
    this.isSubmitted = false;
    this.searchOptions = new BaseSearchOptions(undefined, 'name', 0, this.pageIndex, this.pageSize);
    this.createForm();
  }

  public ngAfterViewInit(): void {

    this.frmGroup.controls['numberControl'].valueChanges.subscribe(value => {
      this.sectionNumber.number = value;
    });
    this.frmGroup.controls['provinceControl'].valueChanges.subscribe(value => {
      this.sectionNumber.province = value;
    });
    this.frmGroup.controls['townControl'].valueChanges.subscribe(value => {
      this.sectionNumber.town = value;
    });
    this.frmGroup.controls['suburbControl'].valueChanges.subscribe(value => {
      this.sectionNumber.suburb = value;
    });

    this.frmGroup.controls['streetControl'].valueChanges.subscribe(value => {
      if (value?.streetOrRoad == "Street") {
        this.sectionNumber.street = value;

      } else {
        this.sectionNumber.roads = value;
      }
    });

  }

  public ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  get f() { return this.frmGroup.controls; }

  private createForm(): void {
    this.frmGroup = this.formBuilder.group({
      numberControl: new FormControl('', [Validators.required, whitespaceValidator()]),
      provinceControl: new FormControl(null, [Validators.required]),
      townControl: new FormControl({ value: null, disabled: true }, [Validators.required]),
      suburbControl: new FormControl(null, [Validators.required]),
      streetControl: new FormControl(null, [Validators.required]),
    })
  }

  private setForm(sectionNumber: SectionNumber): void {
    if (sectionNumber && sectionNumber.id != undefined && sectionNumber.id > 0) {

      const stRoad = new StreetRoad();
      stRoad.id = sectionNumber?.street != null ? sectionNumber.street.id : sectionNumber.roads?.id;
      stRoad.name = sectionNumber?.street != null ? sectionNumber.street.name : sectionNumber.roads?.name;
      stRoad.streetOrRoad = sectionNumber?.street != null ? 'Street' : 'Road';

      this.frmGroup.patchValue({
        numberControl: sectionNumber?.number,
        provinceControl: sectionNumber?.province,
        townControl: sectionNumber?.town,
        suburbControl: sectionNumber?.suburb,
        streetControl: stRoad,
      });
    }
    this.titleText = `${this.currentSectionNumberId === 0 ? "Add" : "Edit"} Section Number`;
    this.titleButton = `${this.currentSectionNumberId === 0 ? "Create" : "Update"}`;
  }

  public onSave(): void {
    this.isSubmitted = true;
    this.loadingService.showLoading();

    if (this.frmGroup.valid) {
      this.addEditSectionNumber();
    }
  }

  private addEditSectionNumber(): void {
    if (this.sectionNumber?.id === 0) {
      this.sectionNumberService.create(this.sanitizeSectionNumber(this.sectionNumber))
        .pipe(
          catchError((err: any) => {
            if (err && err.error) {
              this.isSubmitted = false;
              if (err.error.isCustom) {
                this.errorMessage = err.error.message;
                this.toasterService.warning(err.error.message);
              } else {
                this.toasterService.error('Something went wrong!');
              }
            } else {
              this.isSubmitted = false;
              this.toasterService.error('Something went wrong!');
            }
            return of(null);
          }),
          takeUntil(this.destroy$))
        .subscribe(response => {
          if (response) {
            this.isSubmitted = false;
            this.sectionNumber = response?.body != null ? response?.body : new SectionNumber;
            this.saveConfirmationEvent.emit(true);
            this.toggleClass();
            this.toasterService.success("Save successfully");
          }
        });
    } else {
      this.sectionNumberService.update(this.sanitizeSectionNumber(this.sectionNumber))
        .pipe(
          catchError((err: any) => {
            if (err && err.error) {
              this.isSubmitted = false;
              if (err.error.isCustom) {
                this.errorMessage = err.error.message;
                this.toasterService.warning(err.error.message);
              } else {
                this.toasterService.error('Something went wrong!');
              }
            } else {
              this.isSubmitted = false;
              this.toasterService.error('Something went wrong!');
            }
            return of(null);
          }),
          takeUntil(this.destroy$))
        .subscribe(response => {
          if (response) {
            this.isSubmitted = false;
            this.sectionNumber = response?.body != null ? response?.body : new SectionNumber;
            this.saveConfirmationEvent.emit(true);
            this.toasterService.success("Update successfully");
            this.toggleClass();
          }
        });
    }
  }


  public toggleClass(): void {
    this.classApplied.emit(false);
  }

  private getDropdownData() {
    this.loadingService.showLoading();
    const province = this.provinceService.get(this.searchOptions);
    forkJoin([province])
      .pipe(takeUntil(this.destroy$)).subscribe((result: any) => {
        if (result) {
          this.provinces = result[0].body.values;
          this.getSectionNumberById(this.currentSectionNumberId);
        }
        this.loadingService.hideLoading();
      });
  }

  public GetTownByProvince(provinceId: number | undefined) {
    if (provinceId && provinceId > 0) {
      this.loadingService.showLoading();
      this.townService.GetTownByProvince(provinceId).subscribe(response => {
        this.towns = response;
        const firsttown = this.towns[0] || '';
        this.frmGroup.patchValue({ townControl: firsttown });
        this.sectionNumber.town = firsttown;
        this.GetSuburbByTown(firsttown.id);
        this.loadingService.hideLoading();
      });
    }
  }

  public GetSuburbByTown(townId: number | undefined) {
    this.loadingService.showLoading();
    if (townId && townId > 0) {
      this.suburbService.GetSuburbByTown(townId).subscribe(response => {
        this.suburbs = response;
        this.loadingService.hideLoading();
      });
    }
  }

  public GetstreetAndRoadsBySuburb(suburbId: number | undefined) {
    if (suburbId && suburbId > 0) {
      this.loadingService.showLoading();
      this.streetService.GetstreetAndRoadsBySuburb(suburbId).subscribe(response => {
        this.streets = response;
        this.loadingService.hideLoading();
      });
    }
  }

  public GetAllDropDownData(sectionNumber: SectionNumber) {
    this.GetTownByProvince(this.sectionNumber.provinceId);
    this.GetSuburbByTown(this.sectionNumber.townId);
    this.GetstreetAndRoadsBySuburb(this.sectionNumber.suburbId);
  }


  private sanitizeSectionNumber(sectionNumber: SectionNumber): SectionNumber {
    sectionNumber.province = null;
    sectionNumber.provinceId = this.frmGroup.controls['provinceControl'].value.id;
    sectionNumber.town = null;
    sectionNumber.townId = this.frmGroup.controls['townControl'].value.id;
    sectionNumber.suburb = null;
    sectionNumber.suburbId = this.frmGroup.controls['suburbControl'].value.id;
    sectionNumber.street = null;
    sectionNumber.roads = null;

    if (this.frmGroup.controls['streetControl'].value.streetOrRoad == "Street") {
      sectionNumber.streetId = this.frmGroup.controls['streetControl'].value.id == 0 ? null : this.frmGroup.controls['streetControl'].value.id;
      sectionNumber.roadsId = null;
    } else {
      sectionNumber.roadsId = this.frmGroup.controls['streetControl'].value.id == 0 ? null : this.frmGroup.controls['streetControl'].value.id;
      sectionNumber.streetId = null;
    }

    return sectionNumber;
  }

  public provinceComparer(t1: Province, t2: Province): boolean {
    return t1 && t2 ? (t1.id === t2.id || t1.code === t2.code) : t1 === t2;
  }
  public townComparer(t1: Town, t2: Town): boolean {
    return t1 && t2 ? (t1.id === t2.id || t1.name === t2.name) : t1 === t2;
  }
  public suburbComparer(t1: Suburb, t2: Suburb): boolean {
    return t1 && t2 ? (t1.id === t2.id || t1.name === t2.name) : t1 === t2;
  }
  public streetComparer(t1: StreetRoad, t2: StreetRoad): boolean {
    return t1 && t2 ? (t1.id === t2.id && t1.name === t2.name) : t1 === t2;
  }

  public dataHasChanged(): boolean {
    const baseEqual = ObjectUtility.deepEqual(this.sectionNumber, this.sectionNumberOriginal);
    return !baseEqual;
  }

  public formIsValid(): boolean {
    const isValid = this.frmGroup.controls['provinceControl'].valid
      //&& this.frmGroup.controls['townControl'].valid
      && this.frmGroup.controls['suburbControl'].valid
      && this.frmGroup.controls['streetControl'].valid
      && this.frmGroup.controls['numberControl'].valid
      && (this.sectionNumber.town?.id != 0
        || this.sectionNumber.town?.id != null)
    return isValid;
  }

  public clearValue(change: string) {
    if (change == 'Province') {
      this.frmGroup.controls['townControl'].setValue(null);
      this.towns = [];
      this.sectionNumber.town = null;
      this.frmGroup.controls['suburbControl'].setValue(null);
      this.sectionNumber.suburb = null;
      this.suburbs = [];
      this.frmGroup.controls['streetControl'].setValue(null);
      this.sectionNumber.street = null;
      this.streets = [];

    } else if (change == 'Suburb') {
      this.frmGroup.controls['streetControl'].setValue(null);
      this.sectionNumber.street = null;
      this.streets = [];
    }

  }

  public getSectionNumberById(sectionNumberId: number) {
    if (sectionNumberId) {
      this.loadingService.showLoading();
      this.sectionNumberService.getById(sectionNumberId)
        .pipe(takeUntil(this.destroy$))
        .subscribe(result => {
          if (result) {
            this.sectionNumber = ObjectUtility.deepClone(result);
            this.sectionNumberOriginal = ObjectUtility.deepClone(result);
            this.GetAllDropDownData(this.sectionNumber);
            this.setForm(this.sectionNumber);
          }
          this.loadingService.hideLoading();
        });
    }

  }

  public onProvinceChange(provinceId: number) {
    this.clearValue('Province');
    this.GetTownByProvince(provinceId);
  }
  public onSuburbChange(suburbId: number) {
    this.clearValue('Suburb');
    this.GetstreetAndRoadsBySuburb(suburbId);
  }


}