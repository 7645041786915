import { Component, EventEmitter, Input, OnInit, Output, AfterViewInit, OnDestroy } from '@angular/core';
import { CustomizerSettingsService } from '../../../../../../customizer-settings/customizer-settings.service';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Town } from '../../../../../../models/domain/town';
import { ObjectUtility } from '../../../../../core/utilities/ObjectUtility';
import { catchError, forkJoin, of, Subject, takeUntil } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { whitespaceValidator } from '../../../../../core/validators/ValidationService';
import { TownService } from '../../../../services/town.service ';
import { Province } from '../../../../../../models/domain/province';
import { ProvinceService } from '../../../../services/province.service';
import { LoadingService } from '../../../../../core/services/loading.service';
import { BaseSearchOptions } from '../../../../../../models/core/table/BaseSearchOptions';
import { District } from '../../../../../../models/domain/district';
import { DistrictService } from '../../../../services/district.service';

@Component({
  selector: 'app-town-details',
  templateUrl: './town-details.component.html',
  styleUrl: './town-details.component.scss'
})
export class TownDetailsComponent implements OnInit, AfterViewInit, OnDestroy {

  @Output() classApplied = new EventEmitter<boolean>();
  @Output() saveConfirmationEvent = new EventEmitter<boolean>();
  @Input() currentTownId = 0;
  // isToggled
  isToggled = false;
  frmGroup: FormGroup;

  private destroy$: Subject<boolean> = new Subject<boolean>();
  public townOriginal = new Town;
  public town: Town = new Town;
  public titleText = "";
  public titleButton = "";
  public errorMessage = "";
  isSubmitted = false;

  // drop down bind data
  public provinces: Province[];
  public districts: District[];
  public pageIndex = 0;
  public pageSize = 1000;
  public searchOptions: BaseSearchOptions;

  constructor(
    public themeService: CustomizerSettingsService,
    private formBuilder: FormBuilder,
    private townService: TownService,
    private toasterService: ToastrService,
    private provinceService: ProvinceService,
    private loadingService: LoadingService,
    private districtService: DistrictService,
  ) {
    this.initialize();
  }

  public ngOnInit(): void {
    if (this.currentTownId > 0) {
      this.getDropdownData();
      this.setForm(this.town);
    }
    else {
      this.currentTownId = 0;
      this.getDropdownData();
      const newTown = new Town();
      this.town = ObjectUtility.deepClone(newTown);
      this.townOriginal = ObjectUtility.deepClone(newTown);
      this.setForm(this.town);
    }
  }

  private initialize(): void {
    this.themeService.isToggled$.subscribe(isToggled => {
      this.isToggled = isToggled;
    });
    this.isSubmitted = false;
    this.searchOptions = new BaseSearchOptions(undefined, 'name', 0, this.pageIndex, this.pageSize);
    this.createForm();
  }

  public geTownById(townId: number) {
    if (townId) {
      this.loadingService.showLoading();

      this.townService.getById(townId)
        .pipe(takeUntil(this.destroy$))
        .subscribe(result => {
          if (result) {
            this.town = ObjectUtility.deepClone(result);
            this.townOriginal = ObjectUtility.deepClone(result);
            this.getDistrictByProvince(this.town.provinceId);
            this.setForm(this.town);
          }
          this.loadingService.hideLoading();
        });
    }

  }

  public ngAfterViewInit(): void {

    this.frmGroup.controls['nameControl'].valueChanges.subscribe(value => {
      this.town.name = value;
    });

    this.frmGroup.controls['provinceControl'].valueChanges.subscribe(value => {
      this.town.province = value;
    });
    this.frmGroup.controls['districtControl'].valueChanges.subscribe(value => {
      this.town.district = value;
    });

  }

  public ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  get f() { return this.frmGroup.controls; }

  private createForm(): void {
    this.frmGroup = this.formBuilder.group({
      nameControl: new FormControl('', [Validators.required, whitespaceValidator()]),
      provinceControl: new FormControl(null, [Validators.required]),
      districtControl: new FormControl(null, [Validators.required])
    })
  }

  private setForm(town: Town): void {
    if (town && town.id != undefined && town.id > 0) {
      this.getDistrictByProvince(town.provinceId);
      this.frmGroup.patchValue({
        nameControl: town?.name,
        provinceControl: town?.province,
        districtControl: town?.district,
      });
    }
    this.titleText = `${this.currentTownId === 0 ? "Add" : "Edit"} Town`;
    this.titleButton = `${this.currentTownId === 0 ? "Create" : "Update"}`;
  }

  public onSave(): void {
    this.isSubmitted = true;

    if (this.frmGroup.valid) {
      this.loadingService.showLoading();
      this.addEditTown();
    }
  }

  private addEditTown(): void {
    if (this.town?.id === 0) {
      this.townService.create(this.sanitizeTown(this.town))
        .pipe(
          catchError((err: any) => {
            if (err && err.error) {
              this.isSubmitted = false;
              if (err.error.isCustom) {
                this.errorMessage = err.error.message;
                this.toasterService.warning(err.error.message);
              } else {
                this.toasterService.error('Something went wrong!');
              }
            } else {
              this.isSubmitted = false;
              this.toasterService.error('Something went wrong!');
            }
            return of(null);
          }),
          takeUntil(this.destroy$))
        .subscribe(response => {
          if (response) {
            this.isSubmitted = false;
            this.town = response?.body != null ? response?.body : new Town;
            this.saveConfirmationEvent.emit(true);
            this.toggleClass();
            this.toasterService.success("Save successfully");
          }
        });
    } else {
      this.townService.update(this.sanitizeTown(this.town))
        .pipe(
          catchError((err: any) => {
            if (err && err.error) {
              this.isSubmitted = false;
              if (err.error.isCustom) {
                this.errorMessage = err.error.message;
                this.toasterService.warning(err.error.message);
              } else {
                this.toasterService.error('Something went wrong!');
              }
            } else {
              this.isSubmitted = false;
              this.toasterService.error('Something went wrong!');
            }
            return of(null);
          }),
          takeUntil(this.destroy$))
        .subscribe(response => {
          if (response) {
            this.isSubmitted = false;
            this.town = response?.body != null ? response?.body : new Town;
            this.saveConfirmationEvent.emit(true);
            this.toasterService.success("Update successfully");
            this.toggleClass();
          }
        });
    }
  }


  public toggleClass(): void {
    this.classApplied.emit(false);
  }

  private getDropdownData() {
    const province = this.provinceService.get(this.searchOptions);
    this.loadingService.showLoading();
    forkJoin([province])
      .pipe(takeUntil(this.destroy$)).subscribe((result: any) => {
        if (result) {
          this.provinces = result[0].body.values;
          this.geTownById(this.currentTownId);
        }
        this.loadingService.hideLoading();
      });
  }

  private sanitizeTown(town: Town): Town {
    town.province = null;
    town.provinceId = this.frmGroup.controls['provinceControl'].value.id;
    town.district = null;
    town.districtId = this.frmGroup.controls['districtControl'].value.id;

    return town;
  }

  public provinceComparer(t1: Province, t2: Province): boolean {
    return t1 && t2 ? (t1.id === t2.id || t1.code === t2.code) : t1 === t2;
  }
  public districtComparer(t1: District, t2: District): boolean {
    return t1 && t2 ? (t1.id === t2.id || t1.name === t2.name) : t1 === t2;
  }

  public dataHasChanged(): boolean {
    const baseEqual = ObjectUtility.deepEqual(this.town, this.townOriginal);
    return !baseEqual;
  }

  public formIsValid(): boolean {
    const isValid = this.frmGroup.controls['provinceControl'].valid
      && this.frmGroup.controls['districtControl'].valid
      && this.frmGroup.controls['nameControl'].valid
    return isValid;
  }

  public getDistrictByProvince(provinceId: number | undefined) {
    this.loadingService.showLoading();
    if (provinceId && provinceId > 0) {
      this.districtService.getDistrictsByProvince(provinceId).subscribe(response => {
        this.districts = response;
        this.loadingService.hideLoading();
      });
    }
  }

  public clearValue(change: string) {
    if (change == 'Province') {
      this.frmGroup.controls['districtControl'].setValue(null);
      this.districts = [];
      this.town.district = null;
    }

  }
  public onProvinceChange(provinceId: number) {
    this.clearValue('Province');
    this.getDistrictByProvince(provinceId);
  }



}