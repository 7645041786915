import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterLink, RouterOutlet } from '@angular/router';
import { CustomizerSettingsComponent } from '../../customizer-settings/customizer-settings.component';
import { SharedModule } from '../shared/shared.module';
import { AdminRoutingModule } from './admin.routes';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { ProvinceListComponent } from './components/LocationSection/province/province-list/province-list.component';
import { ProvinceDetailsComponent } from './components/LocationSection/province/province-details/province-details.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CoreModule } from "../core/core.module";
import { TownDetailsComponent } from './components/LocationSection/town/town-details/town-details.component';
import { TownListComponent } from './components/LocationSection/town/town-list/town-list.component';
import { DistrictListComponent } from './components/LocationSection/district/district-list/district-list.component';
import { DistrictDetailsComponent } from './components/LocationSection/district/district-details/district-details.component';
import { LlgListComponent } from './components/LocationSection/llg/llg-list/llg-list.component';
import { LlgDetailsComponent } from './components/LocationSection/llg/llg-details/llg-details.component';
import { WardListComponent } from './components/LocationSection/ward/ward-list/ward-list.component';
import { WardDetailsComponent } from './components/LocationSection/ward/ward-details/ward-details.component';
import { SuburbListComponent } from './components/LocationSection/suburb/suburb-list/suburb-list.component';
import { SuburbDetailsComponent } from './components/LocationSection/suburb/suburb-details/suburb-details.component';
import { RoadsListComponent } from './components/LocationSection/roads/roads-list/roads-list.component';
import { RoadsDetailsComponent } from './components/LocationSection/roads/roads-details/roads-details.component';
import { StreetDetailsComponent } from './components/LocationSection/street/street-details/street-details.component';
import { StreetListComponent } from './components/LocationSection/street/street-list/street-list.component';
import { SectionNumberListComponent } from './components/LocationSection/sectionNumber/sectionNumber-list/sectionNumber-list.component';
import { SectionNumberDetailsComponent } from './components/LocationSection/sectionNumber/sectionNumber-details/sectionNumber-details.component';
import { AllotmentNumberListComponent } from './components/LocationSection/allotmentNumber/allotmentNumber-list/allotmentNumber-list.component';
import { AllotmentNumberDetailsComponent } from './components/LocationSection/allotmentNumber/allotmentNumber-details/allotmentNumber-details.component';
import { ServiceTypeListComponent } from './components/BusinessSection/serviceType/serviceType-list/serviceType-list.component';
import { ServiceTypeDetailsComponent } from './components/BusinessSection/serviceType/serviceType-details/serviceType-details.component';
import { ServiceListComponent } from './components/BusinessSection/service/service-list/service-list.component';
import { ServiceDetailsComponent } from './components/BusinessSection/service/service-details/service-details.component';
import { BusinessTypeListComponent } from './components/BusinessSection/businessType/businessType-list/businessType-list.component';
import { BusinessTypeDetailsComponent } from './components/BusinessSection/businessType/businessType-details/businessType-details.component';
import { ContactListComponent } from './components/BusinessSection/contact/contact-list/contact-list.component';
import { ContactDetailsComponent } from './components/BusinessSection/contact/contact-details/contact-details.component';
import { AddressListComponent } from './components/BusinessSection/address/address-list/address-list.component';
import { AddressDetailsComponent } from './components/BusinessSection/address/address-details/address-details.component';
import { BusinessListComponent } from './components/BusinessSection/business/business-list/business-list.component';
import { BusinessDetailsComponent } from './components/BusinessSection/business/business-details/business-details.component';
import { PostOfficeListComponent } from './components/LocationSection/postOffice/postOffice-list/postOffice-list.component';
import { PostOfficeDetailsComponent } from './components/LocationSection/postOffice/postOffice-details/postOffice-details.component';
import { StreetDetailsListComponent } from './components/LocationSection/streetDetails/streetDetails-list/streetDetails-list.component';
import { StreetDetailsDetailsComponent } from './components/LocationSection/streetDetails/streetDetails-details/streetDetails-details.component';
import { ClientConfigurationListComponent } from './components/ConfigurationSection/clientConfiguration/clientConfiguration-list/clientConfiguration-list.component';
import { ClientConfigurationDetailsComponent } from './components/ConfigurationSection/clientConfiguration/clientConfiguration-details/clientConfiguration-details.component';
import { ClipboardModule } from "@angular/cdk/clipboard"
import { AccountSettingsComponent } from './components/settings/account-settings/account-settings.component';
import { ChangePasswordComponent } from './components/settings/change-password/change-password.component';
import { TableDataCountComponent } from './components/dashboard/crm/table-data-count/total-orders.component';
import { LogeventsListComponent } from './components/ConfigurationSection/logevents/logevents-list/logevents-list.component';
import { LogeventsDetailsComponent } from './components/ConfigurationSection/logevents/logevents-details/logevents-details.component';


@NgModule({
  declarations: [
    DashboardComponent,
    ProvinceListComponent,
    ProvinceDetailsComponent,
    TownListComponent,
    TownDetailsComponent,
    DistrictListComponent,
    DistrictDetailsComponent,
    LlgListComponent,
    LlgDetailsComponent,
    WardListComponent,
    WardDetailsComponent,
    SuburbListComponent,
    SuburbDetailsComponent,
    RoadsListComponent,
    RoadsDetailsComponent,
    StreetListComponent,
    StreetDetailsComponent,
    SectionNumberListComponent,
    SectionNumberDetailsComponent,
    AllotmentNumberListComponent,
    AllotmentNumberDetailsComponent,
    ServiceTypeListComponent,
    ServiceTypeDetailsComponent,
    ServiceListComponent,
    ServiceDetailsComponent,
    BusinessTypeListComponent,
    BusinessTypeDetailsComponent,
    ContactListComponent,
    ContactDetailsComponent,
    AddressListComponent,
    AddressDetailsComponent,
    BusinessListComponent,
    BusinessDetailsComponent,
    PostOfficeListComponent,
    PostOfficeDetailsComponent,
    StreetDetailsListComponent,
    StreetDetailsDetailsComponent,
    ClientConfigurationListComponent,
    ClientConfigurationDetailsComponent,
    AccountSettingsComponent,
    ChangePasswordComponent,
    LogeventsListComponent,
    LogeventsDetailsComponent,

  ],
  imports: [
    AdminRoutingModule,
    CommonModule,
    RouterOutlet,
    SharedModule,
    CustomizerSettingsComponent,
    RouterLink,
    ReactiveFormsModule,
    FormsModule,
    CoreModule,
    ClipboardModule,
    TableDataCountComponent
  ],
})
export class AdminModule { }
