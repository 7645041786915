import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { RouterLink } from '@angular/router';
import { NgClass, NgIf } from '@angular/common';
import { CustomizerSettingsService } from '../../../../../customizer-settings/customizer-settings.service';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ProvinceService } from '../../../services/province.service';
import { Province } from '../../../../../models/domain/province';
import { ObjectUtility } from '../../../../core/utilities/ObjectUtility';
import { catchError, of, Subject, takeUntil } from 'rxjs';
import { ToasterService } from '../../../../core/services/toaster.service';
import { whitespaceValidator } from '../../../../core/validators/ValidationService';
import { LoadingService } from '../../../../core/services/loading.service';

@Component({
  selector: 'app-province-details',
  templateUrl: './province-details.component.html',
  styleUrl: './province-details.component.scss'
})
export class ProvinceDetailsComponent implements OnInit {

  @Output() classApplied = new EventEmitter<boolean>();
  @Output() saveConfirmationEvent = new EventEmitter<boolean>();
  @Input() data!: Province;
  // isToggled
  isToggled = false;
  frmGroup: FormGroup;

  private destroy$: Subject<boolean> = new Subject<boolean>();
  public provinceOriginal = new Province;
  public province: Province = new Province;
  public titleText = "";
  public titleButton = "";
  public errorMessage = "";
  public currentProvinceId: number = 0;
  isSubmitted = false;

  constructor(
    public themeService: CustomizerSettingsService,
    private formBuilder: FormBuilder,
    private provinceService: ProvinceService,
    private toasterService: ToasterService,
    private loadingService: LoadingService
  ) {
    this.initialize();
  }

  public ngOnInit(): void {
    if (this.data) {
      this.currentProvinceId = this.data.id ? this.data.id : 0;
      this.province = ObjectUtility.deepClone(this.data);
      this.provinceOriginal = ObjectUtility.deepClone(this.data);
      this.setForm(this.province);
    }
    else {
      this.currentProvinceId = 0;
      const newProvince = new Province();
      this.province = ObjectUtility.deepClone(newProvince);
      this.provinceOriginal = ObjectUtility.deepClone(newProvince);
      this.setForm(this.province);
    }
  }

  private initialize(): void {
    this.themeService.isToggled$.subscribe(isToggled => {
      this.isToggled = isToggled;
    });
    this.isSubmitted = false;
    this.createForm();
  }

  public ngAfterViewInit(): void {

    this.frmGroup.controls['nameControl'].valueChanges.subscribe(value => {
      this.province.name = value;
    });
    this.frmGroup.controls['codeControl'].valueChanges.subscribe(value => {
      this.province.code = value;
    });


  }

  public ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  get f() { return this.frmGroup.controls; }

  private createForm(): void {
    this.frmGroup = this.formBuilder.group({
      nameControl: new FormControl('', [Validators.required, whitespaceValidator()]),
      codeControl: new FormControl('', [whitespaceValidator()]),
    })
  }

  private setForm(province: Province): void {
    if (province) {
      this.frmGroup.patchValue({
        nameControl: province.name,
        codeControl: province.code,
      });
    }
    this.titleText = `${this.currentProvinceId === 0 ? "Add" : "Edit"} Province`;
    this.titleButton = `${this.currentProvinceId === 0 ? "Create" : "Update"}`;
  }

  public onSave(): boolean {
    this.isSubmitted = true;
    this.loadingService.start(true);

    if (this.frmGroup.valid) {
      this.addEditProvince();
      return true;
    }
    else {
      this.loadingService.start(false);
      return false;
    }
  }

  private addEditProvince(): void {

    if (this.province?.id === 0) {
      this.provinceService.create(this.province)
        .pipe(
          catchError((err: any) => {

            if (err && err.error) {
              this.loadingService.start(false);
              this.isSubmitted = false;
              if (err.error.isCustom) {
                this.errorMessage = err.error.message;
                this.toasterService.warning(err.error.message);
              } else {
                this.toasterService.error('Something went wrong!');
              }
              //this.toasterService.error(err.error.mesage);
            } else {
              this.loadingService.start(false);
              this.isSubmitted = false;
              this.toasterService.error('Something went wrong!');
            }

            return of(null);
          }),
          takeUntil(this.destroy$))
        .subscribe(response => {
          if (response) {
            this.isSubmitted = false;
            this.province = response?.body != null ? response?.body : new Province;
            this.saveConfirmationEvent.emit(true);
            this.toggleClass();
            this.toasterService.success("Save successfully");
            // close popup
            this.loadingService.start(false);
          }
        });
    } else {
      this.provinceService.update(this.province)
        .pipe(
          catchError((err: any) => {
            if (err && err.error) {
              this.loadingService.start(false);
              this.isSubmitted = false;
              if (err.error.isCustom) {
                this.errorMessage = err.error.message;
                this.toasterService.warning(err.error.message);
              } else {
                this.toasterService.error('Something went wrong!');
              }
              //this.toasterService.error(err.error.mesage);
            } else {
              this.loadingService.start(false);
              this.isSubmitted = false;
              this.toasterService.error('Something went wrong!');
            }
            return of(null);
          }),
          takeUntil(this.destroy$))
        .subscribe(response => {
          if (response) {
            this.isSubmitted = false;
            this.province = response?.body != null ? response?.body : new Province;
            this.saveConfirmationEvent.emit(true);
            this.toasterService.success("Update successfully");
            this.toggleClass();
            this.loadingService.start(false);
          }
        });
    }
  }


  public toggleClass(): void {
    this.classApplied.emit(false);
  }



}