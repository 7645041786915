<div class="search-container">
  <input type="text" [(ngModel)]="searchQuery" (input)="onSearchChange()" placeholder="Search for service..."
    class="form-control" />

  <!-- Display filtered items as a list -->
  <div class="filtered-results" *ngIf="filteredItems.length > 0">
    <ul>
      <li *ngFor="let item of filteredItems" (click)="selectChip(item)">
        {{ item.name }} <!-- Adjust to match your data structure -->
      </li>
    </ul>
  </div>

  <!-- Display selected chips -->
  <div class="chips-container" *ngIf="selectedChips.length > 0">
    <div class="chip badge pill-badge rounded-pill text-outline-primary" *ngFor="let chip of selectedChips">
      <span>{{ chip.name }}</span> <!-- Adjust to your data structure -->
      <button class="chip-close" (click)="removeChip(chip)">x</button>
    </div>
  </div>