export class BaseRecord<T> {
    id: T | undefined;
    createdOn: string;
    updatedOn: string;
    isDeleted: boolean;


    constructor() {
        this.id = undefined;
        this.createdOn = new Date().toISOString();
        this.updatedOn = new Date().toISOString();
        this.isDeleted = false;
    }
}
