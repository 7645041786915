<div
    class="revenue-growth-card trezo-card mb-25 border-radius d-block bg-white border-0 shadow-none"
    [class.component-rtl-enabled]="themeService.isRTLEnabled()"
>
    <div class="trezo-card-content">
        <div class="title">
            <span class="d-block">
                Total Client
            </span>
            <h5 class="mb-0">
                {{totalClients}}
            </h5>
        </div>
        <div class="chart-container">
            <apx-chart
                [grid]="chartOptions.grid!"
                [chart]="chartOptions.chart!"
                [xaxis]="chartOptions.xaxis!"
                [yaxis]="chartOptions.yaxis!"
                [title]="chartOptions.title!"
                [labels]="chartOptions.labels!"
                [stroke]="chartOptions.stroke!"
                [colors]="chartOptions.colors!"
                [legend]="chartOptions.legend!"
                [series]="chartOptions.series!"
                [subtitle]="chartOptions.subtitle!"
                [dataLabels]="chartOptions.dataLabels!"
            ></apx-chart>
        </div>
        <div class="info-container d-flex align-items-center justify-content-between">
            <span class="info-badge d-inline-block" [ngClass]="{'down': percentageChange < 0}">
                {{ percentageChange > 0 ? '+' : '' }}{{ percentageChange.toFixed(2) }}%
            </span>
            <span class="days d-block">
                Last {{ dataRage.split('days')[0] }} days
            </span>
        </div>
    </div>
</div>