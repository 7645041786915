import { BaseRecord } from "./BaseRecord";
import { Town } from "./town";


export class Suburb extends BaseRecord<number> {
    public name: string;
    public townId: number;
    public town: Town | null;

    constructor() {
        super();
        this.id = 0;
        this.townId = 0;
        this.name = "";
        this.town = new Town;
    }
}
