import { Component, EventEmitter, Input, OnInit, Output, AfterViewInit, OnDestroy } from '@angular/core';
import { RouterLink } from '@angular/router';
import { NgClass, NgIf } from '@angular/common';
import { CustomizerSettingsService } from '../../../../../../customizer-settings/customizer-settings.service';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { District } from '../../../../../../models/domain/district';
import { ObjectUtility } from '../../../../../core/utilities/ObjectUtility';
import { catchError, forkJoin, of, Subject, takeUntil } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { whitespaceValidator } from '../../../../../core/validators/ValidationService';
import { Province } from '../../../../../../models/domain/province';
import { ProvinceService } from '../../../../services/province.service';
import { DistrictService } from '../../../../services/district.service';
import { LoadingService } from '../../../../../core/services/loading.service';
import { BaseSearchOptions } from '../../../../../../models/core/table/BaseSearchOptions';

@Component({
  selector: 'app-district-details',
  templateUrl: './district-details.component.html',
  styleUrl: './district-details.component.scss'
})
export class DistrictDetailsComponent implements OnInit, AfterViewInit, OnDestroy {

  @Output() classApplied = new EventEmitter<boolean>();
  @Output() saveConfirmationEvent = new EventEmitter<boolean>();
  @Input() currentDistrictId = 0;
  // isToggled
  isToggled = false;
  frmGroup: FormGroup;

  private destroy$: Subject<boolean> = new Subject<boolean>();
  public districtOriginal = new District;
  public district: District = new District;
  public titleText = "";
  public titleButton = "";
  public errorMessage = "";

  isSubmitted = false;

  // drop down bind data
  public provinces: Province[];
  public pageIndex = 0;
  public pageSize = 1000;
  public searchOptions: BaseSearchOptions;

  constructor(
    public themeService: CustomizerSettingsService,
    private formBuilder: FormBuilder,
    private districtService: DistrictService,
    private toasterService: ToastrService,
    private provinceService: ProvinceService,
    private loadingService: LoadingService
  ) {
    this.initialize();
  }

  public ngOnInit(): void {

    if (this.currentDistrictId > 0) {
      this.getDropdownData();
      this.getDistrictById(this.currentDistrictId);
    }
    else {
      this.currentDistrictId = 0;
      this.getDropdownData();
      const newDistrict = new District();
      this.district = ObjectUtility.deepClone(newDistrict);
      this.districtOriginal = ObjectUtility.deepClone(newDistrict);
      this.setForm(this.district);
    }
  }

  private initialize(): void {
    this.themeService.isToggled$.subscribe(isToggled => {
      this.isToggled = isToggled;
    });
    this.isSubmitted = false;
    this.searchOptions = new BaseSearchOptions(undefined, 'name', 0, this.pageIndex, this.pageSize);
    this.createForm();
  }

  public ngAfterViewInit(): void {

    this.frmGroup.controls['nameControl'].valueChanges.subscribe(value => {
      this.district.name = value;
    });
    this.frmGroup.controls['provinceControl'].valueChanges.subscribe(value => {
      this.district.province = value;
    });

  }

  public ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  get f() { return this.frmGroup.controls; }

  private createForm(): void {
    this.frmGroup = this.formBuilder.group({
      nameControl: new FormControl('', [Validators.required, whitespaceValidator()]),
      provinceControl: new FormControl(null, [Validators.required]),
    })
  }

  private setForm(district: District): void {
    if (district && district.id != undefined && district.id > 0) {
      this.frmGroup.patchValue({
        nameControl: district?.name,
        provinceControl: district?.province,
      });
    }
    this.titleText = `${this.currentDistrictId === 0 ? "Add" : "Edit"} District`;
    this.titleButton = `${this.currentDistrictId === 0 ? "Create" : "Update"}`;
  }

  public onSave(): void {
    this.isSubmitted = true;


    if (this.frmGroup.valid) {
      this.loadingService.showLoading();
      this.addEditDistrict();
    }
  }

  private addEditDistrict(): void {
    if (this.district?.id === 0) {
      this.districtService.create(this.sanitizeDistrict(this.district))
        .pipe(
          catchError((err: any) => {
            if (err && err.error) {
              this.isSubmitted = false;
              if (err.error.isCustom) {
                this.errorMessage = err.error.message;
                this.toasterService.warning(err.error.message);
              } else {
                this.toasterService.error('Something went wrong!');
              }

            } else {
              this.isSubmitted = false;
              this.toasterService.error('Something went wrong!');
            }
            return of(null);
          }),
          takeUntil(this.destroy$))
        .subscribe(response => {
          if (response) {
            this.isSubmitted = false;
            this.district = response?.body != null ? response?.body : new District;
            this.saveConfirmationEvent.emit(true);
            this.toggleClass();
            this.toasterService.success("Save successfully");
          }
        });
    } else {
      this.districtService.update(this.sanitizeDistrict(this.district))
        .pipe(
          catchError((err: any) => {
            if (err && err.error) {
              this.isSubmitted = false;
              if (err.error.isCustom) {
                this.errorMessage = err.error.message;
                this.toasterService.warning(err.error.message);
              } else {
                this.toasterService.error('Something went wrong!');
              }
            } else {
              this.isSubmitted = false;
              this.toasterService.error('Something went wrong!');
            }
            return of(null);
          }),
          takeUntil(this.destroy$))
        .subscribe(response => {
          if (response) {
            this.isSubmitted = false;
            this.district = response?.body != null ? response?.body : new District;
            this.saveConfirmationEvent.emit(true);
            this.toasterService.success("Update successfully");
            this.toggleClass();
          }
        });
    }
  }


  public toggleClass(): void {
    this.classApplied.emit(false);
  }

  private getDropdownData() {
    const province = this.provinceService.get(this.searchOptions);
    this.loadingService.showLoading();
    forkJoin([province])
      .pipe(takeUntil(this.destroy$)).subscribe((result: any) => {
        if (result) {
          this.provinces = result[0].body.values;
        }
        this.loadingService.hideLoading();
      });
  }

  private sanitizeDistrict(district: District): District {
    district.province = null;
    district.provinceId = this.frmGroup.controls['provinceControl'].value.id;

    return district;
  }

  public provinceComparer(t1: Province, t2: Province): boolean {
    return t1 && t2 ? (t1.id === t2.id || t1.code === t2.code) : t1 === t2;
  }

  public dataHasChanged(): boolean {
    const baseEqual = ObjectUtility.deepEqual(this.district, this.districtOriginal);
    return !baseEqual;
  }

  public formIsValid(): boolean {
    const isValid = this.frmGroup.controls['provinceControl'].valid && this.frmGroup.controls['nameControl'].valid
    return isValid;
  }

  public getDistrictById(districtId: number) {
    this.loadingService.showLoading();
    this.districtService.getById(districtId)
      .pipe(takeUntil(this.destroy$))
      .subscribe(result => {
        if (result) {
          this.district = ObjectUtility.deepClone(result);
          this.districtOriginal = ObjectUtility.deepClone(result);
          this.setForm(this.district);
        }
        this.loadingService.hideLoading();
      });
  }


}