import { AbstractControl, ValidationErrors, ValidatorFn, FormGroup } from "@angular/forms";

export function phoneFieldValidation(): ValidatorFn {
    const phoneRegEx = /^\d{10}$|^\d{3}[\s-]\d{3}[\s-]\d{4}$/;
    return (control: AbstractControl): ValidationErrors | null => {
        const value: string = control.value;
        if (value && !phoneRegEx.test(value)) {
            return { phoneInvalidFormat: true };
        }
        return null;
    };
}

export function emailValidation(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
        const emailRegEx = /^[a-zA-Z0-9.-_]{1,}@[a-zA-Z.-]{2,}[.]{1}[a-zA-Z]{2,}$/;
        const value: string = control.value;
        if (value && !emailRegEx.test(value)) {
            return { email: true };
        }
        return null;
    };
}

export function dateValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
        return (control.value === "" || control.value === null) ? {
            invalidForm: { valid: false, message: "Date is required" }
        } : null;
    };
}

export function whitespaceValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
        if (control.value === "" || control.value === null) {
            return null;
        }

        const cleanValue = (control.value as string).trim();
        const onlyWhitespace = cleanValue.length === 0;

        return onlyWhitespace ? { onlyWhitespace: true } : null;
    };
}

export function whitespaceExistsValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
        if (control.value === "" || control.value === null) {
            return null;
        }

        const cleanValue = (control.value as string).trim();
        const whiteSpaceExists = cleanValue.includes(" ");

        return whiteSpaceExists ? { whiteSpaceExists: true } : null;
    };
}

export function confirmPasswordValidator(controlName: string, matchingControlName: string) {
    return (formGroup: FormGroup) => {
        const control = formGroup.controls[controlName];
        const matchingControl = formGroup.controls[matchingControlName];

        if (matchingControl.errors && !matchingControl.errors["mustMatch"]) {
            // return if another validator has already found an error on the matchingControl
            return;
        }

        // set error on matchingControl if validation fails
        if (control.value !== matchingControl.value) {
            matchingControl.setErrors({ mustMatch: true });
        } else {
            matchingControl.setErrors(null);
        }
    }
}

export function confirmPasswordValidatorOld(otherInputControl: AbstractControl): ValidatorFn {
    return (inputControl: AbstractControl): ValidationErrors | null => {
        if (inputControl.value !== undefined && inputControl.value.trim() != ""
            && inputControl.value !== otherInputControl.value) {
            return { confirmedValidator: true };
        }
        return null;
    };
}

export function passwordValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
        if (control.value === "" || control.value === null) {
            return null;
        }

        const hasCharacters = control.value.length;
        const hasLower = /[a-z]+/.test(control.value);
        const hasUpper = /[A-Z]+/.test(control.value);
        const numbers = /[0-9]+/.test(control.value);
        const regex = /[$&+,_"{}/:;=?@#|'<>.^*()%!-]/g;
        const symbols = regex.test(control.value);

        if(hasCharacters < 8)
            return { hasCharacters: true };

        if(!hasLower)
            return { hasLower: true };

        if(!hasUpper)
            return { hasUpper: true };

        if(!symbols)
            return { hasSpecialChar: true };

        if(!numbers)
            return { hasNumber: true };

        return null;
    };
}

export function usaPostalCodeValidation(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
        const usaPostalRegEx = /^(^\d{5}$)|(^\d{9}$)|(^\d{5}-\d{4}$)$/;
        const value: string = control.value;
        if (value && !usaPostalRegEx.test(value)) {
            return { postalCodeInvalidFormat: true };
        }
        return null;
    };
}

export function canadaPostalCodeValidation(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
        const canadaPostalRegEx = /^[A-Za-z]\d[A-Za-z][ -]?\d[A-Za-z]\d$/;
        const value: string = control.value;
        if (value && !canadaPostalRegEx.test(value)) {
            return { postalCodeInvalidFormat: true };
        }
        return null;
    };
}

export function year4LengthValidation(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
        const year4LengthRegEx = /^[0-9]{4}$/;
        const value: string = control.value;
        if (value && !year4LengthRegEx.test(value)) {
            return { year4LengthInvalidFormat: true };
        }
        return null;
    };
}

export function atleast5CharacterLengthValidation(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
        const atleast5CharacterLengthRegEx = /^.{5,}$/;
        const value: string = control.value;
        if (value && !atleast5CharacterLengthRegEx.test(value)) {
            return { atleast5CharacterLengthInvalidFormat: true };
        }
        return null;
    };
}
