import { Component, OnInit } from '@angular/core';
import { CustomizerSettingsService } from '../../../../customizer-settings/customizer-settings.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { confirmPasswordValidator } from '../../validators/ValidationService';

@Component({
    selector: 'app-reset-password',
    templateUrl: './reset-password.component.html',
    styleUrl: './reset-password.component.scss'
})
export class ResetPasswordComponent implements OnInit{

    // isToggled
    isToggled = false;
     // Password Show/Hide
     isOldPasswordVisible = false;
     isNewPasswordVisible = false;
     isConfirmPasswordVisible = false;

     resetPassForm!: FormGroup;
     submitted = false;

    constructor(
        public themeService: CustomizerSettingsService,
        private formBuilder: FormBuilder
    ) {
        this.themeService.isToggled$.subscribe(isToggled => {
            this.isToggled = isToggled;
        });
    }

    ngOnInit(): void {
        this.initializer();
      }

      initializer(){
        this.resetPassForm = this.formBuilder.group({
            oldPassword: ['', Validators.required],
            newPassword: ['', Validators.required],
            confirmPassword: ['', Validators.required],
          },
          {
            validator: confirmPasswordValidator('newPassword', 'confirmPassword')
          });
    }

   
    toggleOldPasswordVisibility(): void {
        this.isOldPasswordVisible = !this.isOldPasswordVisible;
    }
    toggleNewPasswordVisibility(): void {
        this.isNewPasswordVisible = !this.isNewPasswordVisible;
    }
    toggleConfirmPasswordVisibility(): void {
        this.isConfirmPasswordVisible = !this.isConfirmPasswordVisible;
    }
    onOldPasswordInput(event: Event): void {
        const inputElement = event.target as HTMLInputElement;
        this.f['oldPassword'].setValue(inputElement.value);
    }
    onNewPasswordInput(event: Event): void {
        const inputElement = event.target as HTMLInputElement;
        this.f['newPassword'].setValue(inputElement.value);
    }
    onConfirmPasswordInput(event: Event): void {
        const inputElement = event.target as HTMLInputElement;
        this.f['confirmPassword'].setValue(inputElement.value);
    }

     // convenience getter for easy access to form fields
     get f() { return this.resetPassForm.controls; }

     /**
   * Form submit
   */
  onSubmit() {
    this.submitted = true;
    // stop here if form is invalid
    if (this.resetPassForm.invalid) {
      return;
    }
  }

}