import { Component, OnInit } from '@angular/core';
import { CustomizerSettingsService } from '../../../../../../customizer-settings/customizer-settings.service';
import { ProvinceService } from '../../../../services/province.service';
import { TableColumn } from '../../../../../../models/core/table/TableColumn';
import { BaseSearchOptions } from '../../../../../../models/core/table/BaseSearchOptions';
import { TableActionButton } from '../../../../../../models/core/table/TableActionButton';
import { QueryBuilderParams } from '../../../../../../models/core/table/QueryBuilderParams';
import { EventTypes } from '../../../../../../models/enums/EventTypes';
import { GroupType } from '../../../../../../models/enums/GroupType';
import { TableEvent } from '../../../../../../models/core/table/TableEvent';
import { Province } from '../../../../../../models/domain/province';
import { catchError, filter, switchMap, take } from 'rxjs/operators';
import { ModalText } from '../../../../../../models/enums/ModalText';
import { ModalService } from '../../../../../core/services/modal.service';
import { TableSearchEventData } from '../../../../../../models/core/table/TableSearchEventData';
import { FilterDataType } from '../../../../../../models/core/table/FilterDataType';
import { getSearchOperator } from '../../../../../core/utilities/common-functions';
import { of } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { RoleService } from '../../../../../shared/services/roleService';
import { LoadingService } from '../../../../../core/services/loading.service';

@Component({
    selector: 'app-province-list',
    templateUrl: './province-list.component.html',
    styleUrl: './province-list.component.scss'
})
export class ProvinceListComponent implements OnInit {

    //#region Table Variables
    public columns: TableColumn[];
    public actions: TableActionButton[];
    public totalRecords = 0;
    public pageIndex = 0;
    public pageSize = 10;
    public pageSizeOptions: number[] = [10, 50, 100];
    public searchOptions: BaseSearchOptions;
    //#endregion

    provinceId = 0;
    province: Province;

    // Popup Trigger
    classApplied = false;
    // isToggled
    isToggled = false;
    showModal = false;

    ConfirmText = ModalText.ConfirmYes
    CancelText = ModalText.ConfirmNo

    constructor(
        public themeService: CustomizerSettingsService,
        public provinceService: ProvinceService,
        private modalService: ModalService,
        private toasterService: ToastrService,
        private roleService: RoleService,
        private loadingService: LoadingService
    ) {
        this.initialize();
    }

    ngOnInit(): void {
        this.getData();
    }

    private initialize(): void {
        this.themeService.isToggled$.subscribe(isToggled => {
            this.isToggled = isToggled;
        });

        this.searchOptions = new BaseSearchOptions(undefined, 'name', 0, this.pageIndex, this.pageSize);

        this.buildTable();
    }

    private getData(): void {
        this.loadingService.showLoading();
        this.provinceService.get(this.searchOptions).pipe(
            take(1),
            catchError((error) => {
                if (error && error.error) {
                    if (error.error.isCustom) {
                        this.toasterService.warning(error.error.message);
                    } else {
                        this.toasterService.error('Something went wrong!');
                    }
                } else {
                    this.toasterService.error('Something went wrong!');
                }
                return of(null);
            })
        ).subscribe(result => {
            if (result) {
                this.totalRecords = result.body?.totalCount ?? 0;
            }
            this.loadingService.hideLoading();
        });
    }

    public buildTable(): void {
        this.columns = [
            new TableColumn('name', 'Name', FilterDataType.String, true, false, (data: Province) => `${data.name}`, true, { tooltip: 'Province Name' }),
            new TableColumn('code', 'Code', FilterDataType.String, true, false, (data: Province) => `${data.code}`, true, { tooltip: 'Province Code' }),
        ];

        this.actions = [];
        if (this.roleService.canUpdate()) {
            this.actions.push(new TableActionButton("edit", (event, value) => this.onEditClick(event, value), (value) => this.onDisabledClick(), "Edit", "Edit"))
        }
        if (this.roleService.canDelete()) {
            this.actions.push(new TableActionButton("delete", (event, value) => this.onDeleteClick(event, value), (value) => this.onDisabledClick(), "Delete", "Delete"))
        }
    }

    public onAddClick(): void {
        this.province = new Province;
        this.toggleClass();

    }

    public onEditClick(event: MouseEvent, data: Province): void {
        event.stopPropagation();
        this.province = data;
        this.toggleClass();
    }

    public onDeleteClick(event: MouseEvent, data: Province): void {
        event.stopPropagation();
        this.modalService.openModal(
            'Delete Confirmation',
            `Are you sure you want to delete <strong> ${data.name} </strong> ?`,
            ModalText.ConfirmYes,
            ModalText.ConfirmNo
        );

        this.modalService.onConfirm.pipe(
            take(1),
            filter((isConfirm) => isConfirm && !!data.id),
            switchMap(() => {
                this.loadingService.showLoading();
                return this.provinceService.delete(data.id).pipe(
                    catchError((error) => {
                        const message = error?.error?.isCustom
                            ? error.error.message
                            : 'Something went wrong!';
                        this.toasterService.error(message);
                        return of(null); // Gracefully handle errors
                    })
                )
            })
        ).subscribe((result) => {
            if (result?.body) {
                this.toasterService.info('Record deleted successfully.');
                // Optimized: Remove the deleted record from the list instead of refetching
                // Filter the data in the service and update the BehaviorSubject
                this.provinceService.removeDataById(data.id as number);
                this.totalRecords = this.provinceService.dataCount;//result.body?.totalCount ?? 0;
            }
            this.loadingService.hideLoading();
        });
    }


    public onDisabledClick(): boolean {
        return false;
    }

    public tableEvent(event: TableEvent<any>): void {
        if (event.type === EventTypes.TABLE) {
            const eventValue = event.data as TableSearchEventData;

            this.searchOptions.updateByObject(eventValue.baseSearchOptions);

            if (eventValue.columnFilterValues) {
                this.createFilter(eventValue);
            }
            else {
                this.searchOptions.filter = '';
            }
            this.getData();
        }
        if (event.type === EventTypes.CLEAR) {
            this.clearFilters();
            this.getData();
        }
    }

    public clearFilters(): void {
        this.pageIndex = 0;
        this.pageSize = 10;
        this.searchOptions = new BaseSearchOptions(undefined, 'name', 0, this.pageIndex, this.pageSize);
    }

    private createFilter(eventValue: TableSearchEventData): void {
        const queryParams: QueryBuilderParams[] = [];

        for (const [columnName, { dataType, opType, searchValue }] of Object.entries(eventValue.columnFilterValues)) {
            const searchOperator = getSearchOperator(opType.toString());
            if (!searchOperator) continue; // Skip if no valid operator

            queryParams.push({
                fieldName: columnName, // Adjust as necessary for your field mapping
                dataType: dataType,
                searchOperator,
                searchValue: searchValue,
                groupType: GroupType.OR
            });
        }

        this.searchOptions.filter = this.searchOptions.generateQuery(queryParams);
    }

    public toggleClass(): void {
        this.classApplied = !this.classApplied;
    }

    public classAppliedEvent(flag: boolean): void {
        this.classApplied = flag;
    }

    public saveConfirmationEvent(isSaved: boolean): void {
        if (isSaved) {
            this.getData();
        }

    }




}
