<div class="popup-dialog" *ngIf="visible">
    <div class="trezo-card w-100 border-radius d-block bg-white border-0 shadow-none">
        <div class="trezo-card-header d-flex align-items-center justify-content-between">
            <div class="trezo-card-title" [ngClass]="getToastClass()">
                <h5 class="mb-0 fontcolor">
                    {{ message }}
                </h5>
            </div>
            <div class="trezo-card-subtitle">
                <button (click)="close()" class="p-0 border-0 lh-1 bg-transparent transition">
                    <i class="ri-close-fill"></i>
                </button>
            </div>
        </div>
    </div>
</div>