import { BaseRecord } from "./BaseRecord";
import { PngIdentityRole } from "./pngIdentityRole";

export class PngIdentityUser extends BaseRecord<number> {
    public userName: string;
    public fullName: string;
    public firstName: string;
    public lastName: string;
    public email: string;
    public emailConfirmed: boolean;
    public phoneNumber: string;
    public phoneConfirmed: boolean;
    public passwordHash: string;
    public twoFactorEnabled: boolean;
    public roles: string[] = [];//PngIdentityRole[];


    constructor() {
        super();
        this.id = 0;
        this.email = "";
        this.twoFactorEnabled = false;
        this.emailConfirmed = false;
        this.phoneNumber = "";
        this.phoneConfirmed = false;
        this.passwordHash = "";
        this.userName = "";
        this.firstName = "";
        this.lastName = "";
        this.fullName = "";
        this.roles = [];
    }
}
