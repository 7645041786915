import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { RouterLink } from '@angular/router';
import { NgClass, NgIf } from '@angular/common';
import { CustomizerSettingsService } from '../../../../../customizer-settings/customizer-settings.service';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Suburb } from '../../../../../models/domain/suburb';
import { ObjectUtility } from '../../../../core/utilities/ObjectUtility';
import { catchError, forkJoin, of, Subject, takeUntil } from 'rxjs';
import { ToasterService } from '../../../../core/services/toaster.service';
import { whitespaceValidator } from '../../../../core/validators/ValidationService';
import { Town } from '../../../../../models/domain/town';
import { LoadingService } from '../../../../core/services/loading.service';
import { SuburbService } from '../../../services/suburb.service';
import { TownService } from '../../../services/town.service ';
import { BaseSearchOptions } from '../../../../../models/core/table/BaseSearchOptions';

@Component({
  selector: 'app-suburb-details',
  templateUrl: './suburb-details.component.html',
  styleUrl: './suburb-details.component.scss'
})
export class SuburbDetailsComponent implements OnInit {

  @Output() classApplied = new EventEmitter<boolean>();
  @Output() saveConfirmationEvent = new EventEmitter<boolean>();
  @Input() data!: Suburb;
  // isToggled
  isToggled = false;
  frmGroup: FormGroup;

  private destroy$: Subject<boolean> = new Subject<boolean>();
  public suburbOriginal = new Suburb;
  public suburb: Suburb = new Suburb;
  public titleText = "";
  public titleButton = "";
  public errorMessage = "";
  public currentSuburbId: number = 0;
  isSubmitted = false;

  // drop down bind data
  public towns: Town[];
  public pageIndex: number = 0;
  public pageSize: number = 1000;
  public searchOptions: BaseSearchOptions;

  constructor(
    public themeService: CustomizerSettingsService,
    private formBuilder: FormBuilder,
    private suburbService: SuburbService,
    private toasterService: ToasterService,
    private townService: TownService,
    private loadingService: LoadingService
  ) {
    this.initialize();
  }

  public ngOnInit(): void {
    if (this.data) {
      this.currentSuburbId = this.data.id ? this.data.id : 0;
      this.getDropdownData();
      this.suburb = ObjectUtility.deepClone(this.data);
      this.suburbOriginal = ObjectUtility.deepClone(this.data);
      this.setForm(this.suburb);
    }
    else {
      this.currentSuburbId = 0;
      this.getDropdownData();
      const newSuburb = new Suburb();
      this.suburb = ObjectUtility.deepClone(newSuburb);
      this.suburbOriginal = ObjectUtility.deepClone(newSuburb);
      this.setForm(this.suburb);
    }
  }

  private initialize(): void {
    this.themeService.isToggled$.subscribe(isToggled => {
      this.isToggled = isToggled;
    });
    this.isSubmitted = false;
    this.searchOptions = new BaseSearchOptions(undefined, undefined, 0, this.pageIndex, this.pageSize);
    this.createForm();
  }

  public ngAfterViewInit(): void {

    this.frmGroup.controls['nameControl'].valueChanges.subscribe(value => {
      this.suburb.name = value;
    });
    this.frmGroup.controls['townControl'].valueChanges.subscribe(value => {
      this.suburb.town = value;
    });

  }

  public ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  get f() { return this.frmGroup.controls; }

  private createForm(): void {
    this.frmGroup = this.formBuilder.group({
      nameControl: new FormControl('', [Validators.required, whitespaceValidator()]),
      townControl: new FormControl('', [Validators.required]),
    })
  }

  private setForm(suburb: Suburb): void {
    if (suburb) {
      this.frmGroup.patchValue({
        nameControl: suburb?.name,
        townControl: suburb?.town,
      });
    }
    this.titleText = `${this.currentSuburbId === 0 ? "Add" : "Edit"} Suburb`;
    this.titleButton = `${this.currentSuburbId === 0 ? "Create" : "Update"}`;
  }

  public onSave(): boolean {
    this.isSubmitted = true;
    this.loadingService.start(true);

    if (this.frmGroup.valid) {
      this.addEditSuburb();
      return true;
    }
    else {
      this.loadingService.start(false);
      return false;
    }
  }

  private addEditSuburb(): void {
    if (this.suburb?.id === 0) {
      this.suburbService.create(this.sanitizeSuburb(this.suburb))
        .pipe(
          catchError((err: any) => {
            if (err && err.error) {
              this.loadingService.start(false);
              this.isSubmitted = false;
              if (err.error.isCustom) {
                this.errorMessage = err.error.message;
                this.toasterService.warning(err.error.message);
              } else {
                this.toasterService.error('Something went wrong!');
              }
              //this.toasterService.error(err.error.mesage);
            } else {
              this.loadingService.start(false);
              this.isSubmitted = false;
              this.toasterService.error('Something went wrong!');
            }
            return of(null);
          }),
          takeUntil(this.destroy$))
        .subscribe(response => {
          if (response) {
            this.isSubmitted = false;
            this.suburb = response?.body != null ? response?.body : new Suburb;
            this.saveConfirmationEvent.emit(true);
            this.toggleClass();
            this.toasterService.success("Save successfully");
            // close popup
            this.loadingService.start(false);
          }
        });
    } else {
      this.suburbService.update(this.sanitizeSuburb(this.suburb))
        .pipe(
          catchError((err: any) => {
            if (err && err.error) {
              this.loadingService.start(false);
              this.isSubmitted = false;
              if (err.error.isCustom) {
                this.errorMessage = err.error.message;
                this.toasterService.warning(err.error.message);
              } else {
                this.toasterService.error('Something went wrong!');
              }
              //this.toasterService.error(err.error.mesage);
            } else {
              this.loadingService.start(false);
              this.isSubmitted = false;
              this.toasterService.error('Something went wrong!');
            }
            return of(null);
          }),
          takeUntil(this.destroy$))
        .subscribe(response => {
          if (response) {
            this.isSubmitted = false;
            this.suburb = response?.body != null ? response?.body : new Suburb;
            this.saveConfirmationEvent.emit(true);
            this.toasterService.success("Update successfully");
            this.toggleClass();
            this.loadingService.start(false);
          }
        });
    }
  }


  public toggleClass(): void {
    this.classApplied.emit(false);
  }

  private getDropdownData() {
    this.loadingService.start(true);
    const town = this.townService.get(this.searchOptions);

    forkJoin([town])
      .pipe(takeUntil(this.destroy$)).subscribe((result: any) => {
        if (result) {
          this.towns = result[0].body.values;
        }

        this.loadingService.start(false);
      });
  }

  private sanitizeSuburb(suburb: Suburb): Suburb {
    suburb.town = null;
    suburb.townId = this.frmGroup.controls['townControl'].value.id;

    return suburb;
  }

  public townComparer(t1: Town, t2: Town): boolean {
    return t1 && t2 ? (t1.id === t2.id || t1.name === t2.name) : t1 === t2;
  }

}