import { BaseRecord } from "./BaseRecord";
import { ServiceType } from "./serviceType";

export class Service extends BaseRecord<number> {
    public name: string;
    public description: string;
    public serviceTypeId: number;
    public serviceType: ServiceType | null;


    constructor() {
        super();
        this.id = 0;
        this.name = "";
        this.description = "";
        this.serviceTypeId = 0;
        this.serviceType = new ServiceType;
    }
}
