<!-- <div class="modal">
    <div class="modal-content">
        <h2>{{ title }}</h2>
        <p>{{ message }}</p>
        <button (click)="onConfirm()">{{ confirmText }}</button>
        <button (click)="onCancel()">{{ cancelText }}</button>
    </div>
</div> -->


<div class="popup-dialog">
    <div class="trezo-card w-100 border-radius d-block bg-white border-0 shadow-none">
        <div class="trezo-card-header d-flex align-items-center justify-content-between">
            <div class="trezo-card-title">
                <h5 class="mb-0">
                    {{ modalService.title }}
                </h5>
            </div>
            <div class="trezo-card-subtitle">
                <button (click)="onCancel()" class="p-0 border-0 lh-1 bg-transparent transition">
                    <i class="ri-close-fill"></i>
                </button>
            </div>
        </div>


        <div class="trezo-card-content">
            <div class="row">
                <div class="col-md-12">
                    <!-- <div class="form-group">
                        <p>{{ message }}</p>
                    </div> -->
                    <div [innerHTML]="modalService.message"></div>
                </div>

            </div>
            <div class="btn-box">
                <br>
                <button (click)="onCancel()" class="warn d-inline-block fw-medium border-0 border-radius transition">
                    {{ modalService.cancelText }}
                </button>
                <button class="primary d-inline-block fw-medium border-0 border-radius transition"
                    (click)="onConfirm()">
                    {{ modalService.confirmText }}
                </button>
            </div>
        </div>
    </div>
</div>