import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { RouterLink } from '@angular/router';
import { NgClass, NgIf } from '@angular/common';
import { CustomizerSettingsService } from '../../../../../customizer-settings/customizer-settings.service';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Llg } from '../../../../../models/domain/llg';
import { ObjectUtility } from '../../../../core/utilities/ObjectUtility';
import { catchError, forkJoin, of, Subject, takeUntil } from 'rxjs';
import { ToasterService } from '../../../../core/services/toaster.service';
import { whitespaceValidator } from '../../../../core/validators/ValidationService';
import { District } from '../../../../../models/domain/district';
import { DistrictService } from '../../../services/district.service';
import { LoadingService } from '../../../../core/services/loading.service';
import { LlgService } from '../../../services/llg.service';
import { BaseSearchOptions } from '../../../../../models/core/table/BaseSearchOptions';

@Component({
  selector: 'app-llg-details',
  templateUrl: './llg-details.component.html',
  styleUrl: './llg-details.component.scss'
})
export class LlgDetailsComponent implements OnInit {

  @Output() classApplied = new EventEmitter<boolean>();
  @Output() saveConfirmationEvent = new EventEmitter<boolean>();
  @Input() data!: Llg;
  // isToggled
  isToggled = false;
  frmGroup: FormGroup;

  private destroy$: Subject<boolean> = new Subject<boolean>();
  public llgOriginal = new Llg;
  public llg: Llg = new Llg;
  public titleText = "";
  public titleButton = "";
  public errorMessage = "";
  public currentLlgId: number = 0;
  isSubmitted = false;

  // drop down bind data
  public districts: District[];
  public pageIndex: number = 0;
  public pageSize: number = 1000;
  public searchOptions: BaseSearchOptions;

  constructor(
    public themeService: CustomizerSettingsService,
    private formBuilder: FormBuilder,
    private llgService: LlgService,
    private toasterService: ToasterService,
    private districtService: DistrictService,
    private loadingService: LoadingService
  ) {
    this.initialize();
  }

  public ngOnInit(): void {
    if (this.data) {
      this.currentLlgId = this.data.id ? this.data.id : 0;
      this.getDropdownData();
      this.llg = ObjectUtility.deepClone(this.data);
      this.llgOriginal = ObjectUtility.deepClone(this.data);
      this.setForm(this.llg);
    }
    else {
      this.currentLlgId = 0;
      this.getDropdownData();
      const newLlg = new Llg();
      this.llg = ObjectUtility.deepClone(newLlg);
      this.llgOriginal = ObjectUtility.deepClone(newLlg);
      this.setForm(this.llg);
    }
  }

  private initialize(): void {
    this.themeService.isToggled$.subscribe(isToggled => {
      this.isToggled = isToggled;
    });
    this.isSubmitted = false;
    this.searchOptions = new BaseSearchOptions(undefined, undefined, 0, this.pageIndex, this.pageSize);
    this.createForm();
  }

  public ngAfterViewInit(): void {

    this.frmGroup.controls['nameControl'].valueChanges.subscribe(value => {
      this.llg.name = value;
    });
    this.frmGroup.controls['districtControl'].valueChanges.subscribe(value => {
      this.llg.district = value;
    });

  }

  public ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  get f() { return this.frmGroup.controls; }

  private createForm(): void {
    this.frmGroup = this.formBuilder.group({
      nameControl: new FormControl('', [Validators.required, whitespaceValidator()]),
      districtControl: new FormControl('', [Validators.required]),
    })
  }

  private setForm(llg: Llg): void {
    if (llg) {
      this.frmGroup.patchValue({
        nameControl: llg?.name,
        districtControl: llg?.district,
      });
    }
    this.titleText = `${this.currentLlgId === 0 ? "Add" : "Edit"} Llg`;
    this.titleButton = `${this.currentLlgId === 0 ? "Create" : "Update"}`;
  }

  public onSave(): boolean {
    this.isSubmitted = true;
    this.loadingService.start(true);

    if (this.frmGroup.valid) {
      this.addEditLlg();
      return true;
    }
    else {
      this.loadingService.start(false);
      return false;
    }
  }

  private addEditLlg(): void {
    if (this.llg?.id === 0) {
      this.llgService.create(this.sanitizeLlg(this.llg))
        .pipe(
          catchError((err: any) => {
            if (err && err.error) {
              this.loadingService.start(false);
              this.isSubmitted = false;
              if (err.error.isCustom) {
                this.errorMessage = err.error.message;
                this.toasterService.warning(err.error.message);
              } else {
                this.toasterService.error('Something went wrong!');
              }
              //this.toasterService.error(err.error.mesage);
            } else {
              this.loadingService.start(false);
              this.isSubmitted = false;
              this.toasterService.error('Something went wrong!');
            }
            return of(null);
          }),
          takeUntil(this.destroy$))
        .subscribe(response => {
          if (response) {
            this.isSubmitted = false;
            this.llg = response?.body != null ? response?.body : new Llg;
            this.saveConfirmationEvent.emit(true);
            this.toggleClass();
            this.toasterService.success("Save successfully");
            // close popup
            this.loadingService.start(false);
          }
        });
    } else {
      this.llgService.update(this.sanitizeLlg(this.llg))
        .pipe(
          catchError((err: any) => {
            if (err && err.error) {
              this.loadingService.start(false);
              this.isSubmitted = false;
              if (err.error.isCustom) {
                this.errorMessage = err.error.message;
                this.toasterService.warning(err.error.message);
              } else {
                this.toasterService.error('Something went wrong!');
              }
              //this.toasterService.error(err.error.mesage);
            } else {
              this.loadingService.start(false);
              this.isSubmitted = false;
              this.toasterService.error('Something went wrong!');
            }
            return of(null);
          }),
          takeUntil(this.destroy$))
        .subscribe(response => {
          if (response) {
            this.isSubmitted = false;
            this.llg = response?.body != null ? response?.body : new Llg;
            this.saveConfirmationEvent.emit(true);
            this.toasterService.success("Update successfully");
            this.toggleClass();
            this.loadingService.start(false);
          }
        });
    }
  }


  public toggleClass(): void {
    this.classApplied.emit(false);
  }

  private getDropdownData() {
    this.loadingService.start(true);
    const district = this.districtService.get(this.searchOptions);

    forkJoin([district])
      .pipe(takeUntil(this.destroy$)).subscribe((result: any) => {
        if (result) {
          this.districts = result[0].body.values;
        }

        this.loadingService.start(false);
      });
  }

  private sanitizeLlg(llg: Llg): Llg {
    llg.district = null;
    llg.districtId = this.frmGroup.controls['districtControl'].value.id;

    return llg;
  }

  public districtComparer(t1: District, t2: District): boolean {
    return t1 && t2 ? (t1.id === t2.id || t1.name === t2.name) : t1 === t2;
  }

}