import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { LocalStorageService, StorageKeys } from "./local-storage.service";
import { map } from "rxjs/operators";
import { environment } from "../../../../environments/environment";
import { Router } from "@angular/router";
import { AuthenticationResult } from "../../../models/other/AuthenticationResult";
import { ChangePassword } from "../../../models/other/ChangePassword";
import { UserConfirmation } from "../../../models/other/UserConfirmation";
import { PngIdentityUser } from "../../../models/domain/pngIdentityUser";
import { JWTTokenService } from "./jwttoken.service";
import { RoleEnum } from "../../../models/enums/RoleEnum";


@Injectable({
    providedIn: "root"
})
export class AuthenticationService {

    private currentAuthSubject: BehaviorSubject<AuthenticationResult | null>;
    public currentAuth$: Observable<AuthenticationResult | null>;
    private userBaseUrl = `${environment.apiUrl}/v1/identity`;
    private refreshTokenTimeout?: any;

    constructor(private http: HttpClient,
        private localStorage: LocalStorageService,
        private router: Router,
        private jwtTokenService: JWTTokenService
    ) {

        if (this.localStorage.get(StorageKeys.CurrentAuth)) {
            const token = JSON.parse(this.localStorage.get(StorageKeys.CurrentAuth) ?? "");
            this.currentAuthSubject = new BehaviorSubject<AuthenticationResult | null>(token);
            this.currentAuth$ = this.currentAuthSubject.asObservable();
        } else {
            this.currentAuthSubject = new BehaviorSubject<AuthenticationResult | null>(null);
            this.currentAuth$ = this.currentAuthSubject.asObservable();
        }

        //this.startRefreshTokenTimer();
    }

    public get userValue() {
        return this.currentAuthSubject.value;
    }

    public login(email: string, password: string, resent: boolean): Observable<AuthenticationResult> {
        const requestData = {
            email: email,
            password: password
        }

        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json; charset=utf-8',
            }),
            withCredentials: true,  // Make sure this is set to true
        };

        return this.http.post<any>(`${this.userBaseUrl}/login`, requestData, httpOptions)
            .pipe(map((result: AuthenticationResult) => {
                if (result.isSuccess) {
                    this.setUserInformationAndRoles(result);

                    this.localStorage.set(StorageKeys.Token, result.accessToken);
                    this.localStorage.set(StorageKeys.CurrentAuth, JSON.stringify(result));

                    this.currentAuthSubject.next(result);

                    //this.startRefreshTokenTimer();
                    return result;
                }
                else {
                    this.currentAuthSubject.next(result);
                    return result;
                }
            }));
    }

    public logout(): void {
        this.localStorage.remove(StorageKeys.CurrentAuth);
        this.localStorage.remove(StorageKeys.Token);
        this.currentAuthSubject.next(null);
        // this.http.post<any>(`${this.userBaseUrl}/logout`, {})
        //     .pipe(map((result: string) => {
        //     })).subscribe();

        this.router.navigate(["/account/login"]);
    }

    public isUserAuthenticated(): boolean {
        if (this.currentAuthSubject &&
            (this.currentAuthSubject.value?.user !== null && this.currentAuthSubject.value?.user != undefined)) {
            return true;
        }
        return false;
    }

    public isUserRoleAuthorized(roles: string[]): boolean {
        if (roles) {
            const availableRoles = this.getUserRoles();
            if (availableRoles.some((x) => x.toLowerCase() == RoleEnum.Admin.toLowerCase()) || roles.some((x) => availableRoles.some((y) => y.toLowerCase() === x.toLowerCase()))) {
                return true;
            }
        }
        return false;
    }

    public getUserRoles(): string []{ //PngIdentityRole[] {
        return this.getCurrentUser()?.roles || [];
    }

    public hasRole(role: string): boolean {
        return this.getUserRoles().includes(role);
      }

    public getCurrentUser(): PngIdentityUser | null {
        if (this.currentAuthSubject &&
            (this.currentAuthSubject.value?.user !== null && this.currentAuthSubject.value?.user != undefined)) {
            return this.currentAuthSubject?.value?.user;
        }
        return null;
    }

    private setUserInformationAndRoles(result: AuthenticationResult): AuthenticationResult {
        // Decode the JWT token to get the payload (claims)
        const jwtData = this.jwtTokenService.getTokenData(result.accessToken);
        if (jwtData) {
            result.user = result.user ?? new PngIdentityUser();
            result.user.roles = result.user.roles ?? [];

            result.user.userName = jwtData["UserEmail"];
            result.user.fullName = jwtData["FullName"];

            const rolesData = jwtData["http://schemas.microsoft.com/ws/2008/06/identity/claims/role"];

            if (rolesData !== undefined && Array.isArray(rolesData)) {
                // If rolesData is an array, iterate through and push the roles to the user
                rolesData.forEach((role) => {
                    result.user.roles.push(role);
                });
            } else if (rolesData !== undefined) {
                // If rolesData is a single value (not an array), just push the role
                result.user.roles.push(rolesData);
            }
        }

        return result;
    }

    public OTPVerify(otp: string, token: string): Observable<AuthenticationResult> {
        return this.http.post<any>(`${this.userBaseUrl}/otpverificationforlogin`, { otp, token })
            .pipe(map((result: AuthenticationResult) => {
                if (result.isSuccess) {
                    this.currentAuthSubject.next(result);
                    this.localStorage.set(StorageKeys.Token, result.accessToken);
                    this.localStorage.set(StorageKeys.CurrentAuth, JSON.stringify(result));
                    return result;
                } else {
                    this.currentAuthSubject.next(result);
                    return result;
                }
            }));
    }

    public getEmail(token: string): Observable<unknown> {
        const apiUrl = `${this.userBaseUrl}/email?token=${token}`;

        return this.http.get<unknown>(apiUrl);
    }

    public confirmAccount(model: UserConfirmation): Observable<unknown> {
        const apiUrl = `${this.userBaseUrl}/confirmAccount`;

        return this.http.post(apiUrl, model);
    }

    public forgotPassword(email: string): Observable<string> {
        const requestData = {
            email: email
        }
        const apiUrl = `${this.userBaseUrl}/forgotPassword`;

        return this.http.post<string>(apiUrl, requestData);
    }

    //Not in use right now
    // public sendForgotPasswordEmail(model: UserConfirmation): Observable<any> {
    //     const apiUrl = `${this.userBaseUrl}/sendForgotPasswordEmail`;
    //     return this.http.post(apiUrl, model);
    // }

    public changePassword(model: ChangePassword): Observable<any> {
        const apiUrl = `${this.userBaseUrl}/changePassword`;

        return this.http.post(apiUrl, model);
    }


    public refreshToken() {
        return this.http.post<AuthenticationResult>(`${this.userBaseUrl}/refresh-token`, {}, { withCredentials: true })
            .pipe(map((result: AuthenticationResult) => {
                this.currentAuthSubject.next(result);
                this.localStorage.set(StorageKeys.Token, result.accessToken);
                this.localStorage.set(StorageKeys.CurrentAuth, JSON.stringify(result));
                this.startRefreshTokenTimer();
                return result;
            }));
    }



    public revokeToken(): void {
        this.http.post<unknown>(`${this.userBaseUrl}/revoke-token`, {}, { withCredentials: true }).subscribe();
    }

    private startRefreshTokenTimer() {
        const accessToken = this.localStorage.get(StorageKeys.Token);
        if (accessToken == null || accessToken == undefined) {
            this.stopRefreshTokenTimer();
        } else {
            const jwtToken = JSON.parse(atob(accessToken.split(".")[1]));
            // set a timeout to refresh the token a minute before it expires
            const expires = new Date(jwtToken.exp * 1000);
            const oneMinuteBeforeExpire = expires.getTime() - Date.now() - (60 * 1000);

            this.refreshTokenTimeout = setTimeout(() => this.refreshToken().subscribe(), oneMinuteBeforeExpire);
        }
    }

    private stopRefreshTokenTimer() {
        clearTimeout(this.refreshTokenTimeout);
    }
}

