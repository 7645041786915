<div class="forgot-password-area" [class.dark-theme]="themeService.isDark()">
    <div class="trezo-form mx-auto" [class.component-dark-theme]="themeService.isDark()"
        [class.component-rtl-enabled]="themeService.isRTLEnabled()">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12 order-2 order-lg-1">
                <div class="form-image">
                    <img src="images/forgot-password1.jpg" alt="forgot-password-image">
                    <br><br>
                    <br><br>
                    <br>
                </div>
            </div>
            <div class="col-lg-6 col-md-12 order-1 order-lg-2">
                <div class="form-content">
                    <div class="logo">
                        <img src="images/logo/FullLogo_Transparent.png" alt="logo">
                        <!-- <img src="images/logo-big.svg" alt="logo">
                        <img src="images/white-logo-big.svg" class="d-none" alt="logo"> -->
                    </div>
                    <div class="title" *ngIf="!isSuccess">
                        <h1 class="fw-semibold">
                            Forgot your password?
                        </h1>
                        <p class="fw-medium">
                            Enter the email address you used when you joined and we’ll send you instructions to reset
                            your password.
                        </p>
                    </div>
                    <form [formGroup]="forgotForm" (ngSubmit)="onSubmit()">
                        <div class="form-group" *ngIf="!isSuccess">
                            <label for="email" class="main-label d-block fw-medium text-black">
                                Email Address
                            </label>
                            <input type="text" class="form-control" placeholder="example&#64;pngaddress.com" id="input-email"
                                formControlName="email" [ngClass]="{ 'is-invalid': submitted && f['email'].errors }">

                            <div class="form-text text-danger"
                                *ngIf="f['email']!.hasError('required') && f['email']!.touched">
                                Email is required.
                            </div>
                            <div class="form-text text-danger"
                                *ngIf="f['email']!.hasError('email') && f['email']!.touched">
                                Please enter a valid email address.
                            </div>


                        </div>
                        <button *ngIf="!isSuccess" type="submit"
                            class="btn-box d-block w-100 border-0 transition fw-medium border-radius">
                            <span class="d-flex align-items-center justify-content-center">
                                <i class="material-symbols-outlined">
                                    autorenew
                                </i>
                                Reset Password
                            </span>
                        </button>
                        <div class="form-group mt-5" *ngIf="isSuccess">
                            <h4 class="link-success">Password Reset Email Sent Successfully</h4>
                            <p>
                                A password reset link has been sent to your email address. Please check your inbox (and
                                your spam folder, just in case) to follow the instructions and reset your password.
                            </p>
                            <p>
                                If you do not receive the email within a few minutes, please try again or contact
                                support for assistance.
                            </p>
                        </div>
                        <div class="form-group link-danger">
                            <span>{{error}}</span>
                        </div>
                        <p class="info mt-5">
                            Back to <a routerLink="" class="fw-semibold text-primary">Sign In</a>
                        </p>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>