import { Injectable } from "@angular/core";
import { HttpClient, HttpParams } from "@angular/common/http";
import { BehaviorSubject, Observable } from "rxjs";
import { environment } from "../../../../environments/environment";
import { BaseService } from "../../shared/services/base.service";
import { Suburb } from "../../../models/domain/suburb";
import { LoadingService } from "../../core/services/loading.service";

@Injectable({
    providedIn: "root"
})
export class SuburbService extends BaseService<Suburb> {
    private readonly rowCountSubject: BehaviorSubject<number>;
    public readonly rowCount$: Observable<number>;
    private http: HttpClient;

    constructor(http: HttpClient, loadingService: LoadingService) {
        super(http, `${environment.apiUrl}/v1/suburb`, loadingService);
        this.http = http;
        this.rowCountSubject = new BehaviorSubject<number>(0);
        this.rowCount$ = this.rowCountSubject.asObservable();
    }


    public GetSuburbByTown(townId: number): Observable<Suburb[]> {
        return this.httpClient.get<Suburb[]>(`${this.baseApiUrl}/${townId}/suburbsByTown`)
    }

    public GetSuburbByWard(wardId: number): Observable<Suburb[]> {
        return this.httpClient.get<Suburb[]>(`${this.baseApiUrl}/${wardId}/suburbsByWard`)
    }
}
