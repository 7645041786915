export class TableActionButton {
  public icon?: string;
  public title: string;
  public tooltip: string;
  public callback: (event: Event, row: any) => any;
  public disabled: (row: any) => boolean;

  constructor(
    icon = "",
    callback: (event: any, row: any) => void,
    disabled: (row: any) => boolean,
    title = '',
    tooltip = ''
  ) {
    this.icon = icon;
    this.title = title;
    this.tooltip = tooltip;
    this.callback = callback;
    this.disabled = disabled;
  }
}
