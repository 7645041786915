import { BaseRecord } from "./BaseRecord";
import { District } from "./district";


export class Llg extends BaseRecord<number> {
    public name: string;
    public districtId: number;
    public district: District | null;

    constructor() {
        super();
        this.id = 0;
        this.districtId = 0;
        this.name = "";
        this.district = new District;
    }
}
