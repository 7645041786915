import { Component, EventEmitter, Input, OnInit, Output, AfterViewInit, OnDestroy } from '@angular/core';
import { RouterLink } from '@angular/router';
import { NgClass, NgIf } from '@angular/common';
import { CustomizerSettingsService } from '../../../../../../customizer-settings/customizer-settings.service';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ContactService } from '../../../../services/contact.service';
import { Contact } from '../../../../../../models/domain/contact';
import { ObjectUtility } from '../../../../../core/utilities/ObjectUtility';
import { catchError, forkJoin, of, Subject, takeUntil } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { emailValidation, phoneFieldValidation, whitespaceValidator } from '../../../../../core/validators/ValidationService';
import { LoadingService } from '../../../../../core/services/loading.service';
import { Business } from '../../../../../../models/domain/business';
import { BaseSearchOptions } from '../../../../../../models/core/table/BaseSearchOptions';
import { BusinessService } from '../../../../services/business.service';

@Component({
  selector: 'app-contact-details',
  templateUrl: './contact-details.component.html',
  styleUrl: './contact-details.component.scss'
})
export class ContactDetailsComponent implements OnInit, AfterViewInit, OnDestroy {

  @Output() classApplied = new EventEmitter<boolean>();
  @Output() saveConfirmationEvent = new EventEmitter<boolean>();
  @Input() currentContactId = 0;
  // isToggled
  isToggled = false;
  frmGroup: FormGroup;

  private destroy$: Subject<boolean> = new Subject<boolean>();
  public contactOriginal = new Contact;
  public contact: Contact = new Contact;
  public titleText = "";
  public titleButton = "";
  public errorMessage = "";

  isSubmitted = false;

  public businesses: Business[];
  public pageIndex = 0;
  public pageSize = 1000;
  public searchOptions: BaseSearchOptions;

  constructor(
    public themeService: CustomizerSettingsService,
    private formBuilder: FormBuilder,
    private contactService: ContactService,
    private toasterService: ToastrService,
    private loadingService: LoadingService,
    private businessService: BusinessService,
  ) {
    this.initialize();
  }

  public ngOnInit(): void {
    if (this.currentContactId > 0) {
      this.getDropdownData();
      this.getContactById(this.currentContactId);
    }
    else {
      this.currentContactId = 0;
      this.getDropdownData();
      const newContact = new Contact();
      this.contact = ObjectUtility.deepClone(newContact);
      this.contactOriginal = ObjectUtility.deepClone(newContact);
      this.setForm(this.contact);
    }
  }

  private initialize(): void {
    this.themeService.isToggled$.subscribe(isToggled => {
      this.isToggled = isToggled;
    });
    this.isSubmitted = false;
    this.searchOptions = new BaseSearchOptions(undefined, 'name', 0, this.pageIndex, this.pageSize);
    this.createForm();
  }

  public ngAfterViewInit(): void {
    this.frmGroup.controls['businessControl'].valueChanges.subscribe(value => {
      this.contact.business = value;
    });
    this.frmGroup.controls['phoneControl'].valueChanges.subscribe(value => {
      this.contact.phone = value;
    });
    this.frmGroup.controls['mobileControl'].valueChanges.subscribe(value => {
      this.contact.mobile = value;
    });
    this.frmGroup.controls['emailControl'].valueChanges.subscribe(value => {
      this.contact.email = value;
    });
    this.frmGroup.controls['faxControl'].valueChanges.subscribe(value => {
      this.contact.fax = value;
    });
    this.frmGroup.controls['websiteControl'].valueChanges.subscribe(value => {
      this.contact.website = value;
    });
    this.frmGroup.controls['facebookControl'].valueChanges.subscribe(value => {
      this.contact.facebook = value;
    });


  }

  public ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  get f() { return this.frmGroup.controls; }

  private createForm(): void {
    this.frmGroup = this.formBuilder.group({
      businessControl: new FormControl(null, [Validators.required]),
      phoneControl: new FormControl('', [Validators.required, whitespaceValidator(), phoneFieldValidation()]),
      mobileControl: new FormControl('', [whitespaceValidator(), phoneFieldValidation()]),
      emailControl: new FormControl('', [Validators.required, whitespaceValidator(), emailValidation()]),
      faxControl: new FormControl('', [whitespaceValidator()]),
      websiteControl: new FormControl('', [whitespaceValidator()]),
      facebookControl: new FormControl('', [whitespaceValidator()])
    })
  }

  private setForm(contact: Contact): void {
    if (contact && contact.id != undefined && contact.id > 0) {
      this.frmGroup.patchValue({
        businessControl: contact?.business,
        phoneControl: contact.phone,
        mobileControl: contact.mobile,
        emailControl: contact.email,
        faxControl: contact.fax,
        websiteControl: contact.website,
        facebookControl: contact.facebook,
      });
    }
    this.titleText = `${this.currentContactId === 0 ? "Add" : "Edit"} Contact`;
    this.titleButton = `${this.currentContactId === 0 ? "Create" : "Update"}`;
  }

  public onSave(): void {
    this.isSubmitted = true;
    this.loadingService.showLoading();

    if (this.frmGroup.valid) {
      this.addEditContact();
    }
  }

  private addEditContact(): void {

    if (this.contact?.id === 0) {
      this.contactService.create(this.sanitizeContact(this.contact))
        .pipe(
          catchError((err: any) => {
            if (err && err.error) {
              this.isSubmitted = false;
              if (err.error.isCustom) {
                this.errorMessage = err.error.message;
                this.toasterService.warning(err.error.message);
              } else {
                this.toasterService.error('Something went wrong!');
              }
            } else {
              this.isSubmitted = false;
              this.toasterService.error('Something went wrong!');
            }

            return of(null);
          }),
          takeUntil(this.destroy$))
        .subscribe(response => {
          if (response) {
            this.isSubmitted = false;
            this.contact = response?.body != null ? response?.body : new Contact;
            this.saveConfirmationEvent.emit(true);
            this.toggleClass();
            this.toasterService.success("Save successfully");
          }
        });
    } else {
      this.contactService.update(this.sanitizeContact(this.contact))
        .pipe(
          catchError((err: any) => {
            if (err && err.error) {
              this.isSubmitted = false;
              if (err.error.isCustom) {
                this.errorMessage = err.error.message;
                this.toasterService.warning(err.error.message);
              } else {
                this.toasterService.error('Something went wrong!');
              }
            } else {
              this.isSubmitted = false;
              this.toasterService.error('Something went wrong!');
            }
            return of(null);
          }),
          takeUntil(this.destroy$))
        .subscribe(response => {
          if (response) {
            this.isSubmitted = false;
            this.contact = response?.body != null ? response?.body : new Contact;
            this.saveConfirmationEvent.emit(true);
            this.toasterService.success("Update successfully");
            this.toggleClass();
          }
        });
    }
  }


  private getDropdownData() {
    const business = this.businessService.get(this.searchOptions);
    this.loadingService.showLoading();
    forkJoin([business])
      .pipe(takeUntil(this.destroy$)).subscribe((result: any) => {
        if (result) {
          this.businesses = result[0].body.values;
        }
        this.loadingService.hideLoading();
      });
  }

  private sanitizeContact(contact: Contact): Contact {
    contact.business = null;
    contact.businessId = this.frmGroup.controls['businessControl'].value.id;

    return contact;
  }

  public businessComparer(t1: Business, t2: Business): boolean {
    return t1 && t2 ? (t1.id === t2.id || t1.name === t2.name) : t1 === t2;
  }

  public toggleClass(): void {
    this.classApplied.emit(false);
  }

  public dataHasChanged(): boolean {
    const baseEqual = ObjectUtility.deepEqual(this.contact, this.contactOriginal);
    return !baseEqual;
  }

  public formIsValid(): boolean {
    const isValid = this.frmGroup.controls['businessControl'].valid
      && this.frmGroup.controls['phoneControl'].valid
      && this.frmGroup.controls['mobileControl'].valid
      && this.frmGroup.controls['emailControl'].valid
      && this.frmGroup.controls['faxControl'].valid
      && this.frmGroup.controls['websiteControl'].valid
      && this.frmGroup.controls['facebookControl'].valid
    return isValid;
  }

  public getContactById(contactId: number) {
    this.loadingService.showLoading();
    this.contactService.getById(contactId)
      .pipe(takeUntil(this.destroy$))
      .subscribe(result => {
        if (result) {
          this.contact = ObjectUtility.deepClone(result);
          this.contactOriginal = ObjectUtility.deepClone(result);
          this.setForm(this.contact);
        }
        this.loadingService.hideLoading();
      });
  }




}