import { BaseRecord } from "./BaseRecord";
import { Suburb } from "./suburb";


export class StreetRoad extends BaseRecord<number> {
    public name: string | undefined;
    public suburbId: number;
    public suburb: Suburb | null;
    public streetOrRoad: string;

    constructor() {
        super();
        this.id = 0;
        this.suburbId = 0;
        this.name = "";
        this.suburb = new Suburb;
        this.streetOrRoad = "";
    }
}
