import { Component } from '@angular/core';
import { ViewportScroller } from '@angular/common';
import { Router, Event, NavigationEnd } from '@angular/router';
import { ModalService } from './modules/core/services/modal.service';
import { LoadingService } from './modules/core/services/loading.service';

@Component({
    selector: 'app-root',
    //standalone: true,
    //imports: [RouterOutlet, CommonModule],
    templateUrl: './app.component.html',
    styleUrl: './app.component.scss'
})
export class AppComponent {

    title = 'Trezo';

    constructor(
        private router: Router,
        private viewportScroller: ViewportScroller,
        public modalService: ModalService,
        public loadingService: LoadingService
    ) {
        this.router.events.subscribe((event: Event) => {
            if (event instanceof NavigationEnd) {
                // Scroll to the top after each navigation end
                this.viewportScroller.scrollToPosition([0, 0]);
            }
        });
    }

}