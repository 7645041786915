import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { RouterLink } from '@angular/router';
import { NgClass, NgIf } from '@angular/common';
import { CustomizerSettingsService } from '../../../../../customizer-settings/customizer-settings.service';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ServiceService } from '../../../services/service.service';
import { Service } from '../../../../../models/domain/service';
import { ObjectUtility } from '../../../../core/utilities/ObjectUtility';
import { catchError, forkJoin, of, Subject, takeUntil } from 'rxjs';
import { ToasterService } from '../../../../core/services/toaster.service';
import { whitespaceValidator } from '../../../../core/validators/ValidationService';
import { LoadingService } from '../../../../core/services/loading.service';
import { ServiceType } from '../../../../../models/domain/serviceType';
import { ServiceTypeService } from '../../../services/serviceType.service';
import { BaseSearchOptions } from '../../../../../models/core/table/BaseSearchOptions';

@Component({
  selector: 'app-service-details',
  templateUrl: './service-details.component.html',
  styleUrl: './service-details.component.scss'
})
export class ServiceDetailsComponent implements OnInit {

  @Output() classApplied = new EventEmitter<boolean>();
  @Output() saveConfirmationEvent = new EventEmitter<boolean>();
  @Input() data!: Service;
  // isToggled
  isToggled = false;
  frmGroup: FormGroup;

  private destroy$: Subject<boolean> = new Subject<boolean>();
  public serviceOriginal = new Service;
  public service: Service = new Service;
  public titleText = "";
  public titleButton = "";
  public errorMessage = "";
  public currentServiceId: number = 0;
  isSubmitted = false;

  //dropdown DataBind
  public serviceTypes: ServiceType[];
  public pageIndex: number = 0;
  public pageSize: number = 1000;
  public searchOptions: BaseSearchOptions;

  constructor(
    public themeService: CustomizerSettingsService,
    private formBuilder: FormBuilder,
    private serviceService: ServiceService,
    private serviceTypeService: ServiceTypeService,
    private toasterService: ToasterService,
    private loadingService: LoadingService
  ) {
    this.initialize();
  }

  public ngOnInit(): void {
    if (this.data) {
      this.currentServiceId = this.data.id ? this.data.id : 0;
      this.getDropdownData();
      this.service = ObjectUtility.deepClone(this.data);
      this.serviceOriginal = ObjectUtility.deepClone(this.data);
      this.setForm(this.service);
    }
    else {
      this.currentServiceId = 0;
      this.getDropdownData();
      const newService = new Service();
      this.service = ObjectUtility.deepClone(newService);
      this.serviceOriginal = ObjectUtility.deepClone(newService);
      this.setForm(this.service);
    }
  }

  private initialize(): void {
    this.themeService.isToggled$.subscribe(isToggled => {
      this.isToggled = isToggled;
    });
    this.isSubmitted = false;
    this.searchOptions = new BaseSearchOptions(undefined, undefined, 0, this.pageIndex, this.pageSize);
    this.createForm();
  }

  public ngAfterViewInit(): void {

    this.frmGroup.controls['nameControl'].valueChanges.subscribe(value => {
      this.service.name = value;
    });
    this.frmGroup.controls['descriptionControl'].valueChanges.subscribe(value => {
      this.service.description = value;
    });
    this.frmGroup.controls['serviceTypeControl'].valueChanges.subscribe(value => {
      this.service.serviceType = value;
    });


  }

  public ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  get f() { return this.frmGroup.controls; }

  private createForm(): void {
    this.frmGroup = this.formBuilder.group({
      nameControl: new FormControl('', [Validators.required, whitespaceValidator()]),
      descriptionControl: new FormControl('', [whitespaceValidator()]),
      serviceTypeControl: new FormControl('', [Validators.required]),
    })
  }

  private setForm(service: Service): void {
    if (service) {
      this.frmGroup.patchValue({
        nameControl: service.name,
        descriptionControl: service.description,
        serviceTypeControl: service?.serviceType,
      });
    }
    this.titleText = `${this.currentServiceId === 0 ? "Add" : "Edit"} Service`;
    this.titleButton = `${this.currentServiceId === 0 ? "Create" : "Update"}`;
  }

  public onSave(): boolean {
    this.isSubmitted = true;
    this.loadingService.start(true);

    if (this.frmGroup.valid) {
      this.addEditService();
      return true;
    }
    else {
      this.loadingService.start(false);
      return false;
    }
  }

  private addEditService(): void {

    if (this.service?.id === 0) {
      this.serviceService.create(this.sanitizeService(this.service))
        .pipe(
          catchError((err: any) => {

            if (err && err.error) {
              this.loadingService.start(false);
              this.isSubmitted = false;
              if (err.error.isCustom) {
                this.errorMessage = err.error.message;
                this.toasterService.warning(err.error.message);
              } else {
                this.toasterService.error('Something went wrong!');
              }
              //this.toasterService.error(err.error.mesage);
            } else {
              this.loadingService.start(false);
              this.isSubmitted = false;
              this.toasterService.error('Something went wrong!');
            }

            return of(null);
          }),
          takeUntil(this.destroy$))
        .subscribe(response => {
          if (response) {
            this.isSubmitted = false;
            this.service = response?.body != null ? response?.body : new Service;
            this.saveConfirmationEvent.emit(true);
            this.toggleClass();
            this.toasterService.success("Save successfully");
            // close popup
            this.loadingService.start(false);
          }
        });
    } else {
      this.serviceService.update(this.sanitizeService(this.service))
        .pipe(
          catchError((err: any) => {
            if (err && err.error) {
              this.loadingService.start(false);
              this.isSubmitted = false;
              if (err.error.isCustom) {
                this.errorMessage = err.error.message;
                this.toasterService.warning(err.error.message);
              } else {
                this.toasterService.error('Something went wrong!');
              }
              //this.toasterService.error(err.error.mesage);
            } else {
              this.loadingService.start(false);
              this.isSubmitted = false;
              this.toasterService.error('Something went wrong!');
            }
            return of(null);
          }),
          takeUntil(this.destroy$))
        .subscribe(response => {
          if (response) {
            this.isSubmitted = false;
            this.service = response?.body != null ? response?.body : new Service;
            this.saveConfirmationEvent.emit(true);
            this.toasterService.success("Update successfully");
            this.toggleClass();
            this.loadingService.start(false);
          }
        });
    }
  }


  public toggleClass(): void {
    this.classApplied.emit(false);
  }

  private getDropdownData() {
    this.loadingService.start(true);
    const serviceType = this.serviceTypeService.get(this.searchOptions);

    forkJoin([serviceType])
      .pipe(takeUntil(this.destroy$)).subscribe((result: any) => {
        if (result) {
          this.serviceTypes = result[0].body.values;
        }

        this.loadingService.start(false);
      });
  }

  private sanitizeService(service: Service): Service {
    service.serviceType = null;
    service.serviceTypeId = this.frmGroup.controls['serviceTypeControl'].value.id;

    return service;
  }

  public serviceTypeComparer(t1: ServiceType, t2: ServiceType): boolean {
    return t1 && t2 ? (t1.id === t2.id || t1.name === t2.name) : t1 === t2;
  }


}